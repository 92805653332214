import React from 'react';
import { MenuItem } from "@material-ui/core";

export const ntakFokategoria = [
    {value: null, label: "Nincs"},
    {value: "ETEL", label: "Étel"},
    {value: "ALKMENTESITAL_HELYBEN", label: "Alkoholmentes ital helyben készített"},
    {value: "ALKMENTESITAL_NEM_HELYBEN", label: "Alkoholmentes ital"},
    {value: "ALKOHOLOSITAL", label: "Alkoholos ital"},
    {value: "EGYEB", label: "Egyéb"},
];

export const ntakNincsFokategoria = [
    {value: null, label: "Nincs"},
];

export const ntakEtelAlketegoria = [
    {value: null, label: "Nincs"},
    {value: "REGGELI", label: "Reggeli"},
    {value: "SZENDVICS", label: "Szendvics"},
    {value: "ELOETEL", label: "Előétel"},
    {value: "LEVES", label: "Leves"},
    {value: "FOETEL", label: "Főétel"},
    {value: "KORET", label: "Köret"},
    {value: "SAVANYUSAG_SALATA", label: "Savanyúság / saláta"},
    {value: "KOSTOLO", label: "Kóstolóétel, kóstolófalat"},
    {value: "PEKSUTEMENY", label: "Péksütemény, pékáru"},
    {value: "DESSZERT", label: "Desszert"},
    {value: "SNACK", label: "Snack"},
    {value: "FOETEL_KORETTEL", label: "Főétel körettel"},
    {value: "ETELCSOMAG", label: "Ételcsomag"},
    {value: "EGYEB", label: "Egyéb"},
];

export const ntakAlkoholmentesItalHelybenAlketegoria = [
    {value: null, label: "Nincs"},
    {value: "VIZ", label: "Víz"},
    {value: "LIMONADE_SZORP_FACSART", label: "Limonádé / Szörp / Frissen facsart ital"},
    {value: "ALKOHOLMENTES_KOKTEL", label: "Alkoholmentes koktél, kevert ital"},
    {value: "TEA_FORROCSOKOLADE", label: "Tea / Forrócsokoládé"},
    {value: "ITALCSOMAG", label: "Italcsomag (helyben készített alkoholmentes italokból)"},
    {value: "KAVE", label: "Kávé"},
];

export const ntakAlkoholmentesItalNemHelybenAlketegoria = [
    {value: null, label: "Nincs"},
    {value: "VIZ", label: "Víz"},
    {value: "ROSTOS_UDITO", label: "Rostos üditő"},
    {value: "SZENSAVAS_UDITO", label: "Szénsavas üditő"},
    {value: "SZENSAVMENTES_UDITO", label: "Szénsavasmentes üditő"},
    {value: "ITALCSOMAG", label: "Italcsomag (nem helyben készített alkoholmentes italokból)"},
];

export const ntakAlkoholosItalAlketegoria = [
    {value: null, label: "Nincs"},
    {value: "KOKTEL", label: "Koktél"},
    {value: "LIKOR", label: "Likőr"},
    {value: "PARLAT", label: "Párlat"},
    {value: "SOR", label: "Sör"},
    {value: "BOR", label: "Bor"},
    {value: "PEZSGO", label: "Pezsgő"},
    {value: "ITALCSOMAG", label: "Italcsomag (alkoholos italokból)"},
];

export const ntakEgyebAlketegoria = [
    {value: null, label: "Nincs"},
    {value: "EGYEB", label: "Egyéb"},
    {value: "SZERVIZDIJ", label: "Szervizdíj"},
    {value: "BORRAVALO", label: "Borravaló"},
    {value: "KISZALLITASI_DIJ", label: "Kiszállítási díj"},
    {value: "NEM_VENDEGLATAS", label: "Nem vendéglátás"},
    {value: "KORNYEZETBARAT_CSOMAGOLAS", label: "Környezetbarát csomagolás"},
    {value: "MUANYAG_CSOMAGOLAS", label: "Műanyag csomagolás"},
    {value: "KEDVEZMENY", label: "Kedvezmény"},
];

export const ntakMennyisegiEgyseg = [
    {value: null, label: "Nincs"},
    {value: "DARAB", label: "Darab"},
    {value: "LITER", label: "Liter"},
    {value: "KILOGRAMM", label: "Kilógramm"},
    {value: "EGYSEG", label: "Egység"},
];

export function getNTAKAlkategoria(ntakFokategoria){
    switch(ntakFokategoria){
        case "ETEL":
            return ntakEtelAlketegoria
        case "ALKMENTESITAL_HELYBEN":
            return ntakAlkoholmentesItalHelybenAlketegoria
        case "ALKMENTESITAL_NEM_HELYBEN":
            return ntakAlkoholmentesItalNemHelybenAlketegoria
        case "ALKOHOLOSITAL":
            return ntakAlkoholosItalAlketegoria
        case "EGYEB":
            return ntakEgyebAlketegoria
        default:
            return ntakNincsFokategoria
    }
}

export function getNTAKAlkategoriaMenuItems(ntakFokategoria){
    return getNTAKAlkategoria(ntakFokategoria).map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
}

export const ntakDataSending = true;
