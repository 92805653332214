import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import EditCustomer from "./EditCustomer";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";

export default class CustomerListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            keyword: "",
            customers: [],
            count: 0,
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            isSuperAdmin: false,
        }
    }

    componentDidMount() {
        this.setState({isSuperAdmin: sessionStorage.getItem("extended_role") === "ROLE_SUPER_ADMIN"})
        this.refreshData()
    }

    refreshData = () => {
        axiosCommon(`${BASE_URL}/api/users?deleted=false&role=CUSTOMER&keyword=${this.state.keyword}`)
            .then(response => {
                this.setState({
                    customers: response.data.items,
                })
            })
            .catch(error => {
                this.setState({error})
            })
    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/users/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({error})
            });
        this.setState({
            deleteInProgress: null,
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/admin/people/editcustomers",
            userId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/admin/people/editcustomers",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd az Ügyfelet?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés a névben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Ügyfelek
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                  <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell>Cím</TableCell>
                                    <TableCell>Adószám</TableCell>
                                    <TableCell>Üzletek</TableCell>
                                    <TableCell>Havidíj</TableCell>
                                    <TableCell>Összesen</TableCell>
                                    <TableCell>Állapot</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.customers
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((u, i) => {
                                            return <TableRow key={u.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(u.id)}>
                                                <TableCell>{`${u.lastName} ${u.firstName}`}</TableCell>
                                                <TableCell>{`${u.address.zipCode} ${u.address.city} ${u.address.street}`}</TableCell>
                                                <TableCell>{u.companyData.taxNumber}</TableCell>
                                                <TableCell>{u.shops.length} db</TableCell>
                                                <TableCell>{u.monthlyFee} Ft</TableCell>
                                                <TableCell>{u.shops.length * u.monthlyFee} Ft</TableCell>
                                                <TableCell>{u.enabled ? "Aktív" : "Szünetel"}</TableCell>
                                                <TableCell padding="none" align="right">
                                                    {this.state.isSuperAdmin
                                                        ?<IconButton aria-label="delete" onClick={() => this.initiateDeletion(u)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        : undefined
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.customers.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Ügyfél"
                onClick={this.initiateCreate}/>
        </>;
    }
}
