
const Permissions = {
    STATISTICS: {label: "Kimutatások", value: "STATISTICS", tooltip: "A Webirodában az összefoglaló és a kimutatások oldalakhoz ad hozzáférést"},
    INVENTORY_REFILL: {label: "Bevételezés", value: "INVENTORY_REFILL", tooltip: "A webirodában és a terminálon a raktárkészlet kezelés bevételezés funkcióhoz ad hozzáférést."},
    INVENTORY_CHARGE: {label: "Kivételezés", value: "INVENTORY_CHARGE", tooltip: "A webirodában és a terminálon a raktárkészlet kezelés kivételezés funkcióhoz ad hozzáférést."},
    INVENTORY_PRODUCE: {label: "Előállítás", value: "INVENTORY_PRODUCE", tooltip: "A webirodában és a terminálon a raktárkészlet kezelés előállítás funkcióhoz ad hozzáférést."},
    INVENTORY_WASTE: {label: "Selejtezés", value: "INVENTORY_WASTE", tooltip: "A webirodában és a terminálon a raktárkészlet kezelés selejtezés funkcióhoz ad hozzáférést."},
    INVENTORY_CHECK: {label: "Leltározás", value: "INVENTORY_CHECK", tooltip: "A webirodában és a terminálon a raktárkészlet kezelés leltározás funkcióhoz ad hozzáférést."},
    PRODUCTS: {label: "Termékek", value: "PRODUCTS", tooltip: "A webirodában és a terminálon a termékek és kategóriák szerkesztéséhez ad hozzáférést. Továbbá a webirodában az üzletenkénti eltérések szerkesztését teszi lehetővé."},
    DISCOUNTS: {label: "Kedvezmények", value: "DISCOUNTS", tooltip: "A webirodában a kedvezmények szerkesztéséhez, a terminálon a manuális kedvezmények adásához ad hozzáférést."},
    CONSUMERS: {label: "Vásárlók", value: "CONSUMERS", tooltip: "A webirodában és a terminálon a vásárlók szerkesztéséhez ad hozzáférést. Továbbá a webirodában a törzsvásárlói típusok szerkesztését teszi lehetővé."},
    STAFF: {label: "Személyzet", value: "STAFF", tooltip: "A webirodában a személyzet és a személyzeti szerepkörök szerkesztéséhez ad hozzáférést."},
    SHOPS: {label: "Üzletek", value: "SHOPS", tooltip: "A webirodában az üzlet tulajdonságainak (pl.: szervizdíj, számlázás, fizetési módok) szerkesztéséhez ad hozzáférést. Továbbá a futárok szerkesztését teszi lehetővé."},
    TABLES: {label: "Asztalok", value: "TABLES", tooltip: "A webirodában és a terminálon az asztalok és az asztaltérkép szerkesztéséhez ad hozzáférést."},
    CASH_OPERATIONS: {label: "Be- és Kifizetések", value: "CASH_OPERATIONS", tooltip: "A webirodában a be- és kifizetések szerkesztéséhez ad hozzáférést."},
    SELL: {label: "Eladás", value: "SELL", tooltip: "A terminálon az eladáshoz, rendelések felvételéhez, rendelések asztalok közötti mozgatásához, borravaló kezeléshez, nap nyitás/záráshoz, eladások menühoz ad hozzáférést. Adóügyi nyomtatónál a napnyitáshoz, napzáráshoz, pénz be, pénz ki, fizetési eszköz cseréhez ad hozzáférést."},
    CHANGE_PAYMENT_METHOD: {label: "Fizetési mód csere", value: "CHANGE_PAYMENT_METHOD", tooltip: "A terminálon a kifizetett rendelések fizetési módjának cseréjéhez ad hozzáférést."},
    BILLING: {label: "Áfás számla kiállítás", value: "BILLING", tooltip: "A terminálon az ÁFÁs számla generáláshoz ad hozzáférést."},
    RETURNS: {label: "Sztornózás", value: "RETURNS", tooltip: "A teminálon a sztornózás funkcióhoz ad hozzáférést. A sztornózás előfeltétele a \"Terminál eladási adatok\" jogosultság megléte."},
    DELETE_TAKEN_ORDERS: {label: "Felvett tételek törlése", value: "DELETE_TAKEN_ORDERS", tooltip: "A terminálon az asztalhoz/vásárlóhoz/személyzethez felvett tételek törlése funkcióhoz ad hozzáférést."},
    TERMINAL_SERVICE_FEE: {label: "Terminál szervizdíj módosítása", value: "TERMINAL_SERVICE_FEE", tooltip: "A terminálon a szervizdíj eladásonkénti módosításához ad hozzáférést."},
    TERMINAL_CLOSING_DATA: {label: "Terminál zárás adatok", value: "TERMINAL_CLOSING_DATA", tooltip: "A terminálon a zárás adatainak lekérdezéséhez ad hozzáférést. Adóügyi nyomtatónál az X és Z riportok nyomtatásához ad hozzáférést."},
    TERMINAL_SELLING_DATA: {label: "Terminál eladási adatok", value: "TERMINAL_SELLING_DATA", tooltip: "A terminálon az eladások menüponthoz ad hozzáférést."},
    TERMINAL_SETTINGS: {label: "Terminál beállítások", value: "TERMINAL_SETTINGS", tooltip: "A terminálon a beállításokhoz ad hozzáférést."},
    DELETE_DATA: {label: "Forgalmi adatok törlése", value: "DELETE_DATA", tooltip: "A Beállítások menüpontban a forgalmi adatok törléséhez ad hozzáférést."},
};

export default Permissions;
