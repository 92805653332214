import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import {
    Paper,
    TextField,
} from "@material-ui/core";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import moment from "moment";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatDateTime from "../../../util/formatDateTime";
import formatNumber from "../../../util/formatNumber";

export default class WorkingDayListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            workingDays: [],
            shopOptions: [],
            selectedShopOption: null,
            fromDate: moment().subtract(7, 'days').toDate(),
            toDate: moment().toDate(),
            error: null,
            columns: [
                {field: 'date', headerName: 'Tárgynap', width: 110},
                {field: 'status', headerName: 'Státusz', align: 'center', headerAlign: 'center', width: 110,},
                {field: 'openingTime', headerName: 'Nyitási idő', align: 'center', headerAlign: 'center', width: 170,},
                {field: 'openingUser', headerName: 'Nyitást végző', align: 'center', headerAlign: 'center', width: 180,},
                // todo ideiglenes kikapcsolva
                // {field: 'openingBudget', headerName: 'Induló Kp.(Ft)', align: 'center', headerAlign: 'center', width: 130,},
                {field: 'closingTime', headerName: 'Zárás idő', align: 'center', headerAlign: 'center', width: 170},
                {field: 'closingUser', headerName: 'Zárást végző', align: 'center', headerAlign: 'center', width: 150,},
                {field: 'totalTip', headerName: 'Borravaló (Ft)', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'totalServiceFee', headerName: 'Szervizdíj (Ft)', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'totalIncome', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
            ],
            rows: [],
            isLoading: true,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(c => {return {label: c.name, value: c.id}});
                const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;;

                this.setState({
                    shopOptions,
                    selectedShopOption,
                }, () => this.refreshData())
            })
    }

    refreshData = () => {
        const queryParams = {}

        if (this.state.selectedShopOption) {
            queryParams.shopId = this.state.selectedShopOption.value;
        }

        if (this.state.fromDate) {
            queryParams.fromDateTime = moment(this.state.fromDate).format("YYYY-MM-DD");
        }

        if (this.state.toDate) {
            queryParams.toDateTime = moment(this.state.toDate).format("YYYY-MM-DD");
        }

        axiosCommon.get(`${BASE_URL}/api/workingday`, { params: queryParams })
        .then(response => {
            const workingDays = response.data.items

            const rows = workingDays.map((w) => {
                if(w.status === 'DIDNT_OPEN'){
                    return {
                        id: w.id,
                        date: w.subjectDay,
                        openingTime: "-",
                        status: "Végig zárva",
                        openingUser: "-",
                        // todo ideiglenes kikapcsolva
                        // openingBudget: w.openingBudget,
                        closingTime: "-",
                        closingUser: "-",
                        totalTip: w.totalTip,
                        totalServiceFee: w.totalServiceFee,
                        totalIncome: w.totalIncome,
                    }
                }

                return {
                    id: w.id,
                    date: w.subjectDay,
                    openingTime: w.openingTime ? formatDateTime(w.openingTime) : "",
                    status: this.formatWorkingDayStatus(w.status),
                    openingUser: w.openingUser ? `${w.openingUser.lastName} ${w.openingUser.firstName}` : "",
                    // todo ideiglenes kikapcsolva
                    // openingBudget: w.openingBudget,
                    closingTime: w.status === 'CLOSED' ? formatDateTime(w.closingTime) : "",
                    closingUser:  w.status === 'CLOSED' ? (w.closingUser ? `${w.closingUser.lastName} ${w.closingUser.firstName}` : "Rendszer") : "",
                    totalTip: w.totalTip,
                    totalServiceFee: w.totalServiceFee,
                    totalIncome: w.totalIncome,
                }
            });

            this.setState({
                workingDays,
                rows,
                isLoading: false,
            })
        })
        .catch(error => {
            this.setState({error, isLoading: false,})
        })
    };

    formatWorkingDayStatus = (status) => {
        switch (status){
            case "OPEN":
                return "Nyitva"
            case "CLOSED":
                return "Lezárva"
            default:
                return ""
        }
    }

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (entry) => {
        this.setState({ rowsPerPage: parseInt(entry, 10)});
    };

    renderList = () => {
        return <>
            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés dátum alapján">
                    <StyledDateRangePicker
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        fromDateChange={(date) =>{
                            this.setState({fromDate: date})
                        }}
                        toDateChange={(date) => {
                            this.setState({toDate: date}, () => this.refreshData())
                        }}
                    />
                </Card>
            </Widget>

            <Paper>
                <DataGrid
                    columns={this.state.columns}
                    rows={this.state.rows}
                    className="cursor-pointer"
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight
                    onPageSizeChange={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={RowsPerPageOptions.items}
                    pageSize={this.state.rowsPerPage}
                    pagination
                    loading={this.state.isLoading}
                    components={{
                        Toolbar: () => {
                            return (
                                <GridToolbarContainer>
                                    <GridToolbarExport/>
                                </GridToolbarContainer>
                            );
                        },
                        NoRowsOverlay: NoData,
                    }}/>
            </Paper>
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
