import React from "react";
import "../../../css/Pages.css"
import EditProfile from "../../../components/EditProfile";
import Footer from "../../../components/Footer";
import NavHeader from "../../../components/NavHeader";

export default function EditProfilePage(props) {
    return <section className="main-content">
        <section className="main-content-body">
            <NavHeader changeSidebarToggled={props.changeSidebarToggled}/>
            <EditProfile profileEdit/>
        </section>

        <Footer/>
    </section>;
};
