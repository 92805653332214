import React from "react";
import Footer from "../../../components/Footer";
import {
    Paper, Toolbar, Typography,
} from "@material-ui/core";
import NavHeader from "../../../components/NavHeader";

export default class TerminalDownloadPage extends React.Component {

    renderList = () => {
        return <Paper>
            <Toolbar>
                <Typography variant="h5" id="tableTitle" component="div">
                    Terminál SW
                </Typography>
            </Toolbar>
            <div style={{paddingLeft: 15, paddingTop: 10}}>
                <Typography>A terminál szoftver legfrissebb verziót le tudod tölteni a Google Play-ről. A letöltéshez kattints az ikonra.</Typography>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:'center', marginTop: 20}}>
                <a target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=bythepay.ui&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style={{height: 110, width: 300}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </div>
        </Paper>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
