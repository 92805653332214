import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getItem,
    removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import TablePagination from "@material-ui/core/TablePagination";
import Search from "../../../components/Search";

export default class DeliveryPersonListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            deliveryPersonNames: [],
            shopOptions: [],
            selectedShopOption: null,
            deleteInProgress: null,
            error: null,
            preSavedShopOption: undefined,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            keyword: "",
        };
        this.selectedShopKey = 'deliveryPersonListPageSelectedShop'
    }

    componentDidMount() {
        this.handleNavigation()

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items
                const selectedShopOption = this.getSelectedShop(shopOptions);
                this.setState({ shopOptions, selectedShopOption}, () => this.filterDeliveryPersonNames())
            })
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedShopKey, this.state.selectedShopOption.id);
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const preSavedShopOption = getItem(this.selectedShopKey)
            this.setState({ preSavedShopOption })
        }else{
            removeSessionKeys([this.selectedShopKey])
        }
    }

    getSelectedShop = (shopOptions) => {
        const ShopId = this.state.preSavedShopOption

        if(ShopId !== undefined){
            return shopOptions.find(shop => shop.id == ShopId)
        }

        return shopOptions.length > 0 ? shopOptions[0] : null;
    }

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value }, () => this.filterDeliveryPersonNames())
    };

    refreshData = () => {
        axiosCommon.get(`${BASE_URL}/api/shops`)
        .then(response => {
            const shopOptions = response.data.items
            let {selectedShopOption} = this.state
            shopOptions.forEach(shop => {
                if(shop.id == selectedShopOption.id) selectedShopOption = shop
            })
            this.setState({ shopOptions, selectedShopOption }, () => this.filterDeliveryPersonNames())
        })
    };

    initiateDeletion = (name) => {
        this.setState({ deleteInProgress: name })
    };

    rejectDeletion = () => {
        this.setState({ deleteInProgress: null })
    };

    confirmDeletion = () => {
        const {selectedShopOption} = this.state
        const nameToDelete = this.state.deleteInProgress

        const data = selectedShopOption.deliveryPersonNames.filter(item => item !== nameToDelete)
        const params = {shopId: selectedShopOption.id}

        axiosCommon.put(`${BASE_URL}/api/shops/deliveryperson`, data, {params})
        .then(response => {
            this.setState({ deleteInProgress: null }, () => this.refreshData())
        }).catch(error => {
            this.setState({error, deleteInProgress: null});
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/shops/editdeliveryperson",
            shopId: this.state.selectedShopOption.id,
        });
    };

    handleChangePage = (event, newPage) => this.setState({ page: newPage })

    handleChangeRowsPerPage = (event) => this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })

    filterDeliveryPersonNames = () => {
        const {selectedShopOption, keyword} = this.state
        const deliveryPersonNames = keyword !== ""
            ? selectedShopOption.deliveryPersonNames.filter(name => name.toLowerCase().includes(keyword.toLowerCase()))
            : selectedShopOption.deliveryPersonNames
        this.setState({ deliveryPersonNames })
    }

    setKeyword = (event) => this.setState({ keyword: event.target.value, page: 0, }, () => this.filterDeliveryPersonNames())

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a futárt?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderList = () => {
        return <>
            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => {
                            if(option === "") return ""
                            return option.name === "" ? "" : option.name}}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Keresés a névben">
                    <Search
                        setKeyword={this.setKeyword}
                        refreshData={this.filterDeliveryPersonNames}
                        value={this.state.keyword}
                    />
                </Card>
            </Widget>

            <Paper>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Futárok
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">Név</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.deliveryPersonNames != null
                                ? this.state.deliveryPersonNames
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((c, i) => {
                                    return <TableRow key={i} hover className="cursor-pointer">
                                        <TableCell>{c}</TableCell>
                                        <TableCell padding="none" align="right">
                                            <IconButton aria-label="delete" onClick={() => this.initiateDeletion(c)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                })
                                : undefined
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.deliveryPersonNames?.length || 0}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>

            {this.renderDeleteDialog()}
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Futár"
                onClick={this.initiateCreate}/>
        </>;
    }
}
