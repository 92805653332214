import React from "react";
import "../../../css/Pages.css"
import InventoryChangeList from "./InventoryChangeList";

export default function CustomerInventoryRefillPage(props) {
    return <InventoryChangeList {...props}
            kind="REFILL"
            pageKey="inventoryRefillPagePageKey"
            rowsPerPageKey="inventoryRefillPageRowsPerPageKey"
            keywordKey="inventoryRefillPageKeywordKey"
            fromDateKey="inventoryRefillPageFromDateKey"
            toDateKey="inventoryRefillPageToDateKey"
            selectedShopsKey="inventoryRefillPageSelectedShopsKey"
    />;
};
