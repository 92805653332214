import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Paper,
    TextField, Toolbar,
    Tooltip, Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import moment from "moment";
import formatNumber from "../../../util/formatNumber";
import formatDateTime from "../../../util/formatDateTime";
import formatPaymentType from "../../../util/formatPaymentType";
import ShowCarts from "../ShowCarts";
import WarningIcon from '@material-ui/icons/Warning';
import { AiFillWarning } from "react-icons/ai";
import HighlightCard from "../../../components/HighlightCard";
import { Alert } from "@material-ui/lab";

export default class NtakErros extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            carts: [],
            workingDays: [],
            processes: [],
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            error: null,
            showNtakErrorDialog: false,
            ntakError: null,

            cartRowsPerPage: RowsPerPageOptions.defaultPageSize,
            cartPage: 0,
            cartRowCount: 0,
            cartColumns: [
                {field: 'date', headerName: 'Dátum', width: 160},
                {field: 'shop', headerName: 'Üzlet', align: 'center', headerAlign: 'center', width: 160},
                {field: 'seller', headerName: 'Eladó', align: 'center', headerAlign: 'center', width: 220},
                {field: 'paymentType', headerName: 'Fizetési mód', align: 'center', headerAlign: 'center', width: 150},
                {field: 'value', headerName: 'Összeg (Ft)', align: 'center', headerAlign: 'center', width: 130, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'ntakState', headerName: 'NTAK Státusz', align: 'center', headerAlign: 'center', width: 200, valueFormatter: this.formatStatus},
                {field: 'error', headerName: 'Hiba', align: 'center', headerAlign: 'center', width: 130, renderCell: this.renderError},
                {field: 'action', headerName: 'Művelet', align: 'center', headerAlign: 'center', width: 160, renderCell: this.renderCartAction},],
            cartRows: [],
            cartResending: [],
            isLoadingCart: false,

            workingDayRowsPerPage: RowsPerPageOptions.defaultPageSize,
            workingDayPage: 0,
            workingDayRowCount: 0,
            workingDayRows: [],
            workingDayColumns: [
                {field: 'date', headerName: 'Tárgynap', width: 160},
                {field: 'shop', headerName: 'Üzlet', align: 'center', headerAlign: 'center', width: 160},
                {field: 'state', headerName: 'Állapot', align: 'center', headerAlign: 'center', width: 220},
                {field: 'ntakState', headerName: 'NTAK Státusz', align: 'center', headerAlign: 'center', width: 200, valueFormatter: this.formatStatus},
                {field: 'error', headerName: 'Hiba', align: 'center', headerAlign: 'center', width: 130, renderCell: this.renderError},
                {field: 'action', headerName: 'Művelet', align: 'center', headerAlign: 'center', width: 160, renderCell: this.renderWorkingDayAction},],
            workingDayResending: [],
            isLoadingWorkingDay: false,

            processRowsPerPage: RowsPerPageOptions.defaultPageSize,
            processPage: 0,
            processRowCount: 0,
            processRows: [],
            processColumns: [
                {field: 'date', headerName: 'Tárgynap', width: 160},
                {field: 'type', headerName: 'Típus', align: 'center', headerAlign: 'center', width: 220},
                {field: 'ntakState', headerName: 'NTAK Státusz', align: 'center', headerAlign: 'center', width: 200, valueFormatter: this.formatStatus},
                {field: 'error', headerName: 'Hiba', align: 'center', headerAlign: 'center', width: 130, renderCell: this.renderError},
                {field: 'action', headerName: 'Művelet', align: 'center', headerAlign: 'center', width: 160, renderCell: this.renderProcessAction},],
            processResending: [],
            isLoadingProcess: false,
        };
    }

    formatStatus = (params) => {
        switch (params.value){
            case "ERROR_SEND_NORMAL":
            case "ERROR":
                return "Beküldési hiba"
            case "ERROR_SEND_HELYESBITO": return "Helyesbítési hiba"
            case "ERROR_SEND_SZTORNO": return "Sztornózási hiba"
            case "ERROR_CHECK_RESULT": return "Feldolgozási hiba"
            default: return ""
        }
    }

    renderError = (params) => {
        return <Tooltip title={<p style={{ fontSize: "14px",}}>{params.value}</p>} aria-label={params.value}>
            <WarningIcon style={{color: "red"}} onClick={() => this.showNtakErrorDialog(params.value)}/>
        </Tooltip>
    }

    renderCartAction = (params) => {
        return !this.state.cartResending.includes(params.id)
            ?<Button
                variant="contained"
                color="primary"
                style={{color: 'white'}}
                onClick={() => this.retryCartDataSending(params.id)}>
                Újra próbál
            </Button>
            : "Folyamatban..."
    }

    renderWorkingDayAction = (params) => {
        return !this.state.workingDayResending.includes(params.id)
            ?<Button
                variant="contained"
                color="primary"
                style={{color: 'white'}}
                onClick={() => this.retryWorkingDaySending(params.id)}>
                Újra próbál
            </Button>
            : "Folyamatban..."
    }

    showNtakErrorDialog = (ntakError) => this.setState({showNtakErrorDialog: true, ntakError,})

    renderProcessAction = (params) => {
        return !this.state.processResending.includes(params.id)
            ?<Button
                variant="contained"
                color="primary"
                style={{color: 'white'}}
                onClick={() => this.retryProcessSending(params.id)}>
                Újra próbál
            </Button>
            : "Folyamatban..."
    }

    retryCartDataSending = (id) => {
        const {cartResending} = this.state;
        cartResending.unshift(id)
        this.setState({cartResending})
        const cartIds = [id]

        axiosCommon.post(`${BASE_URL}/api/ntak/carts`, cartIds)
        .catch(error => this.setState({ error }))
    }

    retryWorkingDaySending = (id) => {
        const {workingDayResending} = this.state;
        workingDayResending.unshift(id)
        this.setState({workingDayResending})
        const ids = [id]

        axiosCommon.post(`${BASE_URL}/api/ntak/workingday`, ids)
        .catch(error => this.setState({ error }))
    }

    retryProcessSending = (id) => {
        const {processResending} = this.state;
        processResending.unshift(id)
        this.setState({processResending})
        const ids = [id]

        axiosCommon.post(`${BASE_URL}/api/ntak/processes`, ids)
        .catch(error => this.setState({ error }))
    }

    componentDidMount() {
        this.setState({isLoadingCart: true, isLoadingWorkingDay: true, isLoadingProcess: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
        .then(response => {
            const shopOptions = response.data.items;
            shopOptions.unshift({ name: "Összes üzlet", id: -1 })
            this.setState({
                shopOptions,
                selectedShopOption: shopOptions[0],
            }, () => this.refreshData());
        });
    }

    refreshData = () => {
        this.refreshCarts()
        this.refreshWorkingDays()
        this.refreshProcess()
    }

    refreshCarts = () => {
        this.setState({isLoadingCart: true})

        const params = {
            ntakOrderCartSate: "ERROR_SEND_NORMAL, ERROR_SEND_PARTIAL, ERROR_SEND_HELYESBITO, ERROR_SEND_SZTORNO, ERROR_CHECK_RESULT",
            pageSize: this.state.cartRowsPerPage,
            pageNumber: this.state.cartPage,
            shopId: this.state.selectedShopOption.id !== -1 ? this.state.selectedShopOption.id : undefined
        }

        axiosCommon(`${BASE_URL}/api/ntak/carts`, { params })
        .then(response => {
            const cartRowCount = response.data.count;
            const carts = response.data.items;
            const cartRows = carts.map((e) => {
                return {
                    id: e.id,
                    date: formatDateTime(e.paymentDateTime),
                    shop: e.shop.name,
                    seller: e.initiator ? `${e.initiator.lastName} ${e.initiator.firstName}` : "-",
                    paymentType: formatPaymentType(e.paymentType),
                    value: e.totalPaid,
                    ntakState: e.ntakState,
                    error: e.ntakErrorMessage
                };
            });
            this.setState({ carts, cartRows, cartRowCount })
        }).catch(error => this.setState({ error}))
        .finally(() => this.setState({ isLoadingCart: false }))
    }

    refreshWorkingDays = () => {
        this.setState({isLoadingWorkingDay: true})

        const params = {
            ntakWorkingDayState: "ERROR_SEND_NORMAL, ERROR_CHECK_RESULT",
            pageSize: this.state.workingDayRowsPerPage,
            pageNumber: this.state.workingDayPage,
            shopId: this.state.selectedShopOption.id !== -1 ? this.state.selectedShopOption.id : undefined
        }

        axiosCommon(`${BASE_URL}/api/ntak/workingday`, { params })
        .then(response => {
            const workingDayRowCount = response.data.count;
            const workingDays = response.data.items;
            const workingDayRows = workingDays.map((e) => {
                return {
                    id: e.id,
                    date: e.subjectDay,
                    shop: e.shop.name,
                    state: this.formatWorkingDayStatus(e.status),
                    ntakState: e.ntakState,
                    error: e.ntakErrorMessage
                };
            });
            this.setState({ workingDays, workingDayRows, workingDayRowCount })
        }).catch(error => this.setState({ error}))
        .finally(() => this.setState({ isLoadingWorkingDay: false }))
    }

    refreshProcess = () => {
        this.setState({isLoadingProcess: true})

        const params = {
            ntakProcessState: "ERROR",
            pageSize: this.state.processRowsPerPage,
            pageNumber: this.state.processPage,
            shopId: this.state.selectedShopOption.id !== -1 ? this.state.selectedShopOption.id : undefined
        }

        axiosCommon(`${BASE_URL}/api/ntak/processes`, { params })
        .then(response => {
            const processRowCount = response.data.count;
            const processes = response.data.items;
            const processRows = processes.map((e) => {
                return {
                    id: e.id,
                    date: formatDateTime(e.sendDateTime),
                    type: this.formatProcessType(e.type),
                    ntakState: e.state,
                    error: e.errorMessage
                };
            });
            this.setState({ processes, processRows, processRowCount })
        }).catch(error => this.setState({ error}))
        .finally(() => this.setState({ isLoadingProcess: false }))
    }

    formatWorkingDayStatus = (status) => {
        switch (status){
            case "OPEN": return "Nyitva"
            case "CLOSED": return "Lezárva"
            case "DIDNT_OPEN": return "Végig zárva"
            default: return ""
        }
    }

    formatProcessType = (type) => {
        switch (type){
            case "RENDELES_OSSZESITO": return "Rendelésösszesítő"
            case "NAP_ZARAS": return "Napzárás"
            default: return ""
        }
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    afterCheck = () => {
        this.setState({
            checkInProgress: null
        });
    };

    renderShowCart = () => {
        return this.state.checkInProgress
            ?<ShowCarts
                open={this.state.checkInProgress !== null}
                cartId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    renderNtakErrorDialog = () => {
        return <Dialog
            maxWidth='md'
            fullWidth
            open={this.state.showNtakErrorDialog}
            onClose={() => this.setState({showNtakErrorDialog: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">NTAK adatküldési hiba</DialogTitle>
            <DialogContent>
                <Typography style={{ wordWrap: "break-word" }}>{this.state.ntakError}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({showNtakErrorDialog: false})} color="primary">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    }

    renderContent = () => {
        return <>
            <section className="flex justify-flex-end mb-16 gap-8" >
                <HighlightCard
                    title="Eladási hibák (db)"
                    tooltip="Az összes eladás darabszáma, amelyek az NTAK adatszolgáltatás kapcsán hibásak voltak.">
                    <Typography variant="h3" align="center">
                        {this.state.cartRowCount || 0}
                    </Typography>
                </HighlightCard>
                <HighlightCard
                    title="Napzárási hibák (db)"
                    tooltip="Az összes napzárás darabszáma, amelyek az NTAK adatszolgáltatás kapcsán hibásak voltak.">
                    <Typography variant="h3" align="center">
                        {this.state.workingDayRowCount || 0}
                    </Typography>
                </HighlightCard>
                <HighlightCard
                    title="Lekérdezési hibák (db)"
                    tooltip="Az összes lekérdezés darabszáma, amelyek az NTAK adatszolgáltatás kapcsán hibásak voltak.">
                    <Typography variant="h3" align="center">
                        {this.state.processRowCount || 0}
                    </Typography>
                </HighlightCard>
            </section>

            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div style={{flex: 1}}/>
            </Widget>

            {this.state.cartRowCount != 0
                ? <>
                    <Paper>
                        <Toolbar>
                            <AiFillWarning style={{fontSize: 32, marginRight: 10}}/>
                            <Typography variant="h5" id="tableTitle" component="div">
                                Eladási hibák
                            </Typography>
                        </Toolbar>
                        <DataGrid
                            columns={this.state.cartColumns}
                            rows={this.state.cartRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            pagination
                            paginationMode="server"
                            rowCount={this.state.cartRowCount}
                            page={this.state.cartPage}
                            onPageSizeChange={(entry) =>
                                this.setState({ cartRowsPerPage: parseInt(entry, 10), cartPage: 0}, () => this.refreshCarts())}
                            onPageChange={(entry) =>
                                this.setState({ cartPage: parseInt(entry, 10)}, () => this.refreshCarts())}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.cartRowsPerPage}
                            loading={this.state.isLoadingCart}
                            onRowDoubleClick={(rowData) => this.setState({checkInProgress: rowData.row.id})}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}/>
                    </Paper>
                    <div style={{marginBottom: 16}}/>
                </>
                : undefined
            }
            {this.renderShowCart()}

            {this.state.workingDayRowCount != 0
                ?<>
                    <Paper>
                        <Toolbar>
                            <AiFillWarning style={{fontSize: 32, marginRight: 10}}/>
                            <Typography variant="h5" id="tableTitle" component="div">
                                Napzárási hibák
                            </Typography>
                        </Toolbar>
                        <DataGrid
                            columns={this.state.workingDayColumns}
                            rows={this.state.workingDayRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            pagination
                            paginationMode="server"
                            rowCount={this.state.workingDayRowCount}
                            page={this.state.workingDayPage}
                            onPageSizeChange={(entry) =>
                                this.setState({ workingDayRowsPerPage: parseInt(entry, 10), workingDayPage: 0}, () => this.refreshWorkingDays())}
                            onPageChange={(entry) =>
                                this.setState({ workingDayPage: parseInt(entry, 10)}, () => this.refreshWorkingDays())}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.workingDayRowsPerPage}
                            loading={this.state.isLoadingWorkingDay}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}/>
                    </Paper>
                    <div style={{marginBottom: 16}}/>
                </>
                : undefined
            }

            {this.state.processRowCount != 0
                ?<>
                    <Paper>
                        <Toolbar>
                            <AiFillWarning style={{fontSize: 32, marginRight: 10}}/>
                            <Typography variant="h5" id="tableTitle" component="div">
                                Lekérdezési hibák
                            </Typography>
                        </Toolbar>
                        <DataGrid
                            columns={this.state.processColumns}
                            rows={this.state.processRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            pagination
                            paginationMode="server"
                            rowCount={this.state.processRowCount}
                            page={this.state.processPage}
                            onPageSizeChange={(entry) =>
                                this.setState({ processRowsPerPage: parseInt(entry, 10), processPage: 0}, () => this.refreshProcess())}
                            onPageChange={(entry) =>
                                this.setState({ processPage: parseInt(entry, 10)}, () => this.refreshProcess())}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.processRowsPerPage}
                            loading={this.state.isLoadingProcess}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}/>
                    </Paper>
                    <div style={{marginBottom: 16}}/>
                </>
                : undefined
            }

            {this.state.cartRowCount == 0 && this.state.workingDayRowCount == 0 && this.state.processRowCount == 0
                ? <Alert severity="success" >Nincs hibás adatküldést az NTAK vonatkozásában.</Alert>
                : undefined
            }

            {this.renderNtakErrorDialog()}
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderContent()}
                </section>

                <Footer/>
            </section>
        </>
    }
}
