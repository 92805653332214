import React from "react";
import "../../../css/Pages.css"
import moment from "moment";
import {axiosCommon} from "../../../util/axiosSetup";
import {BASE_URL} from "../../../util/Urls";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import HighlightCard from "../../../components/HighlightCard";
import { Paper, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatNumber from "../../../util/formatNumber";
import formatDateTime from "../../../util/formatDateTime";

export default class CustomerEmployeeStatisticsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            shopOptions: [],
            selectedShopOption: null,
            employeeOptions: [],
            selectedEmployee: null,
            productOptions: [],
            selectedProduct: null,
            sumAmount: null,
            sumIncome: null,
            employeeRows: [],
            employeeColumns: [
                {field: 'date', headerName: 'Dátum', width: 180},
                {field: 'product', headerName: 'Termék', align: 'left', headerAlign: 'left', flex: 1},
                {field: 'category', headerName: 'Kategória', align: 'left', headerAlign: 'left', flex: 1},
                {field: 'unit', headerName: 'Egység', align: 'center', headerAlign: 'center', flex: 1},
                {field: 'amount', headerName: 'Mennyiség', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'income', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
            ],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items
                shopOptions.unshift({ name: "Összes üzlet", id: -1 })
                this.setState({ shopOptions, selectedShopOption: shopOptions[0] }, () => this.refreshData())
            })
            .catch(error => this.setState({error, isLoading: false}))

        axiosCommon.get(`${BASE_URL}/api/products/basic?deleted=false`)
            .then(response => {
                const productOptions = response.data.items
                productOptions.unshift({ name: "Összes termék", id: -1 })
                this.setState({ productOptions, selectedProduct: productOptions[0] }, () => this.refreshData())
            })
            .catch(error => this.setState({error, isLoading: false}))

        axiosCommon(`${BASE_URL}/api/employees?deleted=false&includeOwner=true`)
            .then(response => {
                const employeeOptions = response.data.items.map(i => {
                    return { id: i.id, name: `${i.lastName} ${i.firstName}` }
                })
                this.setState({ employeeOptions, selectedEmployee: employeeOptions[0] || null }, () => this.refreshData())
            })
            .catch(error => {this.setState({ error, isLoading: false})});
    }

    refreshData = () => {
        if(this.state.selectedShopOption != null && this.state.selectedEmployee != null && this.state.selectedProduct != null){
            this.setState({isLoading: true})

            const params = {
                toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
                fromDate: moment(this.state.fromDate).format("YYYY-MM-DD")
            }

            if (this.state.selectedShopOption.id !== -1) {
                params.shopId = this.state.selectedShopOption.id;
            }
            if (this.state.selectedEmployee.id !== -1) {
                params.employeeId = this.state.selectedEmployee.id;
            }
            if (this.state.selectedProduct.id !== -1) {
                params.productId = this.state.selectedProduct.id;
            }

            axiosCommon(`${BASE_URL}/api/stats/employees/sales`, {
                params
            })
            .then(response => {
                let sumAmount = 0
                let sumIncome = 0
                const employeeRows = response.data.map((item, i) => {
                    sumAmount += item.quantity
                    sumIncome += item.quantity * item.productPrice
                    return{
                        id: i,
                        date: formatDateTime(item.creationDataTime),
                        product: item.productName || "",
                        category: item.categoryName || "",
                        unit: item.sellingUnit,
                        amount: item.quantity,
                        income: item.quantity * item.productPrice
                    }});

                this.setState({ employeeRows, sumAmount, sumIncome })
            })
            .catch(error => this.setState({ error, employeeRows: [], sumAmount: null, sumIncome: null, }))
            .finally(() => this.setState({isLoading: false}));
        }
    }

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value }, () => this.refreshData())
    };

    onChangeEmployee = (event, value) => {
        this.setState({ selectedEmployee: value }, () => this.refreshData())
    };

    onChangeProduct = (event, value) => {
        this.setState({ selectedProduct: value }, () => this.refreshData())
    };

    render() {
        return <>
            <section className="flex justify-flex-end mb-16 gap-8" >
                <HighlightCard title="Eladások (db)">
                    <Typography variant="h3" align="center">
                        {formatNumber(this.state.sumAmount, 2)}
                    </Typography>
                </HighlightCard>
                <div className="m-8"/>
                <HighlightCard title="Generált bevétel (Ft)">
                    <Typography variant="h3" align="center">
                        {formatNumber(this.state.sumIncome, 0)}
                    </Typography>
                </HighlightCard>
            </section>

            <Widget>
                <Card title="Szűrés alkalmazott alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.employeeOptions}
                        onChange={this.onChangeEmployee}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedEmployee || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Alkalmazott" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés dátum alapján">
                    <StyledDateRangePicker
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        fromDateChange={(date) =>{
                            this.setState({fromDate: date})
                        }}
                        toDateChange={(date) => {
                            this.setState({toDate: date}, () => this.refreshData())
                        }}
                    />
                </Card>
            </Widget>

            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés termék alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.productOptions}
                        onChange={this.onChangeProduct}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedProduct || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Termék" variant="outlined" />
                        }
                    />
                </Card>
            </Widget>

            <Paper className="mt-16 mb-16" style={{ height: 780}}>
                <DataGrid
                    columns={this.state.employeeColumns}
                    rows={this.state.employeeRows}
                    disableSelectionOnClick
                    disableColumnMenu
                    loading={this.state.isLoading}
                    components={{
                        Toolbar: () => {
                            return (
                                <GridToolbarContainer>
                                    <GridToolbarExport />
                                </GridToolbarContainer>
                            );
                        },
                        NoRowsOverlay: NoData,
                    }}
                />
            </Paper>
        </>;
    }
};
