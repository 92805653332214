import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import NavHeader from "../../../components/NavHeader";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Button, CircularProgress} from "@material-ui/core";

export default class EditCompanyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                companyData: {
                    companyName: null,
                    companyAddress: {
                        street: null,
                        city: null,
                        zipCode: null,
                    },
                    taxNumber: null,
                    accountNumber: null,
                }
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/users/me`)
            .then(response => {
                this.setState({
                        user: response.data
                    }
                )
            })
            .catch(error => {
                this.setState({error});
            });
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});
        const {user} = this.state;

        axiosCommon.put(`${BASE_URL}/api/users`, user)
            .then(response => {
                this.setState({
                    error: '',
                    user: response.data,
                    isSubmitInProgress: false,
                });
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    handleChange = (event) => {
        const { companyData } = this.state.user;
        companyData[event.target.name] = event.target.value;
        this.setState({ companyData });
    }

    handleAddressChange = (event) => {
        const { companyAddress } = this.state.user.companyData;
        companyAddress[event.target.name] = event.target.value;
        this.setState({ companyAddress });
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <ValidatorForm onSubmit={this.onSubmit}>
                    <Card title="Céges adatok">
                        <Widget>
                            <div className="flex-1">
                                <TextValidator
                                    label="Cégnév"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="companyName"
                                    value={this.state.user.companyData.companyName || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                            <div className="m-8"/>
                            <div className="flex-1">
                                <TextValidator
                                    label="Adószám"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="taxNumber"
                                    value={this.state.user.companyData.taxNumber || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                        </Widget>

                        <Widget>
                            <div className="flex-1">
                                <TextValidator
                                    label="Számlaszám"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="accountNumber"
                                    value={this.state.user.companyData.accountNumber || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                            <div className="m-8"/>
                            <div className="flex-1">
                                <TextValidator
                                    label="Város"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleAddressChange}
                                    name="city"
                                    value={this.state.user.companyData.companyAddress.city || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                        </Widget>

                        <Widget>
                            <div className="flex-1">
                                <TextValidator
                                    label="Irányítószám"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleAddressChange}
                                    name="zipCode"
                                    value={this.state.user.companyData.companyAddress.zipCode || ""}
                                    inputProps={{ maxLength: 10 }}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                            <div className="m-8"/>
                            <div className="flex-1">
                                <TextValidator
                                    label="Cím"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleAddressChange}
                                    name="street"
                                    value={this.state.user.companyData.companyAddress.street || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']}
                                />
                            </div>
                        </Widget>
                    </Card>

                    <section style={{ marginTop: 16, fontSize: 20, color: "red", justifyContent: "center", display: "flex"}}>{getGeneralError(this.state.error)}</section>

                    <div style={{justifyContent: "center", display: "flex"}}>
                        {!this.state.isSubmitInProgress
                            ? <Button type="submit" variant="contained" color="primary"
                                      style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20}}>Mentés</Button>
                            : <div style={{marginTop: 30, textAlign: "center"}}>
                                <CircularProgress color="primary"/>
                            </div>
                        }
                    </div>

                </ValidatorForm>
            </section>

            <Footer/>
        </section>;
    }
}
