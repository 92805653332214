import React from "react";
import { axiosCommon, impersonate } from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";

export default class ImersonationListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            keyword: "",
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            subjects: [],
            filteredSubjects: [],
        }
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/authenticate/impersonateSubjects`)
        .then(response => {
            this.setState({subjects: response.data, filteredSubjects: response.data })
        })
        .catch(error => {
            this.setState({error})
        });
    }

    refreshData = () => {
        this.setState({
           filteredSubjects: this.state.subjects.filter(item => item.name.toLowerCase().includes(this.state.keyword.toLowerCase())
                                                             || item.email.toLowerCase().includes(this.state.keyword.toLowerCase()))
        })

    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    impersonate = (subject) => {
        impersonate(subject.email, subject.name,
            () => {
                this.props.history.push("/customer");
            })
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Ügyfelek
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                  <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="center">Megszemélyesítés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.filteredSubjects
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((u, i) => {
                                            return <TableRow key={i} hover className="cursor-pointer">
                                                <TableCell>{u.name}</TableCell>
                                                <TableCell>{u.email}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        style={{color: 'white'}}
                                                        onClick={() => this.impersonate(u)}>
                                                        Belépés
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.subjects.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
