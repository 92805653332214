import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import formatYearMonth from "../../../util/formatYearMonth";
import ShowFees from "./ShowFees";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";

export default class CartListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            debts: [],
            entries: [],
            error: null,
            debtRowsPerPage: RowsPerPageOptions.defaultPageSize,
            debtPage: 0,
            entryRowsPerPage: RowsPerPageOptions.defaultPageSize,
            entryPage: 0,
        };
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        const debtParams = {
            paid: false,
        }

        axiosCommon(`${BASE_URL}/api/fees`, {
            params: debtParams
        })
            .then(response => {
                this.setState({
                    debts: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });

        const entryParams = {
            paid: true,
            groupByOwner: true
        }

        axiosCommon(`${BASE_URL}/api/fees`, {
            params: entryParams
        })
            .then(response => {
                this.setState({
                    entries: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
    }

    afterCheck = () => {
        this.refreshData();
        this.setState({
            checkInProgress: null
        });
    };

    formatName = (owner) => `${owner.lastName} ${owner.firstName}`;

    markAsPaid = (id) => {
        axiosCommon.post(`${BASE_URL}/api/fees/${id}/paid`)
            .then(this.refreshData)
            .catch(error => {
                this.setState({
                    error
                })
            });
    }

    firstPayment = (fees) => formatYearMonth(fees.sort((a, b) => a.period.localeCompare(b.period))[0].period)

    lastPayment = (fees) => formatYearMonth(fees.sort((a, b) => b.period.localeCompare(a.period))[0].period)

    allPayments = (fees) => {
        if (fees.length === 0) {
            return 0
        }
        if (fees.length === 1) {
            return fees[0].currentUnitFee * fees[0].activeShops
        }
        return fees.map(f => f.currentUnitFee * f.activeShops).reduce((a, b) => a + b, 0)
    }

    handleChangeDebtPage = (event, newPage) => {
        this.setState({
            debtPage: newPage,
        })
    };

    handleChangeRowsPerDebtPage = (event) => {
        const debtRowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            debtRowsPerPage,
            debtPage: 0,
        });
    };

    handleChangeEntryPage = (event, newPage) => {
        this.setState({
            entryPage: newPage,
        })
    };

    handleChangeRowsPerEntryPage = (event) => {
        const entryRowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            entryRowsPerPage,
            entryPage: 0,
        });
    };

    renderShowFees = () => {
        return this.state.checkInProgress
            ?<ShowFees
                open={this.state.checkInProgress !== null}
                ownerId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Paper style={{marginBottom: 15}}>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Tartozások
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell align="center">Időszak</TableCell>
                                    <TableCell align="center">Aktív üzletek</TableCell>
                                    <TableCell align="center">Havidíj/Üzlet</TableCell>
                                    <TableCell align="center">Fizetendő</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.debts
                                    .slice(this.state.debtPage * this.state.debtRowsPerPage, this.state.debtPage * this.state.debtRowsPerPage + this.state.debtRowsPerPage)
                                    .map((n, i) => {
                                            return <TableRow key={i} hover>
                                                <TableCell>{this.formatName(n.owner)}</TableCell>
                                                <TableCell align="center">{formatYearMonth(n.period)}</TableCell>
                                                <TableCell align="center">{n.activeShops}</TableCell>
                                                <TableCell align="center">{n.currentUnitFee} Ft</TableCell>
                                                <TableCell align="center">{n.activeShops * n.currentUnitFee} Ft</TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        style={{color: 'white'}}
                                                        onClick={() => this.markAsPaid(n.id)}>Befizet</Button>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                                <TableRow hover>
                                    <TableCell>Összesen</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell align="center">{this.state.debts.map(e => e.activeShops * e.currentUnitFee).reduce((a, b) => a + b, 0)} Ft</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.debts.length}
                            rowsPerPage={this.state.debtRowsPerPage}
                            page={this.state.debtPage}
                            onPageChange={this.handleChangeDebtPage}
                            onRowsPerPageChange={this.handleChangeRowsPerDebtPage}
                        />
                    </TableContainer>
                </Paper>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Előfizetések
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell align="center">Előfizetés kezdete</TableCell>
                                    <TableCell align="center">Utolsó befizetés</TableCell>
                                    <TableCell align="center">Aktív üzletek</TableCell>
                                    <TableCell align="center">Havidíj/Üzlet</TableCell>
                                    <TableCell align="center">Összes befizetés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.entries
                                    .slice(this.state.entryPage * this.state.entryRowsPerPage, this.state.entryPage * this.state.entryRowsPerPage + this.state.entryRowsPerPage)
                                    .map((n, i) => {
                                            return <TableRow key={i} hover className="cursor-pointer" onDoubleClick={() => this.setState({checkInProgress: n.first.id})}>
                                                <TableCell>{this.formatName(n.first)}</TableCell>
                                                <TableCell align="center">{this.firstPayment(n.second)}</TableCell>
                                                <TableCell align="center">{this.lastPayment(n.second)}</TableCell>
                                                <TableCell align="center">{n.first?.shops?.length || 0}</TableCell>
                                                <TableCell align="center">{n.first?.monthlyFee || 0} Ft</TableCell>
                                                <TableCell align="center">{this.allPayments(n.second)} Ft</TableCell>
                                            </TableRow>
                                        }
                                    )}
                                <TableRow hover>
                                    <TableCell>Összesen</TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell align="center">{this.state.entries.map(e => this.allPayments(e.second)).reduce((a, b) => a + b, 0)} Ft</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.entries.length}
                            rowsPerPage={this.state.entryRowsPerPage}
                            page={this.state.entryPage}
                            onPageChange={this.handleChangeEntryPage}
                            onRowsPerPageChange={this.handleChangeRowsPerEntryPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderShowFees()}

            </section>

            <Footer/>
        </section>;
    }
}
