import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import ShowCarts from "../ShowCarts";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import formatCurrency from "../../../util/formatCurrency";
import { getCartPrice } from "../../../util/CartPriceCalculator";

export default class ConsumerListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            carts: [],
            consumers: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        axiosCommon.get(`${BASE_URL}/api/carts?status=ACTIVE`)
            .then(response => {
                this.setState({
                    carts: response.data.items
                })
            })
            .catch(error => {
                this.setState(error)
            })

        const queryParams = {}
        if (this.state.keyword && this.state.keyword !== "") {
            queryParams.keyword = this.state.keyword
        }

        axiosCommon(`${BASE_URL}/api/consumers`,
            {
                params: queryParams
            })
            .then(response => {
                this.setState({
                    consumers: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });

    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/consumers/editconsumer",
            consumerId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/consumers/editconsumer",
        });
    };

    afterCheck = () => {
        this.refreshData();
        this.setState({
            checkInProgress: null
        });
    };

    initiateDeletion = (consumer) => {
        this.setState({
            deleteInProgress: consumer.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/consumers/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({error})
            });
        this.setState({
            deleteInProgress: null
        })
    };

    hasDebtForConsumer = id =>
        this.state.carts.filter(c => c.consumer?.id === id && (c.status === "ACTIVE")).length > 0

    calculateDebtForConsumer = id => {
        const cartList = this.state.carts.filter(c => c.consumer?.id === id && (c.status === "ACTIVE"))
        if (cartList.length === 0) return ""
        return formatCurrency(cartList.map(cart => getCartPrice(cart)).reduce((a, b) => a + b),0,"HUF")
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a vásárlót?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek! Így a vásárló korábbi
            fogyasztásai névtelenül maradnak meg a rendszerben.
        </DeleteDialog>;
    };

    renderShowCart = () => {
        return this.state.checkInProgress
            ?<ShowCarts
                open={this.state.checkInProgress !== null}
                consumerId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés a névben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Vásárlók
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Telefonszám</TableCell>
                                    <TableCell align="center">Cím</TableCell>
                                    <TableCell align="center">Törzsvásárlói típus</TableCell>
                                    <TableCell align="center">Tartozás</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.consumers
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((c, i) => {
                                            return <TableRow key={c.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(c.id)}>
                                                <TableCell>{`${c.lastName} ${c.firstName}`}</TableCell>
                                                <TableCell align="center">{c.email}</TableCell>
                                                <TableCell align="center">{c.phone}</TableCell>
                                                <TableCell align="center">{c.address ? `${c.address?.zipCode || ''} ${c.address?.city || ''} ${c.address?.street || ''}` : ""}</TableCell>
                                                <TableCell align="center">{c.consumerType ? c.consumerType.name : ""}</TableCell>
                                                <TableCell align="center">
                                                    {this.calculateDebtForConsumer(c.id)
                                                        ? <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{color: 'white'}}
                                                            onClick={() => this.setState({checkInProgress: c.id})}>
                                                            {this.calculateDebtForConsumer(c.id)}
                                                        </Button>
                                                        : ""
                                                    }
                                                </TableCell>
                                                <TableCell padding="none" align="right">
                                                    {this.hasDebtForConsumer(c.id)
                                                        ? ""
                                                        : <IconButton aria-label="delete"
                                                                      onClick={() => this.initiateDeletion(c)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.consumers.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
                {this.renderShowCart()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Vásárló"
                onClick={this.initiateCreate}/>
        </>
    }
}
