import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import formatYearMonth from "../../../util/formatYearMonth";
import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
} from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

export default class ShowCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fees: []
        };
    }

    componentDidMount() {
        if (this.props.ownerId) {
            axiosCommon.get(`${BASE_URL}/api/fees?ownerId=${this.props.ownerId}`)
                .then(response => {
                    this.setState({
                        fees: response.data.items
                    })
                })
                .catch(error => {
                    this.setState(error)
                })
        }
    }

    formatName = (owner) => `${owner.lastName} ${owner.firstName}`;

    render() {
        return <Dialog
            maxWidth='md'
            fullWidth
            open={this.props.open}
            onClose={this.props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Számlák</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Név</TableCell>
                                <TableCell align="center">Időszak</TableCell>
                                <TableCell align="center">Aktív üzletek</TableCell>
                                <TableCell align="center">Havidíj üzletenként</TableCell>
                                <TableCell align="center">Fizetendő</TableCell>
                                <TableCell align="center">Befizetve</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.fees
                                .map((fee, i) => {
                                        return <TableRow key={i} hover>
                                            <TableCell>{this.formatName(fee.owner)}</TableCell>
                                            <TableCell align="center">{formatYearMonth(fee.period)}</TableCell>
                                            <TableCell align="center">{fee.activeShops}</TableCell>
                                            <TableCell align="center">{fee.currentUnitFee}</TableCell>
                                            <TableCell align="center">{fee.activeShops * fee.currentUnitFee}</TableCell>
                                            <TableCell align="center">
                                                <CheckCircleOutline style={{color: "#4caf50"}}/>
                                            </TableCell>
                                        </TableRow>
                                    }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    }
}
