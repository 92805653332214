import React from 'react';
import { Tabs, Toolbar } from "@material-ui/core";


function TabList(props) {
    return (
        <Toolbar className='mb-32'>
            <Tabs
                value={props.value}
                onChange={props.onChange}
                style={{color: 'white'}}
                TabIndicatorProps={{style: {background:'white'}}}
                variant="scrollable"
                scrollButtons="auto">
                {props.children}
            </Tabs>
        </Toolbar>
    );
}

export default TabList;
