import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, MenuItem, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveCancelButtons from "../../../components/SaveCancelButtons";


export default class EditProductDifference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            shopOptions: [],
            productOptions: [],
            selectedShopOption: null,
            selectedProductOption: null,
            diff: {
                currentStockAmount: null,
                desiredStockAmount: null,
                eanCode: null,
                productId: null,
                sellingPrice: null,
                shopId: null,
                sold: true
            }
        };
    }

    componentDidMount() {
        if (this.props.location.entryId) {
              this.loadExistingDifference()
        }else{
            this.loadShops()
            this.loadProducts()
        }
    }

    loadExistingDifference = () => {
        axiosCommon.get(`${BASE_URL}/api/inventories/${this.props.location.entryId}`)
          .then(response => {
              const selectedShopOption = { label: response.data.shop.name, value: response.data.shop.id }
              const selectedProductOption = { label: response.data.product.name, value: response.data.product.id }
              delete response.data.shop;
              delete response.data.product;

              axiosCommon.get(`${BASE_URL}/api/products/${selectedProductOption.value}`)
                .then(response => this.setState({ products: [response.data] }))
                .catch(error => this.setState({error}))

              this.setState({
                  diff: response.data,
                  selectedShopOption,
                  selectedProductOption
              })
          })
          .catch(error => {
              this.setState({error});
          });
    }

    loadShops = () => {
        axiosCommon.get(`${BASE_URL}/api/shops`)
          .then(response => {
              const shopOptions = response.data.items.map(c => {
                  return { label: c.name, value: c.id }
              });
              const selectedShopOption = shopOptions[0] || null;
              this.setState({
                  shopOptions,
                  selectedShopOption,
              })
          })
          .catch(error => {
              this.setState({error});
          });
    }

    loadProducts = () => {
        axiosCommon.get(`${BASE_URL}/api/products/basic?deleted=false`)
          .then(response => {
              const productOptions = response.data.items.map(p => {
                  return { label: p.name, value: p.id }
              });
              this.setState({
                  products: response.data.items,
                  productOptions,
                  selectedProductOption: productOptions[0] || undefined,
              })
          })
          .catch(error => this.setState({error}));
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});
        const {diff} = this.state;
        diff.shopId = this.state.selectedShopOption.value;
        diff.productId = this.state.selectedProductOption.value;

        console.log('diff',diff);
        axiosCommon.patch(`${BASE_URL}/api/inventories/differences`, diff)
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    labelText = (text, origData) => (origData === null || origData === "") ? `${text} (Nincs alapértelmezett)` : `${text} (Eredeti: ${origData})`

    selectedProduct = () => this.state.products.filter(p => p.id === this.state.selectedProductOption?.value)[0]

    handleChange = (event) => {
        const { diff } = this.state;
        diff[event.target.name] = event.target.value;
        this.setState({ diff });
    }

    handleProductChange = (event, value) => {
        this.setState({ selectedProductOption: value });
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value });
    }

    handleSoldChange = (event) => {
        const { diff } = this.state;
        diff.sold = event.target.value === 'true';
        this.setState({ diff });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.entryId ? "Eltérés szerkesztése" : "Új eltérés"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Termék kiválasztása">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disabled={this.props.location.entryId !== undefined}
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleProductChange}
                                name="productId"
                                fullWidth
                                disabled={this.props.location.entryId !== undefined}
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.productOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedProductOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Termék" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                </Card>
                <div className="m-8"/>
                <Card title="Eltérés megadása">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label={this.labelText("Cikkszám", this.selectedProduct()?.defaultEanCode)}
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="eanCode"
                                value={this.state.diff.eanCode || ""}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label={this.labelText("Ár", this.selectedProduct()?.defaultSellingPrice)}
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="sellingPrice"
                                value={this.state.diff.sellingPrice || ""}
                                inputProps={{ maxLength: 8 }}
                                validators={['isNumber','minNumber:1']}
                                errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 1-nél']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label={this.labelText("Készletfigyelmeztetés:", this.selectedProduct()?.defaultDesiredStockAmount)}
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const { diff } = this.state;
                                    diff[event.target.name] = event.target.value.replace(",", ".");
                                    this.setState({ diff });
                                }}
                                name="desiredStockAmount"
                                value={this.state.diff.desiredStockAmount || ""}
                                validators={['isFloat','minNumber:0']}
                                errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Eladható"
                                variant="outlined"
                                onChange={this.handleSoldChange}
                                name="sold"
                                fullWidth
                                value={this.state.diff.sold === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Igen</MenuItem>
                                <MenuItem key='0' value='false'>Nem</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={this.goBack}
                />

            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
