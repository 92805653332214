import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export default class EditCashOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            selectedType: this.typeOptions()[0],
            selectedCurrencyOption: this.currencyOptions()[0],
            op: {
                description: '',
                amount: null,
                currency: null,
                shopId : null,
                type: null,
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(p => {
                    return {label: p.name, value: p.id}
                })

                const selectedShopOption = this.props.location.shopId !== undefined
                    ? shopOptions.find(shop => shop.value == this.props.location.shopId)
                    : shopOptions[0] || undefined

                this.setState(
                    {
                        shopOptions,
                        selectedShopOption,
                    })
            }).catch(error => {
            this.setState({error});
        });

    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {op} = this.state;
        op.currency = this.state.selectedCurrencyOption.value;
        op.shopId = this.state.selectedShopOption.value;
        op.type = this.state.selectedType.value;

        axiosCommon.post(`${BASE_URL}/api/cash-operations`, op)
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false})
            });
    };

    typeOptions = () => {
        return [
            {
                label: "Befizetés",
                value: "IN"
            },
            {
                label: "Kifizetés",
                value: "OUT"
            }
        ]
    };

    currencyOptions = () => {
        return [
            {
                label: "Forint",
                value: "HUF"
            },
            {
                label: "Euró",
                value: "EUR"
            }
        ]
    };

    onAmountChange = (value) => {
        const {op} = this.state
        op.amount = String(value).replace(",", ".");
        this.setState({
            op
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value });
    }

    handleChange = (event) => {
        const { op } = this.state;
        op[event.target.name] = event.target.value;
        this.setState({ op });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    Új művelet
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedType: value })}}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.typeOptions()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedType || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Művelet" variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Összeg"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => this.onAmountChange(event.target.value)}
                                name="amount"
                                value={this.state.op.amount || ""}
                                inputProps={{ maxLength: 8 }}
                                required
                                validators={['required', 'isFloat', 'minFloat:0.001']}
                                errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'Az értéknek nagyobbnak kell lennie 0.001-nél']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedCurrencyOption: value })}}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.currencyOptions()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedCurrencyOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Pénznem" variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.op.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
