import React from "react";
import "../../css/Pages.css"
import Footer from "../../components/Footer";
import NavHeader from "../../components/NavHeader";
import { Button, Collapse, Paper, Toolbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import getGeneralError from "../../util/getGeneralError";
import SyncIcon from '@material-ui/icons/Sync';
import { axiosCommon } from "../../util/axiosSetup";
import { BASE_URL } from "../../util/Urls";

export default class CentralSyncPage extends React.Component {
    constructor() {
        super();
        this.state = {
            hasMessage: false,
            message: null,
            error: null,
            hasError: false,
        };
    }

    syncRequest = () => {
        axiosCommon.post(`${BASE_URL}/api/utils/sync`)
        .then(response => {
            this.setState({ message: "Szinkronizálás kiküldve", hasMessage: true});
        })
        .catch(error => {
            this.setState({ error, hasError: true});
        });

    };

    closeError = () => {
        this.setState({hasError: false})
    }

    closeMessage = () => {
        this.setState({hasMessage: false})
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Collapse in={this.state.hasError} style={{marginBottom: 8}}>
                    <Alert severity="error" onClose={this.closeError}>{getGeneralError(this.state.error)}</Alert>
                </Collapse>

                <Collapse in={this.state.hasMessage} style={{marginBottom: 8}}>
                    <Alert severity="success" onClose={this.closeMessage}>{this.state.message}</Alert>
                </Collapse>

                <Paper style={{paddingBottom: 30}}>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Központi szinkronizálás
                        </Typography>
                    </Toolbar>
                    <div style={{paddingLeft: 15, paddingTop: 10}}>
                        <Typography style={{marginBottom: 8}}>Központi szinkornizálás indításával az összes üzlethez csatolt
                            terminál frissíteni fogja a webirodából az aktuális adatokat.</Typography>
                        <Button variant="contained" startIcon={<SyncIcon />} onClick={this.syncRequest} disabled={this.state.hasMessage}>
                            Indít
                        </Button>
                    </div>
                </Paper>
            </section>
            <Footer/>
        </section>;
    }
};
