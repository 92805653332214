import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        textAlign: "center",
        color: "gray",
        paddingTop: 70,
    },
    image: {
        width: 150,
        height: 150,
    },
});

function NoData(props){
    const classes = useStyles();
    return(
        <section className={classes.root} {...props}>
            <img className={classes.image} src='/img/nodata.svg' alt='nodata'/>
            <Typography style={{fontSize: 18, marginLeft: -25}}>Nincs elérhető adat</Typography>
        </section>
    );
}

export default NoData;
