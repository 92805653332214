function getCurrentDateTimeString() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? `0${  d.getMonth()  }${1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${  d.getDate()}` : d.getDate();
    const hours = d.getHours() < 10 ? `0${  d.getHours()}` : d.getHours();
    const mins = d.getMinutes() < 10 ? `0${  d.getMinutes()}` : d.getMinutes();
    return `${year}${month}${day}_${hours}${mins}`;
}

export default getCurrentDateTimeString;
