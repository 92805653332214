import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import NavHeader from "../../../components/NavHeader";
import {
    Button, CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import getGeneralError from "../../../util/getGeneralError";
import { axiosCommon } from "../../../util/axiosSetup";
import { BASE_URL } from "../../../util/Urls";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import TablePagination from "@material-ui/core/TablePagination";
import NewItemButton from "../../../components/NewItemButton";
import moment from "moment";
import { DatePickerCalendar } from "react-nice-dates";
import { hu } from "date-fns/locale";
import { Alert } from "@material-ui/lab";
import { AiFillWarning } from "react-icons/ai";

export default class DeleteShopData extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            shops: [],
            deleteDateChosen: false,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            deleteInitiated: false,
            toDate: moment().toDate(),
            shopToDelete: null,
            deleteWord: null,
            isDeletitionSuccesfull: false,
            deleteInProgress: false,
            hasError: false,
            errorMessage: null
        }
    }

    componentDidMount() {
        axiosCommon(`${BASE_URL}/api/shops`)
        .then(response => {
            this.setState({
                shops: response.data.items,
            })
        })
        .catch(error => {
            this.setState({error})
        })
    }

    initiateDeletion = (shop) => this.setState({
        deleteInitiated: true,
        deleteDateChosen: false,
        deleteInProgress: true,
        shopToDelete: shop,
        hasError: false,
        errorMessage: null
    })

    setDateForDeletition = () => this.setState({ deleteDateChosen: true, deleteInitiated: false })

    rejectDeletion = () => this.setState({
        deleteInitiated: false,
        shopToDelete: null,
        deleteDateChosen: false,
        deleteWord: null,
        deleteInProgress: false,
        isDeletitionSuccesfull: false,
        hasError: false,
        errorMessage: null,
        toDate: moment().toDate()
    })


    confirmDeletion = () => {
        this.setState({ deleteDateChosen: false, deleteWord: null})

        const deleteParams = {
            shopId: this.state.shopToDelete.id,
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
        }

        axiosCommon.delete(`${BASE_URL}/api/shops/deletedate`, {
            params: deleteParams
        }).then(response => {
            this.setState({isDeletitionSuccesfull: true, deleteInProgress: false})
        })
        .catch(error => {
            this.setState({ hasError: true, errorMessage: error, deleteInProgress: false })
        });


    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteConfigDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.deleteInitiated === true}
            onClose={this.rejectDeletion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Forgalmi adatok törlése a(z) {this.state.shopToDelete?.name || ""} üzletben</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="div">
                    Kérlek válaszd ki a dátumot ameddig törölni szeretnéd az összes forgalmi adatot.
                </Typography>
                <DatePickerCalendar date={this.state.toDate} onDateChange={date => this.setState({toDate: date})} locale={hu} />
                <Typography variant="body1" component="div" style={{fontWeight: 800, color: "red", marginTop: 20, marginBottom: 10}}>
                    Figyelem! A törölt adatok már nem visszaállíthatóak!
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.rejectDeletion} color="primary">
                    Mégsem
                </Button>
                <Button onClick={this.setDateForDeletition} color="primary" autoFocus>
                    Rendben
                </Button>
            </DialogActions>
        </Dialog>;
    };

    renderDeleteDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.deleteDateChosen === true}
            onClose={this.rejectDeletion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Biztosan törölni akarod az adatokat?</DialogTitle>
            <DialogContent>
                <ValidatorForm onSubmit={this.confirmDeletion}>
                    <Typography variant="body1" component="div">
                        Minden <b>{moment(this.state.toDate).format("YYYY-MM-DD")}</b> előtti adat véglegesen törlődni fog.
                        A törlés megerősítéséhez kérlek írd be a <b>törlés</b> szót.
                    </Typography>
                    <TextValidator
                        label="törlés"
                        variant="outlined"
                        onChange={(event) => this.setState({deleteWord: event.target.value})}
                        value={this.state.deleteWord || ""}
                        style={{width: 350, marginTop: 20}}
                        required
                        validators={['required', 'matchRegexp:^(törlés)$']}
                        errorMessages={['A mező kitöltése kötelező', "A beírt szó helytelen."]}
                    />
                    <DialogActions>
                        <Button onClick={this.rejectDeletion} color="primary">
                            Mégsem
                        </Button>
                        <Button color="primary" autoFocus type="submit">
                            Igen
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
        </Dialog>;
    }

    renderList = () => {
        return <>
            <Collapse in={this.state.isDeletitionSuccesfull} style={{marginBottom: 8}}>
                <Alert severity="success" onClose={() => this.setState({isDeletitionSuccesfull: false})}>Az adatok törlését megkezdtük. A folyamat akár
                5 - 10 percet is igénybe vehet az adatok méretétől függően.</Alert>
            </Collapse>

            <Collapse in={this.state.hasError} style={{marginBottom: 8}}>
                <Alert severity="error" onClose={() => this.setState({hasError: false})}>{getGeneralError(this.state.errorMessage)}</Alert>
            </Collapse>

            <Paper>
                <Toolbar style={{backgroundImage: "linear-gradient(132deg, rgba(139, 0, 0, 1) 3%, rgba(238, 43, 43, 1) 100%)"}}>
                    <AiFillWarning style={{fontSize: 32, marginRight: 10}}/>
                    <Typography variant="h5" id="tableTitle" component="div">
                        SAJÁT FELELŐSSÉGRE
                    </Typography>
                </Toolbar>
                <Typography variant="body1" id="tableTitle" component="div" style={{padding: 10, fontWeight: 800}}>
                    A menüpont használata és ezzel a forgalmi adatok törlése saját felelősségre történik. A törölt adatokat
                    semmilyen módon nincs lehetőség visszaállítani! <br/>
                    A folyamat során a következő adatok fognak törlődni:<br/>
                    <ul>
                        <li>Eladások</li>
                        <li>Raktárkészlet műveletek</li>
                    </ul>
                </Typography>
                <Typography variant="body1" id="tableTitle" component="div" style={{padding: 10, fontWeight: 800}}>
                    A Töröl gomb megnyomása után lehetőség van a törlés dátumát kiválasztani.
                </Typography>
            </Paper>

            <Paper style={{marginTop: 20}}>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Forgalmi adatok törlése
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">Üzlet</TableCell>
                                <TableCell align="center">Cím</TableCell>
                                <TableCell align="center">Állapot</TableCell>
                                <TableCell align="center">Adatok</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.shops
                            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                            .map((s, i) => {
                                    return <TableRow key={s.id} hover className="cursor-pointer" >
                                        <TableCell>{s.name}</TableCell>
                                        <TableCell align="center">{`${s.address.zipCode} ${s.address.city} ${s.address.street}`}</TableCell>
                                        <TableCell align="center">{s.enabled ? "Aktív" : "Szünetel"}</TableCell>
                                        <TableCell align="center">
                                            {this.state.deleteInProgress && this.state.shopToDelete.id === s.id
                                                ? <CircularProgress color="primary"/>
                                                :<Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{color: 'white'}}
                                                    onClick={() => this.initiateDeletion(s)}>
                                                    Töröl
                                                </Button>
                                            }

                                        </TableCell>
                                    </TableRow>
                                }
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.shops.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>

            {this.renderDeleteConfigDialog()}
            {this.renderDeleteDialog()}
            <section className="error">{getGeneralError(this.state.error)}</section>
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            {this.state.isAdmin
                ?<NewItemButton
                    title="Üzlet"
                    onClick={this.initiateCreate}/>
                : undefined
            }
        </>;
    }
};
