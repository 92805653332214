import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Paper,
    Toolbar,
    Typography
} from "@material-ui/core";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import { DataGrid } from "@material-ui/data-grid";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

export default class NtakShopConfigPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shops: [],
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            showVerificationDialog: false,
            ntakVerificationResult: null,
            ntakVerificationInProgress: false,
            columns: [
                {field: 'name', headerName: 'Üzlet neve', width: 180},
                {field: 'ntakState', headerName: 'Ntak adatküldés', align: 'center', headerAlign: 'center', width: 180, renderCell: this.renderTrueFalse},
                {field: 'regNumber', headerName: 'Ntak reg. szám', align: 'center', headerAlign: 'center', width: 160},
                {field: 'taxNumber', headerName: 'Adószám', align: 'center', headerAlign: 'center', width: 160},
                {field: 'cer', headerName: '.cer fájl', align: 'center', headerAlign: 'center', width: 110,},
                {field: 'key', headerName: '.key fájl', align: 'center', headerAlign: 'center', width: 110,},
                {field: 'test', headerName: 'Művelet', align: 'center', headerAlign: 'center', width: 150, renderCell: this.renderAction},
            ],
            rows: [],
            isLoading: false
        }
    }

    renderTrueFalse = (params) => {
        return params.value
            ? <CheckCircleOutline style={{color: "#4caf50"}} />
            : <RemoveCircle style={{color: "#f44336"}}/>
    }

    renderAction = (params) => {
        return params.value
            ? this.state.ntakVerificationInProgress
                ?<CircularProgress color="primary"/>
                :<Button
                    variant="contained"
                    color="primary"
                    style={{color: 'white'}}
                    onClick={() => this.verifyNTAKData(params.id)}>
                    Teszt
                </Button>
            : "-"
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        this.setState({isLoading: true})
        axiosCommon(`${BASE_URL}/api/shops`)
            .then(response => {
                const shops = response.data.items
                const rows = shops.map((e) => {
                    return {
                        id: e.id,
                        name: e.name,
                        ntakState: e.ntakData.ntakDataSendingEnabled,
                        regNumber: e.ntakData.ntakRegistrationNumber || "-",
                        taxNumber: e.ntakData.ntakTaxNumber || "-",
                        cer: e.ntakData.certFile != null ? e.ntakData.certFile.name : "-",
                        key: e.ntakData.keyFile != null ? e.ntakData.keyFile.name : "-",
                        test: e.ntakData.ntakDataSendingEnabled,
                    }
                });
                this.setState({ shops, rows})
            })
            .catch(error => {
                this.setState({error})
            })
            .finally(() => this.setState({isLoading: false}))
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/ntak/editshopconfig",
            shopId: id,
        });
    };

    verifyNTAKData = (shopId) => {
        this.setState({ntakVerificationInProgress: true})
        axiosCommon.get(`${BASE_URL}/api/ntak/verify/${shopId}`)
        .then(response => {
            this.setState({ntakVerificationResult: response.data, showVerificationDialog: true})
        }).catch(error => {
            this.setState({ntakVerificationResult: error.data, showVerificationDialog: true})
        }).finally(() => this.setState({ntakVerificationInProgress: false}));
    }

    handlePageSizeChange = (entry) => this.setState({ rowsPerPage: parseInt(entry, 10) })

    onCloseVerificationDialog = () => this.setState({showVerificationDialog: false})

    renderVerificationDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.showVerificationDialog}
            onClose={this.onCloseVerificationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">NTAK adatok ellenőrzése</DialogTitle>
            <DialogContent>
                <Typography>{this.state.ntakVerificationResult}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseVerificationDialog} color="primary">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            NTAK Adatküldés beállítása
                        </Typography>
                    </Toolbar>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        pagination
                        onPageSizeChange={this.handlePageSizeChange}
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        pageSize={this.state.rowsPerPage}
                        loading={this.state.isLoading}
                        onRowDoubleClick={(rowData) => this.initiateEdit(rowData.row.id)}
                    />
                </Paper>
                {this.renderVerificationDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
