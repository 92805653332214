import React from 'react';
import {Card as MaterialCard, CardContent, CardHeader} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    card: {
        flex: 1,
        overflow: 'visible',
    },
    cardHeader: {
        borderBottom: "1px solid #ccc",
        padding: 10,
        paddingLeft: 24,
        color: "var(--color-primary)",
    },
});

function Card(props){
    const classes = useStyles();
    return(
        <MaterialCard className={classes.card} spacing="2">
            {props.title
                ? <CardHeader title={props.title} titleTypographyProps={{variant: 'h5'}} className={classes.cardHeader}/>
                : <></>
            }
            <CardContent>
                {props.children}
            </CardContent>
        </MaterialCard>
    );
}

export default Card;
