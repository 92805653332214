import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import formatDate from "../../../util/formatDate";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import truncateString from "../../../util/truncateString";
import {
    getBoolenValue, getDate,
    getKeyword, getListItems,
    getPageNumber,
    getRowsPerPage, removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import formatNumber from "../../../util/formatNumber";
import formatDateTime from "../../../util/formatDateTime";

export default class CustomerInventoryCheckPage extends React.Component {
    static DATE_FORMAT_REGEX = "2[0-9]{3}-[0-9]{2}-[0-9]{2}";

    constructor() {
        super();
        this.state = {
            inventoryChecks: [],
            keyword: "",
            fromDate: null,
            toDate: null,
            availableShops: [],
            selectedShops: [],
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
        };
        this.pageKey = 'inventoryCheckPagePageKey'
        this.rowsPerPageKey = 'inventoryCheckPageRowsPerPageKey'
        this.keywordKey = 'inventoryCheckPageKeyword'
        this.selectedShopsKey = 'inventoryCheckPageSelectedShops'
        this.fromDateKey = 'inventoryCheckPageFromDate'
        this.toDateKey = 'inventoryCheckPageToDate'
    }

    componentDidMount() {
        this.handleNavigation()
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedShopsKey, JSON.stringify(this.state.selectedShops));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
        saveSessionKey(this.fromDateKey, this.state.fromDate)
        saveSessionKey(this.toDateKey, this.state.toDate)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            const selectedShops = getListItems(this.selectedShopsKey)
            const fromDate = getDate(this.fromDateKey)
            const toDate = getDate(this.toDateKey)
            this.setState({ page, rowsPerPage, keyword, selectedShops, fromDate, toDate}, () => this.refreshData())
        }else{
            removeSessionKeys([
                this.pageKey, this.rowsPerPageKey, this.keywordKey, this.selectedShopsKey, this.fromDateKey, this.toDateKey
            ])
            this.refreshData()
        }
    }

    refreshData = () => {
        const queryParams = {pageSize: this.state.rowsPerPage, pageNumber: this.state.page}
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const shopIds = this.state.selectedShops.map(s => s.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (shopIds !== "") {
            queryParams.shopIds = shopIds
        }
        if (this.state.fromDate) {
            queryParams.fromDate = formatDate(this.state.fromDate)
        }
        if (this.state.toDate) {
            queryParams.toDate = formatDate(this.state.toDate)
        }

        axiosCommon(`${BASE_URL}/api/inventories/checks?`, { params: queryParams })
            .then(response => {
                this.setState({
                    inventoryChecks: response.data.items,
                    count: response.data.count,
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        axiosCommon(`${BASE_URL}/api/shops`)
            .then(response => {
                this.setState({
                    availableShops: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
    };

    calculateDifferenceValue = (checkItem) => {
        let sum = 0
        checkItem.items.forEach(item => {
            if(item.originalQuantity != item.updatedQuantity){
                sum += (item.updatedQuantity - item.originalQuantity) * item.acquisitionPrice
            }
        })

        if(sum > 0) return <div style={{color: "green"}}>{`+${formatNumber(sum, 1)} Ft`}</div>
        if(sum < 0) return <div style={{color: "#f44336"}}>{`${formatNumber(sum, 1)} Ft`}</div>
        return '0 Ft'
    }

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/inventories/editcheck",
            inventoryCheckId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/inventories/editcheck",
        });
    };

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShops: value, page: 0}, () => this.refreshData())
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableShops}
                            onChange={this.onChangeShopFilter}
                            multiple
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShops || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a leírásban">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Leltár / Stand
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dátum</TableCell>
                                    <TableCell align='center'>Üzlet</TableCell>
                                    <TableCell>Leírás</TableCell>
                                    <TableCell align="center" title="Megmutatja, hogy a leltár/standolás során, hány olyan termék van, amelynek eredeti és új mennyisége között eltérés van.">Különbségek</TableCell>
                                    <TableCell align="center" title="Beszerzési áron számított különbség">Eltérés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.inventoryChecks.map((r, i) => {
                                    return <TableRow key={r.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(r.id)}>
                                        <TableCell>{formatDateTime(r.creationDateTime)}</TableCell>
                                        <TableCell align='center'>{r.shop.name}</TableCell>
                                        <TableCell className="break-line">{truncateString(r.description, 127)}</TableCell>
                                        <TableCell align="center">
                                            {r.numberOfDifferences > 0
                                                ? `${r.numberOfDifferences} db`
                                                : ''
                                            }</TableCell>

                                        <TableCell align="center">{this.calculateDifferenceValue(r)}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count }
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Leltár"
                onClick={this.initiateCreate}/>
        </>;
    }
}
