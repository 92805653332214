import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import { Checkbox, Chip, IconButton, MenuItem, TextField, Toolbar, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import axios from "axios";

export default class EditStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                lastName: null,
                firstName: null,
                email: null,
                phone: null,
                address: {
                    street: null,
                    city: null,
                    zipCode: null,
                },
                password: '',
                employeeJobTitleId: null,
                consumerTypeId: null,
                enabled: true,
                role: "ROLE_EMPLOYEE",
                accessibleShopIds: [],
                code: null,
            },
            editor: null,
            selectedRole: null,
            roleOptions: [],
            categoryOptions: [],
            passwordAgain: '',
            isSubmitInProgress: false,
            filteredShopOptions: [],
            accessibleShops: [],
            allOption: { label: "Összes", value: -1 },
            shopOptions: [],
        };
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });


        axios.all([
                    axiosCommon.get(`${BASE_URL}/api/users/me`),
                    axiosCommon.get(`${BASE_URL}/api/consumercategories`),
                    axiosCommon.get(`${BASE_URL}/api/jobtitles`),
                    axiosCommon.get(`${BASE_URL}/api/shops/basic`)])
            .then((response) => {
                const editor = response[0].data

                const categoryOptions = response[1].data.items.map(c => {return {label: c.name, value: c.id}})
                categoryOptions.unshift({label: "Nincs", value: null})

                const roleOptions = response[2].data.items.map(r => { return { label: r.name, value: r.id }})

                const shopOptions = response[3].data.items.map(c => {return { label: c.name, value: c.id }})
                shopOptions.unshift(this.state.allOption)
                this.setState({editor, categoryOptions, roleOptions, shopOptions}, () => {
                    if (this.props.location.userId) {
                        axiosCommon.get(`${BASE_URL}/api/employees/${this.props.location.userId}`)
                        .then(response => {
                            let {user} = this.state;
                            user = response.data;
                            user.password = user.password ? user.password : '';

                            const accessibleShops = this.state.shopOptions.filter(shop => user.accessibleShopIds?.includes(shop.value) === true)
                            let filteredShopOptions = []
                            if(this.state.editor.accessibleShopIds?.includes(-1)) filteredShopOptions = this.state.shopOptions
                            else {
                                filteredShopOptions = this.state.shopOptions.filter(shop => this.state.editor.accessibleShopIds?.includes(shop.value) === true)
                            }

                            this.setState({
                                user,
                                selectedRole: this.state.roleOptions.filter(r => r.value === response.data.employeeJobTitle.id)[0],
                                selectedCategory: this.state.categoryOptions.filter(r => r.value === response.data.consumerType?.id)[0] || {label: "Nincs", value: null},
                                accessibleShops,
                                filteredShopOptions,
                            })

                        })
                        .catch(error => {
                            this.setState({error})
                        });
                    }else {
                        const accessibleShops = this.state.shopOptions.filter(shop => this.state.editor.accessibleShopIds?.includes(shop.value) === true)
                        const filteredShopOptions = accessibleShops
                        this.setState({ accessibleShops,  filteredShopOptions})
                    }
                })
            })
            .catch(error => {this.setState({error})
        })
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    onSubmit = () => {
        if(this.state.passwordAgain !== this.state.user.password){
            this.setState({error: "A két jelszónak meg kell egyeznie"})
            return 0;
        }

        this.setState({isSubmitInProgress: true});

        const {user} = this.state;
        user.employeeJobTitleId = this.state.selectedRole.value
        user.consumerTypeId = this.state.selectedCategory.value

        user.accessibleShopIds = []
        this.state.accessibleShops.forEach((item) => user.accessibleShopIds.push(item.value))

        if(user.password === ''){
            user.password = undefined;
        }

        axiosCommon({
            method: this.props.location.userId ? "put" : "post",
            url: `${BASE_URL}/api/employees`,
            data: user
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                if(user.password === undefined) user.password = ''
                this.setState({error, user, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    handleAddressChange = (event) => {
        const { address } = this.state.user;
        address[event.target.name] = event.target.value;
        this.setState({ address });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.userId ? "Személyzet szerkesztése" : "Új személyzet"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Vezetéknév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="lastName"
                                value={this.state.user.lastName || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Keresztnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="firstName"
                                value={this.state.user.firstName || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Város"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="city"
                                value={this.state.user.address.city || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Irányítószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="zipCode"
                                value={this.state.user.address.zipCode || ""}
                                inputProps={{ maxLength: 10 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cím"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="street"
                                value={this.state.user.address.street || ""}
                                inputProps={{ maxLength: 150 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Telefonszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="phone"
                                value={this.state.user.phone || ""}
                                required
                                inputProps={{ maxLength: 15 }}
                                validators={['isNumber', 'isPositive']}
                                errorMessages={['A megadott érték nem szám', 'A megadott érték negatív']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.user.email || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required', 'isEmail']}
                                errorMessages={['A mező kitöltése kötelező', 'Érvénytelen email cím']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" />
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Munkáltatói adatok">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedRole: value });
                                }}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.roleOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedRole || ""}
                                renderInput={(params) =>
                                    <TextField {...params} required label="Szerepkör" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Állapot"
                                variant="outlined"
                                onChange={(event) => {
                                    const { user } = this.state;
                                    user.enabled = event.target.value === 'true';
                                    this.setState({ user });
                                }}
                                fullWidth
                                value={this.state.user.enabled === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Aktív</MenuItem>
                                <MenuItem key='0' value='false'>Szünetel</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedCategory: value });
                                }}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.categoryOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedCategory || ""}
                                renderInput={(params) =>
                                    <TextField {...params} required label="Törzsvásárlói típus" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Törzsvásárlói kód"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="code"
                                value={this.state.user?.code || ""}
                                inputProps={{ maxLength: 10 }}
                                validators={['isNumber', 'isPositive']}
                                errorMessages={['A megadott érték nem szám', 'A megadott érték negatív']}
                            />
                        </div>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                multiple
                                onChange={(event, value) => {
                                    let filteredShopOptions = []
                                    if(this.state.editor.accessibleShopIds?.includes(-1)) filteredShopOptions = this.state.shopOptions
                                    else {
                                        filteredShopOptions = this.state.shopOptions.filter(shop => this.state.editor.accessibleShopIds?.includes(shop.value) === true)
                                    }

                                    let accessibleShops = value
                                    if(value.includes(this.state.allOption)){
                                        accessibleShops = []
                                        accessibleShops.push(this.state.allOption)
                                    }

                                    this.setState({ accessibleShops, filteredShopOptions})
                                }}
                                name="accesibleShops"
                                fullWidth
                                disableClearable
                                disableCloseOnSelect
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.filteredShopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.accessibleShops || ""}
                                renderOption={(option, { selected }) => (
                                    <>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.label}
                                    </>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Hozzáférés a következő üzletekhez"  />
                                )}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.label}
                                            {...getTagProps({ index })}
                                            disabled={this.state.filteredShopOptions.includes(option) === false}
                                        />
                                    ))
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Jelszó megadása">
                    <Widget>
                        <div className="flex-1">
                        <TextValidator
                            label="Jelszó"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange}
                            name="password"
                            value={this.state.user.password || ""}
                            required={!this.props.location.userId}
                            validators={!this.props.location.userId ? ['required'] : []}
                            errorMessages={!this.props.location.userId ? ['A mező kitöltése kötelező'] : []}
                        />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Jelszó újra"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    this.setState({ passwordAgain:  event.target.value});
                                }}
                                value={this.state.passwordAgain || ""}
                                required={!this.props.location.userId}
                                validators={!this.props.location.userId ? ['required', 'isPasswordMatch'] : ['isPasswordMatch']}
                                errorMessages={!this.props.location.userId ? ['A mező kitöltése kötelező', 'A két jelszó különböző'] : ['A két jelszó különböző']}
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
