import { createTheme } from '@material-ui/core/styles';
import { LAYOUT_TYPE, RETESCAKE_LAYOUT } from "./util/Config";


const theme = createTheme({
    palette: {
        primary: {
            main: LAYOUT_TYPE === RETESCAKE_LAYOUT ? '#FF1617' : '#00afcf'
        },
    },
    overrides: {
        MuiTableCell: {
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "14px",
            },
        },
        MuiToolbar: {
            regular: {
                color: 'white',
                background: LAYOUT_TYPE === RETESCAKE_LAYOUT
                    ? 'linear-gradient(132deg, rgba(139, 0, 0, 1) 3%, rgba(238, 43, 43, 1) 100%)'
                    : 'linear-gradient(90deg, rgba(0,69,143,1) 0%, rgba(0,178,209,1) 100%)'
            }
        },
        MuiTablePagination: {
            toolbar: {
                backgroundImage: 'unset',
                color: 'black'
            }
        },
        MuiTab: {
            root: {
                fontSize: '1.4rem',
            },
        },
    },
});

export default theme;
