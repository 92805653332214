import React from 'react';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    videoResponsive: {
        overflow: 'hidden',
        paddingBottom: '40%',
        height: 0,
        width: '70%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            paddingBottom: '56%',
        },
    },
    iframe: {
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
}));

function Youtube(props){
    const classes = useStyles();
    return(
        <div className={classes.videoResponsive} {...props}>
            <iframe
                className={classes.iframe}
                width="853"
                height="480"
                src={`https://www.youtube-nocookie.com/embed/${props.videoid}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
}


export default Youtube;
