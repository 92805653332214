import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import formatDate from "../../../util/formatDate";
import getGeneralError from "../../../util/getGeneralError";
import Search from "../../../components/Search";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Footer from "../../../components/Footer";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import truncateString from "../../../util/truncateString";
import {
    getDate,
    getKeyword, getListItems,
    getPageNumber,
    getRowsPerPage, removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import formatDateTime from "../../../util/formatDateTime";

export default class InventoryChangeList extends React.Component {
    constructor() {
        super();
        this.state = {
            changeRequests: [],
            keyword: "",
            fromDate: null,
            toDate: null,
            availableShops: [],
            selectedShops: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
        };
    }

    componentDidMount() {
        this.pageKey = this.props.pageKey
        this.rowsPerPageKey = this.props.rowsPerPageKey
        this.keywordKey = this.props.keywordKey
        this.selectedShopsKey = this.props.selectedShopsKey
        this.fromDateKey = this.props.fromDateKey
        this.toDateKey = this.props.toDateKey
        this.handleNavigation()
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedShopsKey, JSON.stringify(this.state.selectedShops));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
        saveSessionKey(this.fromDateKey, this.state.fromDate)
        saveSessionKey(this.toDateKey, this.state.toDate)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            const selectedShops = getListItems(this.selectedShopsKey)
            const fromDate = getDate(this.fromDateKey)
            const toDate = getDate(this.toDateKey)
            this.setState({ page, rowsPerPage, keyword, selectedShops, fromDate, toDate}, () => this.refreshData())
        }else{
            removeSessionKeys([
                this.pageKey, this.rowsPerPageKey, this.keywordKey, this.selectedShopsKey, this.fromDateKey, this.toDateKey
            ])
            this.refreshData()
        }
    }

    refreshData = () => {
        const queryParams = {pageSize: this.state.rowsPerPage, pageNumber: this.state.page}
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const shopIds = this.state.selectedShops.map(s => s.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (shopIds !== "") {
            queryParams.shopIds = shopIds
        }
        if (this.props.kind === "REFILL") {
            queryParams.kinds = "REFILL,MOVE_IN"
        } else if(this.props.kind === "PRODUCE"){
            queryParams.kinds = "PRODUCE"
        } else {
            queryParams.kinds = "CHARGE,WASTE,MOVE_OUT"
        }
        if (this.state.fromDate) {
            queryParams.fromDate = formatDate(this.state.fromDate)
        }
        if (this.state.toDate) {
            queryParams.toDate = formatDate(this.state.toDate)
        }

        axiosCommon(`${BASE_URL}/api/inventories/changeRequests?`, { params: queryParams })
            .then(response => {
                this.setState({
                    changeRequests: response.data.items,
                    count: response.data.count,
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error
                })
            });
        axiosCommon(`${BASE_URL}/api/shops`)
            .then(response => {
                this.setState({
                    availableShops: response.data.items
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error
                })
            });
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/inventories/changeRequests/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/inventories/editchange",
            changeRequestId: id,
            kind: this.props.kind,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/inventories/editchange",
            kind: this.props.kind,
        });
    };

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShops: value, page: 0 }, () => this.refreshData())
    };

    stateString = (state) => {
        switch (state) {
            case "PENDING":
                return "Függőben";
            case "ACKNOWLEDGED":
                if(this.props.kind === "REFILL") return "Beérkezett"
                if(this.props.kind === "PRODUCE") return "Legyártott"
                return "Kivételezett"
            case "REJECTED":
                return "Törölt";
            default:
                return "Hibás adat";
        }
    }

    shopByShopId = (id) => this.state.availableShops.filter(s => s.id === id)[0];

    typeString = (type) => {
        switch (type) {
            case "REFILL":
                return "Bevételezés";
            case "MOVE_IN":
                return "Árumozgatás ide";
            case "CHARGE":
                return "Kivételezés";
            case "WASTE":
                return "Selejtezés";
            case "MOVE_OUT":
                return "Árumozgatás innen";
            case "PRODUCE":
                return "Előállítás"
            default:
                return "Hibás adat";
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0}, () => this.refreshData());
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a tételt?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    getTitle = () => {
        if(this.props.kind === "REFILL") return "Bevételezés / Megrendelés"
        if(this.props.kind === "PRODUCE") return "Előállítás"
        return "Kivételezés / Mozgatás"
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableShops}
                            onChange={this.onChangeShopFilter}
                            multiple
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShops || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a leírásban">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            {this.getTitle()}
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dátum</TableCell>
                                    <TableCell width="15%">Üzlet</TableCell>
                                    <TableCell>Típus</TableCell>
                                    <TableCell width="25%">Leírás</TableCell>
                                    <TableCell>Költség</TableCell>
                                    <TableCell>Állapot</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.changeRequests.map((r, i) => {
                                    return <TableRow key={r.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(r.id)}>

                                        <TableCell>{formatDateTime(r.creationDateTime)}</TableCell>
                                        <TableCell>{this.shopByShopId(r.shopId)?.name}</TableCell>
                                        <TableCell>{this.typeString(r.kind)}</TableCell>
                                        <TableCell className="break-line">{truncateString(r.description, 127)}</TableCell>
                                        <TableCell>{r.totalPrice} Ft</TableCell>
                                        <TableCell>{this.stateString(r.state)}</TableCell>
                                        <TableCell padding="none" align="right">
                                            {r.state === "ACKNOWLEDGED" || r.kind === "MOVE_IN"
                                                ? ""
                                                : <IconButton aria-label="delete" onClick={() => this.initiateDeletion(r)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Tétel"
                onClick={this.initiateCreate}/>
        </>;
    }
}
