import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, CircularProgress, IconButton, Link, Typography} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {FaFacebookSquare, FaInstagramSquare} from "react-icons/fa";
import {doLogin} from "../util/axiosSetup";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: '1100px',
        margin: 10,
        flexDirection: "row",
        backgroundColor: 'transparent',
        textAlign: "center",
        borderRadius: 5,
        filter: 'drop-shadow(3px 4px 2px #999999)',
        [theme.breakpoints.down('md')]: {
            flex: 1,
            width: 'auto',
            flexDirection: "column",
        },
    },
    contentSection: {
        flex: 1.5,
        backgroundColor: "#FF1617",
        borderRadius: '5px 0px 0px 5px',
        paddingTop: 100,
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
    },
    formSection: {
        flex: 1,
        paddingTop: 24,
        paddingBottom: 8,
        backgroundColor: 'white',
        borderRadius: '0px 5px 5px 0px',
        [theme.breakpoints.down('md')]: {
            borderRadius: 5,
        },
    },
    string: {
        backgroundImage: `url(/img/retescake_string.svg)`,
        height: 110,
        width: "100%",
        marginBottom: 150,
        marginTop: -10,
        filter: 'drop-shadow(3px 4px 2px #999999)',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10,
            height: 70,
        },
    },
}));

function RetescakeLoginPage(props){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onLoginSuccess = () => {
        const role = sessionStorage.getItem("role");
        switch (role) {
            case "ROLE_ADMIN":
                props.history.push("/admin");
                break;
            default:
                props.history.push("/customer");
        }
    };

    const onLoginFailure = () => {
        setError("Sikertelen belépés")
        setFormSubmitted(false);
    };

    const onSubmit = () => {
        if(!formSubmitted){
            setFormSubmitted(true);
            doLogin(email, password, onLoginSuccess, onLoginFailure)
        }
    };

    return(
        <div className="login-area" style={{backgroundColor: "#d9d7d7"}}>
            <div className={useStyles().string}/>

            <div className="flex justify-center align-center">
                <div className={useStyles().root}>
                    <section className={useStyles().contentSection}>
                        <img src='/img/retescake_logo.svg' alt='logo' style={{width: 500}}/>
                    </section>

                    <section className={useStyles().formSection}>
                        <Typography variant="h4" paragraph>Üdvözlünk!</Typography>
                        <Typography variant="body2" style={{marginBottom: 16}}>A folytatáshoz add meg az email címed és jelszavadat.</Typography>
                        <ValidatorForm onSubmit={onSubmit}>
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                style={{width: "80%"}}
                                name="email"
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={['A mező kitöltése kötelező', 'Érvénytelen email']}
                            />
                            <TextValidator
                                label="Password"
                                variant="outlined"
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                                margin="normal"
                                style={{width: "80%"}}
                                name="password"
                                type="password"
                                value={password}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                            <section style={{color: "#ff2a00", textAlign: "center"}}>
                                {error}
                            </section>

                            {!formSubmitted
                                ? <Button type="submit" variant="contained" color="primary"
                                          style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20}}>Belépés</Button>
                                : <div style={{marginTop: 30, textAlign: "center"}}>
                                    <CircularProgress color="primary"/>
                                </div>
                            }
                        </ValidatorForm>

                        <Typography variant="body2" style={{marginTop: 50, color: '#0000008a'}}>Kövess bennünket a social médiában</Typography>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <IconButton title="Facebook" color="primary" onClick={() => window.open('https://www.facebook.com/retescake/')}>
                                <FaFacebookSquare/>
                            </IconButton>
                            <IconButton title="Instagram" color="primary" onClick={() => window.open('https://www.instagram.com/retescake/')}>
                                <FaInstagramSquare/>
                            </IconButton>
                        </div>

                        <Typography variant="body2" style={{marginTop: 10, color: '#0000008a'}}>Rétescake © 2023</Typography>
                        <Typography>
                            <Link variant="body2" target="_blank" href="https://www.retescake.hu">www.retescake.hu</Link>
                        </Typography>
                    </section>
                </div>
            </div>
        </div>

    );
}

export default RetescakeLoginPage;
