import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import formatDateTime from "../../../util/formatDateTime";
import moment from "moment";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper, Tab,
    TextField,
    Typography
} from "@material-ui/core";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import HighlightCard from "../../../components/HighlightCard";
import NavHeader from "../../../components/NavHeader";
import ShowCarts from "../ShowCarts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatPaymentType from "../../../util/formatPaymentType";
import formatNumber from "../../../util/formatNumber";
import { getCartDiscountValue, getCartTotalPrice } from "../../../util/CartPriceCalculator";

export default class    CartListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            debts: [],
            entries: [],
            deletes: [],
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            error: null,
            debtRowsPerPage: RowsPerPageOptions.defaultPageSize,
            entryRowsPerPage: RowsPerPageOptions.defaultPageSize,
            deleteRowsPerPage: RowsPerPageOptions.defaultPageSize,
            tabIndex: 0,
            columns: [
                {field: 'date', headerName: 'Dátum', width: 160},
                {field: 'customer', headerName: 'Vásárló/Személyzet', align: 'center', headerAlign: 'center', width: 220,},
                {field: 'table', headerName: 'Asztal', align: 'center', headerAlign: 'center', width: 200,},
                {field: 'seller', headerName: 'Eladó', align: 'center', headerAlign: 'center', width: 220},
                {field: 'paymentType', headerName: 'Fizetési mód', align: 'center', headerAlign: 'center', width: 150},
                {field: 'discount', headerName: 'Kedvezmény (Ft)', align: 'center', headerAlign: 'center', width: 170, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'value', headerName: 'Összeg (Ft)', align: 'center', headerAlign: 'center', width: 130, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'isCartReturned', headerName: 'Sztornó', align: 'center', headerAlign: 'center', width: 120, valueFormatter: (params) => params.value === true ? 'Igen' : ""},],
            payedCartRows: [],
            debtCartRows: [],
            deletesCartRows: [],
            isLoadingPayments: false,
            isLoadingDebts: false,
            isLoadingDeletes: false,
        };
    }

    componentDidMount() {
        this.setState({isLoadingPayments: true, isLoadingDebts: true, isLoadingDeletes: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;
                this.setState({
                    shopOptions,
                    selectedShopOption,
                }, () => this.refreshData());
            });
    }

    refreshData = () => {
        this.setState({isLoadingPayments: true, isLoadingDebts: true, isLoadingDeletes: true})
        this.refreshDebtCarts()
        this.refreshPayedCarts()
        this.refreshDeletedCarts()
    }

    refreshDebtCarts= () => {
        const debtParams = {
            status: "ACTIVE",
            sort: "creationDateTime",
        }

        if (this.state.selectedShopOption) {
            debtParams.shopId = this.state.selectedShopOption.id;
        }else {
            debtParams.shopId = -1;
        }

        axiosCommon(`${BASE_URL}/api/carts`, {
            params: debtParams
        })
            .then(response => {
                const debts = response.data.items
                const debtCartRows = debts.map((e) => {
                    return {
                        id: e.id,
                        date: formatDateTime(e.creationDateTime),
                        customer: this.formatCustomer(e),
                        table: e.orderLocation ? e.orderLocation.name : "-",
                        seller: e.initiator ? `${e.initiator.lastName} ${e.initiator.firstName}` : "-",
                        paymentType: formatPaymentType(e.paymentType),
                        discount: getCartDiscountValue(e),
                        value: getCartTotalPrice(e),
                        isCartReturned: e.isCartReturned
                    }
                });
                this.setState({
                    debts,
                    isLoadingDebts: false,
                    debtCartRows,
                })
            })
            .catch(error => {
                this.setState({
                    error,
                    isLoadingDebts: false,
                })
            });
    }

    refreshPayedCarts = () => {
        const entryParams = {
            status: "PAID",
        }
        if (this.state.selectedShopOption) {
            entryParams.shopId = this.state.selectedShopOption.id;
            const turnAroundTime = this.state.selectedShopOption.turnAroundTime.split(":");
            if (this.state.fromDate) {
                entryParams.fromDateTime = moment(this.state.fromDate)
                    .set({"hour": turnAroundTime[0], "minute": turnAroundTime[1], "second": 0})
                    .format("YYYY-MM-DD[T]HH:mm:ss");
            }

            if (this.state.toDate) {
                entryParams.toDateTime = moment(this.state.toDate)
                    .add(1, 'days')
                    .set({"hour": turnAroundTime[0], "minute": turnAroundTime[1], "second": 0})
                    .format("YYYY-MM-DD[T]HH:mm:ss");
            }
        }else {
            entryParams.shopId = -1
        }

        axiosCommon(`${BASE_URL}/api/carts`, {
            params: entryParams
        })
            .then(response => {
                const entries = response.data.items;
                const payedCartRows = entries.map((e) => {
                    return {
                        id: e.id,
                        date: formatDateTime(e.paymentDateTime),
                        customer: this.formatCustomer(e),
                        table: e.orderLocation ? e.orderLocation.name : "-",
                        seller: e.initiator ? `${e.initiator.lastName} ${e.initiator.firstName}` : "-",
                        paymentType: formatPaymentType(e.paymentType),
                        discount: getCartDiscountValue(e),
                        value: e.totalPaid,
                        isCartReturned: e.isCartReturned
                    }
                });

                this.setState({
                    entries,
                    payedCartRows,
                    isLoadingPayments: false,
                })
            })
            .catch(error => {
                this.setState({
                    error,
                    isLoadingPayments: false,
                })
            });
    }

    refreshDeletedCarts = () => {
        const entryParams = { status: "DELETED", sort: "deleteDateTime", }

        if (this.state.selectedShopOption) {
            entryParams.shopId = this.state.selectedShopOption.id;
            const turnAroundTime = this.state.selectedShopOption.turnAroundTime.split(":");
            if (this.state.fromDate) {
                entryParams.fromDeleteDateTime = moment(this.state.fromDate)
                    .set({"hour": turnAroundTime[0], "minute": turnAroundTime[1], "second": 0})
                    .format("YYYY-MM-DD[T]HH:mm:ss")
            }

            if (this.state.toDate) {
                entryParams.toDeleteDateTime = moment(this.state.toDate)
                    .add(1, 'days')
                    .set({"hour": turnAroundTime[0], "minute": turnAroundTime[1], "second": 0})
                    .format("YYYY-MM-DD[T]HH:mm:ss")
            }
        }else {
            entryParams.shopId = -1
        }

        axiosCommon(`${BASE_URL}/api/carts`, {
            params: entryParams
        })
        .then(response => {
            const deletes = response.data.items;
            const deletesCartRows = deletes.map((e) => {
                return {
                    id: e.id,
                    date: formatDateTime(e.deleteDateTime),
                    customer: this.formatCustomer(e),
                    table: e.orderLocation ? e.orderLocation.name : "-",
                    seller: e.initiator ? `${e.initiator.lastName} ${e.initiator.firstName}` : "-",
                    paymentType: formatPaymentType(e.paymentType),
                    discount: getCartDiscountValue(e),
                    value: getCartTotalPrice(e),
                    isCartReturned: e.isCartReturned
                }
            });

            this.setState({ deletes, deletesCartRows, })
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({isLoadingDeletes: false}))
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    afterCheck = () => {
        this.setState({
            checkInProgress: null
        });
    };

    debtTotal = () => {
        const sum = this.state.debts.map(e => getCartTotalPrice(e)).reduce((a, b) => a + b, 0)
        return (sum?.length !== 0) ? sum : "0"
    }

    deletesTotal = () => {
        const sum = this.state.deletes.map(e => getCartTotalPrice(e)).reduce((a, b) => a + b, 0)
        return (sum?.length !== 0) ? sum : "0"
    }

    formatCustomer = (cart) => {
        if (cart.employeeConsumer) {
            return `${cart.employeeConsumer.lastName} ${cart.employeeConsumer.firstName}`;
        }
        if (cart.consumer) {
            return `${cart.consumer.lastName} ${cart.consumer.firstName}`;
        }
        return "-"
    }

    handleChangeRowsPerDebtPage = (entry) => {
        this.setState({ debtRowsPerPage: parseInt(entry, 10) });
    };

    handleChangeRowsPerEntryPage = (entry) => {
        this.setState({ entryRowsPerPage: parseInt(entry, 10)});
    };

    handleChangeRowsPerDeletesPage = (entry) => {
        this.setState({ deleteRowsPerPage: parseInt(entry, 10)});
    };

    renderShowCart = () => {
        return this.state.checkInProgress
            ?<ShowCarts
                open={this.state.checkInProgress !== null}
                cartId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Kifizetett"/>
                    <Tab label="Függő"/>
                    <Tab label="Törölt"/>
                </TabList>

                <TabPanel value={this.state.tabIndex} index={0}>
                    <Widget>
                        <Card title="Szűrés üzlet alapján">
                            <Autocomplete
                                fullWidth
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                onChange={this.onChangeShopFilter}
                                disableClearable
                                getOptionLabel={(option) => option === "" ? "" : option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" variant="outlined" />
                                }
                            />
                        </Card>
                        <div className="m-8"/>
                        <Card title="Szűrés dátum alapján">
                            <StyledDateRangePicker
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                maximumLength={30}
                                fromDateChange={(date) =>{
                                    this.setState({fromDate: date})
                                }}
                                toDateChange={(date) => {
                                    this.setState({toDate: date}, () => date != null ? this.refreshData() : undefined)
                                }}
                            />
                        </Card>
                    </Widget>
                    <Paper>
                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.payedCartRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            onPageSizeChange={this.handleChangeRowsPerEntryPage}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.entryRowsPerPage}
                            pagination
                            loading={this.state.isLoadingPayments}
                            onRowDoubleClick={(rowData) => this.setState({checkInProgress: rowData.row.id})}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}
                            getRowClassName={(params) => {
                                if(params.row.isCartReturned) return 'cart-row-orange'
                                return '';
                            }} />
                    </Paper>
                </TabPanel>

                <TabPanel value={this.state.tabIndex} index={1}>
                    <section className="flex justify-flex-end mb-16 gap-8" >
                        <HighlightCard
                            title="Függő számlaösszeg (Ft)"
                            tooltip="A ki nem fizetett számlák összege.">
                            <Typography variant="h3" align="center">
                                {Number(this.debtTotal()).toLocaleString('hu-HU', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2})
                                }
                            </Typography>
                        </HighlightCard>
                    </section>

                    <Widget>
                        <Card title="Szűrés üzlet alapján">
                            <Autocomplete
                                fullWidth
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                onChange={this.onChangeShopFilter}
                                disableClearable
                                getOptionLabel={(option) => option === "" ? "" : option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" variant="outlined" />
                                }
                            />
                        </Card>
                        <div style={{flex: 1}}/>
                    </Widget>
                    <Paper>
                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.debtCartRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            onPageSizeChange={this.handleChangeRowsPerDebtPage}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.debtRowsPerPage}
                            pagination
                            loading={this.state.isLoadingDebts}
                            onRowDoubleClick={(rowData) => this.setState({checkInProgress: rowData.row.id})}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}/>
                    </Paper>
                </TabPanel>

                <TabPanel value={this.state.tabIndex} index={2}>
                    <section className="flex justify-flex-end mb-16 gap-8" >
                        <HighlightCard
                            title="Törölt számlaösszeg (Ft)"
                            tooltip="A törölt számlák összege.">
                            <Typography variant="h3" align="center">
                                {Number(this.deletesTotal()).toLocaleString('hu-HU', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2})
                                }
                            </Typography>
                        </HighlightCard>
                    </section>

                    <Widget>
                        <Card title="Szűrés üzlet alapján">
                            <Autocomplete
                                fullWidth
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                onChange={this.onChangeShopFilter}
                                disableClearable
                                getOptionLabel={(option) => option === "" ? "" : option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" variant="outlined" />
                                }
                            />
                        </Card>
                        <div className="m-8"/>
                        <Card title="Szűrés dátum alapján">
                            <StyledDateRangePicker
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                fromDateChange={(date) =>{
                                    this.setState({fromDate: date})
                                }}
                                toDateChange={(date) => {
                                    this.setState({toDate: date}, () => date != null ? this.refreshData() : undefined)
                                }}
                            />
                        </Card>
                    </Widget>
                    <Paper>
                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.deletesCartRows}
                            className="cursor-pointer"
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            onPageSizeChange={this.handleChangeRowsPerDeletesPage}
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            pageSize={this.state.deleteRowsPerPage}
                            pagination
                            loading={this.state.isLoadingDeletes}
                            onRowDoubleClick={(rowData) => this.setState({checkInProgress: rowData.row.id})}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport/>
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}
                            getRowClassName={(params) => {
                                if(params.row.isCartReturned) return 'cart-row-orange'
                                return '';
                            }} />
                    </Paper>
                </TabPanel>

                {this.renderShowCart()}

            </section>

            <Footer/>
        </section>;
    }
}
