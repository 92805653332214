import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, MenuItem, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export default class EditConsumer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            categoryOptions: [],
            selectedCategory: null,
            consumer: {
                lastName: null,
                firstName: null,
                email: null,
                phone: null,
                address: {
                    street: null,
                    city: null,
                    zipCode: null,
                code: null,
                },
                note: null,
                consumerTypeId: null,
                enabled: true,
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/consumercategories`)
            .then(response => {
                const categoryOptions = response.data.items.map(c => {
                    return {label: c.name, value: c.id}
                });
                categoryOptions.unshift({label: "Nincs", value: null})
                this.setState({
                        categoryOptions
                    },
                    () => {
                        if (this.props.location.consumerId) {
                            axiosCommon.get(`${BASE_URL}/api/consumers/${this.props.location.consumerId}`)
                                .then(response => {
                                    this.setState({
                                            consumer: response.data,
                                            selectedCategory: this.state.categoryOptions.filter(r => r.value === response.data.consumerType?.id)[0] || {label: "Nincs", value: null}
                                        }
                                    )
                                })
                                .catch(error => {
                                    this.setState({error})
                                });
                        }
                    }
                )
            })
            .catch(error => {
                this.setState({error})
            });
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {consumer} = this.state;
        consumer.consumerTypeId = this.state.selectedCategory.value

        axiosCommon({
            method: this.props.location.consumerId ? "put" : "post",
            url: `${BASE_URL}/api/consumers`,
            data: consumer
        })
            .then(response => {
               this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = (event) => {
        const { consumer } = this.state;
        consumer[event.target.name] = event.target.value;
        this.setState({ consumer });
    }

    handleAddressChange = (event) => {
        const { consumer } = this.state;
        if(consumer.address == null){
            consumer.address = {};
        }
        consumer.address[event.target.name] = event.target.value == '' ? null : event.target.value;
        this.setState({
            consumer
        });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.consumerId ? "Vásárló szerkesztése" : "Új vásárló"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Vezetéknév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="lastName"
                                value={this.state.consumer.lastName || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Keresztnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="firstName"
                                value={this.state.consumer.firstName || ""}
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.consumer.email || ""}
                                inputProps={{ maxLength: 100 }}
                                validators={['isEmail']}
                                errorMessages={['Érvénytelen email cím']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Telefonszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="phone"
                                value={this.state.consumer.phone || ""}
                                inputProps={{ maxLength: 15 }}
                                validators={['isNumber', 'isPositive']}
                                errorMessages={['A megadott érték nem szám', 'A megadott érték negatív']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Város"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="city"
                                value={this.state.consumer.address?.city || ""}
                                inputProps={{ maxLength: 100 }}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Irányítószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="zipCode"
                                value={this.state.consumer.address?.zipCode || ""}
                                inputProps={{ maxLength: 10 }}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cím"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="street"
                                value={this.state.consumer.address?.street || ""}
                                inputProps={{ maxLength: 150 }}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" />
                    </Widget>
                    <TextValidator
                        label="Jegyzet"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="note"
                        value={this.state.consumer?.note || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Törzsvásárlói adatok">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedCategory: value });
                                }}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.categoryOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedCategory || ""}
                                renderInput={(params) =>
                                    <TextField {...params} required label="Törzsvásárlói típus" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <div className="flex-1">
                                <TextValidator
                                    label="Törzsvásárlói kód"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="code"
                                    value={this.state.consumer?.code || ""}
                                    inputProps={{ maxLength: 10 }}
                                    validators={['isNumber', 'isPositive']}
                                    errorMessages={['A megadott érték nem szám', 'A megadott érték negatív']}
                                />
                            </div>
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
