import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    TableHead,
    Toolbar,
    TextField, CircularProgress, FormControlLabel, IconButton, Tooltip
} from "@material-ui/core";
import TablePagination from '@material-ui/core/TablePagination';
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getKeyword,
    getPageNumber,
    getRowsPerPage,
    getListItems,
    removeSessionKeys, saveSessionKey
} from "../../../util/SessionStorageHandler";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { getNTAKAlkategoria, ntakFokategoria } from "../../../util/NTAK";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from '@material-ui/icons/Edit';

export default class NtakProductConfigPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            products: [],
            availableCategories: [],
            selectedCategories: [],
            count: 0,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            selectedProducts: [],
            isLoading: false,
        };
        this.selectedCategoriesKey = 'ntakProductConfigPageSelectedCategories'
        this.pageKey = 'ntakProductConfigPagePageKey'
        this.rowsPerPageKey = 'ntakProductConfigPageRowsPerPageKey'
        this.keywordKey = 'ntakProductConfigPageKeyword'
        this.scrollToTheTop = React.createRef()
    }

    componentDidMount() {
        this.handleNavigation()

        axiosCommon(`${BASE_URL}/api/categories`)
        .then(response => {
            const availableCategories = response.data.items;
            const selectedCategoryForSequence = availableCategories.length > 0 ? availableCategories[0] : null;
            this.setState({ availableCategories, selectedCategoryForSequence })
        })
        .catch(error => this.setState({ error }))
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedCategoriesKey, JSON.stringify(this.state.selectedCategories));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const selectedCategories = getListItems(this.selectedCategoriesKey)
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            this.setState({ selectedCategories, page, rowsPerPage, keyword }, () => this.refreshData())
        }else{
            removeSessionKeys([this.selectedCategoriesKey, this.pageKey, this.rowsPerPageKey, this.keywordKey])
            this.refreshData()
        }
    }

    refreshData = () => {
        this.setLoading(true);
        const categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");

        const queryParams = {deleted: false}
        if(!this.state.isEditingSequence){
            queryParams.pageSize = this.state.rowsPerPage
            queryParams.pageNumber = this.state.page
        }
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        if(categoryIds !== ""){
            queryParams.categoryIds = categoryIds
        }

        axiosCommon(`${BASE_URL}/api/products?`, { params: queryParams })
            .then(response => {
                this.setState({
                    products: response.data.items,
                    count: response.data.count,
                }, () => this.setLoading(false))
            })
            .catch(error => {
                this.setLoading(false);
                this.setState({ error })
            });
    };

    setLoading = (flag) => {
        this.setState({
            isLoading: flag,
        });
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0, }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/ntak/editproductconfig",
            ids: [id],
        });
    };

    initiateBulkEdit= () => {
        this.props.history.push({
            pathname: "/ntak/editproductconfig",
            ids: this.state.selectedProducts,
        });
    };

    onChangeCategoryFiler = (event, value) => {
        this.setState({ selectedCategories: value, page: 0, selectedProducts: []}, () => this.refreshData())
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, selectedProducts: []}, () => this.refreshData())
        this.scrollToTheTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0, selectedProducts: []}, () => this.refreshData());
    };

    onChangeCheckbox = (i) => {
        const {selectedProducts} = this.state;
        const selectedIndex = selectedProducts.indexOf(i);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedProducts, i);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedProducts.slice(1));
        } else if (selectedIndex === selectedProducts.length - 1) {
            newSelected = newSelected.concat(selectedProducts.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedProducts.slice(0, selectedIndex),
                selectedProducts.slice(selectedIndex + 1),
            );
        }

        this.setState({
            selectedProducts: newSelected
        })
    };

    isRowSelected = (i) => this.state.selectedProducts.indexOf(i) !== -1;

    handleSelectAllClick = () => {
        const newSelected = [];
        if(this.state.selectedProducts.length === 0){
            this.state.products.forEach(p => newSelected.push(p.id))
        }
        this.setState({ selectedProducts: newSelected })
    };

    renderToolbar = () => {
        return <Toolbar>
            {this.state.selectedProducts.length === 0
                ?<>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Termékek
                    </Typography>
                    {this.state.isLoading == true
                        ? <CircularProgress style={{ marginLeft: 10, color: 'white', height: 30, width: 30 }} />
                        : ''
                    }
                </>
                :<>
                    <Typography variant="h5" id="tableTitle" component="div">
                        {this.state.selectedProducts.length} kiválasztott termék:
                    </Typography>
                    <IconButton style={{marginLeft: 10}} onClick={this.initiateBulkEdit}>
                        <Tooltip title="Termékek szerkesztése">
                            <EditIcon style={{color: "white"}} fontSize="small"/>
                        </Tooltip>
                    </IconButton>
                </>
            }
        </Toolbar>
    }

    renderColorBox = (color) => {
        return <div style={{width: 52.5, height: 52.5, background: color}}/>
    }

    hasNtakConfig = (product) => {
        if( product.ntakFokategoria == null ||
            product.ntakAlkategoria == null ||
            product.ntakMennyisegiEgyseg == null ||
            product.ntakMennyiseg == null)
            return false
        return true
    }

    renderTrueFalse = (product) => {
        return this.hasNtakConfig(product)
            ? <CheckCircleOutline style={{color: "#4caf50"}} />
            : <RemoveCircle style={{color: "#f44336"}}/>
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <div ref={this.scrollToTheTop} />
                <Widget>
                    <Card title="Szűrés kategóriák alapján">
                        <Autocomplete
                            multiple
                            onChange={this.onChangeCategoryFiler}
                            name="categoryFilter"
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableCategories}
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedCategories || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Kategória" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a névben / leírásban / vonalkódban">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>
                <Paper>
                    {this.renderToolbar()}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    indeterminate={this.state.selectedProducts.length > 0 && this.state.selectedProducts.length < this.state.rowsPerPage}
                                                    checked={this.state.selectedProducts.length > 0 && this.state.selectedProducts.length === this.state.rowsPerPage}
                                                    onChange={this.handleSelectAllClick}
                                                    color="primary" />}
                                            style={{marginLeft: 0}}
                                        />
                                    </TableCell>
                                    <TableCell>Kép</TableCell>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell align="center">Kategória</TableCell>
                                    <TableCell align="center">NTAK Adatküldés</TableCell>
                                    <TableCell align="center">NTAK Főkategória</TableCell>
                                    <TableCell align="center">NTAK Alkategória</TableCell>
                                    <TableCell align="center">Mennyiségi egység</TableCell>
                                    <TableCell align="center">Mennyiség</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.products.map((p, i) => {
                                    const isItemSelected = this.isRowSelected(p.id);

                                    return <TableRow key={p.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(p.id)} selected={isItemSelected}>
                                        <TableCell padding="checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        key={p.id}
                                                        checked={isItemSelected}
                                                        onChange={() => this.onChangeCheckbox(p.id)}
                                                        color="primary" />}
                                                style={{marginLeft: 0}}
                                            />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            {p.imageId
                                                ? <img src={`${BASE_URL}/api/images/${p.imageId}`} alt="Fotó"/>
                                                : p.colorCode
                                                    ? this.renderColorBox(p.colorCode)
                                                    : <img src="/img/icons/no_image.svg" alt="Nincs fotó"/>
                                            }
                                        </TableCell>
                                        <TableCell>{p.name}</TableCell>
                                        <TableCell align="center">{p.category.name}</TableCell>
                                        <TableCell align="center">{this.renderTrueFalse(p)}</TableCell>
                                        <TableCell align="center">{p.ntakFokategoria
                                            ? ntakFokategoria.find(it => it.value === p.ntakFokategoria)?.label || "-"
                                            : "-"}
                                        </TableCell>
                                        <TableCell align="center">{p.ntakAlkategoria
                                            ? getNTAKAlkategoria(p.ntakFokategoria).find(it => it.value === p.ntakAlkategoria)?.label || "-"
                                            : "-"}
                                        </TableCell>
                                        <TableCell align="center">{p.ntakMennyisegiEgyseg || "-"}</TableCell>
                                        <TableCell align="center">{p.ntakMennyiseg || "-"}</TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </>;
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
