import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import "../../../css/ProductLabel.css";
import Footer from "../../../components/Footer";
import ReactToPrint from "react-to-print";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import { IconButton, Paper, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as htmlToImage from "html-to-image";

export default class PrintLabelPage extends React.Component {
    constructor() {
        super();
        this.state = {
            productOptions: [],
            selectedProductOption: null,
            shopOptions: [],
            selectedShopOption: null,
            entry: null
        };
    }

    componentDidMount() {
      axiosCommon.get(`${BASE_URL}/api/shops`)
        .then(response => {
          this.setState({ shopOptions: response.data.items, selectedShopOption: response.data.items[0] || undefined })
        })
        .catch(error => this.setState({error}))

      axiosCommon.get(`${BASE_URL}/api/products/basic?deleted=false`)
        .then(response => {
          this.setState({
            productOptions: response.data.items,
            selectedProductOption: response.data.items[0] || undefined
          }, () => this.refreshData())
        })
        .catch(error => this.setState({error}))
    }

    refreshData = () => {
      const params = {
        shopId: this.state.selectedShopOption ? this.state.selectedShopOption.id : null,
        productIds: this.state.selectedProductOption ? this.state.selectedProductOption.id : null
      }

      axiosCommon.get(`${BASE_URL}/api/inventories`,
        {params})
        .then(response => {
          const entry = response.data.items.length == 0 ? null : response.data.items[0]
          this.setState({
            entry
          });
        });
    };

    onChangeProductFilter = (event, value) => {
      this.setState({ selectedProductOption: value }, () => this.refreshData())
    };

    onChangeShopFilter = (event, value) => {
      this.setState({ selectedShopOption: value }, () => this.refreshData())
    };

    price = () => this.state.entry?.sellingPrice || (this.state.selectedProductOption ? this.state.selectedProductOption.defaultSellingPrice : null);

    barcode = () => this.state.entry?.eanCode || (this.state.selectedProductOption ? this.state.selectedProductOption.defaultEanCode : null);

    downloadLabelImage = (name) => {
        if(this.state.selectedProductOption != null){
            htmlToImage.toPng(document.getElementById("label-id"))
            .then(function (dataUrl) {
                const link = document.createElement('a')
                link.download =name.concat("_label.png")
                link.href = dataUrl;
                link.click();
            })
            .catch(error => this.setState({error}))
        }
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                <Widget>
                    <Card title="Üzlet kiválasztása">
                         <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Termék kiválasztása">
                      <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.productOptions}
                        onChange={this.onChangeProductFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedProductOption || ""}
                        renderInput={(params) =>
                          <TextField {...params} label="Termék" variant="outlined" />
                        }
                      />
                    </Card>
                </Widget>

                <Paper style={{paddingBottom: 15}}>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Termékcímke
                        </Typography>
                        <ReactToPrint
                            trigger={() => {
                                return <IconButton aria-label="print">
                                    <Tooltip title="Címke nyomtatása">
                                        <PrintIcon fontSize="large" />
                                    </Tooltip>
                                </IconButton>;
                            }}
                            content={() => this.labelRef}
                        />
                        <IconButton aria-label="download" onClick={() => this.downloadLabelImage(this.state.selectedProductOption.name)}>
                            <Tooltip title="Címke letöltése képként">
                                <CloudDownloadIcon fontSize="large"/>
                            </Tooltip>
                        </IconButton>
                    </Toolbar>

                    <div style={{ marginLeft: 15, marginTop: 15 }}>
                        <section
                            id="label-id"
                            className="product-label"
                            ref={el => (this.labelRef = el)}
                            style={{
                                background: "white",
                                width: "38mm",
                                border: "1px solid #aaaaaa",
                                padding: "0 1mm",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}>
                            <p style={{margin: 0, textAlign: "center", fontSize: "4.5mm"}}>
                                {this.state.selectedProductOption ? this.state.selectedProductOption.name : ""}
                            </p>
                            <p style={{ textAlign: "center", margin: 0 }}>
                                <b style={{fontSize: "8mm"}}>{this.price()}</b>Ft
                            </p>

                            {this.barcode()
                                ? <img src={`${BASE_URL}/api/barcodes/${this.barcode()}`}
                                       alt="Vonalkód"
                                       style={{ width: "100%", height: "10mm", margin: 0 }} />
                                : <div style={{textAlign: "center", fontSize: "30px"}}>-</div>
                            }
                            <p style={{ textAlign: "center", margin: "0", fontSize: "4mm" }}>{this.barcode()}</p>
                        </section>
                    </div>
                </Paper>
            </section>
            <Footer/>
        </section>;
    }
}
