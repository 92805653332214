import React from "react";
import "../../../css/Pages.css"
import InventoryChangeList from "./InventoryChangeList";

export default function CustomerInventoryProducePage(props) {
    return <InventoryChangeList {...props}
            kind="PRODUCE"
            pageKey="inventoryProducePagePageKey"
            rowsPerPageKey="inventoryProducePageRowsPerPageKey"
            keywordKey="inventoryProducePageKeywordKey"
            fromDateKey="inventoryProducePageFromDateKey"
            toDateKey="inventoryProducePageToDateKey"
            selectedShopsKey="inventoryProducePageSelectedShopsKey"
    />;
};
