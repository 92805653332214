import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import {BASE_URL} from "../../../util/Urls";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import {ResponsivePie} from "@nivo/pie";
import {
    CircularProgress,
    Paper, Tab, Typography
} from "@material-ui/core";
import NavHeader from "../../../components/NavHeader";
import Card from "../../../components/Card";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import {ResponsiveBar} from "@nivo/bar";
import NoData from "../../../components/NoData";
import formatNumber from "../../../util/formatNumber";
import { BasicTooltip } from "@nivo/tooltip";
import formatCurrency from "../../../util/formatCurrency";
import formatDateTime from "../../../util/formatDateTime";
import formatPaymentType from "../../../util/formatPaymentType";
import workerScript from "../../../util/ProcessSales-worker"

const worker = new Worker(workerScript)

export default class CustomerSalesStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            tabIndex: 0,
            productStatRows: [],
            productStatColumns: [
                {field: 'name', headerName: 'Termék', flex: 1, minWidth: 120},
                {field: 'customerAmount', headerName: 'Vásárlói eladás (db)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 200, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'customerIncome', headerName: 'Vásárlói bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 200, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'employeeAmount', headerName: 'Személyzeti eladás (db)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 220, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'employeeIncome', headerName: 'Személyzeti bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 220, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'amount', headerName: 'Összes eladás (db)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 200, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'sum', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center',  flex: 1, minWidth: 170, valueFormatter: (params) => formatNumber(params.value, 0)},
            ],
            itemStatColumns: [
                {field: 'date', headerName: 'Dátum', width: 155},
                {field: 'product', headerName: 'Termék', flex: 1, minWidth: 120},
                {field: 'category', headerName: 'Kategória', flex: 1, minWidth: 120},
                {field: 'shop', headerName: 'Üzlet', flex: 1, minWidth: 120},
                {field: 'amount', headerName: 'Mennyiség', align: 'center', headerAlign: 'center', flex: 1, minWidth: 130, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'originalPrice', headerName: 'Alap ár (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'price', headerName: 'Végső ár (Ft)', align: 'center', headerAlign: 'center', flex: 1,  minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'sum', headerName: 'Összesen (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'vat', headerName: 'Áfa (%)', align: 'center', headerAlign: 'center', flex: 1,  minWidth: 120,valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'paymentType', headerName: 'Fizetési mód', align: 'center', headerAlign: 'center', flex: 1,  minWidth: 150, valueFormatter: (params) => formatPaymentType(params.value)},
                {field: 'cartId', headerName: 'Kosár ID', align: 'center', headerAlign: 'center', flex: 1,  minWidth: 120},
            ],
            vatStatColumns: [
                {field: 'item', headerName: 'Áfakör (%)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 100},
                {field: 'income', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'vat', headerName: 'Áfa mértéke (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
            ],
            categoryStatColumns: [
                {field: 'name', headerName: 'Kategória', flex: 1, minWidth: 120},
                {field: 'amount', headerName: 'Eladások (db)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 150},
                {field: 'income', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center',  flex: 1,  minWidth: 150, valueFormatter: (params) => formatNumber(params.value, 0)},
            ],
            itemStatRows: [],
            categoryStatRows: [],
            vatStatRows: [],
            categoryStats: [],
            topProductStats: [],
            productStats: [],
            acumulatedProductStats: [],
            isProcessing: true,
            isLoading: true,
            totalSales: null,
            ticks: [],
        };
    }

    processData = data => {
        this.setState({productStatRows: [], isLoading: true, isProcessing: true})

        if(data !== null){
            let{categoryStatRows, categoryStats, productStats, } = this.state

            const allCategoryStats = data.categoryStats.map(i => {
                return {
                    id: i.categoryName,
                    label: i.categoryDeleted ? `${i.categoryName  } (Törölt)` : i.categoryName,
                    value: i.count,
                    income: i.income,
                }
            }).sort((a, b) => b.value - a.value)

            categoryStatRows = allCategoryStats.map((c,i) => {
                return {
                    id: i,
                    name: c.label,
                    amount: c.value,
                    income: c.income,
                }
            })

            categoryStats = allCategoryStats.slice(0, 20)
            const minorCategories = allCategoryStats.slice(20).map(c => c.value).reduce((a, b) => a + b, 0);
            if (minorCategories > 0) {
                categoryStats.push({
                    id: "Egyéb",
                    label: "Egyéb",
                    value: minorCategories
                })
            }

            productStats = data.productStats
            this.setState({categoryStats, productStats, categoryStatRows, isLoading: false})

            worker.postMessage(productStats)
            worker.onerror = (err) => console.log(err)
            worker.onmessage = (e) => {
                const {itemStatRows, } = this.state
                let{productStatRows, vatStatRows, acumulatedProductStats, topProductStats, totalSales, ticks,} = this.state

                acumulatedProductStats = e.data
                topProductStats = acumulatedProductStats.sort((a, b) => b.count - a.count).slice(0, 10)
                totalSales = acumulatedProductStats.map(item => item.count).reduce((a, b) => a + b, 0)
                const maxQuantity = acumulatedProductStats.map(item => item.count).reduce((a, b) => a > b ? a : b, 0)
                ticks = []
                for (let i = 0; i <= maxQuantity; i += Math.max(1, Math.round(maxQuantity / 5))) {
                    ticks.push(i);
                }

                productStatRows= acumulatedProductStats.map((p, i) => {
                    return{
                        id: i,
                        name: p.productName,
                        customerAmount: p.customerCount,
                        customerIncome: p.customerIncome,
                        employeeAmount: p.employeeCount,
                        employeeIncome: p.employeeIncome,
                        amount: p.count,
                        sum: p.income
                    }});

                const vatValues = []
                productStats.forEach((p, i) => {
                    itemStatRows.push({
                        id: i,
                        date: formatDateTime(p.sellTime),
                        product: p.productName,
                        category: p.category,
                        shop: p.shop,
                        amount: p.count,
                        originalPrice: p.originalPrice,
                        price: p.price,
                        sum: p.income,
                        vat: p.vat,
                        paymentType: p.paymentType,
                        cartId: p.cartId,
                    })
                    vatValues[p.vat] = (vatValues[p.vat] == null ? 0 : vatValues[p.vat]) + Math.round(p.count * p.price)
                })

                vatStatRows = Object.entries(vatValues).map((entry, i) =>{
                    return {
                        id: i,
                        item: `${entry[0]} %`,
                        income: entry[1],
                        vat: entry[1] * entry[0] / 100,
                    }
                })

                this.setState({topProductStats, totalSales, itemStatRows, vatStatRows, ticks, acumulatedProductStats, productStatRows, isProcessing: false})
            }

        }else{
            this.setState({categoryStats: [], categoryStatRows: [], productStats: [], topProductStats: [],
                ticks: [], itemStatRows: [], vatStatRows:[], acumulatedProductStats: [], productStatRows: [],
                totalSales: null, isLoading: false, isProcessing: false})
        }
    }

    pieTooltip = (props, unit) => {
        const data = props.datum
        return (
            <BasicTooltip
                id={data.id}
                value={`${data.value} ${unit}`}
                color={data.color}
                enableChip
            />
        );
    };

    barTooltip = (props) => {
        const {data} = props
        return (<>
            <BasicTooltip
                id={data.productName}
                value={`${data.count} db`}
                color="#8DD3C7"
                enableChip
            />
            <BasicTooltip
                id="Bevétel"
                value={formatCurrency(data.income,0,"HUF")}
            />
        </>);
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Diagram"/>
                    <Tab label="Tételenként"/>
                    <Tab label="Termékenként"/>
                    <Tab label="kategóriánként"/>
                    <Tab label="Áfakörönként"/>
                </TabList>

                <CustomerFinanceStatisticsTemplate url={`${BASE_URL}/api/stats/sales`}
                                                   onDataReceived={s => this.processData(s)}
                                                   highlightLabel="Eladások (db)"
                                                   highlightValue={this.state?.totalSales}>

                    <TabPanel value={this.state.tabIndex} index={0}>
                        <Card title="Eladások kategóriánként">
                            <section style={{height: "500px"}}>
                                {(this.state.isProcessing == true || this.state?.categoryStats?.length > 0)
                                    ? <ResponsivePie
                                        {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                        margin={{top: 40, right: 80, bottom: 40, left: 80}}
                                        data={this.state.categoryStats}
                                        padAngle={0.7}
                                        innerRadius={0.55}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        colors={{scheme: 'spectral'}}
                                        arcLabelsSkipAngle={15}
                                        arcLabel={(e) =>{return `${e.value} db`}}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsOffset={5}
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        tooltip={props => this.pieTooltip(props, 'db')}
                                        legends={[]}
                                    />
                                    :<NoData/>
                                }
                            </section>
                        </Card>
                        <div className="mb-16"/>
                        <Card title="Top 10 eladott termék">
                            <section style={{height: "600px"}}>
                                {this.state.isProcessing == true
                                    ?<div style={{marginTop: 30, textAlign: "center"}}>
                                        <CircularProgress color="primary"/>
                                    </div>
                                    : this.state.topProductStats.length > 0
                                        ?<ResponsiveBar
                                            {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                            margin={{top: 20, right: 8, bottom: 150, left: 75}}
                                            colors={{ scheme: 'paired' }}
                                            colorBy="index"
                                            data={this.state.topProductStats}
                                            keys={["count"]}
                                            indexBy="productName"
                                            layout="vertical"
                                            loading={this.state.isProcessing}
                                            label={d => `${d.value} db`}
                                            tooltip={point => this.barTooltip(point)}
                                            legends={[]}
                                        />
                                        :<NoData/>
                                }
                            </section>
                        </Card>
                    </TabPanel>

                    <TabPanel value={this.state.tabIndex} index={1}>
                        <Paper className="mt-16 mb-16" style={{ height: 780}}>
                            <DataGrid
                                columns={this.state.itemStatColumns}
                                rows={this.state.itemStatRows}
                                disableSelectionOnClick
                                disableColumnMenu
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <GridToolbarContainer>
                                                <GridToolbarExport />
                                            </GridToolbarContainer>
                                        );
                                    },
                                    NoRowsOverlay: NoData,
                                }}/>
                        </Paper>
                    </TabPanel>

                    <TabPanel value={this.state.tabIndex} index={2}>
                        <Paper className="mt-16">
                            <DataGrid
                                columns={this.state.productStatColumns}
                                rows={this.state.productStatRows}
                                disableSelectionOnClick
                                disableColumnMenu
                                autoHeight
                                autoPageSize
                                loading={this.state.isProcessing}
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <GridToolbarContainer>
                                                <GridToolbarExport />
                                            </GridToolbarContainer>
                                        );
                                    },
                                    NoRowsOverlay: NoData,
                                }}/>
                        </Paper>
                    </TabPanel>

                    <TabPanel value={this.state.tabIndex} index={3}>
                        <Paper className="mt-16">
                            <DataGrid
                                columns={this.state.categoryStatColumns}
                                rows={this.state.categoryStatRows}
                                disableSelectionOnClick
                                disableColumnMenu
                                autoHeight
                                autoPageSize
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <GridToolbarContainer>
                                                <GridToolbarExport />
                                            </GridToolbarContainer>
                                        );
                                    },
                                    NoRowsOverlay: NoData,
                                }}/>
                        </Paper>
                    </TabPanel>

                    <TabPanel value={this.state.tabIndex} index={4}>
                        <Paper className="mt-16">
                            <DataGrid
                                columns={this.state.vatStatColumns}
                                rows={this.state.vatStatRows}
                                disableSelectionOnClick
                                disableColumnMenu
                                autoHeight
                                autoPageSize
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <GridToolbarContainer>
                                                <GridToolbarExport />
                                            </GridToolbarContainer>
                                        );
                                    },
                                    NoRowsOverlay: NoData,
                                }}/>
                        </Paper>
                        <div className="mt-8"/>
                        <Typography>* A táblázatban feltüntetett adatok a szervizdíjat <strong>NEM</strong> tartalmazzák.</Typography>
                    </TabPanel>
                </CustomerFinanceStatisticsTemplate>
            </section>
            <Footer/>
        </section>;
    }
};
