import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import downloadFile from "../../../util/downloadFile";
import getCurrentDateTimeString from "../../../util/getCurrentDateTimeString";
import Footer from "../../../components/Footer";
import { Button, Collapse, Typography } from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import NavHeader from "../../../components/NavHeader";
import {Alert} from "@material-ui/lab";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudUpload from "@material-ui/icons/CloudUpload";
import getGeneralError from "../../../util/getGeneralError";

export default class ExportImport extends React.Component {
    constructor() {
        super();
        this.state = {
            hasMessage: false,
            message: null,
            error: null,
            hasError: false,
        };
        this.importFile = React.createRef();
    }

    exportCsv = () => {
        axiosCommon.get(`${BASE_URL}/api/dumps`, {
            headers: {Accept: "application/zip"},
            responseType: 'blob'
        })
            .then(response => {
                downloadFile(response.data, `kategoriak_${getCurrentDateTimeString()}.zip`);
            })
            .catch(error => {
                this.setState({ error, hasError: true });
            });
    };

    importCsv = (event) => {
        if (event.target.files[0]) {
            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            axiosCommon.post(`${BASE_URL}/api/dumps`, formData, {
                headers: {"Content-Type": "multipart/form-data"}
            })
                .then(response => {
                    this.importFile.current.value = null;
                    this.importFile.current.files = null;
                    this.setState({ message: "Sikeres importálás", hasMessage: true});
                })
                .catch(error => {
                    this.setState({ error, hasError: true});
                });
        }
    };

    closeError = () => {
        this.setState({hasError: false})
    }

    closeMessage = () => {
        this.setState({hasMessage: false})
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
               <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>


                <Collapse in={this.state.hasError} style={{marginBottom: 8}}>
                    <Alert severity="error" onClose={this.closeError}>{getGeneralError(this.state.error)}</Alert>
                </Collapse>

                <Collapse in={this.state.hasMessage} style={{marginBottom: 8}}>
                    <Alert severity="success" onClose={this.closeMessage}>{this.state.message}</Alert>
                </Collapse>


                <Widget>
                    <Card title="Exportálás">
                        <Typography style={{marginBottom: 8}}>Termékek és kategóriák exportálása</Typography>
                        <Button variant="contained" startIcon={<CloudDownload />} onClick={this.exportCsv}>
                            Letöltés
                        </Button>
                    </Card>
                    <div className="m-8"/>
                    <Card title="Importálás">
                        <Typography style={{marginBottom: 8}}>Termékek és kategóriák importálása</Typography>
                        <Button variant="contained" startIcon={<CloudUpload />} onClick={() => this.importFile.current.click()}>
                            Feltöltés
                        </Button>
                        <input type='file' id='file' ref={this.importFile} style={{display: 'none'}}
                               onChange={this.importCsv}/>
                    </Card>
                </Widget>
            </section>
            <Footer/>
        </section>;
    }
}
