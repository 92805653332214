import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import HighlightCard from "../../../components/HighlightCard";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@material-ui/data-grid";
import TabPanel from "../../../components/TabPanel";
import TabList from "../../../components/TabList";
import NoData from "../../../components/NoData";
import Loading from "../../../util/Loading";
import axios from "axios";
import formatNumber from "../../../util/formatNumber";
import formatCurrency from "../../../util/formatCurrency";

export default class InventoryList extends React.Component {
    constructor() {
        super();
        this.state = {
            availableCategories: [],
            selectedCategories: [],
            shopOptions: [],
            selectedShopOption: null,
            entries: [],
            notifications: [],
            error: null,
            debtRowsPerPage: RowsPerPageOptions.defaultPageSize,
            debtPage: 0,
            rows: [],
            notificationRows: [],
            notificationColumns: [
                {field: 'name', headerName: 'Termék', width: 200},
                {field: 'category', headerName: 'Kategória', align: 'center', headerAlign: 'center', width: 200,},
                {field: 'unit', headerName: 'Egység', align: 'center', headerAlign: 'center', flex: 1,},
                {field: 'quantity', headerName: 'Akt. Mennyiség', align: 'center', headerAlign: 'center', type: 'number', width: 200, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'minQuantity', headerName: 'Min. Mennyiség', align: 'center', headerAlign: 'center', type: 'number', width: 200, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'divergent', headerName: 'Különbség', align: 'center', headerAlign: 'center', type: 'number', width: 200, valueFormatter: (params) => formatNumber(params.value, 3)},],
            columns: [
                {field: 'name', headerName: 'Termék', width: 200},
                {field: 'category', headerName: 'Kategória', align: 'center', headerAlign: 'center', width: 200},
                {field: 'unit', headerName: 'Egység', align: 'center', headerAlign: 'center', flex: 1},
                {field: 'quantity', headerName: 'Akt. Mennyiség', align: 'center', headerAlign: 'center', type: 'number', width: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'minQuantity', headerName: 'Min. Mennyiség', align: 'center', headerAlign: 'center', type: 'number', width: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'divergent', headerName: 'Különbség', align: 'center', headerAlign: 'center', type: 'number', width: 150, valueFormatter: (params) => formatNumber(params.value, 3)},
                {field: 'aquisitionPrice', headerName: 'Beszer. érték (Ft)', align: 'center', headerAlign: 'center', type: 'number', width: 150, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'sellPrice', headerName: 'Elad. érték (Ft)', align: 'center', headerAlign: 'center', type: 'number', width: 150, valueFormatter: (params) => formatNumber(params.value, 0)},],
            tabIndex: 0,
            isLoadingNotifications: true,
            isLoadingInventory: true,
        };

        this.cancelSource = axios.CancelToken.source();
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;
                this.setState({
                    shopOptions,
                    selectedShopOption,
                }, () => this.refreshData());
            });

        axiosCommon(`${BASE_URL}/api/categories`)
            .then(response => {
                this.setState({
                    availableCategories: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
    }

    refreshData = () => {
        this.setState({isLoadingNotifications: true, isLoadingInventory: true,})
        this.cancelSource.cancel()
        this.cancelSource = axios.CancelToken.source();

        const notificationParams = {
            alertsOnly: true,
        }
        if (this.state.selectedShopOption) {
            notificationParams.shopId = this.state.selectedShopOption.id;
        }else {
            notificationParams.shopId = -1
        }
        if (this.state.selectedCategories.length > 0) {
            notificationParams.categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        }

        axiosCommon(`${BASE_URL}/api/inventories`, {
            params: notificationParams,
            cancelToken: this.cancelSource.token
        })
            .then(response => {
                const notifications = response.data.items.filter(i => i.product.deleted === false);

                const notificationRows= notifications.map((e, i) => {
                    return {
                        id: i,
                        name: e.product.name,
                        category: e.product.category.name,
                        unit: e.product.sellingUnit,
                        quantity: e.currentStockAmount,
                        minQuantity: this.desiredStockAmount(e),
                        divergent: this.desiredStockAmount(e)
                            ? e.currentStockAmount - this.desiredStockAmount(e)
                            : undefined,
                    }
                });

                this.setState({
                    notifications,
                    notificationRows,
                    isLoadingNotifications: false,
                })
            })
            .catch(error => {
                if(axios.isCancel(error) === false){
                    this.setState({
                        error,
                        isLoadingNotifications: false,
                    })
                }
            });

        const entryParams = {}
        if (this.state.selectedShopOption) {
            entryParams.shopId = this.state.selectedShopOption.id;
        }else {
            entryParams.shopId = -1
        }
        if (this.state.selectedCategories.length > 0) {
            entryParams.categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        }

        axiosCommon(`${BASE_URL}/api/inventories/groupedByCategory`, {
            params: entryParams,
            cancelToken: this.cancelSource.token,
        })
            .then(response => {
                const entries = response.data.items.filter(i => i.first.deleted === false);
                entries.forEach(entry => {
                    entry.second = entry.second.length === 0 ? entry.second : entry.second.filter(i => i.currentStockAmount != null);
                })

                const preRows = [];
                entries.forEach(entry => {
                    entry.second.forEach(value => preRows.push(value));
                })

                const rows= preRows.map((e, i) => {
                    return {
                        id: i,
                        name: e.product.name,
                        category: e.product.category.name,
                        unit: e.product.sellingUnit,
                        quantity: e.currentStockAmount,
                        minQuantity: this.desiredStockAmount(e),
                        divergent: this.desiredStockAmount(e)
                            ? e.currentStockAmount - this.desiredStockAmount(e)
                            : undefined,
                        aquisitionPrice: e.currentStockAmount * e.product.acquisitionPrice,
                        sellPrice: e.currentStockAmount * this.sellingPrice(e),
                    }
                });

                this.setState({
                    entries,
                    rows,
                    isLoadingInventory: false,
                })
            })
            .catch(error => {
                if(axios.isCancel(error) === false){
                    this.setState({
                        error,
                        isLoadingInventory: false,
                    })
                }
            });
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value,
            entries: [],
            rows: [],
            notificationRows: [],
        }, () => this.refreshData())
    };

    onChangeCategoryFiler = (event, value) => {
        this.setState({
            selectedCategories: value,
            entries: [],
            rows: [],
            notificationRows: [],
        }, () => this.refreshData())
    };

    desiredStockAmount = (entry) => entry.desiredStockAmount ? entry.desiredStockAmount : entry.product.defaultDesiredStockAmount

    sellingPrice = (entry) => {
        let sellPrice = entry.sellingPrice ? entry.sellingPrice : entry.product.defaultSellingPrice
        if(this.state.selectedShopOption.builtInServiceFee && this.state.selectedShopOption.serviceFee > 0){
            sellPrice /= (1 + this.state.selectedShopOption.serviceFee / 100)
        }
        return Math.round(sellPrice)
    }

    style = (e) => {
        if (e.currentStockAmount === null || e.currentStockAmount === undefined) {
            return {}
        }
        if (e.currentStockAmount < 0) {
            return {backgroundColor: "rgba(255,42,0,0.4)"}
        }
        if (!this.desiredStockAmount(e)) {
            return {}
        }
        if (e.currentStockAmount - this.desiredStockAmount(e) <= 0) {
            return {backgroundColor: "rgba(255,199,6, 0.4)"}
        }
        return {}
    }

    handleChangeDebtPage = (event, newPage) => {
        this.setState({
            debtPage: newPage,
        })
    };

    handleChangeRowsPerDebtPage = (event) => {
        const debtRowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            debtRowsPerPage,
            debtPage: 0,
        });
    };

    renderColorBox = (text, color) => {
        return <div style={{display: "flex"}}>
            <div style={{width: 25, height: 25, background: color, borderRadius: 3, marginRight: 10}}/>
            {text}
        </div>
    }

    render() {
        return  <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Kategóriánként"/>
                    <Tab label="Termékenként"/>
                    <Tab label="Figyelmeztetések"/>
                </TabList>

                <section className="flex justify-flex-end mb-16 gap-8" >
                    <HighlightCard
                        title={this.renderColorBox("Készlet Figyelmeztetések (db)","rgba(255,199,6, 0.4)")}
                        tooltip="Azon termékek száma, amelyeknél a minimum mennyiség alá csökkent az aktuális raktárkészlet.">
                        <Typography variant="h3" align="center">
                            {formatNumber(this.state.notifications.length,0)}
                        </Typography>
                    </HighlightCard>
                    <div className="m-8"/>
                    <HighlightCard
                        title={this.renderColorBox("Negatív mennyiségű termékek (db)","rgba(255,42,0,0.4)")}
                        tooltip="Azon termékek száma, melyeknek az aktuális raktárkészlet kevesebb mint 0.">
                        <Typography variant="h3" align="center">
                            {formatNumber(this.state.entries.flatMap(i => i.second).filter(i => i.currentStockAmount < 0).length,0)}
                        </Typography>
                    </HighlightCard>
                </section>

                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés kategóriák alapján">
                        <Autocomplete
                            multiple
                            onChange={this.onChangeCategoryFiler}
                            name="categoryFilter"
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableCategories}
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedCategories || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Kategória" variant="outlined" />
                            }
                        />
                    </Card>
                </Widget>

                <TabPanel value={this.state.tabIndex} index={0}>
                    {this.state.isLoadingInventory
                        ? <Loading/>
                        : this.state.entries.map((entry, i) =>
                            <Paper key={i} className='mt-16 mb-16'>
                                <Toolbar
                                    style={{
                                        backgroundImage: "unset",
                                        color: "var(--color-primary)",
                                        borderBottom: '1px solid #ccc'}}>
                                    <Typography variant="h5" id="tableTitle" component="div">
                                        {entry.first.name}
                                    </Typography>
                                </Toolbar>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Termék</TableCell>
                                                <TableCell align="center">Kategória</TableCell>
                                                <TableCell align="center">Egység</TableCell>
                                                <TableCell align="center">Akt. mennyiség</TableCell>
                                                <TableCell align="center">Min. mennyiség</TableCell>
                                                <TableCell align="center" title="A termék aktuális mennyisége és az általunk megadott minimum mennyiség közötti különbséget mutatja meg.">Különbség</TableCell>
                                                <TableCell align="center">Beszer. érték (Ft)</TableCell>
                                                <TableCell align="center">Elad. érték (Ft)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {entry.second.map((e, i) => {
                                                    return <TableRow key={e.id} hover style={this.style(e)}>
                                                        <TableCell>{e.product.name}</TableCell>
                                                        <TableCell align="center">{e.product.category.name}</TableCell>
                                                        <TableCell align="center">{e.product.sellingUnit}</TableCell>
                                                        <TableCell align="center">{formatNumber(e.currentStockAmount, 3)}</TableCell>
                                                        <TableCell align="center">{this.desiredStockAmount(e) || ''}</TableCell>
                                                        <TableCell align="center">
                                                            {this.desiredStockAmount(e)
                                                                ? formatNumber(e.currentStockAmount - this.desiredStockAmount(e),3)
                                                                : ''
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">{formatNumber(e.currentStockAmount * e.product.acquisitionPrice, 0)}</TableCell>
                                                        <TableCell align="center">{formatNumber(e.currentStockAmount * this.sellingPrice(e), 0)}</TableCell>
                                                    </TableRow>
                                                }
                                            )}
                                            <TableRow hover>
                                                <TableCell style={{fontWeight: 'bold'}}>Összesen</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell align="center" style={{fontWeight: 'bold'}}>
                                                    {formatCurrency(entry.second.map(e => e.currentStockAmount * e.product.acquisitionPrice).reduce((a, b) => a + b, 0),0, "HUF")}
                                                </TableCell>
                                                <TableCell align="center" style={{fontWeight: 'bold'}}>
                                                    {formatCurrency(entry.second.map(e => e.currentStockAmount * this.sellingPrice(e)).reduce((a, b) => a + b, 0), 0, "HUF")}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                    )}
                </TabPanel>

                <TabPanel value={this.state.tabIndex} index={1}>
                    <Paper className="mt-16 mb-16" style={{ height: 780}}>
                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.rows}
                            disableSelectionOnClick
                            disableColumnMenu
                            loading={this.state.isLoadingInventory}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport />
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}
                            getRowClassName={(params) => {
                                if(params.row.quantity < 0) return 'inventory-row-red';
                                if(params.row.divergent < 0) return 'inventory-row-yellow';
                                return '';
                            }}
                        />
                    </Paper>
                </TabPanel>

                <TabPanel value={this.state.tabIndex} index={2}>
                    <Paper className="mt-16 mb-16" style={{ height: 780}}>
                        <DataGrid
                            columns={this.state.notificationColumns}
                            rows={this.state.notificationRows}
                            disableSelectionOnClick
                            disableColumnMenu
                            loading={this.state.isLoadingNotifications}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport />
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}
                            getRowClassName={(params) => {return 'inventory-row-yellow'}}
                        />
                    </Paper>
                </TabPanel>

            </section>

            <Footer/>
        </section>;
    }
}
