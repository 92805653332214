function formatYearMonth(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    let month = date.getMonth()
    month += 1
    if (month < 10)
        month = `0${  month}`
    return `${year}-${month}`
}

export default formatYearMonth;
