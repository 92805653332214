import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    IconButton,
    MenuItem,
    Toolbar,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import {
    getNTAKAlkategoriaMenuItems,
    ntakFokategoria,
    ntakMennyisegiEgyseg,
} from "../../../util/NTAK";

export default class EditNtakProductConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {
                ntakFokategoria: null,
                ntakAlkategoria: null,
                ntakMennyisegiEgyseg: null,
                ntakMennyiseg: null,
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        if(this.props.location.ids.length === 1){
            axiosCommon.get(`${BASE_URL}/api/products/${this.props.location.ids[0]}`)
            .then(response => this.setState({ product: response.data, }))
            .catch(error => this.setState({error}));
        }
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true})
        const data = {
            ids: this.props.location.ids,
            ntakFokategoria: this.state.product.ntakFokategoria,
            ntakAlkategoria: this.state.product.ntakAlkategoria,
            ntakMennyisegiEgyseg: this.state.product.ntakMennyisegiEgyseg,
            ntakMennyiseg: this.state.product.ntakMennyiseg,
        }

        axiosCommon({
            method: "put",
            url: `${BASE_URL}/api/ntak/product`,
            data,
            params: undefined
        })
        .then(response => this.goBack())
        .catch(error => this.setState({error}))
        .finally(() => this.setState({isSubmitInProgress: false}))
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (event) => {
        const { product } = this.state;
        if(event.target.name === 'ntakFokategoria' && product.ntakFokategoria !== event.target.value) product.ntakAlkategoria = null
        if(event.target.name === 'ntakFokategoria' && event.target.value === null) {
            product.ntakAlkategoria = null
            product.ntakMennyisegiEgyseg = null
            product.ntakMennyiseg = null
        }
        product[event.target.name] = event.target.value;
        this.setState({ product });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.ids.length === 1
                        ? this.state.product.name || "Termék"
                        : `${this.props.location.ids.length} kiválasztott termék`
                    }
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="NTAK Adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Főkategória"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="ntakFokategoria"
                                fullWidth
                                value={this.state.product?.ntakFokategoria || ""}
                            >
                                {
                                    ntakFokategoria.map(value => <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Alkategória"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="ntakAlkategoria"
                                fullWidth
                                value={this.state.product?.ntakAlkategoria || ""}
                            >
                                {getNTAKAlkategoriaMenuItems(this.state.product?.ntakFokategoria)}
                            </TextValidator>
                        </div>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Mennyiségi egység"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="ntakMennyisegiEgyseg"
                                fullWidth
                                value={this.state.product?.ntakMennyisegiEgyseg || ""}
                            >
                                {
                                    ntakMennyisegiEgyseg.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Mennyiség"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const { product } = this.state;
                                    product[event.target.name] = event.target.value.replace(",", ".");
                                    this.setState({ product });
                                }}
                                name="ntakMennyiseg"
                                value={this.state.product.ntakMennyiseg === null
                                    ? ""
                                    : this.state.product.ntakMennyiseg}
                                validators={['isFloat','minFloat:0']}
                                errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
