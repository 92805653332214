import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import truncateString from "../../../util/truncateString";
import CallMergeIcon from '@material-ui/icons/CallMerge';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

export default class ToppingListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            entries: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
        };
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        const queryParams = {pageSize: this.state.rowsPerPage, pageNumber: this.state.page}

        axiosCommon(`${BASE_URL}/api/toppings?`, { params: queryParams })
            .then(response => {
                this.setState({ entries: response.data.items, count: response.data.count,})
            })
            .catch(error => this.setState({ error }))
    };

    initiateDeletion = (id) => {
        this.setState({
            deleteInProgress: id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/toppings/${this.state.deleteInProgress}`)
            .then(response => this.refreshData())
            .catch(error => this.setState({ error }))

        this.setState({ deleteInProgress: null })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/products/edittopping",
            entryId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/products/edittopping",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a tételt?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Feltét csoportok
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell>Leírás</TableCell>
                                    <TableCell align="center">Tulajdonságok</TableCell>
                                    <TableCell align="center">Feltét termékek száma</TableCell>
                                    <TableCell padding="none" align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.entries.map((entry, i) => {
                                    return <TableRow key={entry.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(entry.id)}>
                                        <TableCell>{entry.name}</TableCell>
                                        <TableCell className="break-line">{truncateString(entry.description, 127)}</TableCell>
                                        <TableCell align="center">
                                            {entry.embedded
                                                ? <Tooltip title="Termék árba épülő feltét">
                                                    <CallMergeIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                                                </Tooltip>
                                                : undefined
                                            }
                                            {entry.ignorePrice
                                                ? <Tooltip title="Feltét árak nem számolódnak">
                                                    <MoneyOffIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                                                </Tooltip>
                                                : undefined
                                            }
                                        </TableCell>
                                        <TableCell align="center">{entry.products.length}</TableCell>
                                        <TableCell padding="none" align="right">
                                            <IconButton aria-label="delete" onClick={() => this.initiateDeletion(entry.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Feltét"
                onClick={this.initiateCreate}/>
        </>
    }
}
