import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import moment from "moment";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper,
    TextField,
} from "@material-ui/core";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatNumber from "../../../util/formatNumber";

export default class StockChangesListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            entries: [],
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            columns: [
                {field: 'productName', headerName: 'Termék', align: 'left', headerAlign: 'left', width: 200},
                {field: 'categoryName', headerName: 'Kategória', align: 'left', headerAlign: 'left', width: 200},
                {field: 'refill', headerName: 'Bevételezés', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},
                {field: 'charge', headerName: 'Kivételezés', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},
                {field: 'waste', headerName: 'Selejt', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},
                {field: 'produce', headerName: 'Előállítás', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},
                {field: 'move_in', headerName: 'Mozgatás be', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},
                {field: 'move_out', headerName: 'Mozgatás ki', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 1)},],
            rows: [],
            isLoading: false,
        };
    }


    componentDidMount() {
        this.setState({isLoading: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;
                this.setState({ shopOptions, selectedShopOption, }, () => this.refreshData());
            });
    }

    refreshData = () => {
        this.setState({isLoading: true, row: []})

        const entryParams = {
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
            fromDate: moment(this.state.fromDate).format("YYYY-MM-DD")
        }

        if (this.state.selectedShopOption) {
            entryParams.shopId = this.state.selectedShopOption.id;
        }

        axiosCommon(`${BASE_URL}/api/stats/stockchange`, {
            params: entryParams
        })
            .then(response => {
                const entries = response.data;
                const rows = entries.map((e, i) => {
                    return {
                        id: i,
                        productName: e.productName,
                        categoryName: e.productCategoryName,
                        refill: e.refill || 0,
                        charge: e.charge || 0,
                        waste: e.waste || 0,
                        produce: e.produce || 0,
                        move_in: e.move_in || 0,
                        move_out: e.move_out || 0,
                    }
                })
                this.setState({ entries, rows, isLoading: false, })
            })
            .catch(error => {
                this.setState({ error, isLoading: false, })
            });
    }

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value}, () => this.refreshData())
    };

    handleChangeRowsPerPage = (entry) => {
        this.setState({ rowsPerPage: parseInt(entry, 10)});
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                </Widget>
                <Paper>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        onPageSizeChange={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        pageSize={this.state.rowsPerPage}
                        pagination
                        loading={this.state.isLoading}
                        components={{
                            Toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <GridToolbarExport/>
                                    </GridToolbarContainer>
                                );
                            },
                            NoRowsOverlay: NoData,
                        }} />
                </Paper>
            </section>
            <Footer/>
        </section>;
    }
}
