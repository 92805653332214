import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import formatNumber from "../../../util/formatNumber";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Button, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment, Paper,
    Switch, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Widget from "../../../components/Widget";
import ErrorIcon from "@material-ui/icons/Error";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import TablePagination from "@material-ui/core/TablePagination";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import Search from "../../../components/Search";
import truncateString from "../../../util/truncateString";

export default class EditInventoryCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryCheck: {
                description: null,
                shopId: null,
                numberOfDifferences: null,
                items: {},
            },
            selectedShopOption: null,
            shopOptions: [],
            availableCategories: [],
            selectedCategories: [],
            keyword: "",
            products: [],
            filteredProducts: [],
            inventoryEntries: {},
            modifiedQuantities: {},
            showDifferencesOnly: false,
            onlyChanges: false,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon(`${BASE_URL}/api/categories`)
            .then(response => {
                this.setState({
                    availableCategories: response.data.items
                })
            })
            .catch(error => {
                this.setState({error})
            });

        if (this.props.location.inventoryCheckId) {
            axiosCommon.get(`${BASE_URL}/api/inventories/checks/${this.props.location.inventoryCheckId}`)
                .then(response => {
                    const modifiedQuantities = {};
                    const inventoryEntries = {}
                    response.data.items.forEach(i => {
                        modifiedQuantities[i.product.id] = {
                            productId: i.product.id,
                            quantity: i.updatedQuantity,
                            acquisitionPrice: i.acquisitionPrice
                        }

                        inventoryEntries[i.product.id] = {
                            currentStockAmount: i.originalQuantity
                        };
                    })

                    this.setState({
                        inventoryCheck: response.data,
                        modifiedQuantities,
                        inventoryEntries,
                        selectedShopOption: {
                            label: response.data.shop.name,
                            value: response.data.shop.id
                        },
                        onlyChanges: true
                    }, () => {
                        this.refreshData();
                    })
                })
                .catch(error => this.setState({ error }))
        } else {
            axiosCommon.get(`${BASE_URL}/api/shops`)
                .then(response => {
                    const shopOptions = response.data.items.map(c => {
                        return {label: c.name, value: c.id}
                    });
                    this.setState({
                        shopOptions,
                        selectedShopOption: shopOptions[0] || undefined
                    }, () => this.refreshData())
                });
        }
    }

    refreshData = () => {
        const queryParams = {deleted: false, hasInventory: true, sort: "name"}
        if(this.state.showDifferencesOnly){
            queryParams.productIds = Object.values(this.state.modifiedQuantities)
              .filter(q => q.quantity != this.state.inventoryEntries[q.productId]?.currentStockAmount)
              .map(q => q.productId).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        } else if(this.state.onlyChanges){
            queryParams.productIds = Object.values(this.state.modifiedQuantities)
              .map(q => q.productId).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        } else {
            queryParams.pageSize = this.state.rowsPerPage
            queryParams.pageNumber = this.state.page
        }
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (categoryIds !== "") {
            queryParams.categoryIds = categoryIds
        }

        axiosCommon(`${BASE_URL}/api/products?`, { params: queryParams })
          .then(response => {
              if(this.props.location.inventoryCheckId){
                  this.setState({products: response.data.items, count: response.data.count})
              } else {
                  this.setState({ products: response.data.items, count: response.data.count }, () => {
                      const params = {shopId: this.state.selectedShopOption?.value || ""}
                      params.productIds = this.state.products.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
                      axiosCommon.get(`${BASE_URL}/api/inventories?`, { params })
                        .then(response => {
                            const {inventoryEntries} = this.state
                            response.data.items.forEach(e => inventoryEntries[e.product.id] = e)
                            this.setState({ inventoryEntries })
                        })
                  })
              }

          })
          .catch(error => this.setState({error}))
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value, inventoryEntries: {}, page: 0 }, () => this.refreshData())
    }

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    onChangeCategoryFiler = (event, value) => {
        this.setState({ selectedCategories: value, page: 0}, () => this.refreshData())
    };

    numberOfDifferences = () => Object.values(this.state.modifiedQuantities).filter(e => e.quantity != this.state.inventoryEntries[e.productId]?.currentStockAmount).length;

    sumDifference = () => Object.values(this.state.modifiedQuantities)
      .filter(e => e.quantity != this.state.inventoryEntries[e.productId]?.currentStockAmount)
      .map(e => {
          const diff = e.quantity - (this.state.inventoryEntries[e.productId]?.currentStockAmount || 0)
          return diff * e.acquisitionPrice
      }).reduce((sum, e) => sum + e, 0)

    displaySumDifference = () => {
        const sum = formatNumber(this.sumDifference(), 1)
        return `${sum > 0 ? '+' : ''}${sum} Ft`
    }

    onSubmit = () => {
        if(Object.values(this.state.modifiedQuantities).length === 0){
            this.setState({error: "Legalább egy tételt meg kell adnod a mentéshez"})
            return 0;
        }

        this.setState({
            pendingInventoryCheck: true
        })
    };

    confirmSave = debounce(() => {
        this.setState({isSubmitInProgress: true, pendingInventoryCheck: undefined});

        const {inventoryCheck} = this.state;
        inventoryCheck.shopId = this.state.selectedShopOption.value
        inventoryCheck.numberOfDifferences = this.numberOfDifferences();

        const items = Object.values(this.state.modifiedQuantities).map(e => {
            return {
                productId: e.productId,
                acquisitionPrice: e.acquisitionPrice,
                originalQuantity: this.state.inventoryEntries[e.productId] ? this.state.inventoryEntries[e.productId].currentStockAmount : null,
                updatedQuantity: e.quantity
            }
        })
        inventoryCheck.items = items;

        axiosCommon.post(`${BASE_URL}/api/inventories/checks`, inventoryCheck)
            .then(response => {
               this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    })

    rejectSave = () => {
        this.setState({
            pendingInventoryCheck: undefined
        })
    };

    onQuantityChange = (product, value) => {
        if (!String(value).match(/^([0-9]+([.,][0-9]*)?)?$/))
            return;

        const quantity = String(value).replace(",", ".");
        // Only 3 decimal places are allowed
        const decimalPlace = quantity.indexOf(".")
        if(decimalPlace != -1 && value.length - decimalPlace > 4) return;

        const {modifiedQuantities} = this.state
        const field = { productId: product.id, quantity, acquisitionPrice: product.acquisitionPrice }
        modifiedQuantities[product.id] = field

        if (value === null || value === "") {
            delete modifiedQuantities[product.id]
        }

        this.setState({
            modifiedQuantities
        });
    }

    calculateDifferenceValue = (product) => {
        if (this.state.modifiedQuantities[product.id] === undefined || this.state.modifiedQuantities[product.id] === null) return ""

        if (this.state.inventoryEntries[product.id]?.currentStockAmount == this.state.modifiedQuantities[product.id].quantity) return "0 Ft"

        const diffQuantity = this.state.modifiedQuantities[product.id].quantity - (this.state.inventoryEntries[product.id]?.currentStockAmount || 0)
        const diffValue = formatNumber(diffQuantity * this.state.modifiedQuantities[product.id].acquisitionPrice, 1)

        if(diffQuantity > 0){
            return <div style={{color: "green"}}>{`+${diffValue} Ft`}</div>
        }
        return <div style={{color: "#f44336"}}>{`${diffValue} Ft`}</div>
    }

    calculateDifference = (product) => {
        if (this.state.modifiedQuantities[product.id] === undefined || this.state.modifiedQuantities[product.id] === null) return ""
        const value = this.state.modifiedQuantities[product.id].quantity - (this.state.inventoryEntries[product.id]?.currentStockAmount || 0)
        const diffQuantity = formatNumber(value,3)

        if(value === 0){
            return `0 ${product.sellingUnit.toLowerCase()}`
        }
        if(value > 0){
            return <div style={{color: "green"}}>{`+${diffQuantity} ${product.sellingUnit.toLowerCase()}`}</div>
        }
        return <div style={{color: "#f44336"}}>{`${diffQuantity} ${product.sellingUnit.toLowerCase()}`}</div>
    }

    getAcquisitionPrice = (product) => {
        if (this.state.modifiedQuantities[product.id] === undefined || this.state.modifiedQuantities[product.id] === null)
            return `${product.acquisitionPrice} Ft`
        return `${this.state.modifiedQuantities[product.id].acquisitionPrice} Ft`
    }

    onlyChangesVisible = (productId) => {
        if(this.state.modifiedQuantities[productId] !== undefined){
            return true;
        }
        return false;
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    renderIcon = (bool) => {
        if(bool === true)
            return <ErrorIcon style={{color: "#f44336"}}/>;

        return "";
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (event) => {
        const { inventoryCheck } = this.state;
        inventoryCheck[event.target.name] = event.target.value;
        this.setState({ inventoryCheck });
    }

    renderAreYouSureDialog = () => {
        return <Dialog
            open={this.state.pendingInventoryCheck !== undefined}
            onClose={this.rejectSave}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Biztosan mented a leltár / stand tartalmát?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Mentés után a raktárkészlet azonnal felülíródik.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.rejectSave} color="primary">
                    Mégsem
                </Button>
                <Button onClick={this.confirmSave} color="primary" autoFocus>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>;
    };

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    Leltár / Stand
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disabled={this.props.location.inventoryCheckId !== undefined}
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" />
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        disabled={this.props.location.inventoryCheckId !== undefined}
                        variant="outlined"
                        name="description"
                        value={this.state.inventoryCheck.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Tételek">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                multiple
                                onChange={this.onChangeCategoryFiler}
                                name="categoryFilter"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.availableCategories}
                                getOptionLabel={(option) => option === "" ? "" : option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={this.state.selectedCategories || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Kategória szűrés" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Search
                                setKeyword={this.setKeyword}
                                refreshData={this.refreshData}
                            />
                        </div>
                    </Widget>

                    <FormControl>
                        {this.props.location.inventoryCheckId
                            ?""
                            :<FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.onlyChanges}
                                        onChange={(event) => {
                                            this.setState({
                                                onlyChanges: event.target.checked,
                                                page: 0,
                                            }, () => this.refreshData())
                                        }}
                                        name="diffOnly"
                                        color="primary"
                                    />
                                }
                                label="Csak a kitöltött mezőket mutasd"
                            />
                        }

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.showDifferencesOnly}
                                    onChange={(event) => {
                                        this.setState({
                                            showDifferencesOnly: event.target.checked,
                                            page: 0,
                                        }, () => this.refreshData())
                                    }}
                                    name="diffOnly"
                                    color="primary"
                                />
                            }
                            label="Csak a különbségeket mutasd"
                        />

                    </FormControl>

                </Card>
                <Paper className="mt-16">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell width="25%">Leírás</TableCell>
                                    <TableCell>Vonalkód</TableCell>
                                    <TableCell align="center">Kategória</TableCell>
                                    <TableCell align="center">Bekerülési ár</TableCell>
                                    <TableCell align="center">Eredeti mennyiség</TableCell>
                                    <TableCell align="center">Új mennyiség</TableCell>
                                    <TableCell align="center">Különbség</TableCell>
                                    <TableCell align="center" title="Beszerzési áron számított különbség">Érték</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.products
                                    .map((p, i) => {
                                            return <TableRow key={p.id} hover>
                                                <TableCell>{p.name}</TableCell>
                                                <TableCell className="break-line">{truncateString(p.description, 127)}</TableCell>
                                                <TableCell>{p.defaultEanCode}</TableCell>
                                                <TableCell align="center">{p.category.name}</TableCell>
                                                <TableCell align="center">{this.getAcquisitionPrice(p)}</TableCell>
                                                <TableCell align="center">
                                                    {(this.state.inventoryEntries[p.id] && this.state.inventoryEntries[p.id]?.currentStockAmount != null)
                                                        ? `${formatNumber(this.state.inventoryEntries[p.id].currentStockAmount)} ${p.sellingUnit.toLowerCase()}`
                                                        : "Nem kapható"
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <TextValidator
                                                        onChange={(event) => this.onQuantityChange(p, event.target.value)}
                                                        disabled={this.props.location.inventoryCheckId !== undefined}
                                                        value={this.state.modifiedQuantities[p.id] != undefined ? this.state.modifiedQuantities[p.id].quantity : ""}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end">{p.sellingUnit.toLowerCase()}</InputAdornment>
                                                            )
                                                        }}
                                                        style={{width: 80}}
                                                        validators={['isFloat', 'minFloat:0.0', 'maxFloat:999999']}
                                                        errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0.0-nál', 'A megadott érték nem lehet nagyobb 999 999-nél']}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{this.calculateDifference(p)}</TableCell>
                                                <TableCell align="center">{this.calculateDifferenceValue(p)}</TableCell>
                                            </TableRow>;
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        {this.state.onlyChanges
                          ? undefined
                          : <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                          />
                        }
                    </TableContainer>
                </Paper>
                <Typography variant="body1" style={{marginTop: 16}}>Eltérések: {this.numberOfDifferences()} db</Typography>
                <Typography variant="body1" style={{marginTop: 16}}>Különbség beszerzési áron: {this.displaySumDifference()}</Typography>

                {this.props.location.inventoryCheckId
                    ? <div style={{justifyContent: "center", display: "flex"}}>
                        {this.state.error ?
                            <section className="error">{getGeneralError(this.state.error)}</section>
                            : ""}

                        <Button variant="contained" color="primary" onClick={this.goBack}
                              style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20}}>Bezár</Button>
                    </div>
                    : <SaveCancelButtons
                        error={this.state.error}
                        isSubmitInProgress={this.state.isSubmitInProgress}
                        onCancel={this.goBack}
                    />
                }
            </ValidatorForm>

            {this.renderAreYouSureDialog()}
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
