import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, MenuItem, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "../../../components/Card";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import { ShopPlaces } from "../../../util/ShopPlaces";

export default class EditTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderLocation: {
                name: null,
                description: null,
                seats: null,
                shopId: null,
                place: ShopPlaces[0].value,
            },
            shopOptions: [],
            selectedShopOption: null,
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(c => {
                    return {label: c.name, value: c.id}
                });

                const selectedShopOption = this.props.location.shopId !== undefined
                    ? shopOptions.find(shop => shop.value == this.props.location.shopId)
                    : shopOptions[0] || undefined

                this.setState({
                    shopOptions,
                    selectedShopOption
                }, () => {
                    if (this.props.location.tableId) {
                        axiosCommon.get(`${BASE_URL}/api/orderlocations/${this.props.location.tableId}`)
                            .then(response => {
                                const selectedShopOption = {
                                    label: response.data.shop.name,
                                    value: response.data.shop.id
                                };
                                this.setState({
                                    orderLocation: response.data,
                                    selectedShopOption
                                    }
                                )
                            })
                            .catch(error => {
                                this.setState({error});
                            });
                    }
                })
            })

    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {orderLocation} = this.state;
        orderLocation.shopId = this.state.selectedShopOption.value;
        orderLocation.shop = undefined;

        axiosCommon({
            method: this.props.location.tableId ? "put" : "post",
            url: `${BASE_URL}/api/orderlocations`,
            data: orderLocation
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = (event) => {
        const { orderLocation } = this.state;
        orderLocation[event.target.name] = event.target.value;
        this.setState({ orderLocation });
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.tableId ? "Asztal szerkesztése" : "Új asztal"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.orderLocation.name || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                disabled={this.props.location.tableId !== undefined}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Helység"
                                variant="outlined"
                                onChange={(event) => {
                                    const { orderLocation } = this.state;
                                    orderLocation.place = event.target.value
                                    this.setState({orderLocation})
                                }}
                                name="place"
                                fullWidth
                                value={this.state.orderLocation.place || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            >
                                {ShopPlaces.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)}
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Férőhelyek"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="seats"
                                value={this.state.orderLocation.seats|| ""}
                                validators={['isNumber', 'minNumber: 1']}
                                errorMessages={['A megadott érték nem szám', 'Az értéknek nagyobbnak kell lennie 0-nál']}
                            />
                        </div>
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.orderLocation.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
