import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        sessionStorage.getItem('accessToken')
            ? <Component {...props} changeSidebarToggled={rest.changeSidebarToggled} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
);

export default PrivateRoute;
