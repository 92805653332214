import React from "react";

const TerminalDescription = [
    {
        name: "1. Kilépés - Belépés az alkalmazásba",
        link: "",
        text: <div>
            <p>
                Tableteken, valamint telefonokon a belépéshez kattintsunk az élénk zöld színű ByThePay (BTP) applikációra. Amint elindul a program rögtön el is jutunk az azonosítási felületre. Itt ugye kér tőlünk egy emailt, illetve egy jelszót, ezek azok az adatok, amelyeket az üzletvezetőnek/ üzlet tulajdonosának adtatok meg, amikor a vendéglátó egységbe dolgozni kezdtetek. Ha bármikor elfelejtenétek esetleg az említett adatokat, akkor sincsen semmi gond hiszen, az üzletvezető/ üzlet tulajdonosa a  Webirodán keresztül tud nektek segíteni és tőle tudtok új jelszót is igényelni. Amennyiben beírtuk az emailt, illetve a jelszót, nyomjunk a <b>Belépés</b> gombra.
            </p>
            <img src='/img/1.%20Videó-%201.jpg' alt='video1.1'/>
            <p>
                Ha mindezt megtettük szükség lesz egy pinkódra, mellyel a már egyszer belépett fiókba tudunk egyszerűbben és gyorsabban belépni. A pinkód hossza minimum 4 - maximum 8 karakteres lehet és csak számokból állhat. Azért fontos, hogy megadjuk hiszen ennek segítségével később, amikor beléptünk a saját felhasználónkkal a rendszerbe, hogy felvegyük a rendeléseket, akkor elegendő lesz csak ezt a rövid pinkódot beírni. Ahogy beírtuk a pinkódot nyomjunk alul a <b>Tovább</b> gombra, itt rögtön ha először jelentkezünk be az adott terminálon legyen ez telefon/ tablet, akkor mindig hozzá kell kapcsolnunk a készüléket a használni kívánt Üzlethez.
            </p>
            <img src='/img/1.%20Videó-%202.jpg' alt='video1.2'/>
            <p>
                Itt válasszuk ki az Üzletet pl: Teszt Bisztró (kattintsunk rá), majd <b>Kész</b> gombra. Fontos tudni hogy, aki másodjára vagy harmadjára lép be az adott készüléken, annak már ezzel nem lesz feladata, hiszen ilyenkor már automatikusan hozzákapcsolja az üzlethez a rendszert. A bejelentkezést követően be is jutottunk a rendszer fő felületére.
            </p>
            <p>
                Kijelentkezéshez a rendszer jobb sarkában található Menü gombra kattintsatok (3 fekete csíkra), majd meg is nyílik a menürendszer sávja a bal oldalon. A bal felső sarokban látható a saját felhasználó nevünk, alatta az email címünk és mellette egy pici jobbra mutató nyíl. A nyílra kattintva tudtok majd kilépni, de mielőtt kiléptetne a rendszer megkérdezi tőlünk, hogy biztosan ki szeretnénk- e lépni, itt kilépéshez az <b>Igen</b>-re nyomjunk és már ki is jelentkeztünk a rendszerből.
            </p>
        </div>
    },
    {
        name: "2. Elfelejtettt Pinkód helyreállítása",
        link: "",
        text: <div>
            <p>
                Két fajta lehetőség van arra az esetre, ha elfelejtettük a pinkódot, most ezt a két esetet fogjuk átnézni.
            </p>
            <ol>
                <li>
                    A legelső verzió, akkor fordul elő, amikor azt gondoljuk, hogy teljesen jól adtuk meg a pinkódunkat, majd a végén ez mégsem így alakul. Akkor kezdjünk is bele, kiválasztjuk a saját felhasználónkat a felületen és rögtön láthatjuk is, hogy kér egy pinkódot, amint beírjuk - ki is írja, hogy érvénytelen PIN kód próbálkozzon újra. Ilyen esetekben próbálkozhatunk többször is, ha esetleg van még ötletünk, hogy mit adtunk meg, mert nincs limitálva a próbálkozási lehetőség. Amennyiben nem sikerül így, akkor kattintsunk az <b>Elfelejtettem a pinkódom</b> gombra, ilyenkor be tudunk lépni újra a már eddig általunk használt email címmel és jelszóval és utána tudunk egy új pinkódot generálni.
                </li>
                <img src='/img/2.%20videó-%201.jpg'/>
                <li>
                    A második verziónál pedig biztosak vagyunk benne, hogy nem tudjuk a pinkódunkat, ilyenkor mindig a <b>Belépés jelszóval</b> gombra kell kattintani (jobb oldalon alul) és rögtön ugyanarra az azonosítási felületre jutunk, ahol meg kell adnunk az emailünket, valamint a jelszavunkat. Ha mindezzel megvagyunk fel is ajánlja a rendszer, hogy új pinkódot tudunk létrehozni, amely után már be is tudunk lépni.
                </li>
            </ol>
        </div>
    },
    {
        name: "3. Wifi működése, kapcsolat",
        link: "",
        text: <div>
            <p>
                Amint beléptünk BTP felületére a leges-legfontosabb dolog, hogy ellenőrizzük, hogy a jobb sarokban található “WiFi jel” fehéren világít-e. Mindez alapvetően nem a WiFi-t jelöli, hanem azt, hogy a készülékünk legyen az tablet/telefon eléri-e az internetet és ezáltal van-e megfelelő szerverkapcsolat, tehát hogy a szoftverünk el tudja-e küldeni az adatokat a szervernek és onnan a szerver pedig visszaküldi-e megfelelő módon.
            </p>
            <img src='/img/3.%20videó-1.jpg'/>
            <p>
                Vegyük át, hogy mi történik akkor, ha valamilyen ok folytán nem működik az említett jel, tehát pirosan villog. Ilyen eset fennállhat például akkor, ha valamiért elmegy az internet, ezért a készülékünk nem tud megfelelően kommunikálni a szerverrel.
            </p>
            <p>
                Első lépésként ellenőriznünk kell, hogy be van-e kapcsolva a router, amennyiben igen, akkor nézzük meg hogy minden kábel rendesen be van-e dugva. Ha itt mindent rendben találtunk, akkor ilyenkor ellenőrizzük, hogy a megfelelő internetre kapcsolódunk-e.
            </p>
            <p>
                Sajnos vannak olyan esetek mikor az internet nem csak 1-2 percre megy el hanem esetleg egésznap nem érhető el pl: szerelik éppen, ilyen esetekben ugye az adatátvitel sem tud megvalósulni, hiszen az interneten keresztül tudnak a készülékek kommunikálni a szerverrel. Éppen ezért az ezidő alatt történt változtatások melyek a Webirodában lettek végrehajtva nem fognak látszódni a készülékeken és ez fordítva is igaz. Pont ezekre az esetekre fejlesztettük ki úgy a rendszerünket, hogy akkoris tudjon működni, hogyha nincsen WIFI elérés. Azonban ilyenkor mi azt tanácsolnánk Nektek, hogy minden pincér csak a saját asztalához vegyen fel rendeléseket a vendégektől, illetve a saját asztalát fizettesse ki, ezzel elkerülve azt, hogy esetleg néhány tétel nem kerül kifizetésre, mert különböző eszközökről lett felvéve.
            </p>
        </div>
    },
    {
        name: "4. Felület bemutatása",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverben, ha belépünk jobb oldalon a kék felületen láthatjuk a kategóriákat képekkel, valamint szöveggel, ezekbe ha belekattintunk akkor ott találhatjuk a kategóriához tartozó termékeket. A termékek felületről a főoldalra, mindig a jobb oldalon alul található <b>Vissza</b> gombbal tudunk visszanavigálni, vagy egy balról-jobbra irányuló “slide” mozdulattal tehetjük meg mindezt.
            </p>
            <img src='/img/4.%20videó-1%20.jpg'/>
            <p>
                Jobb oldalon alul található nagyító ikon, mely  mindig a keresés funkciót jelenti, erről a következő témákban részletesen beszámolunk majd. A fő felületen felül középen látható, hogy mely üzletbe vagyunk bejelentkezve, valamint, hogy milyen felhasználóval.
            </p>
            <p>
                A jobb oldalon felül található WIFI jelnek tűnő, ám valójában szerverelérési ikon, ami megmutatja, hogy van-e aktuálisan kapcsolat a szerver és a készülékünk között. Ettől jobbra található a 3 fekete csíkkal jelzett menü gomb, mely alatt meg a vonalkódolvasó kap helyet.
            </p>
            <p>
                A fő felület bal oldalán találhatjuk a rendelési listát, ide fognak majd listázódni a vendégek által rendelt ételek/italok. Bal oldalon a fenti sarokban található a <b>Vásárlók</b> gomb, melyen belül a törzsvásárlók, illetve a személyzeti tagok kapnak helyet. A Vásárlóktól jobbra pedig az <b>Asztalok</b> gomb van, melyben az általunk már korábban a Webirodában felvett asztalok listája jelenítődik meg.
            </p>
            <p>
                Szintén bal oldalon, de alul található a <b>Töröl</b> gomb, ami a teljes rendelési listát törli, valamint a <b>Felvesz</b> gomb, mely segítségével asztalokhoz/ törzsvásárlókhoz/személyzethez tudunk felvenni termékeket. Továbbá a <b>Fizet</b> gomb is az alsó szekcióban kapott helyet, mellyel a rendeléseket lehet fizettetni. Mindezek felett helyezkedik el egy pici nyíl felfelé, mely betekintést enged a rendelési lista részleteibe mint például: ÁFA, alkalmazott kedvezmény, darabszám, nettó összeg és a szervizdíj mértéke. Itt még 3 fontos gomb található az Elvitel, a Kedvezmények és a Szervizdíj, amikről a következő témákban részletesebben beszélünk.
            </p>
        </div>
    },
    {
        name: "5. Menüsor bemutatása",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverben jobb oldalon felül találhatjuk a menüsornak az ikonját (3 fekete csík) ha rákattintunk meg is jelenik a bal oldalon egy sávban a menürendszer. Legfelül a sorban látható a felhasználónk neve, mellyel beléptünk, valamint az email címünk tőle jobbra pedig a <b>Kilépés</b> gomb.
            </p>
            <img src='/img/5.videó-%201.jpg'/>
            <p>
                Vegyük sorra, hogy a menüpontok egyes elemei pontosan mit takarnak:
            </p>
            <p>
                <b>Információ:</b> Itt láthatjuk, hogy melyik üzlethelyiségben vagyunk jelenleg bejelentkezve és annak a teljes adatlapját, valamint alatta látható a ByThePay szoftver jelenlegi szoftververziója és a szerver.
            </p>
            <p>
                <b>Beállítások:</b> Megmutatja, hogy a konyhai, a bár és a számla nyomtatóból pontosan hány darab másolatot, hány címkét kell nyomtatnia a nyomtatónak és hogy mikor történjen meg mindez.
            </p>
            <p>
                <b>Eladások:</b> A mai-, a még ki nem fizetett függő-, valamint az összes eladást láthatjuk itt.
            </p>
            <p>
                <b>Szinkronizálás:</b> Ebben a fülben tudunk manuálisan szinkronizálni, ami annyit jelent, hogy egy gombnyomásra tudjuk a készülékünk adatait frissíteni a szerver adataival, ezáltal naprakész lesz a telefonunk/tabletünk.
            </p>
            <p>
                <b>Nyomtató beállítás:</b> Itt be tudjuk állítani az elérhető nyomtatók listájából, hogy melyik IP címmel rendelkező nyomtató pontosan hol nyomtasson (bár, konyha, számla).
            </p>
            <p>
                <b>Vonalkódolvasó:</b> Amint bekapcsoltuk a <b>Vonalkód olvasó csatlakoztatása</b> gombot, azonnal tudunk is csatlakoztatni egy elérhető vonalkódolvasó eszközt  a készülékünkhöz, legyen az telefon/tablet.
            </p>
            <p>
                <b>Zárás:</b> Megmutatja, hogy éppen aznap összesen mekkora bevételt generált az üzletünk készpénzben és bankkártyában kiszámolva, valamint hogy az adott dolgozók milyen mértékben járultak hozzá az aznapi bevételhez. Jobb oldalon felül látható nyomtatás ikonra, ha rákattintunk, akkor ki is tudjuk nyomtatni a számla nyomtatón az aznapi zárást.
            </p>
            <p>
                <b>Leltár:</b> A leltár úgy tudjuk elérni, hogy egy kicsit lejjebb kell görgetnünk a menü sávjában, mert ez a legalsó rész. Amint rákattintottunk láthatjuk is a jobb felső sarokban található Új leltár gombot,mellyel új leltárt tudunk készíteni a terminálunkon néhány perc leforgása alatt.
            </p>
        </div>
    },
    {
        name: "6. Nyomtató beállítás 1. - alapbeállítások 1. rész",
        link: "",
        text: <div>
            <p>
                ByThePay felületünkön, ahhoz hogy be tudjuk állítani a nyomtatókat a jobb oldalon felül található menü gombra (3 fekete csík) rákattintunk és a menüsorban megjelenő <b>Nyomtató beállítás</b> menüpontot választjuk. Amint mindezzel megvagyunk, a jobb sarokban a <b>Nyomtató csatlakoztatása</b> gombot be kell kapcsolnunk és már meg is jelenik a csatlakoztatott és az elérhető nyomtatók listája.
            </p>
            <img src='/img/6.%20videó-1.jpg'/>
            <p>
                A csatlakoztatott nyomtatóknál 3 lehetőség közül tudunk választani: a bár, a konyha, valamint a számla nyomtató. Amint áram alá helyeztük saját nyomtatóinkat és ellenőriztük, hogy ugyanazon a WIFI hálózaton vannak-e, mint amin a készülékünk is, amin a ByThePay szoftver fut (ez a lépés az egyik legfontosabb, hiszen így tudnak majd az eszközök kommunikálni egymás között) akkor meg is kezdhetjük a nyomtatók IP címének lecsekkolását, valamint a csatlakoztatást. Az IP címét egy nyomtatónak, úgy tudjuk a legegyszerűbben megnézni, hogy a nyomtató hátulján lévő kis gombot 5-10 mp-ig nyomva tartjuk majd, a készülék kinyomtat nekünk egy adatokkal teli listát, melyen szerepel az IP címe is. Amint beazonosítottuk a nyomtatóinkat IP címük alapján megkezdhetjük a csatlakoztatást. Első lépésként ki kell választanunk, hogy mely nyomtatón nyomtassunk, legyen például a bár. Ilyenkor belekattintunk a kiválasztott nyomtató (mindig az első sorba látható az adott nyomtató alatt a 4 tagú IP címe), majd felajánlja a rendszer hogy válasszuk ki hogy hova szeretnénk csatlakoztatni a bárhoz, a konyhához, vagy a számla nyomtatóhoz. Amint ezt kiválasztottuk már meg is jelenik az adott eszköz a <b>Csatlakoztatott nyomtatók</b> kiválasztott füle alatt pl: a bárnál. Van arra lehetőség hogy egy nyomtatón jöjjön ki a bár és a számla címkéje/ blokkja és külön egy másik eszközön pedig a konyhai, vagy arra is van lehetőség, hogy ha esetleg az egyiket nem használjuk, akkor arra egyáltalán nem csatlakoztatunk eszközt.
            </p>
        </div>
    },
    {
        name: "6. Nyomtató beállítás 1. - alapbeállítások 2. rész",
        link: "",
        text: <div>
            <p>
                Nyomtató úgy tudunk leválasztani pl: a bár, a konyhai, vagy a számla nyomtatóról, hogy először a Menübe belekattintunk, majd ott a bal oldali menüsorban kiválasztjuk a <b>Nyomtató beállítások</b> menüpontot. Amint a nyomtatóbeállítási felületre elértünk ott a felső sávban látható <b>Csatlakoztatott nyomtató</b>-nál kiválasztjuk hosszan nyomva azt az eszközt melyet le szeretnénk csatlakoztatni. Hosszabb kattintás után a rendszer megkérdezi, hogy biztosan le szeretnénk-e csatlakoztatni, itt az igen lenyomása után már készen is vagyunk, hiszen az adott készülék át is került az <b>Elérhető nyomtatók</b> listájába.
            </p>
            <p>
                Amennyiben újra szeretnénk csatlakoztatni az adott készüléket ugyanoda vagy egy másik helyre például a bár, helyett most a konyhai nyomtatón szeretnénk, hogy kijön a címke, akkor már jól ismert módon rákattintunk az <b>Elérhető nyomtatók</b> felületen a kívánt eszközre és itt a rendszer fel is ajánlja a lehetséges helyeket, ahova csatlakoztatni tudjuk a készüléket. Amint kiválasztottuk a <b>Rendben</b> gomb megnyomásával már kész is a csatlakoztatás és meg is kezdhetjük a munkát.
            </p>
        </div>
    },
    {
        name: "7. Nyomtató beállítás 2. - rendelési blokkok/címkék beállítása",
        link: "",
        text: <div>
            <p>
                A rendelési blokkok/címkék beállítását a jobb felső sarokban található <b>Menü</b> gombra (3 fekete csík) ha rákattintunk, akkor ott a bal oldali menüsorban megjelenő <b>Beállítások</b> alatt találjuk.  Itt a nyomtatókkal kapcsolatos részletes beállításokat tudjuk megtenni.
            </p>
            <p>
                Ezen a felületen 3 részben láthatjuk a konyhai-, a bár-, illetve a számla nyomtatóhoz tartozó specifikus beállításokat, melyek nagy részében legördülő lehetőségek közül választhatunk.
            </p>
            <img src='/img/7.%20videó-1.jpg'/>
            <p>
                <b>Nyomtatási esemény:</b> Megmutatja, hogy az adott nyomtató mikor nyomtatja ki a leadott rendelés címkéjét.
            </p>
            <ul>
                <li>
                    <b>Rendelésfelvétel:</b> A nyomtató, akkor nyomtat amikor az adott rendelés fel lett véve egy asztalhoz/ törzsvendéghez/ személyzethez.
                </li>
                <br/>
                <li>
                    <b>Rendelés kifizetés:</b> A nyomtató akkor nyomtat, amikor a rendelés ki lett fizettetve (tehát nem rendelés felütésnél asztalhoz), ezt a funkciót inkább olyan vendéglátóegységeknek ajánljuk, akiknél nincsen asztalnál történő kiszolgálás, hanem azonnali fizetés, esetleg csak elviteles rendszer.
                </li>
            </ul>
            <p>
                <b>Másolatok száma:</b> Megmutatja, hogy a nyomtató hány darab példányban fogja kinyomtatni a címkéket/blokkokat.
            </p>
            <p>
                A <b>Beállítások</b> felületen a <b>Számla nyomtatás</b> szekcióban láthatjuk a <b>Számlasorszám előtag</b> részt, ide beírhatjuk a készülékünk egyedi azonosítóját pl: TEL1- (1-es telefon), ami megjelenik majd a blokkon fizetésnél így lehet tudni hogy mely készülékről lett fizettetve az adott blokk, ez adminisztrációs célból hasznos lehet a későbbiek folyamán.
            </p>
        </div>
    },
    {
        name: "8. Szinkronizálás",
        link: "",
        text: <div>
            <p>
                ByThePay programunkat igyekeztünk úgy létrehozni, hogy minél könnyebben kezelhető legyen számotokra, éppen ezért sok esetben szükség lesz majd a készüléken belüli szinkronizálásra. A szinkronizálás alapvetően abban segít, hogy a terminál, legyen az telefon/tablet, a lehető legfrissebb adatokat kapja meg a szerverről. Például: ha az üzletvezető változtat a termék árán, akkor a szinkronizálással lehet elérni azt, hogy a terminálon is a legfrissebb ár jelenjen meg az adott termékhez. A terminálok automatikusan is szinkronizálnak, akkor mikor belépünk a saját felhasunálónkkal a rendszerbe.
            </p>
            <img src='/img/8.%20videó-1.jpg'/>
            <p>
                Viszont most vegyük át, hogy pontosan hol és hogyan tudunk szinkronizálni. A jobb oldalon található <b>Menü</b> gombra ha rákattintunk, akkor a megjelenő bal menüsorban a Szinkronizálás menüpontot kell kiválasztanunk. Itt a jobb felső sarokban az <b>Indít</b> gombra kattintás után el is indul a manuális szinkronizálás, mely nem tart tovább pár másodpercnél. Ilyenkor, ahogy már fentebb említettük megkapja a szerverről a készülékünk a legfrissebb adatokat, így a terminálunk teljesen naprakész lesz.
            </p>
            <img src='/img/8.%20videó-2.jpg'/>
        </div>
    },
    {
        name: "9. Asztaltérkép kialakítása",
        link: "",
        text: <div>
            <p>
                A ByThePay rendszerben asztaltérképet a terminálon (telefonon/tableten) tudunk szerkeszteni. Első lépésként lépjünk be az <b>Asztalok</b> fülbe, mely bal oldalon található felül, amint ezzel megvagyunk ki is listázódik számunkra az üzletvezető vagy üzlettulajdonos által már korábban felvett asztalok listája. Ahhoz, hogy szerkeszteni tudjuk a jobb oldalon található <b>Térkép</b> ikonra kell kattintanunk és így rögtön aktívvá is válik az asztaltérkép. Amennyiben először léptünk be ide, akkor hiába vettünk fel több asztalt a Webirodában csak 1 db lesz látható. Ilyenkor nem kell megijedni, hiszen egymás alá vannak rendezve a szoftverben az asztalok, ahhoz hogy szerkeszteni tudjuk  a jobb felső sarokban található <b>Toll</b> ikonra, ha rákattintunk rögtön aktívvá válik a felület. Itt már lehetőségünk van kihúzogatni az asztalokat olyan pozícióba, ami fedi az üzlethelyiségünk elrendezését. Minden asztalnál jobb oldalon látható egy zöld <b>Toll</b> ikon, mellyel az adott asztal formája alakítható. Minden asztalnál egy legördülő menüből választhatunk a négyzet, kör, valamint a téglalap között. Az asztalok jobb alsó sarkában egy piros <b>Átméretező</b> ikon található, mellyel az asztal méreteit változtathatjuk. A felső sorban látható <b>Csúszka</b> szerepe az, hogy nagyítsa, valamint csökkentse az asztaltérkép nézetét, így egy kisebb-, vagy esetleg nagyobb vendéglátóegység is kényelmesen el tudja készíteni saját térképét, hiszen minden elfér rajta és átlátható marad.
            </p>
            <img src='/img/9.%20videó-%201.jpg'/>
            <p>
                Amint elkészültünk saját testreszabott asztaltérképünkkel, akkor a <b>Mentés</b> gombra kattintva tudjuk elmenteni azt. Amennyiben nem szeretnénk elmenteni, akkor az alsó sorban látható <b>Vissza</b> gombra kattintva tudunk visszalépni. Nagyon fontos tudni, hogy a piros <b>Visszanyíl</b> gomb az nem visszalépést jelent, hanem az alaphelyzetbe állítást, vagyis ezt csak akkor használjuk, ha valóban alapértelmezeti nézetbe szeretnénk állítani az általunk készített térképet.
            </p>
            <img src='/img/9.%20videó-%202.jpg'/>
            <p>
                A rendszerünk 2 fajta asztalnézetet tud tárolni az általunk létrehozott szabadon alakított verziót, valamint az egymás mellé rendezett négyzetes alapértelmezeti nézetet (jobb felső sarokban található <b>Kocka</b> ikonra kattintva lehet beállítani). Az hogy mit jelenít meg, akkor amikor az <b>Asztalok</b> menüpontba belekattintunk az attól függ, hogy melyiket választottuk ki utoljára, hiszen a rendszer intelligensen megjegyzi és azt veszi alapul.
            </p>
        </div>
    },
    {
        name: "10. Új asztal felvétele 1. rész",
        link: "",
        text: <div>
            <p>
                Új asztalt fel tudunk venni a terminálon is, a bal felső sorban lévő <b>Asztalok</b> menüpontra kattintva meg is nyílik az eddig felvett asztalok listája. A jobb felső sarokban található <b>Plussz</b> gombra kattintva megnyílik egy <b>Új asztal</b> felvételi lap. Itt meg kell adjuk az asztal nevét pl: 5 vagy T5, majd adhatunk az adott asztalnak leírást is, mely a terminálon ugyan nem, de a Webirodában látszik az asztal leírásnál pl: ablak melletti box, ez nagyban segítheti az üzletvezető/üzlettulajdonos számára az eligazodást. Végezetül a székek számát kell megadnunk egy legördülő menüből 1-10-ig terjedő számban. Amennyiben mindezzel megvagyunk a <b>Rögzít</b> gombra kattintva létre is tudjuk hozni az új asztalt. Ha valamilyen oknál fogva mégsem szeretnénk létrehozni az adott asztalt, akkor a fehér űrlapon kívülre kell koppintani és már vissza is léptünk az asztalok felületre és semmi nem mentődött el a változtatásokból.
            </p>
            <img src='/img/10.%20videó-%201.jpg'/>
        </div>
    },
    {
        name: "11. Termék tulajdonságok 1. - alapkezelés",
        link: "",
        text: <div>
            <p>
                Ahhoz, hogy végig tudjuk nézni, egy adott rendelés leadásánál a felütött termék tulajdonságait, ahhoz vegyünk fel egy terméket a rendelési listára és így lépésről-lépésre megismerhetjük a beállítási lehetőségeket. Mi most egy latte macchiato terméket veszünk fel a rendelési listára a példa kedvéért, melyhez fontos tudni, hogy a Webirodában már előre egy “Kávé napi” kedvezményt is adtunk,(mely a teljes kávé kategóriára vonatkozik és mértéke 20% az eredeti árból).
            </p>
            <img src='/img/11.%20video-1%20.jpg'/>
            <p>
                Amint felvettünk a latte macchiato terméket a rendelési listánkra, láthatjuk is, hogy megjelent a bal oldalon a felütött termék és körülötte kék felületen a különböző beállítási funkciók. A <b>Termék</b> neve mellett jobbra szerepel a felvett mennyiség (<b>Db</b>) mellette az egységre számított kedvezménnyel csökkentett <b>Ár</b> (itt ha nem veszünk fel kedvezményt akkor a normál ár látszódik majd), ettől jobbra pedig a termék <b>Összesen</b> értéke. A termék jobb alsó sarkában látható <b>Töröl</b> gombbal az adott terméket tudjuk kitörölni (nagyon fontos, hogy nem a teljes rendelési listát hanem csak azt a terméket), emellett bal oldalon látható a <b>Fogás</b> gomb, mely a termék neve alatt látható narancssárga fogás sorszámát változtatja, de erről részletesebben egy későbbi témában beszélünk. A Fogás gomb felett <b>Plusszal</b>, illetve <b>Minusszal</b> növelhetjük és csökkenthetjük a termék mennyiségét, valamint ha nem szeretnénk egyenként megtenni mindezt, akkor bele is kattinthatunk a szám felületbe (középre) és manuálisan is megadhatjuk a kívánt darabszámot. A Fogás gombtól balra látható a <b>Kedvezmény</b> gomb, amibe ha belekattintunk meg is jelenik a termék kedvezmény felület, ahol alkalmazhatjuk az előre a Webirodában felvitt kedvezményeket, de erről is részletesebben később esik majd szó. (Az alkalmazott termék kedvezmény az mindig a narancssárga fogás jel alatt szerepel, a mi esetünkben ez a “Kávé Napok”). A kedvezménytől balra helyezkedik el a <b>Jegyzet</b> gomb, ahol a dolgozók fel tudnak venni a termékek mellé extra kéréseket, megjegyzéseket pl: sok jéggel stb.., ami látszódni fog a konyhába és a bárba kimenő címkén.
            </p>
        </div>
    },
    {
        name: "12. Terkékmtulajdonságok 2. - Készlet/Leírás/Mennyiség megadása",
        link: "",
        text: <div>
            <p>
                Amennyiben szeretnénk egy terméknek az adatlapját megnézni a terminálon akkor a kategóriákból ki kell választanunk a kívánt terméket, legyen most a “Tokaji Szamorodni Édes” bor nevű termék. Ahhoz, hogy előhívjuk a termék adatlapját a kiválasztott termékre kell hosszan nyomni majd fel is ugrik egy 3 felületből álló ablak.
            </p>
            <img src='/img/12.%20video-1%20.jpg'/>
            <p>
                Először a <b>Darab</b> felületet láthatjuk itt mikor felveszünk terméket akkor nemcsak a már felütött terméknél lehet megadni mennyiséget, hanem itt még felütés előtt megadhatjuk a vendég által kért mennyiséget( mindez természetesen lehet dl, liter, cl stb..).
            </p>
            <p>
                A második fül a <b>Leírás</b>, ahova ha felül belekattintunk, akkor rögtön el is jutottunk az adott termék előre, a Webirodában már beírt leírásához (ez a funkció például nagyon hasznos lehet akkor, ha a vendéglátó egység sok bort tart és a vendégek belekérdeznek, hogy melyiket ajánlja nekik a pincér).
            </p>
            <p>
                A harmadik és egyben utolsó fül a <b>Készlet</b>, ahol láthatjuk a termékünk aktuális készletmennyiségét üzletekre lebontva (ha több üzlettel rendelkezünk akkor kilistázva láthatjuk egyszerre mindegyikben).
            </p>
            <img src='/img/12.%20video-%202.jpg'/>
        </div>
    },
    {
        name: "13. Keresési funkció",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverünk 3 fő részen helyeztünk el keresési mezőket, melyeket nagyon sok időt tudnak megspórolni számunkra és ezáltal több időt tudunk a vendégeinknek/vevőinknek szentelni. A keresési funkció mindig a felület jobb alsó sarkában lévő Nagyító gombra való kattintás után válik aktívvá. A következő 3 fő területen tudunk keresni: a kategóriákon belül a termékek között, a vásárlók között (törzsvásárlók és személyzet között is), valamint az asztalokban. Amint rákattintottunk a nagyító gombra, rögtön meg is nyílik jobb felül a keresési mező, illetve alul elérhetővé válik a billentyűzet számunkra. A keresőt úgy alakítottuk ki, hogy nem csak szó elejében tudunk keresni, hanem lehetőség van magában a szóban is keresni, ezzel megspórolva a keresgéléssel töltött időt. A <b>Vásárlók</b> és <b>Személyzet</b> tagjai között történő keresést a <b>Vásárlók</b>ba belekattintva tudjuk megtenni, a szintén jobb oldalon alul található Nagyító ikonra kattintva. Amennyiben az <b>Asztalok</b> között szeretnénk használni a keresési funkciót, úgy az Asztalok fület kiválasztva, a Nagyító ikonra kattintva már meg is kezdhetjük a műveletet (ha nagyobb vendéglátó egységgel rendelkezünk, akkor hasznos lehet számunkra, hogy 1-2 karakter beírása után már használható is válik a kívánt asztal).
            </p>
            <p>
                Amennyiben nem szeretnénk tovább keresni, a jobb felső sarokban található keresési felületnél látható <b>X</b>-re kattintva tudjuk bezárni.
            </p>
        </div>
    },
    {
        name: "14. Rendelés fizettetés asztal felütés nélkül",
        link: "",
        text: <div>
            <p>
                A betérő vendégek nem minden esetben fogyasztják el helyben a megvásárolt termékeket, éppen ezért a szoftverünkben lehetőség van arra, hogy asztalhoz felütés nélkül is ki lehessen fizettetni az adott rendelést. Ahhoz, hogy a bár valamint a konyhai nyomtatón megfelelően kinyomtatódjanak a címkék 2 lehetőségünk van:
            </p>
            <p>
                Az első, hogy a rendelési lista bal oldalán alul a kis felfelé nyílba kattintva a megjelenő elvitel gombra kattintunk, hiszen így nem kell átállítanunk a nyomtatási eseményt egy nem asztalnál történő rendelés miatt. Továbbá ki is nyomtatódik a címke, mind a konyhai, mind a bár nyomtatón sőt látni is fogják a dolgozók, hogy elviteles a rendelés a címkén, így be is tudják majd előre csomagolni.
            </p>
            <ul>
                <li>
                    <b><u>Ennek a beállítási menete:</u></b> Amint teljes a rendelés a baloldalon alul található kis <b>Felfelé nyílra</b> kattintunk -&gt; a megjelenő felületen az <b>Elvitel</b> gombra kattintunk -&gt; folytathatjuk tovább a fizettetést a <b>Fizet</b> gomb megnyomásával.
                </li>
            </ul>
            <p>
                A második lehetőség, hogy úgy állítjuk be a konyhai és a bár nyomtatók nyomtatási eseményét, hogy azok a rendelés kifizetésénél nyomtassanak, de ezt mi nem ajánljuk hacsaknem akkor, ha egyáltalán nincsen helyben fogyasztás a vendéglátó egységben, hiszen ilyen esetekben ez a legtökéletesebb megoldás.
            </p>
            <ul>
                <li>
                    <b><u>Ennek a beállítási menete:</u></b> Jobb felső sarokban található <b>Menü</b> gombra (3 fekete csík) kattintunk -&gt; a megjelenő felületen a <b>Beállítások</b> menüpontra kattintunk -&gt; a <b>Konyhai</b> és a <b>Bár</b> nyomtatás alatt található <b>Nyomtatási eseménynél</b> kiválasztjuk a legördülő menüből a <b>Rendelés kifizetés</b> opciót -&gt; amint ezekkel a lépésekkel megvagyunk folytathatjuk a rendelésfelvételt.
                </li>
            </ul>
            <p>
                A következő példában egy paradicsomlevet fogunk kifizettetni asztalhoz rendelés nélkül. Amint a fentiekben említett 2 lehetőség közül választottunk, kezdhetjük is folyamatokat.
            </p>
            <ol>
                <li>
                    Kiválasztjuk a fő felületen a Gyümölcslevek kategórián belül a Paradicsomleves terméket, melyre egyszer rákattintunk, így meg is jelenik a bal oldali rendelési listában.
                </li>
                <li>
                    Amint készen vagyunk a felvenni kívánt termékek listájával akkor a <b>Fizet</b> gombra kattintunk bal oldalon alul.
                </li>
                <li>
                    A felületünk bal oldalt elhalványodott és jobb oldalon megjelentek a <b>Fizetési lehetőségek</b>, valamint <b>módok</b>. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.)
                </li>
                <li>
                    Itt ki kell választanunk a vendég által preferált <b>Fizetési módot</b>, majd fizettetni.
                </li>
                <li>
                    A fizetendő összegnél beírjuk az összeget, mellyel fizettetni fogunk (de ha bankkártyás, esetleges utalványos lesz a térítés akkor elég rögtön a <b>Fizet</b> gombra nyomni), majd a <b>Fizet</b> gombra kattintva, ki is írja a rendszer a visszajáró összegét. Készen is vagyunk.
                </li>
            </ol>
        </div>
    },
    {
        name: "15. Termék törlése/rendelés törlése",
        link: "",
        text: <div>
            <p>
                A ByThePay rendszerünkben már felütött terméket(amit még nem rendeltünk hozzá asztalhoz) a következő lépésekkel tudunk törölni:
            </p>
            <ol>
                <li>
                    A bal oldali sávban megjelentek a rendelni kíván termékek, na most itt a törölni kíván elemre kattintunk, mely körül rögtön világoskék sáv lesz. Ilyenkor tudjuk az adott termék mennyiségét növelni-csökkenteni, fogás számot beállítani, kedvezményt applikálni , valamint az egyik legfontosabb törölni is.
                </li>
                <li>
                    A kék termék-sávban található jobb alsó <b>Töröl</b>(kuka ikon) gombra kattintva el is tudjuk tüntetni a nem kívánt terméket a rendelési listáról.
                </li>
                <li>
                    Folytathatjuk tovább a rendelés felvételét.
                </li>
            </ol>
            <img src='/img/15.%20videó-1%20.jpg'/>
            <p>
                Rendelés törléséhez szükséges lépések viszont eltérnek az idáigiaktól, éppen ezért most vegyük akkor lépésről-lépésre át:
            </p>
            <ol>
                <li>
                    A bal oldali sávban megjelentek a rendelni kíván termékek, mivel itt most nem csak 1 terméket szeretnménk törölni hanem a teljes rendelési listát így a bal alsó sarokban található piros Töröl gombra kell kattintanunk.
                </li>
                <li>
                    A felugró ablakban megjelenik a kérdés, hogy “Biztosan törölni akarod a rendeléseket?” erre “Igen”-nel felelve már üres is lesz a listánk és dolgozhatunk tovább. Amennyiben itt “Nem”-mel felelünk, úgy a törlési szándékunk semmissé válik és folytathatjuk tovább a régi rendelési listánkkal a kiszolgálást.
                </li>
            </ol>
            <p>
                Fontos itt megemlíteni, hogy rendelési lista termékeit-, valamint a teljes teljes rendelési listát törölni, még asztalhoz felvétel előtt, bárki tudja, azonban utána már csak az teheti meg, akinek az üzlettulajdonos/üzletvezető erre külön engedélyt adott a Webirodában, mindezt biztonsági szempontból alakítottuk így ki.
            </p>
        </div>
    },
    {
        name: "16. Rendelésfelvétel asztalhoz/termékfelütés",
        link: "",
        text: <div>
            <p>
                Rendelést felvenni asztalhoz a ByThePay rendszerben kétféleképpen lehetséges. Az első lehetőség, mikor először felvesszük a termékeket majd utána rendeljük, hozzá az asztalt. A második lehetséges megoldás, hogy még rendelésfelvétel előtt belekattintunk az asztalba, melyhez fel lesznek véve a termékek és utána ütjük hozzá a vendég rendelését.
            </p>
            <img src='/img/16.%20video-1.jpg'/>
            <p>
                A következőkben az első lehetséges megoldást vesszük át:
            </p>
            <ol>
                <li>
                    Előszöris válasszuk ki a kategóriákból a felvenni kívánt termékeket (kattintsunk a kiválasztott termékekre és azok rögtön át is kerülnek a bal oldali rendelési listánkra).
                </li>
                <li>
                    Állítsuk be a kívánt mennyiségeket, adhatunk leírást is a rendelési pontosítása érdekében a termékek mellé, esetleg alkalmazzunk kedvezményt.
                </li>
                <li>
                    Ha mindennel megvagyunk és a rendelési listánk teljes, akkor az <b>Asztalok</b> menüpontba kattintva a megnyíló felületen kiválasztjuk az asztalt.
                </li>
                <li>
                    Amint rákattintottunk az asztalra meg is jelent a rendelési listánk felett bal oldalon szürke színnel szedve. Nincsen más dolgunk, mint a <b>Felvesz</b> gombra kattintva felvenni az asztalhoz a rendelést, ezzel készen is vagyunk.
                </li>
            </ol>
            <img src='/img/16.%20video-2.jpg'/>
            <p>
                <b><u>A második lehetséges megoldás a következő:</u></b>
            </p>
            <ol>
                <li>
                    Kiválasztjuk azt az asztalt az <b>Asztalok</b> fül alatt, ahol a vendég rendelni szeretne.
                </li>
                <li>
                    Az asztalra kattintást követően, meg is jelenik a rendelési listánk felett az asztal száma, a bal oldalon szürke színnel szedve. Jól látható hogy 0 Ft-os tartozást ír jelenleg, hiszen az asztal nincsen még beterhelve.
                </li>
                <li>
                    Most, hogy kiválasztottuk az asztalt, megkezdhetjük a rendelés felvételét, kiválasztva a kategóriákon belül a kívánt termékeket.
                </li>
                <li>
                    Amint készen van a teljes rendelési listánk, akkor a bal oldalon középen található <b>Felvesz</b> gombra kattintva már fel is vehetjük a rendelést.
                </li>
            </ol>
            <p>
                Ha ellenőrizni szeretnénk, hogy valóban megtörtént-e a felvétel, azt az <b>Asztalok</b> fülre kattintva tudjuk ellenőrizni, hiszen ott minden rendeléssel beterhelt asztal rögtön sárga színnel lesz szedve, ezzel átláthatóbbá téve a munkafolyamatokat.
            </p>
        </div>
    },
    {
        name: "17. Szervizdíj 2. rész",
        link: "",
        text: <div>
            <p>
                Az 1. részben átvettük, hogy a szervizdíj mértékét (%-ban megadva) a Webirodában tudjuk központilag állítani, de lehetőség van arra is, hogy manuálisan a terminálon, legyen az tablet/telefon, állítsuk. Azonban ilyen esetekben, minden rendelésnél át kell állítani, ahol szeretnénk, hogy a terminál szervizdíj mértéke eltérjen a központilag megadott Webirodás mértéktől.
            </p>
            <img src='/img/17.%20video-1.jpg'/>
            <p>
                <b><u>A terminálon történő szervizdíj mértékének beállítási menete:</u></b>
                <br/>
                (Fontos itt megjegyezni, hogy mi a Webirodában előzőleg 0%-ot állítottunk be szervizdíjnak)
            </p>
            <ol>
                <li>
                    Mielőtt szeretnénk fizettetni a vendéget (mindez lehet asztalhoz felvett rendelésnél, vagy asztalfelütés nélküli rendelésnél) a teljes rendelési listát látjuk a bal oldalon, na most a bal alsó sorba található pici szürke felfelé nyílra kell kattintani.
                </li>
                <li>
                    Amint rákattitottunk meg is nyílik a rendelési részletezése, itt a még szürke színnel jelzett <b>Szervizdíj</b> gombra kattintsunk.
                </li>
                <li>
                    Ha rákattintottunk, meg is jelenik egy felület, amelyen átállíthatjuk a szervizdíj %-át. Alapértelmezettnek a rendszer azt mutatja, amit mi előzőleg a Webirodában beállítottunk, de most a görgő segítségével átállíthatjuk a kívánt százalékra pl: 10%-ra.
                </li>
                <li>
                    A változtatás elmentéséhez a <b>Rendben</b> gombra kattintsunk, amennyiben mégsem szeretnénk elmenteni, úgy kattintsunk a fehér ablakon kívülre és máris visszajutunk az előző felületre.
                </li>
                <li>
                    Ha meg szeretnénk bizonyosodni róla, hogy a beállított szervizdíj mértéke valóban applikálódott-e a rendelésen, akkor a bal oldalon alul található részletezőben a <b>Sze. Díj</b> mellett meg tudjuk nézni.
                </li>
            </ol>
            <img src='/img/17.%20video-2.jpg'/>
        </div>
    },
    {
        name: "18 Fogások/Rendelések elkészítésének sorrendje - konyhai címke",
        link: "",
        text: <div>
            <p>
                Ahogy az előző témákban már szó esett róla, a rendelések ledásakor a pincérek beállíthatják a termináljukon, legyen az tablet/telefon, a fogások sorrendjét, ezzel elősegítve a konyhai dolgozók munkáját. Most vegyük is akkor át, hogy pontosan hol tudjuk mindezt beállítani és hogy miért is olyan hasznos ez a funkció.
            </p>
            <p>
                <b><u>A beállítás lépései:</u></b>
            </p>
            <ol>
                <li>
                    Felvesszük a vendégek rendeléseit a rendelési listára az adott asztalnál, de még mielőtt rányomnánk a <b>Felvesz</b> gombra előtte beállítjuk a fogások sorrendjét a következő képpen. (Az italoknál is beállíthatunk sorrendet, ha szeretnénk, de mivel ott mindennek egyszerre kell készlennie, így azt mi nem tartjuk annyira relevánsnak, de ez döntés kérdése)
                </li>
                <li>
                    A felvett ételeknél legyen például leves-főétel-desszert, ilyen esetekben mivel a levest mindenki először szokta elfogyasztani, ennél hagyhatjuk a “Fogás1” narancssárga jelzést, viszont a főételnél is alapértelmezettként Fogás1 megnevezés szerepel. Ahhoz, hogy mindezen változtassunk, kattintsunk bele az adott ételbe és amint megjelenik körülötte a kék sáv ott láthatjuk is, hogy alul szerepel a <b>Fogás</b> gomb.
                </li>
                <li>
                    A <b>Fogás</b> gombra kattintva tudjuk növelni a fogás sorszámát, így a konyhán látni fogják, hogy az ételeket milyen sorrendben készítsék majd el. (A konyhán, ilyenkor egy vonallal elválasztva érkeznek meg a felvett ételek a címkén, ezzel elősegítve az átláthatóságot).Akkor a főételünknek állítsuk be a “<b>Fogás2</b>”-t a desszertnek a “<b>Fogás3</b>”-at és így tovább. A fogásokat a borvacsorához igazítottuk, így 6 db-ig lehet növelni az értéket. Akkor sem kell megijedni, ha véletlenül tovább kattintottuk és például nem “Fogás2”, hanem “Fogás3”-at állítottunk be hiszen, amint eléri a 6-os értéket a számláló rögtön elölről indul, így bármikor korrigálható.
                </li>
                <img src='/img/18.%20video-1.jpg'/>
                <li>
                    Ha beállítottuk a fogások sorrendjét akkor a <b>Felvesz</b> gombra kattintva be is fejezhetjük a rendelés felvételét. Nagyon fontos itt megjegyezni, hogy a fogások sorrendjét csak rendelés felvétel előtt tudjuk szerkeszteni, mert amint felvettük az adott asztalhoz már nem lesz változtatható.
                </li>
            </ol>
            <p>
                Az említett funkció nagyon hasznos lehet, akkor mikor például egy pár tagjai egy levest és egy főételt kérnek, viszont nem szeretnének egymásra várni, így egyszerre szeretnék elfogyasztani. Ilyen esetekben nagyon megkönnyítheti a dolgunkat ez funkció, mivel csak ugyanolyan fogási sorrendre kell állítanunk a levest és a főételt és már kész is.
            </p>
        </div>
    },
    {
        name: "19. További rendelés felvétele már rendelt asztalhoz",
        link: "",
        text: <div>
            <p>
                Nagyon sokszor van olyan helyzet, mikor a vendégek már ugyan leadták a rendelésüket, de még szeretnének 1-2 dolgot kérni plusszban pl: ásványvíz, kávé stb. Ahogy fentebb a rendelésfelvétel témánknál is, itt is kétféle lehetőség van a rendelések további felvételére. A két lehetőség között az a különbség, hogy az elsőnél felvesszük a rendelt terméket majd utána választjuk hozzá ki hozzá az asztalt, míg a második lehetőségnél pont ellenkezőleg, az asztalhoz rendeljük a terméket.
            </p>
            <p>
                <b><u>Első megoldási lehetőség lépései: </u></b>
            </p>
            <ol>
                <li>
                    Kiválasztjuk a kategóriák termékeiből a vendég által elfogyasztani kívánt terméket, erre rá is kattintunk, így megjelenik a bal oldali rendelési sávban.
                </li>
                <li>
                    Nincs más dolgunk, mint a felvett terméket/keket hozzárendeljük a vendég asztalához. Mindehhez az <b>Asztalok</b> menüpontba kattintunk, jobb oldalon meg is jelenik az asztalok listája, (könnyű lesz kiszúrni az adott asztalt, hiszen minden már beterhelt asztalt, amelyet még nem térítettek a vendégek sárga színnel jelöl a rendszer), amint kiválasztottuk és bele is kattintottunk megjelenik a bal oldali rendelési sávunk felett szürke csíkkal a száma valamint, hogy mennyi tartozás van jelenleg rajta.
                </li>
                <li>
                    Ha szeretnénk akkor a <b>Lista</b> gombra kattintva (a rendelési lista felett)ki is listázhatjuk az eddigi rendeléseit a vendégnek és utána felvehetjük a rendelést a <b>Felvesz</b> gombra kattintva.
                </li>
            </ol>
            <p>
                <b><u>Második megoldási lehetőség lépései:</u></b>
            </p>
            <ol>
                <li>
                    Az Asztalok fülbe kattintva válasszuk ki a vendég asztalát (nagy segítség, hogy minden már beterhelt asztalt sárga színnel jelöl a rendszer), amint ezzel megvagyunk kattintsunk is a konkrét asztalra.
                </li>
                <li>
                    Kattintás után meg is jelenik a bal oldali rendelési sávunk felett szürke csíkkal az asztal száma valamint, hogy mennyi tartozás van jelenleg rajta. Ha szeretnénk akkor a <b>Lista</b> gombra kattintva (a rendelési lista felett) ki is listázhatjuk az eddigi rendeléseit a vendégnek.
                </li>
                <li>
                    Most pedig a főoldalon lévő kategóriákból válasszuk ki a vendég által rendelt termékeket és üssük fel a rendelési litára. Amint mindezzel megvagyunk a <b>Felvesz</b> gombra kattintva hozzá is tudjuk rendelni az asztalhoz.
                </li>
            </ol>
        </div>
    },
    {
        name: "20. Eladások menüpont",
        link: "",
        text: <div>
            <p>
                A terminálunk, legyen az tablet/telefon, minden egyes a készüléken végrehajtott tranzakciót elment és dokumentál a rendszeren belül, így nemcsak a Webirodában, de a készülékünkön is ellenőrizni tudjuk ezeket. Nagyon fontos itt leszögezni hogy a terminálok mindig csak a saját tranzakcióikat mutatják, míg a teljes egészt (tehát az összes általunk használt készülék tranzakcióját) azt a Webirodában tudjuk megtekinteni.
            </p>
            <p>
                <b><u>Az Eladások menüpontot következőképpen érhetjük el:</u></b>
            </p>
            <ol>
                <li>
                    A jobb felső sarokban található <b>Menü</b> gombra (3 fekete csík) kattintás után, a bal oldalon megnyíló menüsoron az <b>Eladások</b> menüpontra kattintsunk.
                </li>
                <li>
                    <p>
                        A jobb oldalon megjelenő <b>Eladások</b> felület 3 fő részre tagolódik: a <b>Mai</b>, a <b>Függő</b> és az <b>Összes</b> fülre. Minden menüpont alatt kilistázva láthatjuk a különböző tranzakciókat részletesen:
                    </p>
                    <ul>
                        <li>
                            <b>Dátum:</b> megmutatja, hogy az adott rendelés mikor lett felvéve.
                        </li>
                        <li>
                            <b>Vásárló:</b> jelzi, hogy melyik asztalhoz/ vásárlóhoz/ személyzethez lett felütve az adott rendelés (ha itt a mező üres akkor a rendelést nem rendelték asztalhoz/vásárlóhoz/ személyzethez).
                        </li>
                        <li>
                            <b>Eladó:</b> mutatja, hogy mely személyzeti tag vette fel a rendelést az adott asztalhoz.
                        </li>
                        <li>
                            <b>Fizetés:</b> prezentálja a vásárláskor alkalmazott fizetési módot.
                        </li>
                        <li>
                            <b>Összeg:</b> a rendelés teljes összegét írja le.
                        </li>
                    </ul>
                    <img src='/img/20.%20video-1.jpg'/>
                    <p>
                        Minden egyes tranzakcióba, mely csíkként jelenik meg a listában bele tudunk kattintani és így ki is listázódik, hogy az adott rendelés mit tartalmazott(árakkal lebontva), illetve 2 nagyon fontos gomb jelenik meg a jobb oldalon. Az említett két gomb, a <b>Hamburger</b> gomb és a <b>Címke</b> gomb. A Hamburger gomb megnyomásával újra ki tudjuk küldeni az adott rendelést a konyhai-,illetve a bár nyomtatóra, a Címke gomb megnyomásával pedig újra tudjuk nyomtatni a rendelés számláját, ha szeretnénk(természetesen ezt mind akkor tudjuk megtenni, ha a rendelés már térítve lett). Az utóbbi gomb nagyon hasznos lehet dokumentációs szempontból, a napi zárásokhoz stb..
                    </p>
                    <img src='/img/20.%20video-2.jpg'/>
                    <p>
                        A legelső megjelenő fül a <b>Mai</b> nevet kapta, hiszen az összes aznap a készülékről elvégzett tranzakció, legyen ez már kifizetett rendelés, vagy még függő rendelés, ami asztalhoz van felütve mind itt jelenik meg. A már asztalhoz felvett-, de még nem térített rendelések mind citromsárga színnel vannak jelölve, ahogy az Asztalok fül alatt már megszokhattuk.
                    </p>
                    <p>
                        A sorban a második menüpont a <b>Függő</b>, mely csak azokat a rendeléseket tartalmazza, melyeket még a vendégek nem térítettek a vendéglátó egységünknek.
                    </p>
                    <p>
                        A harmadik és egyben utolsó <b>Összes</b> fül alatt, az összes az általunk használt készülékről elvégzett tranzakciókat listázza ki.
                    </p>
                </li>
            </ol>
        </div>
    },
    {
        name: "21. Asztalok mozgatása asztalhoz/személyzethez/vásárlóhoz",
        link: "",
        text: <div>
            <p>
                A ByThePay rendszerben lehetőség van a rendelések mozgatására asztalról egy másik asztalhoz/ törzsvásárlóhoz esetleg személyzethez. A következőkben át fogjuk venni,hogy ezt pontosan hogyan tudjuk végrehajtani, valamint hogy melyek a legfontosabb lépések mindebben.
            </p>
            <p>
                <b><u>Rendelés átmozgatása egy adott asztalról egy másik asztalra:</u></b>
            </p>
            <ol>
                <li>
                    Először az <b>Asztalok</b> fül alatt kiválasztjuk az áthelyezni kívánt asztalt, méghozzá úgy, hogy hosszan rákattintunk, ameddig meg nem jelenik az átmozgatási ablak.
                </li>
                <li>
                    A rendszer megkérdezi, hogy hova szeretnénk átmozgatni a tételeket, itt az <b>Asztal</b> gombra kattintsunk, majd a <b>Rendben</b> gombra.
                </li>
                <img src='/img/21.%20video-%201.jpg'/>
                <li>
                    Meg is jelenik a <b>Tételek átmozgatása</b> felület, ahol bal oldalon láthatjuk az eredeti asztalon a tételeket, illetve a jobb oldalon az új asztal számláját, ami még üres. Ahhoz, hogy át tudjuk vinni a termékeket egyenként az új számlára, a bal oldalon lévő eredeti asztalban lévő tételekre kell kattintanunk.
                </li>
                <li>
                    Viszont, ha a teljes rendelést szeretnénk átpakolni az új asztal számlájára, akkor a két számla között található <b>Dupla jobbra nyíl</b> ikont kell használnunk, ilyenkor a teljes lista egyben át is kerül. Ha valamilyen oknál fogva mégis szeretnénk a teljes listát visszatenni az eredeti asztalra, mert például egyenként szeretnénk válogatni a tételek között, akkor a <b>Dupla balra nyíl</b> ikont kell használnunk és minden vissza is kerül az eredeti helyre. Ellenben, ha csak 1-1 tételt szeretnénk visszatenni az eredeti asztal számlájára, akkor az új asztal számláján válasszuk ki az adott tételt, majd rákattintva már vissza is tettük az eredeti asztalra.
                </li>
                <img src='/img/21.%20video-2.jpg'/>
                <li>
                    Amint megvagyunk az átrendezéssel, akkor az <b>Átmozgatás ide</b> legördülő menüből válasszuk ki a kívánt asztalt, majd a <b>Mozgatás</b> gombra kattintva már át is mozgattuk.
                </li>
                <li>
                    Ellenőrzésként az <b>Asztalok</b> fülre kattintva, a megjelenő asztaloknál már láthatjuk is, hogy a kívánt tételek át lettek mozgatva az általunk választott új asztalra.
                </li>
            </ol>
            <p>
                <b><u>Rendelés átmozgatása egy adott asztalról egy vásárlóhoz:</u></b>
            </p>
            <ol>
                <li>
                    Először az <b>Asztalok</b> fül alatt kiválasztjuk az áthelyezni kívánt asztalt, méghozzá úgy, hogy hosszan rákattintunk, ameddig meg nem jelenik az átmozgatási ablak.
                </li>
                <li>
                    A rendszer megkérdezi, hogy hova szeretnénk átmozgatni a tételeket, itt az <b>Vásárló</b> gombra kattintsunk, majd a <b>Rendben</b> gombra.
                </li>
                <li>
                    Meg is jelenik a <b>Tételek átmozgatása</b> felület, ahol bal oldalon láthatjuk az eredeti asztalon a tételeket, illetve a jobb oldalon a vásárlónk új számláját, ami még üres. Ahhoz, hogy át tudjuk vinni a termékeket egyenként az új számlára, a bal oldalon lévő eredeti asztalban lévő tételekre kell kattintanunk.
                </li>
                <li>
                    Viszont, ha a teljes rendelést szeretnénk átpakolni a vásárló számlájára, akkor a két számla között található <b>Dupla jobbra nyíl</b> ikont kell használnunk, ilyenkor a teljes lista egyben át is kerül. Ha valamilyen oknál fogva mégis szeretnénk a teljes listát visszatenni az eredeti asztalra, mert például egyenként szeretnénk válogatni a tételek között, akkor a <b>Dupla balra nyíl</b> ikont kell használnunk és minden vissza is kerül az eredeti helyre. Ellenben, ha csak 1-1 tételt szeretnénk visszatenni az eredeti asztal számlájára, akkor a vásárló új számláján válasszuk ki az adott tételt, majd rákattintva már vissza is tettük az eredeti asztalra.
                </li>
                <li>
                    Amint megvagyunk az átrendezéssel, akkor az <b>Átmozgatás ide</b> legördülő menüből válasszuk ki az adott vásárlót, majd a <b>Mozgatás</b> gombra kattintva már át is mozgattuk.
                </li>
                <li>
                    Ellenőrzésként a <b>Vásárlók</b> fülre kattintva, a megjelenő vásárlóknál már láthatjuk is, hogy a kívánt tételek át lettek mozgatva az általunk kiválasztott vásárló számlájára.
                </li>
            </ol>
            <p>
                <b><u>Rendelés átmozgatása egy adott asztalról egy személyzeti taghoz:</u></b>
            </p>
            <ol>
                <li>
                    Először az <b>Asztalok</b> fül alatt kiválasztjuk az áthelyezni kívánt asztalt, méghozzá úgy, hogy hosszan rákattintunk, ameddig meg nem jelenik az átmozgatási ablak.
                </li>
                <li>
                    A rendszer megkérdezi, hogy hova szeretnénk átmozgatni a tételeket, itt a <b>Személyzet</b> gombra kattintsunk, majd a <b>Rendben</b> gombra.
                </li>
                <li>
                    Meg is jelenik a <b>Tételek átmozgatása</b> felület, ahol bal oldalon láthatjuk az eredeti asztalon a tételeket, illetve a jobb oldalon a személyzeti tagunk új számláját, ami még üres. Ahhoz, hogy át tudjuk vinni a termékeket egyenként az új számlára, a bal oldalon lévő eredeti asztalban lévő tételekre kell kattintanunk.
                </li>
                <li>
                    Viszont, ha a teljes rendelést szeretnénk átpakolni a személyzeti tagunk számlájára, akkor a két számla között található <b>Dupla jobbra nyíl</b> ikont kell használnunk, ilyenkor a teljes lista egyben át is kerül. Ha valamilyen oknál fogva mégis szeretnénk a teljes listát visszatenni az eredeti asztalra, mert például egyenként szeretnénk válogatni a tételek között, akkor a <b>Dupla balra nyíl</b> ikont kell használnunk és minden vissza is kerül az eredeti helyre. Ellenben, ha csak 1-1 tételt szeretnénk visszatenni az eredeti asztal számlájára, akkor a személyzeti tagunk új számláján válasszuk ki az adott tételt, majd rákattintva már vissza is tettük az eredeti asztalra.
                </li>
                <li>
                    Amint megvagyunk az átrendezéssel, akkor az <b>Átmozgatás ide</b> legördülő menüből válasszuk ki az adott személyzeti tagot, majd a <b>Mozgatás</b> gombra kattintva már át is mozgattuk.
                </li>
                <li>
                    Ellenőrzésként a <b>Vásárlók</b> menüpontot választva, majd a megjelenő füleknél a <b>Személyzet</b>-re kattintva már láthatjuk is, hogy a kívánt tételek át lettek mozgatva az általunk kiválasztott személyzeti tag számlájára.
                </li>
            </ol>
            <p>
                Nagyon fontos megemlíteni, hogy ha asztalra mozgatunk át egy rendelést, azt mivel asztalon van mindig tovább tudjuk majd mozgatni, viszont ha vásárlóhoz, vagy személyzethez mozgatjuk át, onnan már nem tudjuk visszamozgatni az asztalokhoz.
            </p>
        </div>
    },
    {
        name: "22. Számlabontás",
        link: "",
        text: <div>
            <p>
                Nagyon sokszor adódik olyan helyzet amikor egy asztal vendégei külön szeretnének fizetni, ilyenkor számlabontásra lesz szükség.
            </p>
            <p>
                <b><u>A rendszerünkben a számlabontást a következőképpen tudjuk megtenni:</u></b>
                <br/>
                Tegyük fel, hogy asztalunknál két barát ül, akik külön szeretnék rendezni a számlájukat.
            </p>
            <ol>
                <li>
                    Első lépésként az <b>Asztalok</b> fülre kattintva, válasszuk ki a fizetni kívánó vendégeink asztalát. Amint a kívánt asztalra kattintottunk, meg is jelent a bal oldali rendelési listánk felett szürke csíkban az asztal száma, valamint a tartozás mértéke.
                </li>
                <li>
                    Mindezek után a leges-legfontosabb lépés az, hogy a <b>Lista</b> gombra kattintva kilistázzuk a rendszer számára, illetve számunkra, hogy az adott asztalnál a vendégek mit fogyasztottak. Amint kilisztáztuk a rendelést, a bal oldalon alul található <b>Fizet</b> gombra nyomjunk. (Ha elfelejtünk a Fizet gomb megnyomása előtt, a Lista gombra nyomni, mely kilistázza a rendeléseket, akkor a rendszer azt fogja kiírni, hogy “A rendelési lista üres”. Ilyenkor sincsen semmi baj, bármikor tudjuk korrigálni a dolgot.)
                </li>
                <img src='/img/22.%20video-%201.jpg'/>
                <li>
                    A <b>Fizet</b> gombra kattintva a jobb oldalon látható lesz felül a <b>Számlabontás</b> gomb. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.) Kattintsunk a <b>Számlabontás</b> gombra.
                </li>
                <img src='/img/22.%20video-%202.jpg'/>
                <li>
                    Az előző témából már jól ismert átmozgatási felület nyílik meg számunkra. Bal oldalon láthatjuk az asztal teljes tétellistáját, jobb oldalon pedig az új számlát, mely még üres. Ahhoz, hogy át tudjuk vinni a termékeket egyenként az új számlára, a bal oldalon lévő eredeti számlán lévő tételekre kell kattintanunk. A középen található <b>Dupla jobbra nyíl</b> ikon, abban segít ha a teljes eredeti számla tételsorát át szeretnénk vinni az új számlára. Természetesen ez visszafelé is lehetséges, tehát az új számla teljes tételsora visszatehető az eredeti számlára, méghozzá a <b>Dupla balra nyíl</b> ikonra kattintva. Amennyiben esetleg túl sok tételt vittünk át az új számlára, akkor sincsen semmi gond, hiszen ugyanúgy az új számlán lévő tételre kattintva vissza is tudjuk küldeni az eredeti számlára.
                </li>
                <img src='/img/22.%20video-%203.jpg'/>
                <li>
                    Ha megvagyunk az első vendég új számlájának összeállításával a <b>Fizet</b> gombra kattintva, meg is jelenik a <b>Fizetési mód</b> alatt villogva, hogy <b>Részszámla fizetése</b>. Itt ki kell választanunk a vendég által preferált fizetési módot, majd fizettetni.
                </li>
                <img src='/img/22.%20video-%204.jpg'/>
                <li>
                    A következő vendégnél, mivel újra visszajutottunk a fő felületre a <b>Fizet</b> gombra kell nyomnunk, majd ott ki kell választanunk a vendég által preferált fizetési módot, majd fizettetni. (Viszont ha többen lennének, akkor a <b>Számlabontás</b> gombra kattintva folytathatnánk tovább a folyamatokat…)
                </li>
            </ol>
            <p>
                Részszámlát bontani egy számlán belül többször is lehet, így például egy 15 fős asztalt is simán külön-külön számlákra lehet bontani néhány perc alatt.
            </p>
            <p>
                Néha adódik olyan helyzet, hogy a vendégek mégsem szeretnének számlát bontani, ilyenkor ha már a számlabontási felületen vagyunk, a <b>Mégse</b> gombra kattintva ki tudunk lépni és folytatni tovább a teljes asztal rendelésének fizettetést.
            </p>
        </div>
    },
    {
        name: "23. Fizettetés",
        link: "",
        text: <div>
            <p>
                A fizettetés a ByThePay szoftverben lehetséges úgyis, hogy egy adott asztalhoz felvett rendelést fizettetünk ki, valamint megoldható az is, hogy mindez asztalhoz felütés nélkül történik. <b><u>Az utóbbit a 14. témában átvettük részletesen, így most az első megoldást vesszük górcső alá:</u></b>
            </p>
            <ol>
                <li>
                    A legelső lépésként az <b>Asztalok</b> fülre kattintunk, a megjelenő listából kiválasztjuk a fizetni kívánó vendég asztalát, tehát rá is kattintunk.
                </li>
                <li>
                    Amint belekattintottunk az kívánt asztalba, a bal oldali rendelési lista felett (szürke csíkban) rögtön meg is jelenik az asztal száma, valamint az asztalon felhalmozott tartozás. Ahogy a fentebbi témákban is említettük a leges-legfontosabb lépés a fizettetésnél az az, hogy a felső sorban található <b>Lista</b> gombra kattintsunk. A <b>Lista</b> gomb segít kilistázni a rendszer számára, illetve számunkra, hogy az adott asztalnál a vendégek mit fogyasztottak. Amint kilisztáztuk a rendelést, a bal oldalon alul található <b>Fizet</b> gombra nyomjunk. (Ha elfelejtünk a Fizet gomb megnyomása előtt, a Lista gombra nyomni, mely kilistázza a rendeléseket, akkor a rendszer azt fogja kiírni, hogy “A rendelési lista üres”. Ilyenkor sincsen semmi baj, bármikor tudjuk korrigálni a dolgot.)
                </li>
                <img src='/img/23.%20video-1.jpg'/>
                <li>
                    A felületünk bal oldalt elhalványodott és jobb oldalon megjelentek a <b>Fizetési lehetőségek</b>, valamint <b>módok</b>. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.)
                </li>
                <li>
                    Itt ki kell választanunk a vendég által preferált <b>Fizetési módot</b>, majd fizettetni.
                </li>
                <li>
                    A fizetendő összegnél beírjuk az összeget, mellyel fizettetni fogunk (de ha bankkártyás, esetleges utalványos lesz a térítés akkor elég rögtön a <b>Fizet</b> gombra nyomni), majd a <b>Fizet</b> gombra kattintva, ki is írja a rendszer a visszajáró összegét. Készen is vagyunk.
                </li>
            </ol>
            <img src='/img/23.%20video-2.jpg'/>
        </div>
    },
    {
        name: "24. Kedvezmények használata 1.",
        link: "",
        text: <div>
            <p>
                Kedvezményeinket 3 fő pillérre építettük fel: a termék-, a kategória-, valamint a törzsvásárlói csoporthoz rendelt kedvezményekre. Két fajta kedvezményt tudunk biztosítani vendégeink számára:
            </p>
            <ul>
                <li>
                    árkülönbség kedvezményt (például: -100Ft kedvezmény az eredeti árból)
                </li>
                <li>
                    százalékos kedvezményt (például: 10% kedvezmény az eredeti árból)
                </li>
            </ul>
            <p>
                Minden a terminálon manuálisan alkalmazható, vagy a rendszer által automatikusan hozzászámolt kedvezmény a Webirodában kerül kialakításra, majd szinkronizálás útján a készülékek megkapják a legfrissebb adatokat a szerverről. Jelen esetben a szinkronizálódásnak köszönhetően a kedvezmények fajtája és mértéke kerül frissítésre, mely rögtön használhatóvá is válik a dolgozók számára.
            </p>
            <p>
                Mind a kategória-, mind a termék-, mind a törzsvásárlói csoporthoz rendelt kedvezményeknél általában minden automatikusan alkalmazódik a rendszer által. Az egyetlen kivétel az imént említett eset alól az, ha olyan kategória-, termék-, vagy törzsvásárlói csoporthoz rendelt kedvezményt hozunk létre, ahol nem jelöljük  meg hogy pontosan melyik termékre, kategóriára, vásárlónak/személyzeti tagnak adjuk, hanem a “bármelyik” mezőt választjuk ki a Webirodában. Ilyen esetekben, a kívánt kategória-, illetve termék kedvezményt a rendelési listára már felvett termékek sávjában tudjuk manuálisan hozzáadni, továbbá ha törzsvásárlói csoporthoz rendelt kedvezményről van szó azt a rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva a <b>Kedvezmények</b> gombot kiválasztva tudjuk megtenni.
            </p>
            <p>
                <b>Jelen témában a kategória- és a termék kedvezményeket fogjuk górcső alá venni</b>, valamint <b>belepillantunk a kosár összegre adható kedvezményekbe is</b> (ilyenkor a teljes rendelési listára alkalmazódik a választott kedvezmény).
            </p>
            <p>
                <b>Elsőként a kategória kedvezményt vesszük át részletesen.</b> Fontos megemlíteni, hogy a példa kedvéért mi a Webirodában előzőleg már felvettünk egy “Kávé napok” nevű 20%-os kedvezményt a Kávék kategóriára (így tehát a Kávék kategóriába tartozó összes elem, a 20%-os csökkentett árral üthető majd fel a vendégeknek a rendelés során).
            </p>
            <ul>
                <li>
                    Ahhoz, hogy a fentebb leírtaknak a működését ellenőrizni tudjuk, a főmenübe kattintsunk a Kávék kategórián belül bármely termékre pl: Frappé-ra, majd amint rákattintottunk, meg is jelent a bal oldali rendelési listánkon. A termék neve alatt kettővel lejjebb (a fogás szám alatt) láthatóvá is válik, hogy a rendszer automatikusan fel is számolta a kedvezményt, hiszen meg is jelenik a “Kávé napok” megnevezés.
                </li>
                <li>
                    Amennyiben mégsem szeretnénk az adott kedvezményt használni, vagy esetleg egy másik kedvezményt alkalmaznánk, arra is van mód, ilyenkor a termék kék sávjában alul található <b>Kedvezmény</b> gombra kattintsunk. Meg is jelennek számunkra az adott termék kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé. Amint megvagyunk a kívánt kedvezmények alkalmazásával a <b>Rendben</b> gombra kattintva el is tudjuk menteni a változtatásokat. Innen folytathatjuk is rendelés felvételét és a vendég kiszolgálását.
                </li>
                <li>
                    Ha szeretnénk megnézni a teljes rendelési listánk kedvezményének mértékét, akkor a bal oldali rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva meg is jelenik a rendelési lista részletezője. Itt a <b>Kedv.</b> csík mellett látható a rendelési listán alkalmazott összes kedvezmény mértéke.
                </li>
            </ul>
            <img src='/img/24.%20video-%201.jpg'/>
            <p>
                <b>Most pedig folytassuk a termék kedvezménnyel.</b> Fontos megemlíteni, hogy a példa kedvéért mi a Webirodában előzőleg már felvettünk egy “Gofri kedvelők” nevű 300 Ft értékű árkülönbség kedvezményt a Belga gofri termékünkre (így tehát a Belga Gofri termék most -300 Ft-tal kevesebb áron üthető majd fel a vendégeknek a rendelés során).
            </p>
            <ul>
                <li>
                    Ahhoz, hogy a fentebb leírtaknak a működését ellenőrizni tudjuk, a főmenübe kattintsunk a Desszertek kategórián belül a Belga gofri termékre, majd amint rákattintottunk, meg is jelent a bal oldali rendelési listánkon. A termék neve alatt kettővel lejjebb (a fogás szám alatt) láthatóvá is válik, hogy a rendszer automatikusan fel is számolta a kedvezményt, hiszen meg is jelenik a “Gofri kedvelők” megnevezés.
                </li>
                <li>
                    Amennyiben mégsem szeretnénk az adott kedvezményt használni, vagy esetleg egy másik kedvezményt alkalmaznánk, arra is van mód, ilyenkor a termék kék sávjában alul található <b>Kedvezmény</b> gombra kattintsunk. Meg is jelennek számunkra az adott termék kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé. Amint megvagyunk a kívánt kedvezmények alkalmazásával a <b>Rendben</b> gombra kattintva el is tudjuk menteni a változtatásokat. Innen folytathatjuk is rendelés felvételét és a vendég kiszolgálását.
                </li>
                <li>
                    Ha szeretnénk megnézni a teljes rendelési listánk kedvezményének mértékét, akkor a bal oldali rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva meg is jelenik a rendelési lista részletezője. Itt a <b>Kedv.</b> csík mellett látható a rendelési listán alkalmazott összes kedvezmény mértéke.
                </li>
            </ul>
            <img src='/img/24.%20video-%202.jpg'/>
            <p>
                <b>Eljutottunk a téma utolsó részéhez, méghozzá a teljes kosárra adható kedvezményekhez.</b> Fontos megemlíteni, hogy a példa kedvéért létrehoztunk egy “Végösszeg 5%”-os kedvezményt (amely bármely vásárláshoz adható), ami a teljes kosárösszegből számolja le a megadott kedvezmény mértékét.
            </p>
            <ul>
                <li>
                    Mindezt úgy tudjuk alkalmazni, hogy amint készen vagyunk a rendelési listával (még mielőtt rákattintunk a <b>Felvesz</b> gombra) a bal oldali rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva megnyitjuk a rendelés részletezőjét. A részletezőben alul található <b>Kedvezm.</b> gomb még szürke, de amint belekattintunk meg is jelenik a jól ismert kedvezmények lap. Itt jelennek meg a rendelési lista kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé. Itt ki tudjuk választani a <b>Lehetséges kedvezmények</b> közül a “Végösszeg 5%” kedvezményt.
                </li>
                <li>
                    Amint megvagyunk a kívánt kedvezmények alkalmazásával a <b>Rendben</b> gombra kattintva el is tudjuk menteni a változtatásokat.
                </li>
                <li>
                    Amint bezárult a kedvezmények lap már láthatjuk is hogy a <b>Kedvezm.</b> gomb kék lett tehát aktívvá vált, valamint a rendelési lista tetején megjelent piros csíkban a “Végösszeg 5%” kedvezményünk.
                </li>
                <li>
                    Nagyon fontos kiemelni, hogy minden kosárösszetételre adott kedvezmény a rendelési lista tetején fog szerepelni piros csíkban, így tudjuk majd a lehető legkönnyebben megkülönböztetni a kategória- és a termék kedvezményektől.
                </li>
            </ul>
            <img src='/img/24.%20video-%203.jpg'/>
            <p>
                Mindemellett van arra is lehetőség, hogy összefésüljük a fent említett kedvezményeket méghozzá egy rendelésen. Tehát például lehetőség van arra, hogy a fent említett “Kávé napok” alkalmával rendelt cappuccino mellé elfogyasszunk egy belga gofrit “Gofri kedvelők” kedvezménnyel és hogy mindezekre még egy “Végösszeg 5%-os” kedvezményt is kapjunk.
            </p>
        </div>
    },
    {
        name: "25. Kedvezmények használata 2.",
        link: "",
        text: <div>
            <p>
                Az előző témában átbeszéltük, a termék-, illetve kategória kedvezmények használatát, most pedig a harmadik adható kedvezményről, a törzsvásárlói csoporthoz rendelt kedvezményekről fogunk szót ejteni. A törzsvásárlói csoporthoz rendelt kedvezmény is szintén a Webirodában kerül kialakításra, majd szinkronizálás útján a készülékek megkapják a legfrissebb adatokat a szerverről. Jelen esetben a szinkronizálódásnak köszönhetően a kedvezmények fajtája és mértéke kerül frissítésre, mely rögtön használhatóvá is válik a dolgozók számára.
            </p>
            <p>
                Ahogy már korábban tisztáztuk, két fajta kedvezményt tudunk biztosítani vendégeink számára:
            </p>
            <ul>
                <li>
                    árkülönbség kedvezményt (például: -100Ft kedvezmény az eredeti árból)
                </li>
                <li>
                    százalékos kedvezményt (például: 10% kedvezmény az eredeti árból)
                </li>
            </ul>
            <p>
                Ahogyan a kategória- és a termék kedvezményeknél, úgy a törzsvásárlói csoporthoz rendelt kedvezményeknél is általában minden automatikusan alkalmazódik a rendszer által. Az egyetlen kivétel az imént említett eset alól az, ha olyan kategória-, termék-, vagy törzsvásárlói csoporthoz rendelt kedvezményt hozunk létre, ahol nem jelöljük  meg hogy pontosan melyik termékre, kategóriára, vásárlónak/személyzeti tagnak adjuk, hanem a “bármelyik” mezőt választjuk ki a Webirodában. Ilyen esetekben, a kívánt törzsvásárlói csoporthoz rendelt kedvezményt a rendelésünkhöz úgy tudjuk manuálisan hozzáadni, hogy a rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintunk, majd a megjelenő részletezőben a <b>Kedvezmények</b> gombot kiválasztjuk és ott kedvezmények ablakban pedig a kívánt kedvezményt.
            </p>
            <p>
                Fontos leszögezni, hogy a törzsvásárlói csoporthoz rendelt kedvezmények mindig minden esetben a teljes kosárösszetételre vonatkoznak (tehát a teljes végösszeget csökkenti majd a választott kedvezmény). Valamint nagyon könnyű megismerni az ilyen típusú kedvezményeket, hiszen mindig a bal oldali rendelési lista felett piros csíkban jelennek meg, jelezve, hogy a teljes rendelési listára értendőek.
            </p>
            <p>
                A példa kedvéért létrehoztunk előzőleg a Webirodában 3 féle törzsvásárlói kedvezményt, mellyeket az egyes vásárlókhoz rendeltünk:
            </p>
            <ul>
                <li>
                    Arany csomagot a  Vásárló 1-nek   -   20% kedvezmény a teljes fogyasztásból
                </li>
                <li>
                    Ezüst csomagot a  Vásárló 2-nek   -   15% kedvezmény a teljes fogyasztásból
                </li>
                <li>
                    Bronz csomagot a  Vásárló 3-nek   -   10% kedvezmény a teljes fogyasztásból
                </li>
            </ul>
            <p>
                Úgy tudjuk tesztelni, hogy működik-e a létrehozott kedvezményünk, hogy a főoldalon a bal felső sarokban lévő <b>Vásárlók</b> gombra kattintunk, majd a megjelenő listában kiválasztjuk az egyik vásárlót, például a Vásárló1-et akinek arany csomagja van (20%-os kosárösszeg kedvezmény).
                <br/>
                Ha kiválasztottuk a vásárlónkat, akkor elkezdjük felvenni a rendelését. Amint megvagyunk a rendeléssel láthatjuk is hogy a bal felső rendelési lista felett piros csíkban meg is jelent a “Kedvezmény 20%” felirat, ami a teljes rendelési listára érvényes. Amennyiben mégsem szeretnénk az adott kedvezményt használni, vagy esetleg egy másik kedvezményt alkalmaznánk, arra is van mód, ilyenkor még mielőtt rákattintunk a <b>Felvesz</b> gombra a bal oldali rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva megnyitjuk a rendelés részletezőjét. A részletezőben alul található <b>Kedvezm.</b> gomb még szürke, de amint belekattintunk meg is jelenik a jól ismert kedvezmények lap. Itt jelennek meg a rendelési lista kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé.
            </p>
            <img src='/img/25.%20video-1.jpg'/>
            <p>
                Természetesen nem csak törzsvásárlóknak adhatóak a <b>törzsvásárlói csoporthoz rendelt kedvezmények</b> hanem a <b>személyzeti tagoknak</b> is, éppen ezért a következő példában ezt fogjuk részletesen átvenni.
            </p>
            <p>
                A példa kedvéért a Webirodában előzőleg létrehoztunk egy “Személyzeti 40%”-os kedvezményt, mely a személyzet összes tagjára érvényes, illetve ahogy eddig is a teljes rendelési listára vonatkozik.
            </p>
            <p>
                Úgy tudjuk ellenőrizni a létrehozott kedvezményünk, hogy a főoldalon a bal felső sarokban lévő <b>Vásárlók</b> gombra-, majd a megjelenő <b>Személyzet</b> gombra kattintunk. Amint ezzel megvagyunk a személyzeti tagok kilistázódnak és közülük válasszunk ki egyet, például: az üzletvezetőt. Ha rákattintottuk a választott személyzeti tagra és elkezdjük felvenni a rendelést láthatjuk, hogy a bal felső rendelési lista felett piros csíkban meg is jelent a “Kedvezmény 40%” felirat, ami a teljes rendelési listára érvényes. Amennyiben mégsem szeretnénk az adott kedvezményt használni, vagy esetleg egy másik kedvezményt alkalmaznánk, arra is van mód, ilyenkor még mielőtt rákattintunk a <b>Felvesz</b> gombra a bal oldali rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintva megnyitjuk a rendelés részletezőjét. A részletezőben alul található <b>Kedvezm.</b> gomb még szürke, de amint belekattintunk meg is jelenik a jól ismert kedvezmények lap. Itt jelennek meg a rendelési lista kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé.
            </p>
            <img src='/img/25.%20video-2.jpg'/>
            <p>
                Mindemellett van arra is lehetőség, hogy összefésüljük mind a termék-, mind a kategória-, mind a törzsvásárlói csoporthoz adott kedvezményeket méghozzá egy rendelésen. Tehát például lehetőség van arra, hogy Vásárló 2 (Ezüst csomag 15 % kedvezménnyel) a “Kávé napok” alkalmával rendelt cappuccino (20%-os kategória kedvezmény) mellé elfogyasszon egy belga gofrit “Gofri kedvelők” kedvezménnyel (300Ft-os termék kedvezmény) és hogy már az alapvetően is alkalmazott ezüst csomagos 15%-os kosárösszeg kedvezményre még egy “Végösszeg 5%-os” kedvezményt is kapjon.
            </p>
            <img src='/img/25.%20video-3.jpg'/>
        </div>
    },
    {
        name: "26. Elviteles rendelés",
        link: "",
        text: <div>
            <p>
                <b><u>Az elviteles rendelés felvételét következőképpen tudjuk megtenni:</u></b>
            </p>
            <ol>
                <li>
                    A főoldalon a kategóriák termékeiből kiválasztjuk a vendég által kért tételeket, amelyek automatikusan megjelennek a bal oldali rendelési listában.
                </li>
                <li>
                    Amint teljesen kész a rendelésünk, akkor a rendelési lista alján található szürke <b>Pici felfelé nyílra</b> kattintunk. Meg is jelenik rögtön a rendelés részletezője, vele együtt a bal oldalon, az <b>Elvitel</b> gombbal.
                </li>
                <li>
                    Az <b>Elvitel</b> gomb jelenleg szürke, hiszen passzív (nincs használatban), viszont amint rákattintunk aktívvá, kék színűvé válik. Innen már nincs más dolgunk mint fizettetni a vendéget.Tehát rákattintunk a <b>Fizet</b> gombra.
                </li>
                <img src='/img/26%20video-1.jpg'/>
                <li>
                    A felületünk bal oldalt elhalványodott és jobb oldalon megjelentek a <b>Fizetési lehetőségek</b>, valamint <b>módok</b>. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.)
                </li>
                <li>
                    Itt ki kell választanunk a vendég által preferált <b>Fizetési módot</b>, majd fizettetni.
                </li>
                <li>
                    A fizetendő összegnél beírjuk az összeget, mellyel fizettetni fogunk (de ha bankkártyás, esetleges utalványos lesz a térítés akkor elég rögtön a <b>Fizet</b> gombra nyomni), majd a <b>Fizet</b> gombra kattintva, ki is írja a rendszer a visszajáró összegét.
                </li>
                <li>
                    Mindemellett az egyik legfontosabb, hogy mind a bár-, mind a konyhai nyomtatón kinyomtatódtak a fizetéssel egyidőben a címkék, méghozzá “Elvitel” felirattal, ami nagy segítség a konyha és a bár dolgozóinak. Innentől ők már tudni fogják, hogy a betérő vendég nem fogyaszt helyben, illetve az ő ételét/ italát már előre be is tudják csomagolni a szállításra.
                </li>
            </ol>
            <p>
                Fontos itt megjegyezni, hogy elviteles rendelést soha nem tudunk felvenni asztalhoz, hiszen a vendég nem fog helyben fogyasztani, így a két dolog üti egymást.
            </p>
        </div>
    },
    {
        name: "27. Vásárlók kezelése",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverben a Webirodában már előre felvett törzsvásárlókat úgy érhetjük el, hogy a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk. Amint rákattintottunk, meg is jelennek az előzőleg már felvett törzsvásárlók listája a jobb oldalon. A sárgával jelölt vásárlók azok, akik még nem térített a vendéglátóegység felé a rendelésüket, (de ezt már az <b>Asztalok</b> fülnél megszokhattuk).
                <br/>
                Minden vásárló adatlapja megtekinthető a terminálon, méghozzá úgy, hogy hosszan rákattintunk az adott vásárlóra, például: Vásárló1 -re és meg is jelenik az összes adata számunkra. Az adatok között szerepel:
            </p>
            <ul>
                <li>
                    Neve
                </li>
                <li>
                    Email címe
                </li>
                <li>
                    Telefonszáma
                </li>
                <li>
                    Tartozása (Amennyiben van olyan rendelése amit még nem térített, akkor ez itt fog megjelenni)
                </li>
            </ul>
            <img src='/img/27%20video-1.jpg'/>
            <p>
                Új vásárlót felvenni lehet a terminálon keresztül közvetlenül tabletről/telefonról, illetve a Webirodában is megtehető mindez, viszont vásárlót törölni csak a Webirodában lehet biztonsági szempontból.
            </p>
            <p>
                Most vegyük végig lépésről-lépésre, hogy pontosan hogyan tudunk egy adott <b>vásárlóhoz terméket felvenni majd azt fizettetni.</b>
            </p>
            <p>
                A példa kedvéért létrehoztunk előzőleg a Webirodában 3 féle törzsvásárlói kedvezményt, mellyeket az egyes vásárlókhoz rendeltünk:
            </p>
            <ul>
                <li>
                    Arany csomagot a  Vásárló 1-nek   -   20% kedvezmény a teljes fogyasztásból
                </li>
                <li>
                    Ezüst csomagot a  Vásárló 2-nek   -   15% kedvezmény a teljes fogyasztásból
                </li>
                <li>
                    Bronz csomagot a  Vásárló 3-nek   -   10% kedvezmény a teljes fogyasztásból
                </li>
            </ul>
            <p>
                <b>Vegyünk is akkor fel a Vásárló1-hez egy rendelést majd fizettessük is ki:</b>
                <br/>
                (Itt is, ahogy 16. “Rendelésfelvétel asztalhoz” témában 2 lehetőségünk van, vagy felvesszük a kívánt termékeket, majd utána rendeljük hozzá a vásárlót, vagy először kiválasztjuk a vásárlót, majd utána rendelünk hozzá termékeket, végső soron ugyanaz mindkettő, csak más módszerrel van végrehajtva a folyamat. Mi most az első lehetőség megoldását fogjuk bemutatni.)
            </p>
            <ol>
                <li>
                    A fő felületen a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk és meg is jelenik rögtön a vásárlók listája a jobb oldalon.
                </li>
                <li>
                    Rákattintunk a kívánt vásárlóra (például: Vásárló1) és meg is jelenik a neve a bal oldali rendelési lista felett szürke csíkban (jelenleg 0 Ft-os tartozással). Elkezdjük felvenni hozzá a termékeket, amint szépen adjuk, hozzá a termékeket láthatóvá válik a termékek felett piros csíkban a mi esetünkben a Vásárló1 20%-os kedvezménye, mely a teljes kosárösszegre alkalmazódik automatikusan a rendszer által.
                </li>
                <li>
                    Amennyiben elkészült a rendelési listánk, akkor a bal oldalon alul található <b>Felvesz</b> gombra kattintva, hozzá is adtuk a vásárlóhoz a rendelését.
                </li>
                <li>
                    Amennyiben a vásárló elfogyasztotta a rendelést és kéri a számláját, akkor megkezdhetjük a fizettetést. A fő felületen a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk és meg is jelenik rögtön a vásárlók listája a jobb oldalon. Rákattintunk a már közben sárga színnel jelölt vásárlónkra (Vásárló1), majd a bal oldalon, a rendelési lista felett megjelenő csíkban láthatjuk is a vásárlónk nevét, valamint a tartozásának mértékét.
                </li>
                <li>
                    Most pedig a leges-legfontosabb lépés következik a fizettetésnél mégpedig az, hogy a felső sorban található <b>Lista</b> gombra kattintsunk. A <b>Lista</b> gomb segít kilistázni a rendszer számára, illetve számunkra, hogy az adott asztalnál a vendégek mit fogyasztottak. (Ha elfelejtünk a Fizet gomb megnyomása előtt, a Lista gombra nyomni, mely kilistázza a rendeléseket, akkor a rendszer azt fogja kiírni, hogy “A rendelési lista üres”. Ilyenkor sincsen semmi baj, bármikor tudjuk korrigálni a dolgot.)
                </li>
                <img src='/img/27%20video-2.jpg'/>
                <li>
                    Amennyiben szeretnénk ellenőrizni, hogy pontosan milyen kedvezmény adódott a vásárlónk számlájához, akkor azt a rendelési alján lévő szürke <b>Pici felfelé nyílra</b> kattintva tudjuk megtenni. Amint rákattintottunk meg is nyílik a rendelési lista részletezője. A részletezőben alul található <b>Kedvezm.</b> gomb még szürke, de amint belekattintunk meg is jelenik a jól ismert kedvezmények lap. Itt láthatóak a rendelési lista kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé. (Itt ki tudjuk választani a <b>Lehetséges kedvezmények</b> közül például a “Végösszeg 5%” kedvezményt, mely a már meglévő kedvezményünkhez adódik hozzá, így nemcsak 20%, hanem 25% kosárösszeg kedvezmény is adható.)
                </li>
                <img src='/img/27%20video-3.jpg'/>
                <li>
                    Ha végeztünk a kedvezmények adásával, akkor a bal oldalon alul található <b>Fizet</b> gombra nyomjunk.
                </li>
                <li>
                    A felületünk bal oldalt elhalványodott és jobb oldalon megjelentek a <b>Fizetési lehetőségek</b>, valamint <b>módok</b>. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.)
                </li>
                <li>
                    Itt ki kell választanunk a vendég által preferált <b>Fizetési módot</b>, majd fizettetni.
                </li>
                <li>
                    A fizetendő összegnél beírjuk az összeget, mellyel fizettetni fogunk (de ha bankkártyás, esetleges utalványos lesz a térítés akkor elég rögtön a <b>Fizet</b> gombra nyomni), majd a <b>Fizet</b> gombra kattintva, ki is írja a rendszer a visszajáró összegét.
                </li>
                <li>
                    A számlán jól látszik, hogy mely vásárlónak adtuk a kedvezményt, a kedvezménynek mekkora mértéke volt, valamint a termékek eredeti és már a kedvezménnyel csökkentett ára is szerepel.
                </li>
            </ol>
        </div>
    },
    {
        name: "28. Új vásárló létrehozása a terminálon",
        link: "",
        text: <div>
            <p>
                Új vásárlót felvenni lehet a terminálon keresztül közvetlenül tabletről/telefonról, illetve a Webirodában is megtehető mindez, viszont vásárlót törölni csak a Webirodában lehet biztonsági szempontból.
            </p>
            <p>
                Vegyük végig, hogy hogyan tudunk a terminálon, legyen az tablet/ telefon, új vásárlót felvenni:
            </p>
            <ol>
                <li>
                    A bal felső sarokban lévő <b>Vásárlók</b> fülre kattintsunk, meg is jelenik a jobb oldalon a törzsvásárlók listája.
                </li>
                <li>
                    A jobb sarokban található <b>+ gombra</b> kattintsunk ( ami közvetlenül a menü gomb alatt van), kattintás után meg is jelenik egy fehér adatlap, ahol meg kell adjuk az új vásárlónk adatait.
                </li>
                <li>
                    <p>
                        Az adatoknál szükség lesz a vásárló:
                    </p>
                    <ul>
                        <li>
                            <b>Vezetéknevére</b>
                        </li>
                        <li>
                            <b>Keresztnevére</b>
                        </li>
                        <li>
                            <b>Email-jére</b>
                        </li>
                        <li>
                            <b>Telefonszámára</b>
                        </li>
                        <li>
                            Valamint megadhatjuk, hogy ő melyik <b>törzsvásárlói típusba</b> tartozik. A törzsvásárlói típusokat a Webirodában előzőleg kell létrehozni és a terminálon már csak kiválasztani a legördülő menüből és hozzárendelni az adott vásárlóhoz. Ilyen törzsvásárlói típus lehet például: Arany csomag, akinek 20%-os kosárvégösszeg kedvezménye van, vagy a Személyzeti kedvezmény, mely 40%-os fogyasztási kedvezményt jelent a dolgozók számára.
                        </li>
                    </ul>
                    <img src='/img/28%20video-1.jpg'/>
                    <p>
                        A kötelezően kitöltendő mezők:  keresztnév, email és a telefonszám, ameddig ezek nincsenek kitöltve a rendszer nem fogja engedni rögzíteni az új vásárlót.
                    </p>
                </li>
                <li>
                    Ha kitöltöttük a szükséges adatokat és kiválasztottuk a törzsvásárlói típust( persze ha szeretnénk adni az adott vásárlónak) akkor az alul található <b>Rögzít</b> gombra kattintsunk. Amennyiben valamilyen ok folytán mégsem szeretnénk létrehozni az új vásárlónkat, akkor úgy tudunk visszalépni, hogy a fehér vásárlói adatlapon kívülre kattintunk és már vissza is léptünk, illetve a rendszer így nem mentette el a változtatásokat.
                </li>
                <li>
                    Amint elmentettük már láthatjuk is, hogy a vásárlók listájában megjelent az új vásárlónk. Ha meg szeretnénk tekinteni a vásárlónk adatlapját, akkor hosszan rányomva tudjuk ezt megtenni, viszont szerkeszteni itt a terminálon már nem lehetséges, csak a Webirodában.

                </li>
            </ol>
        </div>
    },
    {
        name: "29. Személyzet kezelése terminálon",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverben a Webirodában már előre felvett személyzeti tagokat úgy érhetjük el, hogy a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk. Amint rákattintottunk, meg is jelennek a törzsvásárlók listája a jobb oldalon, viszont ahhoz, hogy a személyzeti tagokhoz jussunk 2 lehetőségünk van:
            </p>
            <ul>
                <li>
                    a jobb oldali felületen, egy jobbról-balra tartó elhúzó(slide) mozdulat
                </li>
                <li>
                    a jobb oldali felületen a <b>Vásárlók</b> fül mellett a <b>Személyzet</b> fülre kattintva
                </li>
            </ul>
            <p>
                Minden személyzeti tag adatlapja megtekinthető a terminálon, méghozzá úgy, hogy hosszan rákattintunk az adott személyzeti tagra, például: Pincér1-re és meg is jelenik az összes adata számunkra. Az adatok között szerepel:
            </p>
            <ul>
                <li>
                    Neve
                </li>
                <li>
                    Email címe
                </li>
                <li>
                    Telefonszáma
                </li>
                <li>
                    Tartozása (Amennyiben van olyan rendelése, amit még nem térített, akkor ez itt fog megjelenni)
                </li>
            </ul>
            <img src='/img/29.video-1.jpg'/>
            <p>
                A sárgával jelölt személyzeti tagok azok, akik még nem térítették a vendéglátóegységünk felé a rendelésüket, (de ezt már az <b>Asztalok</b> fülnél megszokhattuk). Új személyzeti tagot felvenni jelenleg csak a Webirodában lehetséges, de a fejlesztések folyamatban vannak, így nemsokára mindez lehetséges lesz a terminálon is. Törölni személyzeti tagot, viszont csakis kizárólag a Webirodában lehetséges, méghozzá biztonsági szempontból.
            </p>
            <p>
                Most vegyük végig lépésről-lépésre, hogy pontosan hogyan tudunk egy adott <b>személyzeti taghoz terméket felvenni, majd azt fizettetni</b>. A példa kedvéért létrehoztunk előzőleg a Webirodában egy olyan törzsvásárlói csoportot, hogy “Személyzet”, melyet ha egy személyzeti taghoz rendelünk, akkor az rögtön egy 40%-os kosárvégösszeg kedvezményt kap.
            </p>
            <p>
                <b>Az általunk felvett dolgozók közül, mi most a Pincér1 tagunk kontójára fogunk rendelést felvenni, majd fizettetni azt:</b>
                <br/>
                (Itt is, ahogy 16. “Rendelésfelvétel asztalhoz” témában 2 lehetőségünk van, vagy felvesszük a kívánt termékeket, majd utána rendeljük hozzá a személyzeti tagot, vagy először kiválasztjuk a személyzeti tagot, majd utána rendelünk hozzá termékeket, végső soron ugyanaz mindkettő, csak más módszerrel van végrehajtva a folyamat. Mi most az első lehetőség megoldását fogjuk bemutatni.)
            </p>
            <ol>
                <li>
                    A fő felületen a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk és meg is jelenik a vásárlók listája a jobb oldalon, itt a felső sorban található <b>Személyzet</b> fülre kattintsunk.
                </li>
                <li>
                    Rákattintunk a megjelenő személyzeti tagok listájában az Pincér1-re és meg is jelenik a neve a bal oldali rendelési lista felett szürke csíkban (jelenleg 0 Ft-os tartozással). Elkezdjük felvenni hozzá a termékeket, amint szépen adjuk, hozzá a termékeket láthatóvá válik a termékek felett piros csíkban a mi esetünkben a Személyzet 40%-os kedvezménye, mely a teljes kosárösszegre alkalmazódik automatikusan a rendszer által.
                </li>
                <li>
                    Amennyiben elkészült a rendelési listánk, akkor a bal oldalon alul található <b>Felvesz</b> gombra kattintva, hozzá is adtuk a Pincér1 tagunk rendelését.
                </li>
                <li>
                    Amennyiben a Pincér1 tagunk jelez, akkor meg is kezdjük a fizettetést. A fő felületen a bal felső sarokban található <b>Vásárlók</b> fülre kattintunk, majd ott a jobb oldali felületen a <b>Személyzet</b> fülre és meg is jelenik rögtön a személyzeti tagok listája. Rákattintunk a már közben sárga színnel jelölt személyzeti tagunkra (Pincér1), majd a bal oldalon, a rendelési lista felett megjelenő csíkban láthatjuk is a személyzeti tagunk nevét, valamint a tartozásának mértékét.
                </li>
                <li>
                    Most pedig a leges-legfontosabb lépés következik a fizettetésnél mégpedig az, hogy a felső sorban található <b>Lista</b> gombra kattintsunk. A <b>Lista</b> gomb segít kilistázni a rendszer számára, illetve számunkra, hogy az adott asztalnál a vendégek mit fogyasztottak. (Ha elfelejtünk a Fizet gomb megnyomása előtt, a Lista gombra nyomni, mely kilistázza a rendeléseket, akkor a rendszer azt fogja kiírni, hogy “A rendelési lista üres”. Ilyenkor sincsen semmi baj, bármikor tudjuk korrigálni a dolgot.)
                </li>
                <img src='/img/29.video-2.jpg'/>
                <li>
                    Amennyiben szeretnénk ellenőrizni, hogy pontosan milyen kedvezmény adódott a személyzeti tagunk számlájához, akkor azt a rendelési alján lévő szürke <b>Pici felfelé nyílra</b> kattintva tudjuk megtenni. Amint rákattintottunk meg is nyílik a rendelési lista részletezője. A részletezőben alul található <b>Kedvezm.</b> gomb még szürke, de amint belekattintunk meg is jelenik a jól ismert kedvezmények lap. Itt láthatóak a rendelési lista kedvezményei, bal oldalon a már alkalmazott kedvezmények jobb oldalon pedig a további adható kedvezmények. Minden <b>Alkalmazott kedvezmény</b> jobb sarkában látható egy <b>Kuka</b> ikon, erre kattintva el tudjuk távolítani az adott kedvezményt és az automatikusan átkerül a <b>Lehetséges kedvezmények</b> listájába. Minden lehetséges kedvezmény oszlopában található kedvezmény jobb sarkában pedig egy <b>Kattints</b> ikon, mely segít abban, hogy a kiválasztott kedvezmény applikálva legyen, így át is kerül az <b>Alkalmazott kedvezmények</b> közé. (Itt ki tudjuk választani a <b>Lehetséges kedvezmények</b> közül például a “Végösszeg 5%” kedvezményt, mely a már meglévő kedvezményünkhez adódik hozzá, így nemcsak 40%, hanem 45% kosárösszeg kedvezmény is adható.)
                </li>
                <img src='/img/29.video-3.jpg'/>
                <li>
                    Ha végeztünk a kedvezmények adásával, akkor a bal oldalon alul található <b>Fizet</b> gombra nyomjunk.
                </li>
                <li>
                    A felületünk bal oldalt elhalványodott és jobb oldalon megjelentek a <b>Fizetési lehetőségek</b>, valamint <b>módok</b>. (Jól látszik, hogy a bal oldalon a rendelési lista passzívvá vált, tehát nem szerkesztő, ha szeretnénk mégis változtatni még rajta, akkor a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudjuk megtenni mindezt.)
                </li>
                <li>
                    Itt ki kell választanunk a személyzeti tag által preferált <b>Fizetési módot</b>, majd fizettetni.
                </li>
                <li>
                    A fizetendő összegnél beírjuk az összeget, mellyel fizettetni fogunk (de ha bankkártyás, esetleges utalványos lesz a térítés akkor elég rögtön a <b>Fizet</b> gombra nyomni), majd a <b>Fizet</b> gombra kattintva, ki is írja a rendszer a visszajáró összegét.
                </li>
                <li>
                    A számlán jól látszik, hogy mely személyzeti tagnak adtuk a kedvezményt, a kedvezménynek mekkora mértéke volt, valamint a termékek eredeti és már a kedvezménnyel csökkentett ára is szerepel.
                </li>
            </ol>
        </div>
    },
    {
        name: "30. Vonalkód és törzsvásárlói kártya kódjának olvasása",
        link: "",
        text: <div>
            <p>
                A ByThePay szoftverben lehetőség van arra, hogy vonalkód alapján azonosítsuk be a termékeket, méghozzá egy vonalkód olvasó vagy a saját készülékünk (tablet/telefon) kamerájának segítségével, ha mégis manuálisan írnánk be a kódot arra is van lehetőség.
                <br/>
                Mindez igaz a törzsvásárlókra is, tehát ott ugyanígy leolvasható a törzsvásárlói kártya vonalkód olvasóval vagy a saját készülékünkkel (tablet/telefon), de persze manuálisan is beírhatjuk, ha szeretnénk.
            </p>
            <p>
                Törzsvásárlói kódot, valamint a termékeknek vonalkódot csak a Webirodában tudunk létrehozni. A példa kedvéért kreáltunk a Webirodában a “Tropicana ananász” nevű terméknek egy vonalkódot manuálisan, valamint a Vásárló1-hez, mivel Arany csomag (20 %-os kosár végösszeg kedvezmény) van rendelve, így neki automatikusan generálódik saját kód.
            </p>
            <p>
                Minden olyan vásárlónak és személyzeti tagnak generálódik automatikusan vonalkód, akihez hozzá van rendelve valamilyen törzsvásárlói típus. Ilyen törzsvásárlói típus lehet például: Arany csomag, akinek 20%-os kosárvégösszeg kedvezménye van, vagy a Személyzeti kedvezmény, mely 40%-os fogyasztási kedvezményt jelent a dolgozók számára.
            </p>
            <p>
                <b>Nézzük át, hogy hogyan is működik a vonalkódolvasó felület valamint, hogyan tudunk beazonosítani vásárlókat, valamint termékeket: </b>
                <br/>
                Először is a fő felületen a jobb oldalon felül található <b>Vonalkódolvasó</b> ikonra kattintunk (közvetlenül a 3 fekete csíkkal jelzett <b>Menü</b> gomba alatt helyezkedik el). Meg is jelenik a vonalkódolvasó felület a jobb oldalon, itt látható egy csík <b>Vonalkód</b> felirattal, ide ha belekattintunk, meg is nyílilik a billetyűzet, így  manuálisan tudunk bevinni vonalkódokat (beleírva a Webirodában generált számsort).
            </p>
            <ul>
                <li>
                    Alatta látható 2 fő gomb: az első a <b>Termék kód</b> gomb, melyre kattintva rendszer számára specifikáljuk, hogy termék kódját szeretnénk manuálisan vagy szkennelve bevinni.
                </li>
                <li>
                    A másik fontos gomb a <b>Vásárló kód</b> gomb, melyre kattintva rendszer számára specifikáljuk, hogy egy törzsvásárló kódját szeretnénk manuálisan vagy szkennelve bevinni.
                </li>
                <li>
                    A jobb alsó sarokban látható egy <b>Szkennelő</b> gomb, melyre kattintva, majd ha már kiválasztottuk, hogy terméket, vagy vásárlói kódot fogunk szkennelni akkor itt el is végezhetjük azt.
                </li>
                <li>
                    Jobb oldalon legalul pedig a <b>Vissza</b> gomb szerepel, ha vissza szeretnénk térni a fő felületre akkor ide kattintva meg is tudjuk tenni mindezt.
                </li>
            </ul>
            <p>
                <b><u>A következő példában be fogunk szkennelni egy terméket:</u></b>
            </p>
            <ol>
                <li>
                    Belépünk a fent leírtak szerint a <b>Vonalkódolvasó</b> felületre.
                </li>
                <li>
                    Rákattintunk a <b>Termék kód</b> gombra (onnan tudjuk hogy aktív a gomb, hogy világosabb kék színű lesz).
                </li>
                <li>
                    Amint ezekkel megvagyunk 2 lehetőségünk van a “Tropicana ananász” termék vonalkód alapján történő felismertetésére:
                    <ul>
                        <li>
                            manuálisan a <b>Vonalkód</b> csíkba kattintva beírjuk a termék számsorát
                        </li>
                        <li>
                            csatlakoztatott vonalkódolvasó eszköz vagy a saját készülékünk (tablet telefon) kameráját használva a jobb alsó sarokban található <b>Szkennelő</b> gombra nyomunk és már be is húzta a rendszer a kívánt terméket.
                        </li>
                    </ul>
                </li>
                <li>
                    Amint felismerte a rendszer a terméket a bal oldalon található rendelési listára teszi és így majd vásárlóhoz/személyzethez/asztalhoz tudjuk rendelni a termékeket vagy rögtön kifizettetni, amit szeretnénk.
                </li>
            </ol>
            <img src='/img/30.%20VIDEO-1.jpg'/>
            <p>
                <b><u>A következő példában be fogunk szkennelni törzsvásárlói kártya alapján egy vásárlót:</u></b>
            </p>
            <ol>
                <li>
                    Belépünk a fent leírtak szerint a <b>Vonalkódolvasó</b> felületre.
                </li>
                <li>
                    Rákattintunk a <b>Vásárló kód</b> gombra (onnan tudjuk hogy aktív a gomb, hogy világosabb kék színű lesz).
                </li>
                <li>
                    Amint ezekkel megvagyunk 2 lehetőségünk van a “Vásárló1” nevű Törzsvásárló vonalkód alapján történő felismertetésére:
                    <ul>
                        <li>
                            manuálisan a <b>Vonalkód</b> csíkba kattintva beírjuk a törzsvásárló számsorát
                        </li>
                        <li>
                            csatlakoztatott vonalkódolvasó eszköz vagy a saját készülékünk (tablet telefon) kameráját használva a jobb alsó sarokban található <b>Szkennelő</b> gombra nyomunk és már fel is ismerte a rendszer a “Vásárló1” törzsvásárlót.
                        </li>
                    </ul>
                </li>
                <li>
                    Amint felismerte a rendszer a törzsvásárlót a bal oldalon található rendelési lista tetéjén meg is jeleníti egy szürke csíkban névvel, illetve tartozzással ha van neki.
                </li>
            </ol>
            <img src='/img/30.%20VIDEO-2.jpg'/>
            <p>
                Természetesen nem csak törzsvásárlókat tudunk vonalkód alapján felismertetni a rendszerrel, hanem személyzeti tagokat is, a lényeg hogy mindig legyen törzsvásárlói típus rendelve az adott személyhez, hiszen így tud automatikusan gereálódni az adott személynek vonalkód.
            </p>
        </div>
    },
    {
        name: "31. Napi zárás",
        link: "",
        text: <div>
            <p>
                A terminálokon, lehet az tablet/telefon, a nap bármely pillanatában ellenőrizni tudjuk, hogy a vendéglátó egységünk pontosan mennyit termelt, valamint mindezt készpénzre és bankkártyára lebontva. Továbbá látható az is, hogy a termelt bevételből a dolgozók pontosan milyen mértékben vették ki a részüket összegszerűen, illetve mindez kettébontva készpénzre és bankkártyára. A napi zárás teljes listája nyomtatható, méghozzá egy számla formátumban és itt nagyon fontos megjegyezni, hogy a napi zárás listája mindig a <b>Számla nyomtatón</b> fog kinyomtatódni, ezért elengedhetetlen, hogy nyomtatás előtt erre legyen csatlakoztatva a nyomtató készülékünk. (lásd: 6. téma: Nyomtatóbeállítások 1. -  Alapbeállítások 1. és 2. Rész)
            </p>
            <img src='/img/31.%20video-%201.jpg'/>
            <p>
                <b>A terminálon a napi zárást elérni, valamint kinyomtatni egy számlán a következőképpen tudjuk: </b>
            </p>
            <ol>
                <li>
                    A fő felületen a jobb felső sarokban lévő Menü gombra kattintunk (3 fekete csík), majd meg is nyílik a menüsor ahol a <b>Zárás</b> gombra kattintunk.
                </li>
                <li>
                    Láthatóvá is válik az aznapi termelt bevétele a vendéglátó egységünknek. A napi zárás teljes előnézete látható a jobb oldali fehér felületen:
                    <ul>
                        <li>
                            Üzletünk megnevezve
                        </li>
                        <li>
                            Másodpercre pontos dátum
                        </li>
                        <li>
                            Összesen bevétel és ez lebontva készpénz és kártyás forgalomra is
                        </li>
                        <li>
                            Minden egyes személyzeti tagunk neve, alatta pedig a hozzáadott munkája az aznapi bevételhez összesítve, valamint készpénz és bankkártyás forgalomra lebontva
                        </li>
                    </ul>
                </li>
                <li>
                    Amennyiben ki szeretnénk nyomtatni a napi zárást, akkor a jobb felső sarokban található <b>Nyomtatás</b> ikonra kattintva tudjuk megtenni mindezt. (Amint feljebb említettük, a napi zárás blokkja mindig a Számla nyomtatón fog kijönni.)
                </li>
                <li>
                    Visszalépni a fő felületre a bal oldalon alul található <b>Bezár</b> gombra kattintva tudunk.
                </li>
            </ol>
        </div>
    },
    {
        name: "32. Leltározás a terminálon",
        link: "",
        text: <div>
            <p>
                A ByThePay rendszerben nem csak a Webirodában van lehetőség a leltározásra, hanem mindez megtehető a terminálokon is, legyen az tablet/telefon. Minden egyes leltározás, melyet a terminálon végzünk azonnal felküldődik a felhőbe, így a Webirodában azonnal látható is válik, hogy milyen az üzlet aktuális készlete.
            </p>
            <p>
                <b>Leltározni a terminálon a következő lépéseket követve tudunk: </b>
            </p>
            <ol>
                <li>
                    A fő felületen a jobb felső sarokban lévő Menü gombra kattintunk (3 fekete csík), majd meg is nyílik a menüsor, ahol egy picit feljebb kell görgetni és láthatóvá válik a <b>Leltár</b> gomb. (<b>Zárás</b> gomb alatt helyezkedik el)
                </li>
                <li>
                    Kattintsunk a <b>Leltár</b> gombra, írja is rögtön a rendszer a jobb oldali felületen, hogy itt tudjuk elkészíteni az aktuális leltárunkat a termékeinkről. A jobb felső sarokban található <b>Új leltár</b> gombra kattintsunk.
                </li>
                <img src='/img/32.video-1.jpg'/>
                <li>
                    A fő felülethez hasonló kép nyílik meg előttünk, csak itt nem rendelést fogunk felvenni a bal oldali sávban hanem a leltárunkat. Kezdjünk is akkor bele, ellenőrizzük, hogy a polcon lévő termék mennyiségek megegyeznek-e a rendszer szerinti mennyiségekkel. Először is a felsorolt kategóriákból válasszuk ki a leltározni  kívánt termékeket. Amint kiválasztottunk egy terméket és rákattintunk, fel is ugrik a termék adatlapja (lásd: 12. videó: Terméktulajdonságok 2. - Készlet/ Leírás/ Mennyiség megadás). Az adatlap 3 részből áll: <b>Darab, Leírás, Mennyiség</b>.
                </li>
                <li>
                    A <b>Darab</b> fülnél meg tudjuk adni a polcokon számolt termékmennyiséget, azonban ha szeretnénk ellenőrizni, hogy a rendszer szerint mennyinek kellene lennie, akkor a <b>Készlet</b> fülre kattintva, az <b>Üzletünk</b> neve mellett mennyiségi jelzéssel láthatjuk.
                </li>
                <li>
                    Ha beírtuk a termék darabszámát, akkor meg a <b>Beállít</b> gombra kattintva fel is vettük a leltár listánkba bal oldalra. A bal oldali leltár listán megjelent terméknél látható néhány gyorsgomb, méghozzá a <b>+, -,</b> valamint a <b>Töröl</b> gomb. Az említett gombokat azért hoztuk létre, hogyha esetleg rosszul írtuk volna be a mennyiséget a terméknél stb.. akkor még lehessen rajta változtatni a leltározási listán is. A kategóriákból visszalépni a jobb oldalon alul található <b>Vissza</b> gombra kattintva tudunk.
                </li>
                <img src='/img/32.video-2.jpg'/>
                <li>
                    Amint elkészültünk a leltározási listánkkal akkor a bal oldalon alul található zöld színű <b>Leltárba vesz</b> gombra kattintsunk. Ha valamilyen ok folytán mégsem szeretnénk megcsinálni ezt a leltározást, akkor a piros <b>Mégse</b> gombra kattintva vissza tudunk lépni, de előtte természetesen megkérdezi a rendszer, hogy biztosan ki akarunk-e lépni, itt <b>Igen</b>-t nyomjunk.
                </li>
                <li>
                    Viszont amennyiben folytatjuk a leltározást, akkor a <b>Leltárba vesz</b> gomb megnyomása után, adhatunk leírást is a leltárunknak pl: Hónap végi nagyleltár stb.., (természetesen nem muszáj csak ha szeretnénk).
                </li>
                <li>
                    A most megadott leírás a Webirodában fog látszódni a leltározott tételsor leírásánál majd.  Amint készen vagyunk a <b>Rendben</b> gombra kattintsunk.(Ha itt még szerkeszteni szeretnénk, mert valami esetleg lemaradt, akkor a <b>Mégse</b> gombra kattintva vissza tudunk lépni a leltározási felületre.)
                </li>
                <img src='/img/32.video-3.jpg'/>
                <li>
                    A leltárunk el is készült és a rendszer most megkérdezi, hogy szeretnénk-e új leltárt készíteni, itt mindenki el tudja majd dönteni, hogy folytatná-e egy új leltárral vagy sem.
                </li>
                <li>
                    Visszalépni a fő felületre a menüsor felületéről a bal oldalon alul található <b>Bezár</b> gombra kattintva tudunk.
                </li>
            </ol>
        </div>
    }
];

export default TerminalDescription

