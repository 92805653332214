import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import {axiosCommon} from "../../../util/axiosSetup";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Permissions from "../../../util/Permissions";

export default class CustomerRoleListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            roles: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        }
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        axiosCommon(`${BASE_URL}/api/jobtitles`)
            .then(response => {
                this.setState({
                    roles: response.data.items
                })
            })
            .catch(error => {
                this.setState({error})
            })
    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/jobtitles/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/people/editroles",
            roleId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/people/editroles",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a szerepkört?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderIcon = (bool) => {
        if(bool === true)
            return <CheckCircleOutline style={{color: "#4caf50"}} />;

        return <RemoveCircle style={{color: "#f44336"}}/>;
    }

    renderList = () => {
        return <>
            <Paper>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Személyzeti szerepkörök
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Név</TableCell>
                                {Object.values(Permissions).map((perm, i) =>
                                    <TableCell align="center" key={i}>{perm.label}</TableCell>
                                )}
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.roles
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((r, i) => {
                                        return <TableRow key={r.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(r.id)}>
                                            <TableCell>{r.name}</TableCell>
                                            {Object.values(Permissions).map((perm, i) =>
                                                <TableCell align="center" key={i}>{this.renderIcon(r.permissions.includes(perm.value))}</TableCell>
                                            )}
                                            <TableCell padding="none" align="right">
                                                <IconButton aria-label="delete" onClick={() => this.initiateDeletion(r)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    }
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.roles.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>

            {this.renderDeleteDialog()}

            {this.state.error ?
                <section className="minor-error">{getGeneralError(this.state.error)}</section>
                : ""}
        </>;
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Szerepkör"
                onClick={this.initiateCreate}/>
        </>;
    }
};
