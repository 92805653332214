import React from 'react';
import "../css/ErrorBoundary.css";
import {withRouter} from "react-router";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
        const data = {
            error,
            info
        };
        console.log(data);
    }

    back = () => {
        if (sessionStorage.getItem("role") === "ROLE_CUSTOMER")
            this.props.history.push("/customer");
        else if (sessionStorage.getItem("role") === "ROLE_ADMIN")
            this.props.history.push("/admin");
    };

    render() {
        if (this.state.hasError) {
            return <section className="error-boundary">
                <section>Váratlan hiba történt. Kérjük próbálja újra, vagy ha a hiba fennáll,
                    lépjen kapcsolatba az ügyfélszolgálattal.
                </section>
                <button onClick={this.back}>Vissza</button>
            </section>
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
