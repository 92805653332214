import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import "../../../css/ProductLabel.css";
import Footer from "../../../components/Footer";
import Widget from "../../../components/Widget";
import { Button, Chip, Paper, TextField, Toolbar, Typography } from "@material-ui/core";
import NavHeader from "../../../components/NavHeader";

export default class ProductNotesListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            note: "",
            productNotes: [],
        };
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        axiosCommon.get(`${BASE_URL}/api/notes`)
        .then(response => {
            this.setState({ productNotes: response.data,})
        })
        .catch(error => this.setState({error, productNotes: []}))
    };

    createNote = () => {
        axiosCommon.post(`${BASE_URL}/api/notes`, this.state.note)
        .then(response => {
            this.refreshData()
            this.setState({note: ""})
        })
    }

    deleteNote = (note) => {
        axiosCommon.delete(`${BASE_URL}/api/notes/${note.id}`)
        .then(response => {
            this.refreshData();
        })
        .catch(error => {
            this.setState({
                error
            })
        });
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Megjegyzések felvétele
                    </Typography>
                </Toolbar>
                <Paper style={{padding: 15, paddingTop: 10}}>
                    <Widget>
                        <div className="flex-1">
                            <TextField
                                label="Megjegyzés"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => this.setState({ note:  event.target.value})}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter') {
                                        this.createNote()
                                    }
                                }}
                                name="notes"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.note}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Button
                                variant="contained"
                                color="primary"
                                style={{color: 'white', marginTop: 10,}}
                                onClick={this.createNote}
                            >
                                Felvesz
                            </Button>
                        </div>
                    </Widget>
                    <div className="flex-1" style={{color: "white"}}>
                        {this.state.productNotes.map((data, i) =>
                            <Chip
                                key={i}
                                label={data.note}
                                style={{margin: 2, maxWidth: '100%', overflow: 'hidden'}}
                                onDelete={() => this.deleteNote(data)}
                            />
                        )}
                    </div>
                </Paper>
            </section>
            <Footer/>
        </section>;
    }
}
