import React from "react";
import Footer from "../../components/Footer";
import NavHeader from "../../components/NavHeader";

export default class AdminOverviewPage extends React.Component {

    render() {
        return <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                    <p>Üdvözöljük!</p>
                </section>

                <Footer/>
        </section>;
    }
};
