import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    widget: {
        marginBottom: 16,
        marginTop: 8,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
    },
}));

function Widget(props){
    return(
        <div className={useStyles().widget}>
            {props.children}
        </div>
    );
}

export default Widget;
