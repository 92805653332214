import React from 'react';
import {BrowserRouter} from "react-router-dom"

import ScrollToTop from "./util/ScrollToTop";
import ErrorBoundary from "./util/ErrorBoundary";
import Layout from "./components/Layout";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <ErrorBoundary>
                    <Layout/>
                </ErrorBoundary>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
