import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    TableHead,
    Toolbar,
    IconButton,
    Button, TextField, CircularProgress, Collapse, Menu, MenuItem, Tooltip,
} from "@material-ui/core";
import TablePagination from '@material-ui/core/TablePagination';
import DeleteIcon from '@material-ui/icons/Delete';
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import truncateString from "../../../util/truncateString";
import SequenceIcon from "@material-ui/icons/FormatListBulleted";
import {Alert} from "@material-ui/lab";
import {ImSortAlphaAsc, ImSortAlphaDesc} from "react-icons/im";
import SaveIcon from "@material-ui/icons/Save";
import {DraggableComponent, DroppableComponent} from "../../../components/DnDTable";
import OptionsIcon from "@material-ui/icons/MoreVert";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ListAlt from '@material-ui/icons/ListAlt';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import {
    getKeyword,
    getPageNumber,
    getRowsPerPage,
    getListItems,
    removeSessionKeys, saveSessionKey
} from "../../../util/SessionStorageHandler";
import formatNumber from "../../../util/formatNumber";
import LinkIcon from '@material-ui/icons/Link';

export default class ProductListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            products: [],
            availableCategories: [],
            selectedCategories: [],
            selectedCategoryForSequence: null,
            count: 0,
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            selectedProducts: [],
            showDelteDialog: false,
            isLoading: false,
            isSavingSequence: false,
            isEditingSequence: false,
            anchorEl: null,
            notSupportedSearch: false,
            notSupportedSelection: false,
        };
        this.selectedCategoriesKey = 'productListPageSelectedCategories'
        this.pageKey = 'productListPagePageKey'
        this.rowsPerPageKey = 'productListPageRowsPerPageKey'
        this.keywordKey = 'productListPageKeyword'
        this.scrollToTheTop = React.createRef()
    }

    componentDidMount() {
        this.handleNavigation()

        axiosCommon(`${BASE_URL}/api/categories`)
        .then(response => {
            const availableCategories = response.data.items;
            const selectedCategoryForSequence = availableCategories.length > 0 ? availableCategories[0] : null;
            this.setState({
                availableCategories,
                selectedCategoryForSequence
            })
        })
        .catch(error => {
            this.setState({
                error
            })
        });
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedCategoriesKey, JSON.stringify(this.state.selectedCategories));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const selectedCategories = getListItems(this.selectedCategoriesKey)
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            this.setState({ selectedCategories, page, rowsPerPage, keyword }, () => this.refreshData())
        }else{
            removeSessionKeys([this.selectedCategoriesKey, this.pageKey, this.rowsPerPageKey, this.keywordKey])
            this.refreshData()
        }
    }

    refreshData = () => {
        this.setLoading(true);
        let categoryIds;

        if(this.state.isEditingSequence){
            categoryIds = this.state.selectedCategoryForSequence.id;
        }else{
            categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        }

        const queryParams = {deleted: false}
        if(!this.state.isEditingSequence){
            queryParams.pageSize = this.state.rowsPerPage
            queryParams.pageNumber = this.state.page
        }
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        if(categoryIds !== ""){
            queryParams.categoryIds = categoryIds
        }

        axiosCommon(`${BASE_URL}/api/products?`, { params: queryParams })
            .then(response => {
                this.setState({
                    products: response.data.items,
                    count: response.data.count,
                }, () => this.setLoading(false))
            })
            .catch(error => {
                this.setLoading(false);
                this.setState({
                    error
                })
            });
    };

    setLoading = (flag) => {
        this.setState({
            isLoading: flag,
        });
    };

    setKeyword = (event) => {
        if(this.state.isEditingSequence == false){
            this.setState({ keyword: event.target.value, page: 0, }, () => this.refreshData())
        }else{
            this.setState({ notSupportedSearch: true })
        }
    };

    initiateDeletion = (product) => {
        axiosCommon.get(`${BASE_URL}/api/products/${product.id}`)
            .then(response => {
                this.setState({
                    deleteInProgress: response.data
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/products/${this.state.deleteInProgress.id}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/products/editproduct",
            productId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/products/editproduct",
        });
    };

    onChangeCategoryFiler = (event, value) => {
        if(this.state.isEditingSequence == false){
            this.setState({ selectedCategories: value, page: 0, }, () => this.refreshData())
        }else{
            this.setState({ notSupportedSelection: true })
        }
    };

    onChangeSequenceCategoryFiler = (event, value) => {
        this.setState({
            selectedCategoryForSequence: value,
        }, () => {
            this.refreshData()
        })
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a termékeket?"
            open={this.state.deleteInProgress !== null}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
            onConfirm={this.confirmDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
            {this.state?.deleteInProgress?.dependentProducts?.length > 0 &&
            <>
                <p>Törlés esetén ez a termék kikerül az alábbi recept típusú termékek összetevői közül:</p>

                <ul style={{textAlign: "left"}}>
                    {this.state?.deleteInProgress?.dependentProducts.map((p, i) =>
                        <li key={i}>{p}</li>)}
                </ul>
            </>
            }
        </DeleteDialog>;
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage}, () => this.refreshData())
        this.scrollToTheTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0}, () => this.refreshData());
    };

    onChangeCheckbox = (i) => {
        const {selectedProducts} = this.state;
        const selectedIndex = selectedProducts.indexOf(i);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedProducts, i);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedProducts.slice(1));
        } else if (selectedIndex === selectedProducts.length - 1) {
            newSelected = newSelected.concat(selectedProducts.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedProducts.slice(0, selectedIndex),
                selectedProducts.slice(selectedIndex + 1),
            );
        }

        console.log('selected', newSelected);
        this.setState({
            selectedProducts: newSelected
        })
    };

    isRowSelected = (i) => this.state.selectedProducts.indexOf(i) !== -1;

    toggleEditingSequence = () => {
        let {isEditingSequence} = this.state;
        isEditingSequence = !isEditingSequence;
        this.setState({
            isLoading: true,
            isEditingSequence,
            keyword: '',
            selectedCategories: [],
        }, () => this.refreshData());
    }

    closeNotSupportedSearch = () =>  {
        this.setState({
            notSupportedSearch: false
        })
    }

    closeNotSupportedSelection = () =>  {
        this.setState({
            notSupportedSelection: false
        })
    }

    sortAsc = () =>{
        const {products} = this.state;

        products.sort((a,b) => a.name.localeCompare(b.name));
        this.setState({products});
    }

    sortDesc = () =>{
        const {products} = this.state;

        products.sort((a,b) => a.name.localeCompare(b.name)).reverse();
        this.setState({products});
    }

    setIsSavingSequence = (value) => {
        this.setState({isSavingSequence: value});
    }

    saveSequence = () => {
        this.setIsSavingSequence(true);

        const {products} = this.state;
        let sequence = 0;
        const data = {
            items: []
        };

        products.forEach(item => {
            data.items.push({
                first: item.id,
                second: sequence
            })
            sequence++;
        })

        axiosCommon({
            method: "put",
            url: `${BASE_URL}/api/products/sequence`,
            data,
        })
            .then(response => {
                this.toggleEditingSequence();
                this.setIsSavingSequence(false);

            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
                this.refreshData();
            })
    }

    setAnchorForMenu = (event, index) => {
        this.setState({
            anchorEl:{
                top: event.clientY,
                left: event.clientX,
                index,
            }
        })
    };

    clearAnchorForMenu = () => {
        this.setState({anchorEl: null});
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    moveToTheTop = () => {
        const products = this.reorder(
            this.state.products,
            this.state.anchorEl.index,
            0
        )

        this.setState({
            products
        })
        this.clearAnchorForMenu();

    }

    moveToTheBottom = () => {
        const products = this.reorder(
            this.state.products,
            this.state.anchorEl.index,
            this.state.products.length
        )

        this.setState({
            products
        })
        this.clearAnchorForMenu();
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const products = this.reorder(
            this.state.products,
            result.source.index,
            result.destination.index
        )

        this.setState({
            products
        })
    }

    renderToolbar = () => {
        return <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Typography variant="h5" id="tableTitle" component="div">
                    Termékek
                </Typography>
                {this.state.isLoading == true
                    ? <CircularProgress style={{marginLeft: 10, color: 'white', height: 30, width: 30}}/>
                    : ''
                }
            </div>

            <Button
                variant="contained"
                style={{backgroundColor: 'white'}}
                onClick={this.toggleEditingSequence}
                startIcon={<SequenceIcon />}>
                Sorrend
            </Button>
        </Toolbar>
    }

    renderEditSequenceBar = () => {
        return <Collapse in={this.state.isEditingSequence} >
            <div className='table-edit-bar pr-24 pl-24 pt-24'>
                <Typography variant="h6">
                    Sorrend szerkesztése
                </Typography>
                <Typography variant="body2">
                    Az elemek sorrendjének szerkesztéséhez válaszd ki a kívánt kategóriát, amelyen belül szeretnéd a
                    termékek sorrendjét megváltoztatni. Ezután mozgazsd az elemet a kívánt helyre, vagy használd a
                    beépített rendezéseinket a lenti gombokkal.
                </Typography>
                <Typography variant="body2" style={{marginTop: 8}}>
                    <strong>FONTOS: Új kategória kiválasztása előtt ne felejts el menteni!</strong>
                </Typography>

                <Autocomplete
                    onChange={this.onChangeSequenceCategoryFiler}
                    name="categoryFilterForSequence"
                    noOptionsText="Nincs ilyen tétel"
                    options={this.state.availableCategories}
                    disableClearable
                    style={{marginTop: 24}}
                    getOptionLabel={(option) => option === "" ? "" : option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={this.state.selectedCategoryForSequence || ""}
                    renderInput={(params) =>
                        <TextField {...params} label="Kategória" variant="outlined" />
                    }
                />

                <div className='flex pt-24 pb-24'>
                    <Button
                        variant="contained"
                        style={{backgroundColor: 'white'}}
                        onClick={() => this.sortAsc()}
                        startIcon={<ImSortAlphaAsc />}>
                        ABC
                    </Button>
                    <div className='m-8'/>
                    <Button
                        variant="contained"
                        style={{backgroundColor: 'white'}}
                        onClick={() => this.sortDesc()}
                        startIcon={<ImSortAlphaDesc />}>
                        CBA
                    </Button>
                </div>

                <div className='flex justify-flex-end pb-24'>
                    <Button
                        variant="outlined"
                        style={{color: 'gray'}}
                        onClick={this.toggleEditingSequence}>
                        Mégse
                    </Button>
                    <div className='m-8'/>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{backgroundColor: 'green', color: 'white'}}
                        onClick={() => this.saveSequence()}
                        startIcon={
                            this.state.isSavingSequence
                                ?<CircularProgress color="primary" style={{color: 'white', width: 16, height: 16}}/>
                                :<SaveIcon />
                        }>
                        Mentés
                    </Button>
                </div>
            </div>
        </Collapse>
    }

    renderCategoryListItems = () => {
        return <TableBody>
            {this.state.products.map((p, i) => {
                    const isItemSelected = this.isRowSelected(p.id);

                    return <TableRow key={p.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(p.id)} selected={isItemSelected}>
                        {this.renderListItems(p)}
                        <TableCell padding="none" align="right">
                            <IconButton onClick={() => this.initiateDeletion(p)}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>;
                }
            )}
        </TableBody>
    }

    renderEditingSequenceItems = () => {
        return <TableBody component={DroppableComponent(this.onDragEnd)}>
            {this.state.products
                .map((p, i) => {
                        return <TableRow key={p.id} hover component={DraggableComponent(p.id.toString(), i)}>
                            {this.renderListItems(p)}
                            <TableCell padding="none" align="right">
                                <IconButton aria-label="grab" onClick={(event) => {this.setAnchorForMenu(event, i)}}>
                                    <OptionsIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>;
                    }
                )}
            {this.renderEditingSequenceMenu()}
        </TableBody>
    }

    renderColorBox = (color) => {
        return <div style={{width: 52.5, height: 52.5, background: color}}/>
    }

    hasNtakConfig = (product) => {
        if( product.ntakFokategoria == null ||
            product.ntakAlkategoria == null ||
            product.ntakMennyisegiEgyseg == null ||
            product.ntakMennyiseg == null)
            return false
        return true
    }

    renderListItems = (p) => {
        return <>
            <TableCell padding="checkbox">
                {p.imageId
                  ? <img src={`${BASE_URL}/api/images/${p.imageId}`} alt="Fotó"/>
                  : p.colorCode
                    ? this.renderColorBox(p.colorCode)
                    : <img src="/img/icons/no_image.svg" alt="Nincs fotó"/>
                }
            </TableCell>
            <TableCell>{p.name}</TableCell>
            <TableCell className="break-line">{truncateString(p.description, 127)}</TableCell>
            <TableCell align='center'>
                {p.disabledInShops.length > 0
                    ?<Tooltip title="A termék nem minden üzletben látható">
                        <VisibilityOff style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    : ""
                }
                {p.isRecipeProduct
                    ?<Tooltip title="Receptúra termék">
                        <ListAlt style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
                {!p.hasInventory
                    ?<Tooltip title="A termékhez nem lett raktárkészlet rendelve">
                        <DomainDisabledIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
                {this.hasNtakConfig(p)
                    ?<Tooltip title="A termék konfigurálva van NTAK adatküldésre">
                        <LinkIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
                {p.productToppings.length > 0
                    ?<Tooltip title="A termékhez feltétek vannak kapcsolva">
                        <LibraryAddOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
                {p.hasBottleDeposit
                    ?<Tooltip title="A termékhez tartozik betétdíj (+ 50 Ft)">
                        <LocalBarOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
                {p.isLateAmountSet
                    ?<Tooltip title="A termék mennyisége rendelés felvételt követően (elkészítés után) is állítható">
                        <HourglassEmptyOutlinedIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    :""
                }
            </TableCell>
            <TableCell>{p.defaultEanCode}</TableCell>
            <TableCell align="center">{p.category.name}</TableCell>
            <TableCell align="center">{formatNumber(p.acquisitionPrice)}</TableCell>
            <TableCell align="center">{formatNumber(p.defaultSellingPrice)}</TableCell>
            <TableCell align="center">{((p.defaultSellingPrice / (1 + p.vat / 100)) / p.acquisitionPrice).toFixed(2)}</TableCell>
        </>
    }

    renderEditingSequenceMenu = () => {
        return <Menu
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.clearAnchorForMenu}
            anchorReference="anchorPosition"
            anchorPosition={this.state.anchorEl !== null
                ? { top: this.state.anchorEl.top, left: this.state.anchorEl.left }
                : undefined
            }
        >
            <MenuItem onClick={this.moveToTheTop}>Mozgatás az elejére</MenuItem>
            <MenuItem onClick={this.moveToTheBottom}>Mozgatás a végére</MenuItem>
        </Menu>
    }

    renderPagination = () => {
        return this.state.isEditingSequence
            ? ''
            :<TablePagination
                labelRowsPerPage="Elemek az oldalon:"
                rowsPerPageOptions={RowsPerPageOptions.items}
                component="div"
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <div ref={this.scrollToTheTop} />
                <Widget>
                    <Card title="Szűrés kategóriák alapján">
                        <Collapse in={this.state.notSupportedSelection} style={{marginBottom: 8}}>
                            <Alert severity="info" onClose={this.closeNotSupportedSelection}>Sorrend szerkesztés közben a szűrés nem lehetséges.</Alert>
                        </Collapse>
                        <Autocomplete
                            multiple
                            onChange={this.onChangeCategoryFiler}
                            name="categoryFilter"
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableCategories}
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedCategories || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Kategória" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a névben / leírásban / vonalkódban">
                        <Collapse in={this.state.notSupportedSearch} style={{marginBottom: 8}}>
                            <Alert severity="info" onClose={this.closeNotSupportedSearch}>Sorrend szerkesztés közben a keresés nem lehetséges.</Alert>
                        </Collapse>
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>

                <Paper>
                    {this.renderToolbar()}
                    {this.renderEditSequenceBar()}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Kép</TableCell>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell width="20%">Leírás</TableCell>
                                    <TableCell align='center'>Tulajdonságok</TableCell>
                                    <TableCell>Vonalkód</TableCell>
                                    <TableCell align="center">Kategória</TableCell>
                                    <TableCell align="center">Nettó beker. ár</TableCell>
                                    <TableCell align="center">Bruttó eladási ár</TableCell>
                                    <Tooltip title="A haszonkulcs a nettó eladási ár és a nettó beszerzési ár hányadosa.">
                                        <TableCell align="center">Haszonkulcs</TableCell>
                                    </Tooltip>
                                    <TableCell padding="none" align="right"/>
                                </TableRow>
                            </TableHead>
                            {this.state.isEditingSequence
                                ? this.state.isLoading
                                    ? ''
                                    : this.renderEditingSequenceItems()
                                : this.renderCategoryListItems()
                            }
                        </Table>
                        {this.renderPagination()}
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Termék"
                onClick={this.initiateCreate}/>
        </>;
    }
}
