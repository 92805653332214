import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export default class EditConsumerCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {
                name: null,
                description: null,
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        if (this.props.location.categoryId) {
            axiosCommon.get(`${BASE_URL}/api/consumercategories/${this.props.location.categoryId}`)
                .then(response => {
                    this.setState({
                        category: response.data
                    })
                })
                .catch(error => {
                    this.setState({error});
                });
        }
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {category} = this.state

        axiosCommon({
            method: this.props.location.categoryId ? "put" : "post",
            url: `${BASE_URL}/api/consumercategories`,
            data: category
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = (event) => {
        const { category } = this.state;
        category[event.target.name] = event.target.value;
        this.setState({ category });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.roleId ? "Törzsvásárlói típus  szerkesztése" : "Új törzsvásárlói típus"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.category.name || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" />
                    </Widget>
                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.category.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
