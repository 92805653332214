import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import formatDateTime from "../../../util/formatDateTime";
import moment from "moment";
import Search from "../../../components/Search";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import {
    Button, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import HighlightCard from "../../../components/HighlightCard";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import truncateString from "../../../util/truncateString";
import formatNumber from "../../../util/formatNumber";


export default class CashOperationListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            ops: [],
            fromDate: moment().subtract(7, 'days').toDate(),
            toDate: moment().toDate(),
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        };
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = async () => {
        const response = await axiosCommon.get(`${BASE_URL}/api/shops`)
        const shopOptions = response.data.items;

        let {selectedShopOption} = this.state;

        if(selectedShopOption != null){
            selectedShopOption = shopOptions.find((shopItem) => shopItem.id === selectedShopOption.id);
        }else{
            selectedShopOption = this.getSelectedShop(shopOptions);
        }

        this.setState({
            shopOptions,
            selectedShopOption,
        }, () => {
            const params = {}
            if (this.state.selectedShopOption) {
                params.shopId = this.state.selectedShopOption.id;
            }
            if (this.state.fromDate) {
                params.fromDateTime = moment(this.state.fromDate).startOf('day').format("YYYY-MM-DD[T]HH:mm:ss");
            }
            if (this.state.toDate) {
                params.toDateTime = moment(this.state.toDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
            }
            if (this.state.keyword) {
                params.keyword = this.state.keyword
            }

            axiosCommon(`${BASE_URL}/api/cash-operations`, {
                params
            })
                .then(response => {
                    this.setState({
                        ops: response.data.items
                    })
                })
                .catch(error => {
                    this.setState({
                        error
                    })
                });
        });
    }

    getSelectedShop = (shopOptions) => {
        const ShopId = this.props.location.state?.selectedShopId;

        if(ShopId !== undefined){
            return shopOptions.find(shop => shop.id == ShopId)
        }

        return shopOptions.length > 0 ? shopOptions[0] : null;
    }

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    onChangeShopFilter = (event, value) => {
        this.props.history.replace({
            pathname: this.props.location.pathname,
            state:{
                selectedShopId: value.id
            }
        })

        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    formatEmployee = (user) => `${user.lastName} ${user.firstName}`;

    formatType = (type) => {
        switch (type) {
            case "IN":
                return "Befizetés";
            case "OUT":
                return "Kifizetés";
            default:
                return "Érvénytelen adat";
        }
    }

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/shops/editpayments",
            shopId: this.state.selectedShopOption.id,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderList = () => {
        return <>
            <section className="flex justify-flex-end mb-16 gap-8" >
                <HighlightCard
                    title="Forint egyenleg (Ft)"
                    tooltip="A kiválasztott üzlet forint egyenlege.">
                    <Typography variant="h3" align="center">
                        {formatNumber(this.state.selectedShopOption ? this.state.selectedShopOption.hufBalance || 0 : 0, 0)}
                    </Typography>
                </HighlightCard>
                <div className="m-8"/>
                <HighlightCard
                    title="Euro egyenleg (€)"
                    tooltip="A kiválasztott üzlet euro egyenlege.">
                    <Typography variant="h3" align="center">
                        {formatNumber(this.state.selectedShopOption ? this.state.selectedShopOption.eurBalance || 0.0 : 0.0, 2)}
                    </Typography>
                </HighlightCard>
            </section>

            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés dátum alapján">
                    <StyledDateRangePicker
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        fromDateChange={(date) =>{
                            this.setState({fromDate: date})
                        }}
                        toDateChange={(date) => {
                            this.setState({toDate: date}, () => this.refreshData())
                        }}
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Keresés a leírásban">
                    <Search
                        setKeyword={this.setKeyword}
                        refreshData={this.refreshData}
                    />
                </Card>
            </Widget>

            <Paper>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Be- és kifizetések
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Dátum</TableCell>
                                <TableCell width="25%">Leírás</TableCell>
                                <TableCell align="center">Művelet</TableCell>
                                <TableCell align="center">Személyzet</TableCell>
                                <TableCell align="center">Érték</TableCell>
                                <TableCell align="center">Pénznem</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.ops
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((n, i) => {
                                        return <TableRow key={n.id} hover>
                                            <TableCell>{formatDateTime(n.creationDateTime)}</TableCell>
                                            <TableCell className="break-line">{truncateString(n.description, 127)}</TableCell>
                                            <TableCell align="center">{this.formatType(n.type)}</TableCell>
                                            <TableCell align="center">{this.formatEmployee(n.initiator)}</TableCell>
                                            <TableCell align="center">{n.amount}</TableCell>
                                            <TableCell align="center">{n.currency}</TableCell>
                                        </TableRow>
                                    }
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.ops.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </>;
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Tétel"
                onClick={this.initiateCreate}/>
        </>;
    }
}
