function getGeneralError(error) {
    if (typeof error === 'string' || error instanceof String) {
        return error;
    }
    if (!error)
        return null
    if (error.response) {
        if (Array.isArray(error.response.data) && error.response.data.length > 0) {
            return error.response.data[0].message;
        }
        if (error.response.data.message) {
            return error.response.data.message;
        }
        return "Váratlan hiba történt.";
    }

    if (error.request) {
        return "Nem sikerült a szerverrel kommunikálni. Kérjük ellenőrizze a hálózati kapcsolatot.";
    }
    return "Váratlan hiba történt.";
}

export default getGeneralError;
