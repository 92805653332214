import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {IconButton, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "../../../components/Card";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export default class EditDeliveryPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryPersonName: null,
            shopOptions: [],
            selectedShopOption: null,
            shops: [],
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isNameUnique', (value) => {
            const {shops, selectedShopOption} = this.state
            let deliveryPersonNames = null

            shops.forEach(shop => {
                if(shop.id === selectedShopOption.value) deliveryPersonNames = shop.deliveryPersonNames
            })

            if(deliveryPersonNames.includes(value)) return false;
            return true;
        });

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shops = response.data.items
                const shopOptions = shops.map(c => {
                    return {label: c.name, value: c.id}
                });

                const selectedShopOption = this.props.location.shopId !== undefined
                    ? shopOptions.find(shop => shop.value == this.props.location.shopId)
                    : shopOptions[0] || undefined

                this.setState({ shops, shopOptions, selectedShopOption })
            })
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isNameUnique');
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});


        const {selectedShopOption} = this.state
        let data = null

        this.state.shops.forEach(shop => {
            if(shop.id == selectedShopOption.value) data = shop.deliveryPersonNames
        })

        if(data == null) data = []
        data.push(this.state.deliveryPersonName)
        const params = {shopId: selectedShopOption.value}

        axiosCommon.put(`${BASE_URL}/api/shops/deliveryperson`, data, {params})
        .then(response => {
            this.goBack();
        }).catch(error => {
            this.setState({error, isSubmitInProgress: false});
        });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    Új futár
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const deliveryPersonName = event.target.value;
                                    this.setState({ deliveryPersonName });
                                }}
                                onBlur={() => {
                                    let { deliveryPersonName } = this.state;
                                    deliveryPersonName = deliveryPersonName.trim()
                                    this.setState({ deliveryPersonName });
                                }}
                                name="name"
                                inputProps={{ maxLength: 30 }}
                                value={this.state.deliveryPersonName || ""}
                                required
                                validators={['required', 'isNameUnique']}
                                errorMessages={['A mező kitöltése kötelező', 'Nem lehet két azonos nevű futár az üzletben.']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
