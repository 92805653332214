import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const myStyles = makeStyles((theme) => ({
    widget: {
        marginBottom: 16,
        marginTop: 8,
        display: "flex",
        flexWrap: "wrap",
        gap: 16,
        flexDirection: "row",
        "& div": {
            flex: "45%",
            [theme.breakpoints.down('md')]: {
                flex: "100%"
            },
        }
    },
}));

function WidgetAutoColumn(props){
    return(<>
        <div className={myStyles().widget}>
            {props.children}
        </div>
    </>);
}

export default WidgetAutoColumn;
