function getTypeString(type){
    switch (type) {
        case "CASH":
            return "Készpénz";
        case "CARD":
            return "Bankkártya";
        case "OTHER":
            return "Egyéb";
        case "OTP_SZEP":
            return "Szép kártya";
        case "KHB_SZEP":
            return "Szép kártya (K&H)";
        case "MKB_SZEP":
            return "Szép kártya (MKB)";
        case "WOLT":
            return "Wolt";
        case "FOOD_PANDA":
            return "Foodora";
        case "BOLT":
            return "Bolt";
        case "COUPON":
            return "Kupon";
        case "MUNCH":
            return "Munch";
        case "TRANSFER":
            return "Átutalás";
        case "FALATOZZ":
            return "Falatozz.hu";
        case "FESTIPAY":
            return "Festipay";
        case null:
            return "Nincs megadva";
        default:
            return "Érvénytelen adat";
    }
}

function formatPaymentType(paymentType){
    if(paymentType == null) return ""
    const keys = Object.keys(paymentType)
    if(keys.length === 1){
        return getTypeString(keys[0].toString())
    }
    return "Vegyes fizetés"
}

export default formatPaymentType;
