import React from "react";
import CustomerOverviewPage from "../pages/customer/CustomerOverviewPage";
import ProductDifferencesPage from "../pages/customer/products/ProductDifferencesPage";
import CustomerDiscountsListPage from "../pages/customer/discounts/CustomerDiscountsListPage";
import ShopListPage from "../pages/customer/shops/ShopListPage";
import CustomerProfilePage from "../pages/customer/settings/CustomerProfilePage";
import EditCompanyPage from "../pages/customer/settings/EditCompanyPage";
import ProductCategoryListPage from "../pages/customer/products/ProductCategoryListPage";
import ProductListPage from "../pages/customer/products/ProductListPage";
import CustomerInventoryRefillPage from "../pages/customer/inventories/CustomerInventoryRefillPage";
import CustomerInventoryChargePage from "../pages/customer/inventories/CustomerInventoryChargePage";
import CustomerInventoryCheckPage from "../pages/customer/inventories/CustomerInventoryCheckPage";
import TableListPage from "../pages/customer/shops/TableListPage";
import CustomerFinanceStatisticsPage from "../pages/customer/stats/CustomerFinanceStatisticsPage";
import CustomerSalesStatisticsPage from "../pages/customer/stats/CustomerSalesStatisticsPage";
import CustomerCartStatisticsPage from "../pages/customer/stats/CustomerCartStatisticsPage";
import CustomerEmployeeStatisticsPage from "../pages/customer/stats/CustomerEmployeeStatisticsPage";
import CustomerStaffListPage from "../pages/customer/people/CustomerStaffListPage";
import CustomerRoleListPage from "../pages/customer/people/CustomerRoleListPage";
import ConsumerCategoryListPage from "../pages/customer/people/ConsumerCategoryListPage";
import ConsumerListPage from "../pages/customer/people/ConsumerListPage";
import InventoryListPage from "../pages/customer/stats/InventoryListPage";
import CartListPage from "../pages/customer/stats/CartListPage";
import PrintLabelPage from "../pages/customer/products/PrintLabelPage";
import CashOperationListPage from "../pages/customer/shops/CashOperationListPage";
import TerminalHelpPage from "../pages/customer/help/TerminalHelpPage";
import {
    AiOutlineLineChart,
    AiOutlineDashboard,
    AiOutlineSetting,
    AiOutlineAppstore,
    AiFillAndroid,
    AiOutlineDelete
} from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { IoStorefrontOutline } from "react-icons/io5";
import { BiBox, BiBriefcase, BiHelpCircle, BiSync, BiMailSend} from "react-icons/bi";
import EditCategory from "../pages/customer/products/EditCategory";
import EditProductDifference from "../pages/customer/products/EditProductDifference";
import EditProduct from "../pages/customer/products/EditProduct";
import EditDiscount from "../pages/customer/discounts/EditDiscount";
import EditRole from "../pages/customer/people/EditRole";
import EditStaff from "../pages/customer/people/EditStaff";
import EditConsumerCategory from "../pages/customer/people/EditConsumerCategory";
import EditConsumer from "../pages/customer/people/EditConsumer";
import EditTable from "../pages/customer/shops/EditTable";
import EditCashOperation from "../pages/customer/shops/EditCashOperation";
import EditShop from "../pages/customer/shops/EditShop";
import EditInventoryCheck from "../pages/customer/inventories/EditInventoryCheck";
import EditInventoryChange from "../pages/customer/inventories/EditInventoryChange";
import OfficeVideos from "../pages/customer/help/OfficeVideos";
import TerminalDownloadPage from "../pages/customer/terminal/TerminalDownloadPage";
import GeneralStatisticsPage from "../pages/customer/stats/GeneralStatisticsPage";
import CustomerInventoryProducePage from "../pages/customer/inventories/CustomerInventoryProducePage";
import ExportImport from "../pages/customer/products/ExportImport";
import WorkingDayListPage from "../pages/customer/stats/WorkingDayListPage";
import DiscountStatisticsPage from "../pages/customer/stats/DiscountStatisticsPage";
import CentralSyncPage from "../pages/customer/CentralSyncPage";
import ProductStatisticsPage from "../pages/customer/stats/ProductStatisticsPage";
import Permissions from "./Permissions";
import ProductNotesListPage from "../pages/customer/products/ProductNotesListPage";
import ServiceFeeListPage from "../pages/customer/stats/ServiceFeeListPage";
import DeleteShopData from "../pages/customer/settings/DeleteShopData";
import TerminalVideos from "../pages/customer/help/TerminalVideos";
import CsrFileGeneration from "../pages/customer/ntak/CsrFileGeneration";
import NtakErros from "../pages/customer/ntak/NtakErros";
import NtakShopConfigPage from "../pages/customer/ntak/NtakShopConfigPage";
import EditNtakShopConfig from "../pages/customer/ntak/EditNtakShopConfig";
import NtakProductConfigPage from "../pages/customer/ntak/NtakProductConfigPage";
import EditNtakProductConfig from "../pages/customer/ntak/EditNtakProductConfig";
import ToppingListPage from "../pages/customer/products/ToppingListPage";
import EditTopping from "../pages/customer/products/EditTopping";
import VisibilityListPage from "../pages/customer/products/VisibilityListPage";
import DeliveryPersonListPage from "../pages/customer/shops/DeliveryPersonListPage";
import EditDeliveryPerson from "../pages/customer/shops/EditDeliveryPerson";
import StockChangesListPage from "../pages/customer/stats/StockChangesListPage";

class CustomerMenuConstants {
    static ACTIVE_MENU_KEY = "customer_active_menuitem";

    static ACTIVE_SUBMENU_KEY = "customer_active_submenuitem";

    static OVERVIEW = "Összefoglaló";

    static CENTRAL_SYNC = "Szinkronizálás";

    static STATISTICS = "Kimutatások";

    static STATISTICS_GENERAL = "Forgalom";

    static STATISTICS_INCOME = "Bevétel";

    static STATISTICS_SALES = "Eladások";

    static STATISTICS_SERVICE_FEE = "Szervizdíjak";

    static STATISTICS_CARTS = "Vásárlói szokások";

    static STATISTICS_EMPLOYEES = "Alkalmazottak";

    static STATISTICS_INVENTORY = "Raktárkészlet";

    static STATISTICS_STOCK_CHANGES = "Raktárkészlet műveletek";

    static STATISTICS_INVOICES = "Számlák";

    static INVENTORY = "Raktárkészlet";

    static INVENTORY_REFILL = "Bevételezés / Megrendelés";

    static INVENTORY_CHARGE = "Kivételezés / Mozgatás";

    static INVENTORY_PRODUCE = "Előállítás";

    static INVENTORY_MOVE = "Mozgatás";

    static INVENTORY_CHECK = "Leltár / Stand";

    static EDIT_INVENTORY_CHECK = "EDIT_INVENTORY_CHECK";

    static EDIT_INVENTORY_CHANGE = "EDIT_INVENTORY_CHANGE";

    static PRODUCTS = "Termékek";

    static PRODUCTLIST = "Termékek";

    static EDIT_PRODUCT = "EDIT_PRODUCT";

    static CATEGORIES = "Kategóriák";

    static EDIT_CATEGORY = "EDIT_CATEGORY";

    static TOPPINGLIST = "Feltét csoportok";

    static EDIT_TOPPING = "EDIT_TOPPING";

    static VISIBILITY = "Láthatóság";

    static PRODUCT_LABEL = "Termékcímke-nyomtatás";

    static PRODUCT_NOTES = "Megjegyzések";

    static PRODUCT_DIFFERENCES = "Üzletenkénti eltérés";

    static EDIT_PRODUCT_DIFFERENCES = "EDIT_PRODUCT_DIFFERENCES";

    static PRODUCT_EXPORT_IMPORT = "Export/Import";

    static DISCOUNTS = "Kedvezmények";

    static EDIT_DISCOUNTS = "EDIT_DISCOUNTS";

    static PEOPLE = "Személyzet";

    static EDIT_PEOPLE = "EDIT_PEOPLE";

    static PEOPLE_ROLES = "Személyzeti szerepkörök";

    static EDIT_PEOPLE_ROLES = "EDIT_PEOPLE_ROLES";

    static CONSUMERS = "Vásárlók";

    static NTAK = "NTAK";

    static NTAK_CSR_GENERATION = "Tanusítvány kérelem";

    static NTAK_ERRORS = "Adatküldési hibák";

    static NTAK_SHOP_CONFIG = "Adatküldés beállítása";

    static EDIT_NTAK_SHOP_CONFIG = "EDIT_NTAK_SHOP_CONFIG";

    static NTAK_PRODUCT_CONFIG = "Termékek beállítása";

    static EDIT_NTAK_PRODUCT_CONFIG = "EDIT_NTAK_PRODUCT_CONFIG";

    static EDIT_CONSUMERS = "EDIT_CONSUMERS";

    static CONSUMERS_TYPE = "Törzsvásárlói típusok";

    static EDIT_CONSUMERS_TYPE = "EDIT_CONSUMERS_TYPE";

    static SHOPS = "Üzletek";

    static SHOPLISTING = "Üzletek";

    static EDIT_SHOPLISTING = "EDIT_SHOPLISTING";

    static PAYMENTS = "Be- és kifizetések";

    static EDIT_PAYMENTS = "EDIT_PAYMENTS";

    static HISTORY = "Eseménytörténet";

    static TABLES = "Asztalok";

    static DELIVERY_PERSON = "Futárok";

    static EDIT_DELIVERY_PERSON = "EDIT_DELIVERY_PERSON";

    static STATISTICS_WORKING_DAYS = "Nyitás/Zárás";

    static STATISTICS_DISCOUNTS = "Kedvezmény";

    static STATISTICS_PRODUCT = "Termék történet";

    static EDIT_TABLES = "EDIT_TABLES";

    static SETTINGS = "Beállítások";

    static PROFILE = "Profil";

    static COMPANY = "Cég adatok";

    static HELP_CENTER = "Tudásbázis";

    static HELP_TERMINAL = "Terminál ismertető";

    static WEB_OFFICE_VIDEOS = "Webiroda videók";

    static TERMINAL_VIDEOS = "Terminál videók";

    static TERMINAL = "Terminál SW";

    static DELETE_SHOP_DATA = "Forgalmi adatok törlése";


    static menuItems = {
        [CustomerMenuConstants.OVERVIEW]: {
            title: CustomerMenuConstants.OVERVIEW,
            img: <AiOutlineDashboard />,
            url: "/customer",
            comp: CustomerOverviewPage,
            permissions: []
        },
        [CustomerMenuConstants.STATISTICS]: {
            title: CustomerMenuConstants.STATISTICS,
            img: <AiOutlineLineChart />,
            permissions: [Permissions.STATISTICS],
            subMenus: {
                [CustomerMenuConstants.STATISTICS_GENERAL]: {
                    title: CustomerMenuConstants.STATISTICS_GENERAL,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/general",
                    comp: GeneralStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_INCOME]: {
                    title: CustomerMenuConstants.STATISTICS_INCOME,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/finance",
                    comp: CustomerFinanceStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_SALES]: {
                    title: CustomerMenuConstants.STATISTICS_SALES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/sales",
                    comp: CustomerSalesStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_SERVICE_FEE]: {
                    title: CustomerMenuConstants.STATISTICS_SERVICE_FEE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/servicefee",
                    comp: ServiceFeeListPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_PRODUCT]: {
                    title: CustomerMenuConstants.STATISTICS_PRODUCT,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/product",
                    comp: ProductStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_DISCOUNTS]: {
                    title: CustomerMenuConstants.STATISTICS_DISCOUNTS,
                    url: "/customer/stats/discounts",
                    comp: DiscountStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_CARTS]: {
                    hidden: true,
                    title: CustomerMenuConstants.STATISTICS_CARTS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/carts",
                    comp: CustomerCartStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_EMPLOYEES]: {
                    title: CustomerMenuConstants.STATISTICS_EMPLOYEES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/employees",
                    comp: CustomerEmployeeStatisticsPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_INVENTORY]: {
                    title: CustomerMenuConstants.STATISTICS_INVENTORY,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/inventory",
                    comp: InventoryListPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_STOCK_CHANGES]: {
                    title: CustomerMenuConstants.STATISTICS_STOCK_CHANGES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/stockchanges",
                    comp: StockChangesListPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_INVOICES]: {
                    title: CustomerMenuConstants.STATISTICS_INVOICES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/stats/invoices",
                    comp: CartListPage,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.STATISTICS_WORKING_DAYS]: {
                    title: CustomerMenuConstants.STATISTICS_WORKING_DAYS,
                    url: "/customer/stats/workingdays",
                    comp: WorkingDayListPage,
                    permissions: [Permissions.STATISTICS]
                },
            }
        },
        [CustomerMenuConstants.INVENTORY]: {
            title: CustomerMenuConstants.INVENTORY,
            img: <AiOutlineAppstore />,
            permissions: [],
            subMenus: {
                [CustomerMenuConstants.INVENTORY_REFILL]: {
                    title: CustomerMenuConstants.INVENTORY_REFILL,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/inventories/refill",
                    comp: CustomerInventoryRefillPage,
                    permissions: [Permissions.INVENTORY_REFILL]
                },
                [CustomerMenuConstants.INVENTORY_CHARGE]: {
                    title: CustomerMenuConstants.INVENTORY_CHARGE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/inventories/charge",
                    comp: CustomerInventoryChargePage,
                    permissions: [Permissions.INVENTORY_CHARGE, Permissions.INVENTORY_WASTE]
                },
                [CustomerMenuConstants.INVENTORY_PRODUCE]: {
                    title: CustomerMenuConstants.INVENTORY_PRODUCE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/inventories/produce",
                    comp: CustomerInventoryProducePage,
                    permissions: [Permissions.INVENTORY_PRODUCE]
                },
                [CustomerMenuConstants.EDIT_INVENTORY_CHANGE]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_INVENTORY_CHANGE,
                    url: "/customer/inventories/editchange",
                    comp: EditInventoryChange,
                    permissions: [Permissions.INVENTORY_REFILL, Permissions.INVENTORY_CHARGE, Permissions.INVENTORY_WASTE, Permissions.INVENTORY_PRODUCE]
                },
                [CustomerMenuConstants.INVENTORY_CHECK]: {
                    title: CustomerMenuConstants.INVENTORY_CHECK,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/inventories/check",
                    comp: CustomerInventoryCheckPage,
                    permissions: [Permissions.INVENTORY_CHECK]
                },
                [CustomerMenuConstants.EDIT_INVENTORY_CHECK]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_INVENTORY_CHECK,
                    url: "/customer/inventories/editcheck",
                    comp: EditInventoryCheck,
                    permissions: [Permissions.INVENTORY_CHECK]
                }
            }
        },
        [CustomerMenuConstants.PRODUCTS]: {
            title: CustomerMenuConstants.PRODUCTS,
            img: <BiBox />,
            permissions: [Permissions.PRODUCTS, Permissions.DISCOUNTS],
            subMenus: {
                [CustomerMenuConstants.PRODUCTLIST]: {
                    title: CustomerMenuConstants.PRODUCTLIST,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/list",
                    comp: ProductListPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.EDIT_PRODUCT]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_PRODUCT,
                    url: "/customer/products/editproduct",
                    comp: EditProduct,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.EDIT_CATEGORY]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_CATEGORY,
                    url: "/customer/products/editcategory",
                    comp: EditCategory,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.CATEGORIES]: {
                    title: CustomerMenuConstants.CATEGORIES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/categories",
                    comp: ProductCategoryListPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.TOPPINGLIST]: {
                    title: CustomerMenuConstants.TOPPINGLIST,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/toppings",
                    comp: ToppingListPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.EDIT_TOPPING]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_TOPPING,
                    url: "/customer/products/edittopping",
                    comp: EditTopping,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.VISIBILITY]: {
                    title: CustomerMenuConstants.VISIBILITY,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/visibility",
                    comp: VisibilityListPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.DISCOUNTS]: {
                    title: CustomerMenuConstants.DISCOUNTS,
                    url: "/customer/products/discount",
                    comp: CustomerDiscountsListPage,
                    permissions: [Permissions.DISCOUNTS]
                },
                [CustomerMenuConstants.EDIT_DISCOUNTS]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_DISCOUNTS,
                    url: "/customer/products/editdiscount",
                    comp: EditDiscount,
                    permissions: [Permissions.DISCOUNTS]
                },
                [CustomerMenuConstants.PRODUCT_NOTES]: {
                    title: CustomerMenuConstants.PRODUCT_NOTES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/notes",
                    comp: ProductNotesListPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.PRODUCT_LABEL]: {
                    title: CustomerMenuConstants.PRODUCT_LABEL,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/products/labels",
                    comp: PrintLabelPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.PRODUCT_DIFFERENCES]: {
                    title: CustomerMenuConstants.PRODUCT_DIFFERENCES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/inventories/differences",
                    comp: ProductDifferencesPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.EDIT_PRODUCT_DIFFERENCES]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_PRODUCT_DIFFERENCES,
                    url: "/customer/inventories/editdifference",
                    comp: EditProductDifference,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.PRODUCT_EXPORT_IMPORT]: {
                    title: CustomerMenuConstants.PRODUCT_EXPORT_IMPORT,
                    url: "/customer/products/dumps",
                    comp: ExportImport,
                    permissions: [],
                    role: "ROLE_ADMIN"
                },
            }
        },
        [CustomerMenuConstants.NTAK]: {
            title: CustomerMenuConstants.NTAK,
            img: <BiMailSend />,
            url: "/ntak",
            permissions: [],
            subMenus: {
                [CustomerMenuConstants.NTAK_ERRORS]: {
                    title: CustomerMenuConstants.NTAK_ERRORS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/ntak/ntakdatasending",
                    comp: NtakErros,
                    permissions: [Permissions.STATISTICS]
                },
                [CustomerMenuConstants.NTAK_PRODUCT_CONFIG]: {
                    title: CustomerMenuConstants.NTAK_PRODUCT_CONFIG,
                    img: "/img/icons/right_arrow.svg",
                    url: "/ntak/productconfig",
                    comp: NtakProductConfigPage,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.EDIT_NTAK_PRODUCT_CONFIG]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_NTAK_PRODUCT_CONFIG,
                    img: "/img/icons/right_arrow.svg",
                    url: "/ntak/editproductconfig",
                    comp: EditNtakProductConfig,
                    permissions: [Permissions.PRODUCTS]
                },
                [CustomerMenuConstants.NTAK_SHOP_CONFIG]: {
                    title: CustomerMenuConstants.NTAK_SHOP_CONFIG,
                    img: "/img/icons/right_arrow.svg",
                    url: "/ntak/shopconfig",
                    comp: NtakShopConfigPage,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.EDIT_NTAK_SHOP_CONFIG]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_NTAK_SHOP_CONFIG,
                    url: "/ntak/editshopconfig",
                    comp: EditNtakShopConfig,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.NTAK_CSR_GENERATION]: {
                    title: CustomerMenuConstants.NTAK_CSR_GENERATION,
                    img: "/img/icons/right_arrow.svg",
                    url: "/ntak/certification",
                    comp: CsrFileGeneration,
                    permissions: []
                },
            }
        },
        [CustomerMenuConstants.CONSUMERS]: {
            title: CustomerMenuConstants.CONSUMERS,
            img: <BsPerson />,
            url: "/customer/consumers",
            permissions: [Permissions.CONSUMERS],
            subMenus: {
                [CustomerMenuConstants.CONSUMERS]: {
                    title: CustomerMenuConstants.CONSUMERS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/consumers/list",
                    comp: ConsumerListPage,
                    permissions: [Permissions.CONSUMERS]
                },
                [CustomerMenuConstants.EDIT_CONSUMERS]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_CONSUMERS,
                    url: "/customer/consumers/editconsumer",
                    comp: EditConsumer,
                    permissions: [Permissions.CONSUMERS]
                },
                [CustomerMenuConstants.CONSUMERS_TYPE]: {
                    title: CustomerMenuConstants.CONSUMERS_TYPE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/consumers/types",
                    comp: ConsumerCategoryListPage,
                    permissions: [Permissions.CONSUMERS]
                },
                [CustomerMenuConstants.EDIT_CONSUMERS_TYPE]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_CONSUMERS_TYPE,
                    url: "/customer/consumers/edittypes",
                    comp: EditConsumerCategory,
                    permissions: [Permissions.CONSUMERS]
                }
            }
        },
        [CustomerMenuConstants.PEOPLE]: {
            title: CustomerMenuConstants.PEOPLE,
            img: <BiBriefcase />,
            url: "/customer/people",
            permissions: [Permissions.STAFF],
            subMenus: {
                [CustomerMenuConstants.PEOPLE]: {
                    title: CustomerMenuConstants.PEOPLE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/people/staff",
                    comp: CustomerStaffListPage,
                    permissions: [Permissions.STAFF]
                },
                [CustomerMenuConstants.EDIT_PEOPLE]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_PEOPLE_ROLES,
                    url: "/customer/people/editstaff",
                    comp: EditStaff,
                    permissions: [Permissions.STAFF]
                },
                [CustomerMenuConstants.PEOPLE_ROLES]: {
                    title: CustomerMenuConstants.PEOPLE_ROLES,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/people/roles",
                    comp: CustomerRoleListPage,
                    permissions: [Permissions.STAFF]
                },
                [CustomerMenuConstants.EDIT_PEOPLE_ROLES]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_PEOPLE_ROLES,
                    url: "/customer/people/editroles",
                    comp: EditRole,
                    permissions: [Permissions.STAFF]
                }
            }
        },
        [CustomerMenuConstants.SHOPS]: {
            title: CustomerMenuConstants.SHOPS,
            img: <IoStorefrontOutline />,
            permissions: [Permissions.SHOPS, Permissions.TABLES, Permissions.CASH_OPERATIONS],
            subMenus: {
                [CustomerMenuConstants.SHOPLISTING]: {
                    title: CustomerMenuConstants.SHOPLISTING,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/shops/list",
                    comp: ShopListPage,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.EDIT_SHOPLISTING]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_SHOPLISTING,
                    url: "/customer/shops/editshop",
                    comp: EditShop,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.TABLES]: {
                    title: CustomerMenuConstants.TABLES,
                    url: "/customer/shops/tables",
                    comp: TableListPage,
                    permissions: [Permissions.TABLES]
                },
                [CustomerMenuConstants.EDIT_TABLES]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_TABLES,
                    url: "/customer/shops/edittables",
                    comp: EditTable,
                    permissions: [Permissions.TABLES]
                },
                [CustomerMenuConstants.DELIVERY_PERSON]: {
                    title: CustomerMenuConstants.DELIVERY_PERSON,
                    url: "/customer/shops/deliveryperson",
                    comp: DeliveryPersonListPage,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.EDIT_DELIVERY_PERSON]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_DELIVERY_PERSON,
                    url: "/customer/shops/editdeliveryperson",
                    comp: EditDeliveryPerson,
                    permissions: [Permissions.SHOPS]
                },
                [CustomerMenuConstants.PAYMENTS]: {
                    title: CustomerMenuConstants.PAYMENTS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/shops/payments",
                    comp: CashOperationListPage,
                    permissions: [Permissions.CASH_OPERATIONS]
                },
                [CustomerMenuConstants.EDIT_PAYMENTS]: {
                    hidden: true,
                    title: CustomerMenuConstants.EDIT_PAYMENTS,
                    url: "/customer/shops/editpayments",
                    comp: EditCashOperation,
                    permissions: [Permissions.CASH_OPERATIONS]
                }
            }
        },
        [CustomerMenuConstants.CENTRAL_SYNC]: {
            title: CustomerMenuConstants.CENTRAL_SYNC,
            img: <BiSync />,
            url: "/sync",
            comp: CentralSyncPage,
            permissions: []
        },
        [CustomerMenuConstants.HELP_CENTER]: {
            title: CustomerMenuConstants.HELP_CENTER,
            img: <BiHelpCircle />,
            permissions: [],
            subMenus: {
                [CustomerMenuConstants.WEB_OFFICE_VIDEOS]: {
                    title: CustomerMenuConstants.WEB_OFFICE_VIDEOS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/helpcenter/officevideo",
                    comp: OfficeVideos,
                    permissions: [],
                    profile: ["hasAccessToVideos"]
                },[CustomerMenuConstants.TERMINAL_VIDEOS]: {
                    title: CustomerMenuConstants.TERMINAL_VIDEOS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/helpcenter/terminalvideo",
                    comp: TerminalVideos,
                    permissions: [],
                    profile: ["hasAccessToVideos"]
                },
                [CustomerMenuConstants.HELP_TERMINAL]: {
                    title: CustomerMenuConstants.HELP_TERMINAL,
                    img: "/img/icons/right_arrow.svg",
                    url: "/helpcenter/terminal",
                    comp: TerminalHelpPage,
                    permissions: []
                }
            }
        },
        [CustomerMenuConstants.SETTINGS]: {
            title: CustomerMenuConstants.SETTINGS,
            img: <AiOutlineSetting />,
            permissions: [Permissions.DELETE_DATA],
            subMenus: {
                [CustomerMenuConstants.PROFILE]: {
                    title: CustomerMenuConstants.PROFILE,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/settings/profile",
                    comp: CustomerProfilePage,
                    permissions: [],
                    role: "ROLE_CUSTOMER"
                },
                [CustomerMenuConstants.COMPANY]: {
                    title: CustomerMenuConstants.COMPANY,
                    img: "/img/icons/right_arrow.svg",
                    url: "/customer/settings/company",
                    comp: EditCompanyPage,
                    permissions: [],
                    role: "ROLE_CUSTOMER"
                },
                [CustomerMenuConstants.DELETE_SHOP_DATA]: {
                    title: CustomerMenuConstants.DELETE_SHOP_DATA,
                    img: <AiOutlineDelete />,
                    url: "/customer/deteshopdata",
                    comp: DeleteShopData,
                    permissions: [Permissions.DELETE_DATA]
                },
            }
        },
        [CustomerMenuConstants.TERMINAL]: {
            title: CustomerMenuConstants.TERMINAL,
            img: <AiFillAndroid />,
            url: "/customer/terminal",
            comp: TerminalDownloadPage,
            permissions: []
        },
    };

    static defaultItem = CustomerMenuConstants.menuItems[CustomerMenuConstants.OVERVIEW];

    static isUserAuthorizedFor = (menuItem, profile) => {
        if(sessionStorage.getItem("impersonating") != null) return true
        if(menuItem.role && menuItem.role !== sessionStorage.getItem("role")) return false

        if(menuItem.profile && menuItem.profile.length > 0){
            if(profile == null) return false
            for (const p of menuItem.profile) {
                if(profile[p] === false) return false;
            }
        }

        if(sessionStorage.getItem("role") === "ROLE_CUSTOMER") return true
        if(menuItem.permissions.length === 0) return true

        const permissions = sessionStorage.getItem("permissions").split(",");
        for (const e of menuItem.permissions) {
            if (permissions.indexOf(e.value) != -1) return true
        }

        return false
    }


    static searchByUrl = (url) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const e of Object.values(CustomerMenuConstants.menuItems)) {
            if (e.url === url)
                return e.title;
            if (e.subMenus) {
                const matchingSubmenu = Object.values(e.subMenus).find(s => (s.url === url))
                if (matchingSubmenu) {
                    return matchingSubmenu.title;
                }
            }
        }
        return null;
    }

}

export default CustomerMenuConstants;
