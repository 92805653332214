

function getCartItemToppingsQuantity(cartItem) {
    if(cartItem.toppings == null || cartItem.toppings.length == 0) return 0
    return Math.round(cartItem.quantity)
}

function getCartItemOriginalPriceForOne(cartItem){
    let toppingsPrice = 0
    if(cartItem.toppings != null && cartItem.toppings.length > 0){
        toppingsPrice += cartItem.toppings.map(topping => (topping.originalProductPrice + topping.bottleDeposit) * topping.quantity).reduce((a, b) => a + b, 0)
    }
    return Math.round(cartItem.originalProductPrice + cartItem.bottleDeposit + toppingsPrice)
}

function getCartItemPriceForOne(cartItem){
    let toppingsPrice = 0
    if(cartItem.toppings != null && cartItem.toppings.length > 0){
        toppingsPrice += cartItem.toppings.map(topping => (topping.productPrice + topping.bottleDeposit) * topping.quantity).reduce((a, b) => a + b, 0)
    }
    return Math.round(cartItem.productPrice + cartItem.bottleDeposit + toppingsPrice)
}

function getCartItemOriginalPrice(cartItem){
    let toppingsPrice = 0
    if(cartItem.toppings != null && cartItem.toppings.length > 0){
        const oneToppingPrice = cartItem.toppings.map(topping => (topping.originalProductPrice + topping.bottleDeposit) * topping.quantity).reduce((a, b) => a + b, 0)
        toppingsPrice = getCartItemToppingsQuantity(cartItem) * oneToppingPrice
    }
    const itemPrice = (cartItem.originalProductPrice + cartItem.bottleDeposit) * cartItem.quantity

    return Math.round(itemPrice + toppingsPrice)
}

function getCartItemPrice(cartItem) {
    let toppingsPrice = 0
    if(cartItem.toppings != null && cartItem.toppings.length > 0){
        const oneToppingPrice = cartItem.toppings.map(topping => (topping.productPrice + topping.bottleDeposit) * topping.quantity).reduce((a, b) => a + b, 0)
        toppingsPrice = getCartItemToppingsQuantity(cartItem) * oneToppingPrice
    }
    const itemPrice = (cartItem.productPrice + cartItem.bottleDeposit) * cartItem.quantity

    return Math.round(itemPrice + toppingsPrice)
}

function getCartPrice(cart) {
    return cart.items.map(item => getCartItemPrice(item)).reduce((a, b) => a + b, 0)
}

function getCartItemDiscountValue(cartItem) {
    return getCartItemOriginalPrice(cartItem) - getCartItemPrice(cartItem)
}

function getCartDiscountValue(cart) {
    return cart.items.map(item => getCartItemDiscountValue(item)).reduce((a, b) => a + b, 0)
}

function getCartServiceFee(cart) {
    return cart.serviceFeeEntries.map(item => item.amount).reduce((a, b) => a + b, 0)
}

function getCartTotalPrice(cart){
    return getCartPrice(cart) + getCartServiceFee(cart)
}

export {getCartItemToppingsQuantity, getCartItemPrice, getCartItemOriginalPrice, getCartItemDiscountValue,
    getCartPrice, getCartDiscountValue, getCartServiceFee, getCartTotalPrice, getCartItemOriginalPriceForOne,
    getCartItemPriceForOne}