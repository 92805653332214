import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import moment from "moment";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper, Tab, TextField,
} from "@material-ui/core";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@material-ui/data-grid";
import {ResponsivePie} from "@nivo/pie";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import Loading from "../../../util/Loading";
import NoData from "../../../components/NoData";
import { BasicTooltip } from '@nivo/tooltip';
import formatNumber from "../../../util/formatNumber";
import formatCurrency from "../../../util/formatCurrency";

export default class GeneralStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            generalStats: null,
            error: null,
            tabIndex: 0,
            rows: [],
            columns: [],
            paymentTypePieChart: [],
            incomeTypePieChart: [],
            discountPieChart: [],
            categoryStatsPieChart: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})
        const columns=[
            {field: 'name', headerName: 'Tétel', width: 270},
            {field: 'value', headerName: 'Érték (Ft)', align: 'center', headerAlign: 'center', width: 200, valueFormatter: (params) => formatNumber(params.value, 0)},
            {field: 'meaning', headerName: 'Leírás', align: 'left', headerAlign: 'left', flex: 1},
        ];

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                shopOptions.unshift({
                    name: "Összes üzlet",
                    id: -1
                })
                this.setState({
                    shopOptions,
                    selectedShopOption: shopOptions[0],
                    columns
                }, () => this.refreshData());
            });
    }

    refreshData = () => {
        this.setState({isLoading: true})

        const params = {
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
            fromDate: moment(this.state.fromDate).format("YYYY-MM-DD")
        }

        if (this.state.selectedShopOption.id !== -1) {
            params.shopId = this.state.selectedShopOption.id;
        }

        axiosCommon(`${BASE_URL}/api/stats/general`, {
            params
        })
            .then(response => {
                const generalStats = response.data;

                const allCategoryStats = generalStats.categoryPercents.map(i => {
                    return {
                        id: i.categoryName,
                        label: i.categoryDeleted ? `${i.categoryName  } (Törölt)` : i.categoryName,
                        value: i.avgPercent.toFixed(1)
                    }
                }).sort((a, b) => b.value - a.value)

                const categoryStatsPieChart = allCategoryStats.slice(0, 20)
                const minorCategories = categoryStatsPieChart.slice(20).map(c => c.value).reduce((a, b) => a + b, 0);
                if (minorCategories > 0) {
                    categoryStatsPieChart.push({
                        id: "Egyéb",
                        label: "Egyéb",
                        value: minorCategories
                    })
                }

                let id = 1;
                const rows = [
                    {id: id++, name: "Teljes Bevétel", value: generalStats.totalIncome, meaning: "Az összes bevétel beleértve a szervizdíjat, betéti díjat is (amennyiben van), borravaló nélkül"},
                    {id: id++, name: "Teljes Szervizdíj", value: generalStats.totalServiceFee, meaning: "Az összes szervizdíj értéke"},
                    {id: id++, name: "Teljes Betétdíj", value: generalStats.totalBottleDeposit, meaning: "Az összes betéti díj értéke"},
                    {id: id++, name: "Teljes Borravaló", value: generalStats.tipValue, meaning: "Az összes borravaló értéke"},
                    {id: id++, name: "Teljes Kedvezmény", value: generalStats.totalDiscount, meaning: "Az összes vásárlónak adott kedvezmény értéke"},
                    {id: id++, name: "Teljes Készletérték", value: generalStats.usedStockValue, meaning: "Az összes eladott termék beszerzési értéke"},

                    {id: id++, name: "Kosarak száma", border: true, value: generalStats.numberOfCarts, meaning: "Az összes értékesítés darabszáma"},
                    {id: id++, name: "Elviteles Kosarak száma", value: generalStats.numberOfTakeAwayCarts, meaning: "Az összes elviteles értékesítés darabszáma"},
                    {id: id++, name: "Átlagos kosárérték", value: generalStats.avgCartIncome, meaning: "A kosarak átlagos értéke beleértve a szervizdíjat is (amennyiben van)"},
                    {id: id++, name: "Vásárlók száma", value: generalStats.numberOfGuests, meaning: "Az összes vásárló darabszáma"},
                    {id: id++, name: "Átlagos vásárlóérték", value: generalStats.avgGuestIncome, meaning: "A vásárlók által átlagosan elköltött érték beleértve a szervizdíjat is (amennyiben van)"},

                    {id: id++, name: "Eseti vásárlói bevétel", border: true, value: generalStats.oneTimeCustomerIncome, meaning: "Eseti vásárlók által generált összes bevétel beleértve a szervizdíjat is (amennyiben van)"},
                    {id: id++, name: "Törzsvásárlói bevétel", value: generalStats.loyalityCustomerIncome, meaning: "Törzsvásárlók által generált összes bevétel beleértve a szervizdíjat is (amennyiben van)"},
                    {id: id++, name: "Személyzeti bevétel", value: generalStats.employeeIncome, meaning: "Személyzet által generált összes bevétel beleértve a szervizdíjat is (amennyiben van)"},

                    {id: id++, name: "Eseti vásárlói kedvezmény", border: true, value: generalStats.oneTimeCustomerDiscount, meaning: "Eseti vásárlóknak adott kedvezmény értéke"},
                    {id: id++, name: "Törzsvásárlói kedvezmény", value: generalStats.loyalityCustomerDiscount, meaning: "Törzsvásárlóknak adott kedvezmény értéke"},
                    {id: id++, name: "Személyzeti kedvezmény", value: generalStats.employeeDiscount, meaning: "Személyzetnek adott kedvezmény értéke"},
                ]

                if(generalStats.cashIncome > 0){
                    rows.push(
                        {id: id++, name: "Készpénzes fizetések", border: true, value: generalStats.cashIncome - generalStats.cashServiceFee, meaning: "Készpénzzel kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Készpénzes szervizdíj", value: generalStats.cashServiceFee, meaning: "Készpénzzel kifizetett szervizdíj összege"},
                        {id: id++, name: "Készpénz összesen", value: generalStats.cashIncome, meaning: "Az összes készpénzes bevétel"},
                    )
                }

                if(generalStats.cardIncome > 0){
                    rows.push(
                        {id: id++, name: "Bankkártyás fizetések", border: true, value: generalStats.cardIncome - generalStats.cardServiceFee, meaning: "Bankkártyával kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Bankkártyás szervizdíj", value: generalStats.cardServiceFee, meaning: "Bankkártyával kifizetett szervizdíj összege"},
                        {id: id++, name: "Bankkártya összesen", value: generalStats.cardIncome, meaning: "Az összes bankkártyás bevétel"},
                    )
                }

                if(generalStats.szepIncome > 0){
                    rows.push(
                        {id: id++, name: "SZÉP kártyás fizetések", border: true, value: generalStats.szepIncome - generalStats.szepServiceFee, meaning: "SZÉP kártyával kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "SZÉP kártyás szervizdíj", value: generalStats.szepServiceFee, meaning: "SZÉP kártyával kifizetett szervizdíj összege"},
                        {id: id++, name: "SZÉP kártya összesen", value: generalStats.szepIncome, meaning: "Az összes SZÉP kártyás bevétel"},
                    )
                }

                if(generalStats.couponIncome > 0){
                    rows.push(
                        {id: id++, name: "Kuponos fizetések", border: true, value: generalStats.couponIncome - generalStats.couponServiceFee, meaning: "Kuponnal kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Kuponos szervizdíj", value: generalStats.couponServiceFee, meaning: "Kuponnal kifizetett szervizdíj összege"},
                        {id: id++, name: "Kupon összesen", value: generalStats.couponIncome, meaning: "Az összes kuponos bevétel"},
                    )
                }

                if(generalStats.transferIncome > 0){
                    rows.push(
                        {id: id++, name: "Átutalás fizetések", border: true, value: generalStats.transferIncome - generalStats.transferServiceFee, meaning: "Átutalással kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Átutalás szervizdíj", value: generalStats.transferServiceFee, meaning: "Átutalással kifizetett szervizdíj összege"},
                        {id: id++, name: "Átutalás összesen", value: generalStats.transferIncome, meaning: "Az összes átutalásos bevétel"},
                    )
                }

                if(generalStats.otherIncome > 0){
                    rows.push(
                        {id: id++, name: "Egyéb fizetések", border: true, value: generalStats.otherIncome - generalStats.otherServiceFee, meaning: "Egyéb fizetési móddal kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Egyéb szervizdíj", value: generalStats.otherServiceFee, meaning: "Egyéb fizetési móddal kifizetett szervizdíj összege"},
                        {id: id++, name: "Egyéb összesen", value: generalStats.otherIncome, meaning: "Az összes egyéb fizetési bevétel"},
                    )
                }

                if(generalStats.woltIncome > 0){
                    rows.push(
                        {id: id++, name: "Wolt fizetések", border: true, value: generalStats.woltIncome - generalStats.woltServiceFee, meaning: "Wolt rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Wolt szervizdíj", value: generalStats.woltServiceFee, meaning: "Wolt szervizdíj összege"},
                        {id: id++, name: "Wolt összesen", value: generalStats.woltIncome, meaning: "Az összes Wolt bevétel"},
                    )
                }

                if(generalStats.foodPandeIncome > 0){
                    rows.push(
                        {id: id++, name: "Foodora fizetések", border: true, value: generalStats.foodPandeIncome - generalStats.foodPandeServiceFee, meaning: "Foodora rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Foodora szervizdíj", value: generalStats.foodPandeServiceFee, meaning: "Foodora szervizdíj összege"},
                        {id: id++, name: "Foodora összesen", value: generalStats.foodPandeIncome, meaning: "Az összes Foodora bevétel"},
                    )
                }

                if(generalStats.falatozzIncome > 0){
                    rows.push(
                        {id: id++, name: "Falatozz.hu fizetések", border: true, value: generalStats.falatozzIncome - generalStats.falatozzServiceFee, meaning: "Falatozz.hu rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Falatozz.hu szervizdíj", value: generalStats.falatozzServiceFee, meaning: "Falatozz.hu szervizdíj összege"},
                        {id: id++, name: "Falatozz.hu összesen", value: generalStats.falatozzIncome, meaning: "Az összes Falatozz.hu bevétel"},
                    )
                }

                if(generalStats.festipayIncome > 0){
                    rows.push(
                        {id: id++, name: "Festipay fizetések", border: true, value: generalStats.festipayIncome - generalStats.festipayServiceFee, meaning: "Festipay rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Festipay szervizdíj", value: generalStats.festipayServiceFee, meaning: "Festipay szervizdíj összege"},
                        {id: id++, name: "Festipay összesen", value: generalStats.festipayIncome, meaning: "Az összes Festipay bevétel"},
                    )
                }

                if(generalStats.boltIncome > 0){
                    rows.push(
                        {id: id++, name: "Bolt fizetések", border: true, value: generalStats.boltIncome - generalStats.boltServiceFee, meaning: "Bolt rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Bolt szervizdíj", value: generalStats.boltServiceFee, meaning: "Bolt szervizdíj összege"},
                        {id: id++, name: "Bolt összesen", value: generalStats.boltIncome, meaning: "Az összes Bolt bevétel"},
                    )
                }

                if(generalStats.munchIncome > 0){
                    rows.push(
                        {id: id++, name: "Munch fizetések", border: true, value: generalStats.munchIncome - generalStats.munchServiceFee, meaning: "Munch-al kifizetett rendelések összege (szervizdíj és borravaló nélkül)"},
                        {id: id++, name: "Munch szervizdíj", value: generalStats.munchServiceFee, meaning: "Munch-al kifizetett szervizdíj összege"},
                        {id: id++, name: "Munch összesen", value: generalStats.munchIncome, meaning: "Az összes Munch-os bevétel"},
                    )
                }

                const paymentTypePieChart = [
                    { id: "Készpénz", label: "Készpénz", value: generalStats.cashIncome},
                    { id: "Bankkártya", label: "Bankkártya", value: generalStats.cardIncome},
                    { id: "SZÉP Kártya", label: "SZÉP Kártya", value: generalStats.szepIncome},
                    { id: "Egyéb", label: "Egyéb", value: generalStats.otherIncome},
                    { id: "Wolt", label: "Wolt", value: generalStats.woltIncome},
                    { id: "Foodora", label: "Foodora", value: generalStats.foodPandeIncome},
                    { id: "Bolt", label: "Bolt", value: generalStats.boltIncome},
                    { id: "Kupon", label: "Kupon", value: generalStats.couponIncome},
                    { id: "Munch", label: "Munch", value: generalStats.munchIncome},
                    { id: "Átutalás", label: "Átutalás", value: generalStats.transferIncome},
                    { id: "Falatozz.hu", label: "Falatozz.hu", value: generalStats.falatozzIncome},
                    { id: "Festipay", label: "Festipay", value: generalStats.festipayIncome},
                ].filter(item => item.value > 0)

                const incomeTypePieChart = [
                    { id: "Törzsvásárló", label: "Törzsvásárló", value: generalStats.loyalityCustomerIncome},
                    { id: "Személyzet", label: "Személyzet", value: generalStats.employeeIncome},
                    { id: "Eseti vásárló", label: "Eseti vásárló", value: generalStats.oneTimeCustomerIncome},
                ].filter(item => item.value > 0)

                const discountPieChart =  [
                    { id: "Törzsvásárló", label: "Törzsvásárló", value: generalStats.loyalityCustomerDiscount},
                    { id: "Személyzet", label: "Személyzet", value: generalStats.employeeDiscount},
                    { id: "Eseti vásárló", label: "Eseti vásárló", value: generalStats.oneTimeCustomerDiscount},

                ].filter(item => item.value > 0)

                this.setState({
                    generalStats,
                    rows,
                    paymentTypePieChart,
                    incomeTypePieChart,
                    discountPieChart,
                    categoryStatsPieChart,
                }, () => this.setState({isLoading: false}))
            })
            .catch(error => {
                this.setState({
                    error,
                    paymentTypePieChart: [],
                    incomeTypePieChart: [],
                    discountPieChart: [],
                    categoryStatsPieChart: [],
                    rows: [],
                    generalStats: null,
                }, () => this.setState({isLoading: false}))
            });
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    pieTooltip = (props, unit) => {
        const data = props.datum
        return (
            <BasicTooltip
                id={data.id}
                value={formatCurrency(data.value, 0, unit)}
                color={data.color}
                enableChip
            />
        );
    };

    percentageToolTip = (props, unit) => {
        const data = props.datum
        return (
            <BasicTooltip
                id={data.id}
                value={`${data.value} ${unit}`}
                color={data.color}
                enableChip
            />
        );
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Diagram"/>
                    <Tab label="Táblázat"/>
                </TabList>


                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                </Widget>

                {this.state.isLoading == true
                    ?<Loading/>
                    :<>
                        <TabPanel value={this.state.tabIndex} index={0}>
                            <Widget>
                                <Card title="Bevétel megoszlása">
                                    <section style={{height: "400px"}}>
                                        {this.state.incomeTypePieChart.length > 0
                                            ? <ResponsivePie
                                                {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                                colors={{scheme: 'category10'}}
                                                margin={{top: 30, right: 20, bottom: 60, left: 20}}
                                                data={this.state.incomeTypePieChart}
                                                padAngle={0.7}
                                                innerRadius={0.55}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                arcLabelsSkipAngle={20}
                                                arcLabel={(e) =>{return formatCurrency(e.value, 0, "HUF")}}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsOffset={5}
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                tooltip={props => this.pieTooltip(props, "HUF")}
                                                legends={[{
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateY: 60,
                                                    itemsSpacing: 10,
                                                    itemWidth: 120,
                                                    itemHeight: 20,
                                                    symbolSize: 20,
                                                }]}/>
                                            : <NoData/>
                                        }
                                    </section>
                                </Card>
                                <div className="m-8"/>
                                <Card title="Átlagos kosárösszetétel">
                                    <section style={{height: "400px"}}>
                                        {this.state.categoryStatsPieChart.length > 0
                                            ? <ResponsivePie
                                                {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                                colors={{scheme: 'dark2'}}
                                                margin={{top: 30, right: 20, bottom: 60, left: 20}}
                                                data={this.state.categoryStatsPieChart}
                                                padAngle={0.7}
                                                innerRadius={0.55}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                arcLabelsSkipAngle={15}
                                                arcLabel={(e) =>{return `${e.value} %`}}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsOffset={5}
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                tooltip={props => this.percentageToolTip(props, '%')}
                                            />
                                            : <NoData/>
                                        }
                                    </section>
                                </Card>
                            </Widget>
                            <div className="mb-16"/>
                            <Widget>
                                <Card title="Kedvezmény megoszlása">
                                    <section style={{height: "400px"}}>
                                        {this.state.discountPieChart.length > 0
                                            ? <ResponsivePie
                                                {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                                colors={{scheme: 'accent'}}
                                                margin={{top: 30, right: 20, bottom: 60, left: 20}}
                                                data={this.state.discountPieChart}
                                                padAngle={0.7}
                                                innerRadius={0.55}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                arcLabelsSkipAngle={20}
                                                arcLabel={(e) =>{return formatCurrency(e.value, 0, "HUF")}}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsOffset={5}
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                tooltip={props => this.pieTooltip(props, "HUF")}
                                                legends={[{
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateY: 60,
                                                    itemsSpacing: 10,
                                                    itemWidth: 120,
                                                    itemHeight: 20,
                                                    symbolSize: 20,
                                                }]}
                                            />
                                            : <NoData/>
                                        }
                                    </section>
                                </Card>
                                <div className="m-8"/>
                                <Card title="Fizetési mód megoszlása">
                                    <section style={{height: "400px"}}>
                                        {this.state.paymentTypePieChart.length > 0
                                            ? <ResponsivePie
                                                {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                                colors={{scheme: 'set2'}}
                                                margin={{top: 30, right: 20, bottom: 60, left: 20}}
                                                data={this.state.paymentTypePieChart}
                                                padAngle={0.7}
                                                innerRadius={0.55}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                arcLabelsSkipAngle={20}
                                                arcLabel={(e) =>{return formatCurrency(e.value, 0, "HUF")}}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsOffset={5}
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                tooltip={props => this.pieTooltip(props, "HUF")}
                                                legends={[{
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateY: 60,
                                                    itemsSpacing: 10,
                                                    itemWidth: 120,
                                                    itemHeight: 20,
                                                    symbolSize: 20,
                                                }]}
                                            />
                                            : <NoData/>
                                        }
                                    </section>
                                </Card>
                            </Widget>
                        </TabPanel>

                        <TabPanel value={this.state.tabIndex} index={1}>
                    <Paper className="mt-16">
                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.rows}
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            autoPageSize
                            components={{
                                Toolbar: () => {
                                    return (
                                        <GridToolbarContainer>
                                            <GridToolbarExport />
                                        </GridToolbarContainer>
                                    );
                                },
                                NoRowsOverlay: NoData,
                            }}
                            getRowClassName={(params) => {
                                if(params.row.border) return "grid-row-border"
                                return ""
                            }}
                        />
                    </Paper>
                </TabPanel>
                    </>
                }
            </section>
            <Footer/>
        </section>;
    }
}
