import React, {useState} from 'react';
import ProSideBarNav from "./ProSideBarNav";
import theme from "../theme";
import {ThemeProvider} from "@material-ui/core";
import {Route, Switch} from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import RetescakeLoginPage from "../pages/RetescakeLoginPage";
import CustomerMenuConstants from "../util/CustomerMenuConstants";
import PrivateRoute from "../util/PrivateRoute";
import AdminMenuConstants from "../util/AdminMenuConstants";
import AdminRoute from "../util/AdminRoute";
import "../css/Print.css";
import { LAYOUT_TYPE, RETESCAKE_LAYOUT } from "../util/Config";

const setGlobalStyles = (theme) => {
    if(LAYOUT_TYPE === RETESCAKE_LAYOUT)
        document.documentElement.style.setProperty('--color-primary', theme.palette.primary.main)
}

function Layout() {
    const [sidebarToggled, setSidebarToggled] = useState(true);

    const handleSidebarToggleChange= () => setSidebarToggled(!sidebarToggled);

    return (
        <Route render={(props)=>
            <ThemeProvider theme={theme}>
                {setGlobalStyles(theme)}
                {sessionStorage.getItem('accessToken')
                    ? <ProSideBarNav history={props.history} toggled={sidebarToggled} changeSidebarToggled={handleSidebarToggleChange}/>
                    : ""
                }
                <Switch>
                    {LAYOUT_TYPE === RETESCAKE_LAYOUT
                        ? <Route path='/' exact component={RetescakeLoginPage}/>
                        : <Route path='/' exact component={LoginPage}/>
                    }

                    {Object.values(CustomerMenuConstants.menuItems).map(e => e.subMenus
                        ? Object.values(e.subMenus).map(s =>
                            <PrivateRoute {...props} key={s.url} path={s.url} changeSidebarToggled={handleSidebarToggleChange} exact component={s.comp}/>)

                        : <PrivateRoute {...props} key={e.url} path={e.url} changeSidebarToggled={handleSidebarToggleChange} exact component={e.comp}/>)}

                    {Object.values(AdminMenuConstants.menuItems).map(e => e.subMenus
                        ? Object.values(e.subMenus).map(s =>
                            <PrivateRoute key={s.url} path={s.url} changeSidebarToggled={handleSidebarToggleChange} exact component={s.comp}/>)

                        : <AdminRoute key={e.url} path={e.url} changeSidebarToggled={handleSidebarToggleChange} exact component={e.comp}/>)}
                </Switch>
            </ThemeProvider>

        }/>
    );
}

export default Layout;
