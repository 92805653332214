import React from 'react';
import {Card as MaterialCard, CardContent, CardHeader, Tooltip} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: "13em",
        [theme.breakpoints.down('md')]: {
            minWidth: "10em",
        },
    },
    cardHeader: {
        color: "#0000008a",
    },
    tooltip:{
        fontSize: "14px"
    },
}));

function RenderContent(props){
    const classes = useStyles();
    return <MaterialCard className={classes.card}>
        {props.title
            ? <CardHeader title={props.title} titleTypographyProps={{variant: 'subtitle1'}} className={classes.cardHeader}/>
            : ""
        }
        <CardContent>
            {props.children}
        </CardContent>
    </MaterialCard>;
}

function HighlightCard(props){
    const classes = useStyles();
    return (<>
        {props.tooltip
            ? <Tooltip title={props.tooltip} aria-label={props.tooltip} classes={{ tooltip: classes.tooltip}}>
                    {RenderContent(props)}
            </Tooltip>
            : RenderContent(props)
        }
    </>);
}

export default HighlightCard;
