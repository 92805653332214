import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import formatNumber from "../../../util/formatNumber";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Button, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControlLabel,
    IconButton,
    InputAdornment, Paper,
    Switch, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Widget from "../../../components/Widget";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import Search from "../../../components/Search";
import truncateString from "../../../util/truncateString";
import formatCurrency from "../../../util/formatCurrency";

export default class EditInventoryChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableCategories: [],
            selectedCategories: [],
            keyword: "",
            products: [],
            quantityFields: {},
            selectedStatus: this.statusOptions()[1],
            chargeType: this.chargeOptions()[0],
            shopOptions: [],
            selectedShopOption: null,
            inventoryEntries: {},
            readOnly: false,
            changeRequest: {
                description: null,
                shopId: null,
                state: null,
                kind: null,
                toShopId: null,
                totalPrice: null,
                items: [],
            },
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
            isSubmitInProgress: false,
            onlyChanges: false,
            firstLoad: true,
            moveToShopOptions: [],
            selectedMoveToShopOption: null,
        };
    }

    componentDidMount() {
        axiosCommon(`${BASE_URL}/api/categories`)
          .then(response => {
              this.setState({ availableCategories: response.data.items })
          })
          .catch(error => {this.setState({error});});

        axiosCommon.get(`${BASE_URL}/api/shops/basic`)
        .then(response => {
            const moveToShopOptions = response.data.items.map(c => {
                  return {label: c.name, value: c.id}
              });
              this.setState({ moveToShopOptions })
          })
          .catch(error => {this.setState({error})
        })

        axiosCommon.get(`${BASE_URL}/api/shops`)
          .then(response => {
              const shopOptions = response.data.items.map(c => {
                  return {label: c.name, value: c.id}
              });
              this.setState({
                  shopOptions,
              }, () => {
                  if (this.props.location.changeRequestId) {
                      axiosCommon.get(`${BASE_URL}/api/inventories/changeRequests/${this.props.location.changeRequestId}`)
                        .then(response => {
                            const selectedShopOption = this.state.shopOptions.filter(o => o.value === response.data.shopId)[0]
                            const selectedStatusOption = this.statusOptions().filter(o => o.value === response.data.state)[0]
                            const changeRequest = response.data
                            const quantityFields = {}

                            changeRequest.items.forEach(i => {
                                quantityFields[i.productId] = {
                                    productId: i.productId,
                                    quantity: i.stockChange,
                                    acquisitionPrice: i.acquisitionPrice
                                }
                            })

                            const stateUpdate = {
                                changeRequest,
                                quantityFields,
                                selectedShopOption,
                                selectedStatus: selectedStatusOption,
                                readOnly: response.data.state === "ACKNOWLEDGED" || response.data.kind === "MOVE_IN",
                                onlyChanges: response.data.state === "ACKNOWLEDGED" || response.data.kind === "MOVE_IN",
                            }

                            const chargeType = this.chargeOptions().filter(o => o.value === response.data.kind)[0] || undefined;
                            if (chargeType) stateUpdate.chargeType = chargeType
                            const selectedMoveToShopOption = response.data.toShopId
                              ? this.state.shopOptions.filter(o => o.value === response.data.toShopId)[0]
                              : undefined;
                            if (selectedMoveToShopOption) stateUpdate.selectedMoveToShopOption = selectedMoveToShopOption
                            this.setState(stateUpdate, () => this.refreshData());
                        })
                        .catch(error => {
                            this.setState({error});
                        });
                  } else {
                      this.setState({selectedShopOption: this.state.shopOptions[0] || undefined}, () => this.refreshData())
                  }
              })
          })
          .catch(error => {
              this.setState({error});
          });
    }

    refreshData = () => {
        const queryParams = {deleted: false, hasInventory: true, sort: "name"}
        if(this.state.onlyChanges){
            queryParams.productIds = Object.values(this.state.quantityFields)
              .map(q => q.productId).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        }
        else {
            queryParams.pageSize = this.state.rowsPerPage
            queryParams.pageNumber = this.state.page
        }
        if(this.props.location.kind === "PRODUCE"){
            queryParams.isRecipe = true
        }
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const categoryIds = this.state.selectedCategories.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (categoryIds !== "") {
            queryParams.categoryIds = categoryIds
        }

        axiosCommon(`${BASE_URL}/api/products?`, { params: queryParams })
          .then(response => {
              this.setState({ products: response.data.items, count: response.data.count }, () =>{
                  const params = {shopId: this.state.selectedShopOption?.value || ""}
                  params.productIds = this.state.products.map(c => c.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
                  axiosCommon.get(`${BASE_URL}/api/inventories?`, { params })
                    .then(response => {
                        const inventoryEntries = {}
                        response.data.items.forEach(e => inventoryEntries[e.product.id] = e)
                        this.setState({ inventoryEntries })
                    })
              })
          })
          .catch(error => {
              this.setState({error});
          });
    }

    kindTextLowerCase = () => {
        if(this.props.location.kind === "REFILL") return "bevételezés / megrendelés"
        if(this.props.location.kind === "PRODUCE") return "előállítás"
        return "kivételezés"
    }

    kindTextUpperCase = () => {
        if(this.props.location.kind === "REFILL") return "Bevételezés / Megrendelés"
        if(this.props.location.kind === "PRODUCE") return "Előállítás"
        return "Kivételezés"
    }

    confirmationText = () => {
        if (this.props.location.kind === "REFILL") {
            return "A bevételezés állapotát beérkezettre állítottad. " +
                "Mentés után a cikkek jóváíródnak a cél üzlet raktárkészletében, és a művelet később már nem vonható vissza. " +
                "Biztosan véglegesíted a bevételezést?";
        }
        if(this.props.location.kind === "PRODUCE") {
            return "Az előállítás állapotát legyártottra állítottad. " +
              "Mentés után a cikkek jóváíródnak a cél üzlet raktárkészletében, az alapanyag termékek mennyisége pedig levonódik" +
              " és a művelet később már nem vonható vissza. Biztosan véglegesíted az elkészítést?";
        }
        if (this.state?.chargeType?.value === "MOVE_OUT") {
            return "A mozgatás állapotát kivételezettre állítottad. " +
                "Mentés után a cikkek levonódnak az üzlet raktárkészletéből, és a művelet később már nem vonható vissza. " +
                "A célként megadott üzlethez egy függőben levő bevételezés jön létre, ezt bevételezettre kell állítnod, ha az áru beérkezik. " +
                "Biztosan véglegesíted a kivételezést?";
        }
        return "A kivételezés állapotát kivételezettre állítottad. " +
            "Mentés után a cikkek levonódnak az üzlet raktárkészletéből, és a művelet később már nem vonható vissza. " +
            "Biztosan véglegesíted a kivételezést?";

    }

    getStatusName = () => {
        if(this.props.location.kind === "REFILL") return "Beérkezett"
        if(this.props.location.kind === "PRODUCE") return "Legyártott"
        return "Kivételezett"
    }

    statusOptions = () => {
        return [
            {
                label: "Függőben",
                value: "PENDING"
            },
            {
                label: this.getStatusName(),
                value: "ACKNOWLEDGED"
            },
            {
                label: "Elutasított",
                value: "REJECTED"
            }
        ]
    }

    chargeOptions = () => {
        return [
            {
                label: "Normál kivételezés",
                value: "CHARGE"
            },
            {
                label: "Selejtezés",
                value: "WASTE"
            },
            {
                label: "Mozgatás",
                value: "MOVE_OUT"
            }
        ]
    }

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value , page: 0}, () => this.refreshData())
    };

    onChangeCategoryFiler = (event, value) => {
        this.setState({ selectedCategories: value, page: 0 }, () => this.refreshData())
    };

    productById = (id) => this.state.products.filter(p => p.id === id)[0];

    onSubmit = () => {
        if(Object.values(this.state.quantityFields).length === 0){
            this.setState({error: "Legalább egy tételt meg kell adnod a mentéshez"})
            return 0;
        }
        if (this.state.selectedStatus.value === "ACKNOWLEDGED") {
            this.setState({
                pendingChangeRequest: true
            })
        } else {
            this.confirmSave()
        }
    };

    confirmSave = debounce(() => {
        this.setState({isSubmitInProgress: true, pendingChangeRequest: undefined});

        const {changeRequest} = this.state;
        changeRequest.shopId = this.state.selectedShopOption.value
        changeRequest.state = this.state.selectedStatus.value

        if (this.props.location.kind === "REFILL" || this.props.location.kind === "PRODUCE") {
            changeRequest.kind = this.props.location.kind
        } else {
            changeRequest.kind = this.state.chargeType.value
        }

        if (this.state.chargeType.value === "MOVE_OUT") {
            changeRequest.toShopId = this.state.selectedMoveToShopOption.value
        }

        const items = []
        Object.values(this.state.quantityFields).map(q => {
            return {productId: q.productId, stockChange: q.quantity, acquisitionPrice: q.acquisitionPrice}
        }).forEach(e => items.push(e))
        changeRequest.items = items
        changeRequest.totalPrice = this.totalPrice()

        axiosCommon({
            method: this.props.location.changeRequestId ? "put" : "post",
            url: `${BASE_URL}/api/inventories/changeRequests`,
            data: changeRequest
        })
            .then(response => {
               this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    })

    rejectSave = () => {
        this.setState({
            pendingChangeRequest: undefined
        })
    };

    onQuantityChange = (product, value) => {
        if (this.state.readOnly)
            return;

        const quantity = String(value).replace(",", ".");
        // Only 3 decimal places are allowed
        const decimalPlace = quantity.indexOf(".")
        if(decimalPlace != -1 && value.length - decimalPlace > 4) return;

        const {quantityFields} = this.state;
        const field = { productId: product.id, quantity, acquisitionPrice: product.acquisitionPrice }
        quantityFields[product.id] = field

        if (value === null || value === "") {
            delete quantityFields[product.id]
        }

        this.setState({
            quantityFields
        });
    }

    onAcquisitionPriceChange = (product, value) => {
        if (this.state.readOnly) return;

        const {quantityFields} = this.state;
        quantityFields[product.id].acquisitionPrice = value
        this.setState({ quantityFields });
    }

    onAcquisitionPriceFocusChange  = (product) => {
        if (this.state.readOnly) return;

        const {quantityFields} = this.state;
        if (quantityFields[product.id].acquisitionPrice === null || quantityFields[product.id].acquisitionPrice === "") {
            quantityFields[product.id].acquisitionPrice = product.acquisitionPrice
            this.setState({ quantityFields });
        }
    }

    onlyChangesVisible = (productId) => {
        if(this.state.quantityFields[productId] !== undefined){
            return true;
        }
        return false;
    }

    delEntry = (id) => {
        if (!this.state.readOnly){
            const {quantityFields} = this.state;
            delete quantityFields[id];
            this.setState({
                quantityFields
            });
        }
    }

    totalPrice = () => Object.values(this.state.quantityFields).map(q => q.quantity * q.acquisitionPrice).reduce((sum, e) => sum + e, 0)

    getItemPrice = (productId) => {
        const item = this.state.quantityFields[productId]
        if(item === undefined) return undefined
        return formatCurrency(item.quantity * item.acquisitionPrice, 1, 'HUF')
    }

    getItemAcquisitionPrice = (p) => {
        if(this.state.readOnly){
            const item = this.state.quantityFields[p.id]
            if(item !== undefined)  return formatCurrency(item.acquisitionPrice, 0, 'HUF')
        }

       return formatCurrency(p.acquisitionPrice, 0, 'HUF')
    }

    editingMoveIn = () =>
        this.state.changeRequest?.kind === "MOVE_IN" && this.state.changeRequest.state !== "ACKNOWLEDGED"

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value, selectedMoveToShopOption: null, page: 0 }, () => this.refreshData());
    }

    handleChange = (event) => {
        const { changeRequest } = this.state;
        changeRequest[event.target.name] = event.target.value;
        this.setState({ changeRequest });
    }

    renderAreYouSureDialog = () => {
        return <Dialog
            open={this.state.pendingChangeRequest !== undefined}
            onClose={this.rejectSave}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Biztosan mented?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.confirmationText()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.rejectSave} color="primary">
                    Mégsem
                </Button>
                <Button onClick={this.confirmSave} color="primary" autoFocus>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>;
    };

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.changeRequestId ? `${this.kindTextUpperCase()} szerkesztése` : `Új ${this.kindTextLowerCase()}`}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disabled={this.state.readOnly}
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" required variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" >
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({ selectedStatus: value })}}
                                name="status"
                                fullWidth
                                disabled={this.state.readOnly && !this.editingMoveIn()}
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.statusOptions()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedStatus || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Állapot" variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        disabled={this.state.readOnly && !this.editingMoveIn()}
                        variant="outlined"
                        name="description"
                        value={this.state.changeRequest.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />

                    <Widget>
                        <div className="flex-1 mt-8">
                            {this.props.location.kind === "CHARGE"
                                ?<Autocomplete
                                    onChange={(event, value) => {
                                        this.setState({ chargeType: value })}}
                                    name="status"
                                    fullWidth
                                    disabled={this.state.readOnly}
                                    disableClearable
                                    noOptionsText="Nincs ilyen tétel"
                                    options={this.chargeOptions()}
                                    getOptionLabel={(option) => option === "" ? "" : option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={this.state.chargeType || ""}
                                    renderInput={(params) =>
                                        <TextField {...params} label={`${this.kindTextUpperCase()} típusa`} variant="outlined" />
                                    }
                                />
                                : ""
                            }
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1 mt-8">
                            {this.state.chargeType.value === "MOVE_OUT"
                                ?<Autocomplete
                                    onChange={(event, value) => {
                                        this.setState({ selectedMoveToShopOption: value })}}
                                    name="status"
                                    fullWidth
                                    disabled={this.state.readOnly}
                                    disableClearable
                                    noOptionsText="Nincs ilyen tétel"
                                    options={this.state.moveToShopOptions.filter(s => s.value !== this.state.selectedShopOption.value)}
                                    getOptionLabel={(option) => option === "" ? "" : option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={this.state.selectedMoveToShopOption || ""}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Cél üzlet" required variant="outlined" />
                                    }
                                />
                                : ""
                            }
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Tételek">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                multiple
                                onChange={this.onChangeCategoryFiler}
                                name="categoryFilter"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.availableCategories}
                                getOptionLabel={(option) => option === "" ? "" : option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={this.state.selectedCategories || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Kategória szűrés" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Search
                                setKeyword={this.setKeyword}
                                refreshData={this.refreshData}
                            />
                        </div>
                    </Widget>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.onlyChanges}
                                onChange={(event) => {
                                    this.setState({
                                        onlyChanges: event.target.checked,
                                        page: 0
                                    }, () => {
                                        this.refreshData();
                                    })
                                }}
                                name="diffOnly"
                                color="primary"
                                disabled={this.state.readOnly}
                            />
                        }
                        label="Csak a kitöltött mezőket mutasd"
                    />
                </Card>

                <Paper className="mt-16">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell width="10%">Leírás</TableCell>
                                    <TableCell>Vonalkód</TableCell>
                                    <TableCell align="center">Kategória</TableCell>
                                    {this.state.readOnly === true
                                        ? undefined
                                        : <TableCell align="center">Raktárkészlet</TableCell>
                                    }
                                    <TableCell align="center">Bekerülési ár</TableCell>
                                    <TableCell align="center">Mennyiség</TableCell>
                                    {(this.state.readOnly === false && this.props.location.kind === "REFILL")
                                        ? <TableCell align="center">Új ár</TableCell>
                                        : undefined
                                    }
                                    <TableCell align="center">Érték</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.products.map((p, i) => {
                                    return <TableRow key={p.id} hover>
                                        <TableCell>{p.name}</TableCell>
                                        <TableCell className="break-line">{truncateString(p.description, 127)}</TableCell>
                                        <TableCell>{p.defaultEanCode}</TableCell>
                                        <TableCell align="center">{p.category.name}</TableCell>
                                        {this.state.readOnly === true
                                            ? undefined
                                            : <TableCell align="center">
                                                {(this.state.inventoryEntries[p.id] && this.state.inventoryEntries[p.id]?.currentStockAmount != null) ? `${formatNumber(this.state.inventoryEntries[p.id].currentStockAmount)} ${p.sellingUnit.toLowerCase()}` : "Nem kapható"}
                                            </TableCell>
                                        }
                                        <TableCell align="center">{this.getItemAcquisitionPrice(p)}</TableCell>
                                        <TableCell align="center">
                                            <TextValidator
                                                disabled={this.state.readOnly}
                                                onChange={(event) => this.onQuantityChange(p, event.target.value)}
                                                value={this.state.quantityFields[p.id] !== undefined
                                                  ? this.state.quantityFields[p.id].quantity || ""
                                                  : ""
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end">{p.sellingUnit.toLowerCase()}</InputAdornment>
                                                    )
                                                }}
                                                style={{width: 80}}
                                                validators={['isFloat', 'minFloat:0.001', 'maxFloat:999999']}
                                                errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0.001-nál', 'A megadott érték nem lehet nagyobb 999 999-nél']}
                                            />
                                        </TableCell>
                                        {(this.state.readOnly === false && this.props.location.kind === "REFILL")
                                            ? <TableCell align="center">
                                                <TextValidator
                                                    disabled={this.state.readOnly || this.state.quantityFields[p.id] == undefined}
                                                    onChange={(event) => this.onAcquisitionPriceChange(p, event.target.value)}
                                                    onBlur={() => this.onAcquisitionPriceFocusChange(p)}
                                                    value={this.state.quantityFields[p.id] !== undefined
                                                        ? this.state.quantityFields[p.id].acquisitionPrice
                                                        : ""
                                                    }
                                                    InputProps={{ endAdornment: (<InputAdornment position="end">Ft</InputAdornment>)}}
                                                    style={{width: 80}}
                                                    validators={['isNumber','minNumber:0', 'maxFloat:99999999']}
                                                    errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál', 'A megadott érték nem lehet nagyobb 99 999 999-nél']}
                                                />
                                            </TableCell>
                                            : undefined
                                        }
                                        <TableCell align="center">{this.getItemPrice(p.id)}</TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>
                        {this.state.onlyChanges
                          ? undefined
                          :<TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                          />
                        }
                    </TableContainer>
                </Paper>

                <Typography variant="body1" style={{marginTop: 16}}>Tétel: {formatNumber(Object.values(this.state.quantityFields).length)} db</Typography>
                <Typography variant="body1">Beszerzési ár: {formatCurrency(this.totalPrice(), 0,"HUF")}</Typography>

                {this.state.readOnly && !this.editingMoveIn()
                    ? <div style={{justifyContent: "center", display: "flex"}}>
                        {this.state.error ?
                            <section className="error">{getGeneralError(this.state.error)}</section>
                            : ""}

                        <Button variant="contained" color="primary" onClick={this.goBack}
                                style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20}}>Bezár</Button>
                    </div>
                    : <SaveCancelButtons
                        error={this.state.error}
                        isSubmitInProgress={this.state.isSubmitInProgress}
                        onCancel={this.goBack}
                    />
                }
            </ValidatorForm>

            {this.renderAreYouSureDialog()}
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
