import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getKeyword, getListItems,
    getPageNumber,
    getRowsPerPage, removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";

export default class ProductDifferencesPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            entries: [],
            availableShops: [],
            selectedShops: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
        };
        this.pageKey = 'productDifferencesListPagePageKey'
        this.rowsPerPageKey = 'productDifferencesListPageRowsPerPageKey'
        this.keywordKey = 'productDifferencesListPageKeyword'
        this.selectedShopsKey = 'productDifferencesListPageSelectedShops'
    }

    componentDidMount() {
        this.handleNavigation()
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedShopsKey, JSON.stringify(this.state.selectedShops));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            const selectedShops = getListItems(this.selectedShopsKey)
            this.setState({ page, rowsPerPage, keyword, selectedShops}, () => this.refreshData())
        }else{
            removeSessionKeys([this.pageKey, this.rowsPerPageKey, this.keywordKey, this.selectedShopsKey])
            this.refreshData()
        }
    }

    refreshData = () => {
        const queryParams = {pageSize: this.state.rowsPerPage, pageNumber: this.state.page}
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const shopIds = this.state.selectedShops.map(s => s.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (shopIds !== "") {
            queryParams.shopIds = shopIds
        }

        axiosCommon(`${BASE_URL}/api/inventories/differences?`, { params: queryParams })
            .then(response => {
                this.setState({
                    entries: response.data.items,
                    count: response.data.count,
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        axiosCommon(`${BASE_URL}/api/shops`)
            .then(response => {
                this.setState({
                    availableShops: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateDeletion = (id) => {
        this.setState({
            deleteInProgress: id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.get(`${BASE_URL}/api/inventories/differences/${this.state.deleteInProgress}/reset`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/inventories/editdifference",
            entryId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/inventories/editdifference",
        });
    };

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShops: value, page: 0 }, () => this.refreshData())
    };

    renderDifferences = (orig, actual, measure) =>
        orig !== actual && !!actual ?
            (<><span className="correction">{actual} {measure}</span>(<span
                className="corrected">{orig} {measure}</span>)</>)
            :
            (orig ? `${orig} ${measure}` : "Nincs megadva")

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a tételt?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderIcon = (bool) => {
        if(bool === true)
            return <CheckCircleOutline style={{color: "#4caf50"}} />;

        return <RemoveCircle style={{color: "#f44336"}}/>;
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableShops}
                            onChange={this.onChangeShopFilter}
                            multiple
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShops || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a termékekben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>
                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Üzletenkénti eltérések
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Termék</TableCell>
                                    <TableCell align="center">Kategória</TableCell>
                                    <TableCell align="center">Üzlet</TableCell>
                                    <TableCell align="center">Vonalkód</TableCell>
                                    <TableCell align="center">Eladási ár</TableCell>
                                    <TableCell align="center">Készlet figyelmeztetés</TableCell>
                                    <TableCell align="center">Eladható</TableCell>
                                    <TableCell padding="none" align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.entries.map((entry, i) => {
                                    return <TableRow key={entry.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(entry.id)}>
                                        <TableCell>{entry.product.name}</TableCell>
                                        <TableCell align="center">{entry.product.category.name}</TableCell>
                                        <TableCell align="center">{entry.shop.name}</TableCell>
                                        <TableCell align="center">{this.renderDifferences(entry.product.defaultEanCode, entry.eanCode, "")}</TableCell>
                                        <TableCell align="center">{this.renderDifferences(entry.product.defaultSellingPrice, entry.sellingPrice, "Ft")}</TableCell>
                                        <TableCell align="center">{this.renderDifferences(entry.product.defaultDesiredStockAmount, entry.desiredStockAmount, entry.product.sellingUnit)}</TableCell>
                                        <TableCell align="center">{this.renderIcon(entry.sold)}</TableCell>
                                        <TableCell padding="none" align="right">
                                            <IconButton aria-label="delete" onClick={() => this.initiateDeletion(entry.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Tétel"
                onClick={this.initiateCreate}/>
        </>
    }
}
