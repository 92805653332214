import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import ShowCarts from "../ShowCarts";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button, Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import { getCartPrice } from "../../../util/CartPriceCalculator";
import formatCurrency from "../../../util/formatCurrency";

export default class CustomerStaffListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            carts: [],
            staff: [],
            deleteInProgress: null,
            editInProgress: null,
            createInProgress: false,
            keyword: "",
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            shopOptions: [],
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops/basic`)
        .then(response => {
            const shopOptions = response.data.items.map(c => {
                return {label: c.name, value: c.id}
            });
            this.setState({ shopOptions })
        })
        .catch(error => {this.setState({error})
        })

        this.refreshData();
    }

    refreshData = () => {
        axiosCommon.get(`${BASE_URL}/api/carts?status=ACTIVE`)
            .then(response => {
                this.setState({
                    carts: response.data.items
                })
            })
            .catch(error => {
                this.setState(error)
            })

        const queryParams = {}
        if (this.state.keyword !== "") {
            queryParams.keyword = this.state.keyword
        }

        axiosCommon(`${BASE_URL}/api/employees?deleted=false`,
            {
                params: queryParams
            })
            .then(response => {
                this.setState({
                    staff: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });

    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/people/editstaff",
            userId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/people/editstaff",
        });
    };

    afterCheck = () => {
        this.refreshData();
        this.setState({
            checkInProgress: null
        });
    };


    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/employees/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({error})
            });
        this.setState({
            deleteInProgress: null
        })
    };

    hasDebtForEmployee = id =>
        this.state.carts.filter(c => c.employeeConsumer?.id === id && (c.status === "ACTIVE")).length > 0

    calculateDebtForEmployee = id => {
        const cartList = this.state.carts.filter(c => c.employeeConsumer?.id === id && (c.status === "ACTIVE"))
        if (cartList.length === 0) {
            return "";
        }
        return formatCurrency(cartList.map(cart => getCartPrice(cart)).reduce((a, b) => a + b),0,"HUF")
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a Személyzet tagját?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderShowCart = () => {
        return this.state.checkInProgress
            ?<ShowCarts
                open={this.state.checkInProgress !== null}
                employeeConsumerId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    getAccessibleShops = (employee) => {
        if(employee.accessibleShopIds.includes(-1)) return <Chip label="Összes" />

        if(this.state.shopOptions.length > 0) {
            return <>
                {employee.accessibleShopIds.map((id) =>
                    <Chip key={id} label={this.state.shopOptions.filter(shop => shop.value === id)[0].label} style={{marginBottom: 5}}/>)}
            </>
        }
        return ""
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés a névben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Személyzet
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Szerepkör</TableCell>
                                    <TableCell align="center">Törzsvásárlói típus</TableCell>
                                    <TableCell align="center" width="20%">Üzletek</TableCell>
                                    <TableCell align="center">Tartozás</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.staff
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((s, i) => {
                                            return <TableRow key={s.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(s.id)}>
                                                <TableCell>{`${s.lastName} ${s.firstName}`}</TableCell>
                                                <TableCell align="center">{s.email}</TableCell>
                                                <TableCell align="center">{s.employeeJobTitle.name}</TableCell>
                                                <TableCell align="center">{s.consumerType ? s.consumerType.name : ""}</TableCell>
                                                <TableCell align="center" className="break-line">{this.getAccessibleShops(s)}</TableCell>
                                                <TableCell align="center">
                                                    {this.hasDebtForEmployee(s.id)
                                                        ? <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{color: 'white'}}
                                                            onClick={() => this.setState({checkInProgress: s.id})}>
                                                            {this.calculateDebtForEmployee(s.id)}
                                                        </Button>
                                                        : ""
                                                    }
                                                </TableCell>
                                                <TableCell padding="none" align="right">
                                                    {this.hasDebtForEmployee(s.id)
                                                        ? ""
                                                        : <IconButton aria-label="delete"
                                                                      onClick={() => this.initiateDeletion(s)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.staff.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
                {this.renderShowCart()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Személyzet"
                onClick={this.initiateCreate}/>
        </>
    }
}
