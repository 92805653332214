import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import {
    Paper,
    TextField, Toolbar, Typography
} from "@material-ui/core";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import moment from "moment";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatNumber from "../../../util/formatNumber";

export default class ProductStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            productActions: [],
            productOptions: [],
            selectedProduct: null,
            shopOptions: [],
            selectedShopOption: null,
            fromDate: moment().subtract(7, 'days').toDate(),
            toDate: moment().toDate(),
            error: null,
            columns: [
                {field: 'date', headerName: 'Tárgynap', align: 'left', width: 160},
                {field: 'shop', headerName: 'Üzlet', align: 'center', headerAlign: 'center', width: 200,},
                {field: 'product', headerName: 'Termék', align: 'center', headerAlign: 'center', flex: 1},
                {field: 'user', headerName: 'Felhasználó', align: 'center', headerAlign: 'center', width: 180,},
                {field: 'action', headerName: 'Művelet', align: 'center', headerAlign: 'center', width: 220,},
                {field: 'quantity', headerName: 'Műv. mennyiség', align: 'center', headerAlign: 'center', width: 180},
                {field: 'stockChange', headerName: 'Készletváltozás', align: 'center', headerAlign: 'center', width: 180},
                {field: 'acquisitionPrice', headerName: 'Készlet érték (Ft)', align: 'center', headerAlign: 'center', width: 180},
            ],
            rows: [],
            isLoading: true,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(c => {return {label: c.name, value: c.id}})
                shopOptions.unshift({ label: "Összes üzlet", value: -1 })
                this.setState({ shopOptions, selectedShopOption: shopOptions[0] })
            })
        .catch(error => this.setState({error}))

        axiosCommon.get(`${BASE_URL}/api/products/basic?deleted=false`)
        .then(response => {
            const productOptions = response.data.items.map(p => {return {label: p.name, value: p.id}})
            const selectedProduct = productOptions.length > 0 ? productOptions[0] : null
            this.setState({ productOptions, selectedProduct },() => this.refreshData())
        })
        .catch(error => this.setState({error}))
    }

    refreshData = () => {
        this.setState({ isLoading: true })

        const queryParams = {
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
            fromDate: moment(this.state.fromDate).format("YYYY-MM-DD"),
            productId: this.state.selectedProduct.value,
        }

        if (this.state.selectedShopOption != null && this.state.selectedShopOption.value != -1) {
            queryParams.shopId = this.state.selectedShopOption.value;
        }

        axiosCommon.get(`${BASE_URL}/api/stats/product`, { params: queryParams })
        .then(response => {
            const productActions = response.data

            const rows = productActions.map((p, i) => {
                const price = p.stockChange * p.acquisitionPrice
                const priceText = formatNumber(p.stockChange * p.acquisitionPrice,0)
                return {
                    id: i,
                    date: p.dateTime,
                    shop: p.shopName,
                    product: p.productName,
                    user: p.initiator,
                    action: p.action,
                    quantity: p.quantity,
                    stockChange: p.stockChange > 0 ? `+${p.stockChange}` : p.stockChange,
                    acquisitionPrice: price > 0 ? `+${priceText}` : priceText,
                }
            });

            this.setState({
                productActions,
                rows,
                isLoading: false,
            })
        })
        .catch(error => {
            this.setState({error, rows: [],isLoading: false,})
        })
    };


    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (entry) => {
        this.setState({ rowsPerPage: parseInt(entry, 10)});
    };

    renderList = () => {
        return <>
            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés dátum alapján">
                    <StyledDateRangePicker
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        fromDateChange={(date) =>{
                            this.setState({fromDate: date})
                        }}
                        toDateChange={(date) => {
                            this.setState({toDate: date}, () => this.refreshData())
                        }}
                    />
                </Card>
            </Widget>

            <Widget>
                <Card title="Termék kiválasztása">
                    <Autocomplete
                        onChange={(event, value) => {
                            this.setState({selectedProduct: value}, () => this.refreshData())
                        }}
                        fullWidth
                        disableClearable
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.productOptions}
                        getOptionLabel={(option) => option === "" ? "" : option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        value={this.state.selectedProduct || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Termék" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <div className="flex-1"/>
            </Widget>

            <Paper>
                <Toolbar style={{display: 'flex', flexDirection: 'row', }}>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Termék történet
                    </Typography>
                </Toolbar>
                <DataGrid
                    columns={this.state.columns}
                    rows={this.state.rows}
                    className="cursor-pointer"
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight
                    onPageSizeChange={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={RowsPerPageOptions.items}
                    pageSize={this.state.rowsPerPage}
                    pagination
                    loading={this.state.isLoading}
                    components={{
                        Toolbar: () => {
                            return (
                                <GridToolbarContainer>
                                    <GridToolbarExport/>
                                </GridToolbarContainer>
                            );
                        },
                        NoRowsOverlay: NoData,
                    }}/>
            </Paper>
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
