import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import ShowCarts from "../ShowCarts";
import Footer from "../../../components/Footer";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import truncateString from "../../../util/truncateString";
import formatCurrency from "../../../util/formatCurrency";
import { ShopPlaces } from "../../../util/ShopPlaces";
import { getCartPrice } from "../../../util/CartPriceCalculator";

export default class TableListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            carts: [],
            orderLocations: [],
            shopOptions: [],
            selectedShopOption: null,
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            selectedShopPlaces: [],
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(c => {
                    return {label: c.name, value: c.id}
                });

                const selectedShopOption = this.getSelectedShop(shopOptions);
                const selectedShopPlaces = this.getSelectedPlaces();

                this.setState({
                    shopOptions,
                    selectedShopOption,
                    selectedShopPlaces,
                }, () => this.refreshData())
            })
    }

    getSelectedShop = (shopOptions) => {
        const ShopId = this.props.location.state?.selectedShopId;

        if(ShopId !== undefined){
            return shopOptions.find(shop => shop.value == ShopId)
        }

        return shopOptions.length > 0 ? shopOptions[0] : null;
    }

    getSelectedPlaces= () => {
        const selectedPlaces = this.props.location.state?.selectedPlaces;

        if(selectedPlaces != undefined) return selectedPlaces
        return []
    }

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value }, () => {
            this.saveLocationParams()
            this.refreshData()
        })
    };

    onChangePlaceFilter = (event, value) => {
        this.setState({ selectedShopPlaces: value, page: 0 }, () => this.saveLocationParams())
    }

    saveLocationParams = () => {
        this.props.history.replace({
            pathname: this.props.location.pathname,
            state:{
                selectedShopId: this.state.selectedShopOption.value,
                selectedPlaces: this.state.selectedShopPlaces,
            }
        })
    }

    refreshData = () => {
        const queryParams = {}

        if (this.state.selectedShopOption) {
            queryParams.shopId = this.state.selectedShopOption.value;
        }

        axiosCommon.get(`${BASE_URL}/api/carts?status=ACTIVE`)
            .then(response => {
                this.setState({
                    carts: response.data.items
                })
            })
            .catch(error => {
                this.setState(error)
            })

        axiosCommon(`${BASE_URL}/api/orderlocations?deleted=false`,
            {
                params: queryParams
            })
            .then(response => {
                this.setState({
                    orderLocations: response.data.items
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });

    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/orderlocations/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/shops/edittables",
            tableId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/shops/edittables",
            shopId: this.state.selectedShopOption.value,
        });
    };

    afterCheck = () => {
        this.refreshData();
        this.setState({
            checkInProgress: null
        });
    };

    hasDebtForOrderLocation = id =>
        this.state.carts.filter(c => c.orderLocation?.id === id && (c.status === "ACTIVE")).length > 0

    calculateDebtForOrderLocation = id => {
        const cartList = this.state.carts.filter(c => c.orderLocation?.id === id && (c.status === "ACTIVE"))
        if (cartList.length === 0) return ""
        return formatCurrency(cartList.map(cart => getCartPrice(cart)).reduce((a, b) => a + b), 0, "HUF")
    }

    deleteConfirmationText = () =>
        this.hasDebtForOrderLocation(this.state.deleteInProgress) ? "Az asztalon még tartozás van. Biztos, hogy így is törölni szeretnéd?" : "Biztosan törölni szeretnéd?";

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd az asztalt?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderShowCart = () => {
        return this.state.checkInProgress
            ?<ShowCarts
                open={this.state.checkInProgress !== null}
                orderLocationId={this.state.checkInProgress}
                onClose={this.afterCheck}
                onReject={this.afterCheck}/>
            : ""
    };

    renderList = () => {
        return <>
            <Widget>
                <Card title="Szűrés üzlet alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={this.state.shopOptions}
                        onChange={this.onChangeShopFilter}
                        disableClearable
                        getOptionLabel={(option) => option === "" ? "" : option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        value={this.state.selectedShopOption || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Üzlet" variant="outlined" />
                        }
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Szűrés helység alapján">
                    <Autocomplete
                        fullWidth
                        noOptionsText="Nincs ilyen tétel"
                        options={ShopPlaces}
                        onChange={this.onChangePlaceFilter}
                        multiple
                        getOptionLabel={(option) => option === "" ? "" : option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        value={this.state.selectedShopPlaces || ""}
                        renderInput={(params) =>
                            <TextField {...params} label="Helység" variant="outlined" />
                        }
                    />
                </Card>
            </Widget>

            <Paper>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Asztalok
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">Név</TableCell>
                                <TableCell width="25%">Leírás</TableCell>
                                <TableCell align="center">Helység</TableCell>
                                <TableCell align="center">Tartozás</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.orderLocations
                                .filter(table => this.state.selectedShopPlaces.length > 0
                                    ? this.state.selectedShopPlaces.filter(place => place.value === table.place).length > 0
                                    : true)
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((c, i) => {
                                        return <TableRow key={c.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(c.id)}>
                                            <TableCell>{c.name}</TableCell>
                                            <TableCell className="break-line">{truncateString(c.description, 127)}</TableCell>
                                            <TableCell align="center">{ShopPlaces.find(place => place.value === c.place).label}</TableCell>
                                            <TableCell align="center">
                                                {this.hasDebtForOrderLocation(c.id)
                                                    ? <Button
                                                        variant="contained"
                                                        color="primary"
                                                        style={{color: 'white'}}
                                                        onClick={() => this.setState({checkInProgress: c.id})}>
                                                        {this.calculateDebtForOrderLocation(c.id)}
                                                    </Button>
                                                    : ""
                                                }
                                            </TableCell>
                                            <TableCell padding="none" align="right">
                                                {this.hasDebtForOrderLocation(c.id)
                                                    ? ""
                                                    : <IconButton aria-label="delete" onClick={() => this.initiateDeletion(c)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    }
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.orderLocations.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>

            {this.renderDeleteDialog()}
            {this.renderShowCart()}
        </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Asztal"
                onClick={this.initiateCreate}/>
        </>;
    }
}
