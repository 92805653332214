import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import moment from "moment";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper, Tab, TextField, Typography
} from "@material-ui/core";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@material-ui/data-grid";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import Loading from "../../../util/Loading";
import NoData from "../../../components/NoData";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import HighlightCard from "../../../components/HighlightCard";
import formatNumber from "../../../util/formatNumber";
import formatCurrency from "../../../util/formatCurrency";

export default class DiscountStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            discountStats: null,
            error: null,
            tabIndex: 0,
            rows: [],
            columns: [
                {field: 'name', headerName: 'Kedvezmény neve', flex: 1},
                {field: 'count', headerName: "Alkalom", align: 'center', headerAlign: 'center', flex: 1},
                {field: 'value', headerName: "Összesen (Ft)", align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)}],
            isLoading: false,
            discountLineChartData: [],
            discountBarChartData: [],
            sumDiscount: null,
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                shopOptions.unshift({
                    name: "Összes üzlet",
                    id: -1
                })
                this.setState({
                    shopOptions,
                    selectedShopOption: shopOptions[0],
                }, () => this.refreshData());
            });
    }

    refreshData = () => {
        this.setState({isLoading: true})

        const params = this.getRefreshParams()
        const dailyStat = params.aggregation === "HOUR"

        axiosCommon(`${BASE_URL}/api/stats/discounts`, {
            params
        })
            .then(response => {
                const discountStats = response.data;
                let sumDiscount = 0

                const rows = discountStats.map((d, i) => {
                    sumDiscount += d.value
                    return{
                        id: i,
                        name: d.discountName,
                        count: d.count,
                        value: d.value
                    }});

                const discountBarChartData = discountStats.map(it => {
                    return {
                        id: it.discountName,
                        "Érték": it.value
                    }
                })

                const discountLineChartData = this.getLineChartData(discountStats, dailyStat)

                this.setState({
                    discountStats,
                    sumDiscount,
                    rows,
                    discountBarChartData,
                    discountLineChartData,
                }, () => this.setState({isLoading: false}))
            })
            .catch(error => {
                this.setState({
                    error,
                    rows: [],
                    discountLineChartData: [],
                    discountBarChartData: [],
                    discountStats: null,
                    sumDiscount: null,
                }, () => this.setState({isLoading: false}))
            });
    }

    getRefreshParams = () => {
        const params = {
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
            fromDate: moment(this.state.fromDate).format("YYYY-MM-DD")
        }

        params.aggregation = params.toDate === params.fromDate ? "HOUR" : "DAY"

        if (this.state.selectedShopOption.id !== -1) {
            params.shopId = this.state.selectedShopOption.id;
        }

        return params
    }

    getLineChartData = (discountStats, dailyStat) => {
        return discountStats.map(it => {
            const stats = []
            if (dailyStat) {
                for (let i = 0; i < 24; i += 1) {
                    stats[i] = {
                        label: i < 10 ? `0${i}:00` : `${i}:00`,
                        value: 0,
                    }
                }

                it.items.forEach(i => {
                    const time = i.label.split(" ")[1]
                    const hour = Number(time.split(":")[0])
                    stats[hour].value = i.value
                })
            }else {
                let day = this.state.fromDate
                let dayString = moment(day).format("YYYY-MM-DD")
                const finalDayString = moment(this.state.toDate).add(1, 'days').format("YYYY-MM-DD")
                while(dayString !== finalDayString){
                    stats[dayString] = { label: dayString, value: 0, }
                    day = moment(day).add(1, 'days').toDate()
                    dayString = moment(day).format("YYYY-MM-DD")
                }

                it.items.forEach(i => {
                    stats[i.label].label = i.label
                    stats[i.label].value = i.value
                })
            }

            return {
                id: it.discountName,
                data: Object.values(stats).map(i => {
                    return {x: i.label, y: i.value}
                })
            }})
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    sliceTooltip = (props) => {
        const xLabel = props.slice.points[0].data.xFormatted
        return (
            <>
                <BasicTooltip
                    key="title"
                    id="Időpont"
                    value={xLabel}
                />
                {props.slice.points.map(i => {
                    return <BasicTooltip
                        key={i.serieId}
                        id={i.serieId}
                        value={formatCurrency(i.data.yFormatted, 0, "HUF")}
                        color={i.color}
                        enableChip
                    />
                })}
            </>
        );
    };

    barTooltip = (props) => {
        return (<>
            <BasicTooltip
                id={props.indexValue}
                value={formatCurrency(props.formattedValue, 0, "HUF")}
                color={props.color}
                enableChip
            />
        </>);
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Diagram"/>
                    <Tab label="Táblázat"/>
                </TabList>

                {this.state.sumDiscount != null
                    ?<section className="flex justify-flex-end mb-16 gap-8" >
                        <HighlightCard title="Összes kedvezmény (Ft)">
                            <Typography variant="h3" align="center">
                                {formatNumber(this.state.sumDiscount, 0)}
                            </Typography>
                        </HighlightCard>
                    </section>
                    : undefined
                }

                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                </Widget>

                {this.state.isLoading == true
                    ?<Loading/>
                    :<>
                        <TabPanel value={this.state.tabIndex} index={0}>
                            <Card title="Kedvezmények megoszlása">
                                <section style={{height: "600px"}}>
                                    {this.state.discountLineChartData.length > 0
                                        ?<ResponsiveLine
                                            {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                            data={this.state.discountLineChartData}
                                            colors={{scheme: 'paired'}}
                                            xScale={{type: 'point'}}
                                            yScale={{type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false}}
                                            curve="monotoneX"
                                            enableArea
                                            pointColor={{ from: 'color', modifiers: [] }}
                                            pointLabelYOffset={-12}
                                            useMesh
                                            enableSlices="x"
                                            sliceTooltip={point => this.sliceTooltip(point)}
                                            legends={[]}
                                        />
                                        :<NoData/>
                                    }
                                </section>
                            </Card>
                            <div className="mb-16"/>
                            <Card title="Kedvezmények összesen" className='mt-16'>
                                <section style={{height: "600px"}}>
                                    {(this.state.discountBarChartData.length > 0)
                                        ?<ResponsiveBar
                                            {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                            margin={{top: 20, right: 8, bottom: 150, left: 75}}
                                            colors={{ scheme: 'paired' }}
                                            colorBy="index"
                                            data={this.state.discountBarChartData}
                                            keys={["Érték"]}
                                            indexBy="id"
                                            legends={[]}
                                            labelSkipHeight={20}
                                            label={d => formatCurrency(d.value, 0, "HUF")}
                                            tooltip={point => this.barTooltip(point)}
                                        />
                                        :<NoData/>
                                    }
                                </section>
                            </Card>
                        </TabPanel>

                        <TabPanel value={this.state.tabIndex} index={1}>
                            <Paper className="mt-16">
                                <DataGrid
                                    columns={this.state.columns}
                                    rows={this.state.rows}
                                    disableSelectionOnClick
                                    disableColumnMenu
                                    autoHeight
                                    autoPageSize
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <GridToolbarContainer>
                                                    <GridToolbarExport />
                                                </GridToolbarContainer>
                                            );
                                        },
                                        NoRowsOverlay: NoData,
                                    }}/>
                            </Paper>
                        </TabPanel>
                    </>
                }
            </section>
            <Footer/>
        </section>;
    }
}
