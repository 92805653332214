import React from "react";
import AdminOverviewPage from "../pages/admin/AdminOverviewPage";
import AdminListPage from "../pages/admin/people/AdminListPage";
import AdminSettingsPage from "../pages/admin/AdminSettingsPage";
import CustomerListPage from "../pages/admin/people/CustomerListPage";
import FeeListPage from "../pages/admin/fees/FeeListPage";
import FeeStatsPage from "../pages/admin/fees/FeeStatsPage";
import { AiOutlineLineChart, AiOutlineDashboard, AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { MdSubscriptions } from "react-icons/md";
import EditCustomer from "../pages/admin/people/EditCustomer";
import EditAdmin from "../pages/admin/people/EditAdmin";
import ImersonationListPage from "../pages/admin/people/ImersonationListPage";
import { LAYOUT_TYPE, RETESCAKE_LAYOUT } from "./Config";

class AdminMenuConstants {
    static ACTIVE_MENU_KEY = "admin_active_menuitem";

    static ACTIVE_SUBMENU_KEY = "admin_active_submenuitem";

    static OVERVIEW = "Összefoglaló";

    static STATISTICS = "Kimutatások";

    static STATISTICS_SUBSCRIPTIONS = "Előfizetések";

    static STATISTICS_PAYMENTS = "Befizetések";

    static PEOPLE = "Felhasználók";

    static IMPERSONATION = "Megszemélyesítés";

    static SETTINGS = "Beállítások";

    static TERMINAL = "Terminál SW";

    static EDIT_TERMINAL = "EDIT_TERMINAL";

    static CUSTOMERS = "Ügyfelek";

    static EDIT_CUSTOMERS = "EDIT_CUSTOMERS";

    static ADMINS = "Adminok";

    static EDIT_ADMINS = "EDIT_ADMINS";

    static menuItems = {
        [AdminMenuConstants.OVERVIEW]: {
            title: AdminMenuConstants.OVERVIEW,
            img: <AiOutlineDashboard />,
            url: "/admin",
            comp: AdminOverviewPage
        },
        [AdminMenuConstants.STATISTICS]: {
            title: AdminMenuConstants.STATISTICS,
            img: <AiOutlineLineChart />,
            subMenus: {
                [AdminMenuConstants.STATISTICS_SUBSCRIPTIONS]: {
                    title: AdminMenuConstants.STATISTICS_SUBSCRIPTIONS,
                    img: <MdSubscriptions />,
                    url: "/admin/stats/subscriptions",
                    comp: FeeListPage,
                    role: "ROLE_SUPER_ADMIN"
                },
                [AdminMenuConstants.STATISTICS_PAYMENTS]: {
                    title: AdminMenuConstants.STATISTICS_PAYMENTS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/admin/stats/payments",
                    comp: FeeStatsPage,
                    role: "ROLE_SUPER_ADMIN"
                },
            },
            role: "ROLE_SUPER_ADMIN"
        },
        [AdminMenuConstants.PEOPLE]: {
            title: AdminMenuConstants.PEOPLE,
            img: <AiOutlineUser />,
            subMenus: {
                [AdminMenuConstants.CUSTOMERS]: {
                    title: AdminMenuConstants.CUSTOMERS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/admin/people/customers",
                    comp: CustomerListPage,
                    role: LAYOUT_TYPE === RETESCAKE_LAYOUT ? "ROLE_SUPER_ADMIN" : undefined
                },
                [AdminMenuConstants.EDIT_CUSTOMERS]: {
                    hidden: true,
                    title: AdminMenuConstants.EDIT_CUSTOMERS,
                    url: "/admin/people/editcustomers",
                    comp: EditCustomer,
                    role: LAYOUT_TYPE === RETESCAKE_LAYOUT ? "ROLE_SUPER_ADMIN" : undefined
                },
                [AdminMenuConstants.ADMINS]: {
                    title: AdminMenuConstants.ADMINS,
                    img: "/img/icons/right_arrow.svg",
                    url: "/admin/people/admins",
                    comp: AdminListPage,
                    role: "ROLE_SUPER_ADMIN"
                },
                [AdminMenuConstants.EDIT_ADMINS]: {
                    hidden: true,
                    title: AdminMenuConstants.EDIT_ADMINS,
                    url: "/admin/people/editadmins",
                    comp: EditAdmin,
                    role: "ROLE_SUPER_ADMIN"
                },
                [AdminMenuConstants.IMPERSONATION]: {
                    title: AdminMenuConstants.IMPERSONATION,
                    img: "/img/icons/right_arrow.svg",
                    url: "/admin/people/impersonation",
                    comp: ImersonationListPage,
                    role: LAYOUT_TYPE === RETESCAKE_LAYOUT ? undefined : "ROLE_SUPER_ADMIN",
                },
            }
        },
        [AdminMenuConstants.SETTINGS]: {
            title: AdminMenuConstants.SETTINGS,
            img: <AiOutlineSetting />,
            url: "/admin/settings",
            comp: AdminSettingsPage
        }
    };

    static defaultItem = AdminMenuConstants.menuItems[AdminMenuConstants.OVERVIEW];

    static isUserAuthorizedFor = (menuItem) => {
        if (sessionStorage.getItem("role") === "ROLE_ADMIN"){
            if(sessionStorage.getItem("extended_role") === "ROLE_SUPER_ADMIN") return true
            if(menuItem.role !== undefined && menuItem.role != null && menuItem.role === "ROLE_SUPER_ADMIN") return false
            return true
        }
        return false
    }

    static searchByUrl = (url) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const e of Object.values(AdminMenuConstants.menuItems)) {
            if (e.url === url)
                return e.title;
            if (e.subMenus) {
                const matchingSubmenu = Object.values(e.subMenus).find(s => (s.url === url))
                if (matchingSubmenu) {
                    return matchingSubmenu.title;
                }
            }
        }
        return null;
    }
}

export default AdminMenuConstants;
