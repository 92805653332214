import RowsPerPageOptions from "./RowsPerPageOptions";
import moment from "moment";

export function getPageNumber(key) {
    const page = sessionStorage.getItem(key)
    if(page != null){
        return parseInt(page,10)
    }
    return 0
}

export function getRowsPerPage(key){
    const rowsPerPage = sessionStorage.getItem(key)
    if(rowsPerPage != null){
        return parseInt(rowsPerPage,10)
    }
    return RowsPerPageOptions.defaultPageSize
}

export function getKeyword(key){
    const keyword = sessionStorage.getItem(key)
    if(keyword != null){
        return keyword
    }
    return ""
}

export function getBoolenValue(key){
    const value = sessionStorage.getItem(key)
    if(value != null){
        return value === 'true'
    }
    return false
}

export function removeSessionKeys(keys){
    keys.forEach(key => sessionStorage.removeItem(key))
}

export function saveSessionKey(key, value){
    sessionStorage.setItem(key, value);
}

export function getListItems(key){
    const list = sessionStorage.getItem(key);
    if(list != null){
        const dataObj = JSON.parse(list);
        return dataObj != null ? dataObj : [];
    }
    return []
}

export function getItem(key){
    const item = sessionStorage.getItem(key);
    if(item != null){
        return JSON.parse(item)
    }
    return null
}

export function getDate(key){
    const value = sessionStorage.getItem(key)
    if(value != null && value !== 'null'){
        return moment(sessionStorage.getItem(key)).toDate();
    }
    return null
}

