import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import {
    Checkbox, Chip, FormControl,
    FormControlLabel, FormGroup,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import { DropzoneArea } from 'material-ui-dropzone';
import Widget from "../../../components/Widget";
import NavHeader from "../../../components/NavHeader";
import ClearIcon from "@material-ui/icons/Clear";
import ColorPickerBox from "../../../components/ColorPickerBox";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default class EditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryOptions: [{label: "Nincs", value: -1}],
            selectedParentCategoryOption: {label: "Nincs", value: -1},
            selectedKitchenOrder: {label: "Fogás 1", value: 1},
            category: {
                colorCode: null,
                description: null,
                id: null,
                imageId: null,
                name: null,
                printer: null,
                showInTerminal: true,
                parentCategoryId: null,
                defaultKitchenOrder: 1,
                disabledInShops: [],
            },
            isSubmitInProgress: false,
            printLocations: [],
            availableShops: [],
        };
    }

    componentDidMount() {
        if (this.props.location.categoryId) {
            axiosCommon.get(`${BASE_URL}/api/categories/${this.props.location.categoryId}`)
                .then(response => {
                    const selectedParentCategoryOption = response.data.parentCategory
                        ?{ label: response.data.parentCategory.name, value: response.data.parentCategory.id }
                        : {label: "Nincs", value: -1}
                    const selectedKitchenOrder = response.data.defaultKitchenOrder
                        ? this.getKitchenOrders().filter(option => option.value === response.data.defaultKitchenOrder)[0]
                        : {label: "Fogás 1", value: 1}

                    const category = response.data
                    const printLocations = this.getPrintLocations().filter(printLocation => category.printer?.includes(printLocation.value) === true)

                    this.setState({ category, selectedParentCategoryOption, selectedKitchenOrder, printLocations })
                })
                .catch(error => {
                    this.setState({error});
                });
        }

        axiosCommon.get(`${BASE_URL}/api/categories`)
        .then(response => {
            let categories = response.data.items
            if (this.props.location.categoryId) {
                categories = categories.filter(c => this.filterOutCyclicalParentCategories(c))
            }
            const categoryOptions = categories.map(c => {
                return {label: c.name, value: c.id}
            });
            categoryOptions.unshift({label: "Nincs", value: -1})
            this.setState({
                categoryOptions
            })
        })
        .catch(error => {
            this.setState({error});
        });

        axiosCommon.get(`${BASE_URL}/api/shops`)
        .then(response => {
            const availableShops = response.data.items.map(c => {
                return {label: c.name, id: c.id}
            })
            this.setState({ availableShops })
        }).catch(error => {this.setState({error})})
    }

    filterOutCyclicalParentCategories = (category) => {
        if(category.id == this.props.location.categoryId) return false

        if(category.parentCategory) {
            return this.filterOutCyclicalParentCategories(category.parentCategory)
        }
        return true
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {category} = this.state;

        if (this.state.encodedImage) {
            category.imageData = this.state.encodedImage;
            category.imageContentType = this.state.imageType;
        }

        if (this.state.selectedParentCategoryOption.value == -1) {
            category.parentCategoryId = undefined;
        } else {
            category.parentCategoryId = this.state.selectedParentCategoryOption.value;
        }

        if (this.state.selectedKitchenOrder){
            category.defaultKitchenOrder = this.state.selectedKitchenOrder.value
        }

        category.printer = null
        if(this.state.printLocations.length > 0){
            category.printer = []
            category.printer = this.state.printLocations.map(it => it.value)
        }

        const queryParams = {}
        if (this.state.deleteImage) {
            queryParams.deleteImage = true
        }
        axiosCommon({
            method: this.props.location.categoryId ? "put" : "post",
            url: `${BASE_URL}/api/categories`,
            data: category,
            params: queryParams
        })
            .then(response => {
                this.setState({
                    category: response.data,
                });
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    onSelectImage = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    encodedImage: btoa(reader.result),
                    imageType: loadedFiles[0].type,
                    deleteImage: false
                })
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteImage = () => {
        const {category} = this.state
        delete category.imageId
        this.setState({
            category,
            deleteImage: true
        })
    }

    getPrintLocations = () => {
        return [
            {label: "Pult1", value: "BAR1"},
            {label: "Pult2", value: "BAR2"},
            {label: "Pult3", value: "BAR3"},
            {label: "Konyha1", value: "KITCHEN1"},
            {label: "Konyha2", value: "KITCHEN2"},
            {label: "Konyha3", value: "KITCHEN3"},
        ]
    }

    getKitchenOrders = () => {
        return [
            {label: "Fogás 1", value: 1},
            {label: "Fogás 2", value: 2},
            {label: "Fogás 3", value: 3},
            {label: "Fogás 4", value: 4},
            {label: "Fogás 5", value: 5},
            {label: "Fogás 6", value: 6},
        ]
    }

    handleChange = (event) => {
        const { category } = this.state;
        category[event.target.name] = event.target.value;
        this.setState({ category });
    }

    onChangeColor = (value) => {
        const { category } = this.state;
        category.colorCode = value.hex ? `#${value.hex}` : null;
        this.setState({ category });
    }

    onClearColor = () => {
        const { category } = this.state;
        category.colorCode =  null;
        this.setState({ category });
    }

    onPrinterChange = (event) => {
        const { category } = this.state;
        category[event.target.name] = event.target.value === "null" ? null : event.target.value;
        this.setState({ category });
    }

    handleCategoryChange = (event, value) => {
        this.setState({ selectedParentCategoryOption: value });
    }

    handleKitchenOrderChange = (event, value) => {
        this.setState({ selectedKitchenOrder: value })
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.categoryId ? "Kategória szerkesztése" : "Új kategória"}
                </Typography>
                <IconButton onClick={() => this.props.history.goBack()}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.category.name || ""}
                                required
                                inputProps={{ maxLength: 70 }}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleCategoryChange}
                                name="parentCategoryId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.categoryOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedParentCategoryOption}
                                renderInput={(params) =>
                                    <TextField {...params} label="Szülő kategória" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                multiple
                                onChange={(event, value) => {
                                    this.setState({ printLocations: value})
                                }}
                                name="printers"
                                fullWidth
                                disableClearable
                                disableCloseOnSelect
                                noOptionsText="Nincs ilyen tétel"
                                options={this.getPrintLocations()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.printLocations || "null"}
                                renderOption={(option, { selected }) => (
                                    <>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.label}
                                    </>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Nyomatási hely"  />
                                )}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.label}
                                            {...getTagProps({ index })}
                                            disabled={this.state.printLocations.includes(option) === false}
                                        />
                                    ))
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleKitchenOrderChange}
                                name="defaultKitchenOrder"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.getKitchenOrders()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedKitchenOrder}
                                renderInput={(params) =>
                                    <TextField {...params} label="Alapértelmezett fogás szám" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.category.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>
                <div style={{ marginTop: 16}}/>

                <Card title="Láthatóság üzletenként">
                    <FormControl component="fieldset">
                        <FormGroup>
                            {Object.values(this.state.availableShops).map((shop, i) =>
                                <div key={i} style={{display: "flex", alignItems: 'center',}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={i}
                                                checked={!this.state.category.disabledInShops.includes(shop.id.toString())}
                                                onChange={(event) => {
                                                    const { category } = this.state;
                                                    if(event.target.checked) category.disabledInShops = category.disabledInShops.filter(item => item !== shop.id.toString())
                                                    else category.disabledInShops.push(shop.id.toString())
                                                    this.setState({ category });
                                                }}
                                                color="primary"
                                                name={shop.label} />}
                                        label={shop.label}
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </FormControl>
                </Card>

                <div style={{ marginTop: 16}}/>
                <Card title="Kép vagy színkód">
                    <Widget>
                        <ColorPickerBox
                            value={this.state.category.colorCode ? this.state.category.colorCode : "none"}
                            onChange={this.onChangeColor}
                            onClear={this.onClearColor}
                        />
                        <div className="m-8"/>
                        <div className="flex-1">
                            <DropzoneArea
                                filesLimit={1}
                                key={this.state.category.id}
                                acceptedFiles={['image/*']}
                                maxFileSize={300000}
                                initialFiles={this.state.category.imageId ? [`${BASE_URL}/api/images/${this.state.category.imageId}`] : undefined}
                                dropzoneText="Kép feltöltés (150 x 150 px)"
                                onChange={this.onSelectImage}
                                onDelete={this.onDeleteImage}
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderEdit()}
                </section>

                <Footer/>
            </section>;
    }
}
