const SellingUnits = [
    {
        value: "DB",
        label: "darab (db)"
    },
    {
        value: "KG",
        label: "kilogramm (kg)"
    },
    {
        value: "DKG",
        label: "dekagramm (dkg)"
    },
    {
        value: "G",
        label: "gramm (g)"
    },
    {
        value: "L",
        label: "liter (l)"
    },
    {
        value: "DL",
        label: "deciliter (dl)"
    },
    {
        value: "CL",
        label: "centiliter (cl)"
    },
    {
        value: "ML",
        label: "mililiter (ml)"
    },
    {
        value: "M",
        label: "méter (m)"
    },
    {
        value: "CM",
        label: "centiméter (cm)"
    },
    {
        value: "H",
        label: "óra"
    },
    {
        value: "MIN",
        label: "perc"
    },
    {
        value: "D",
        label: "nap"
    }
];

export default SellingUnits;
