import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import CustomerFinanceStatisticsTemplate from "../../customer/stats/CustomerFinanceStatisticsTemplate";
import {ResponsiveLine} from "@nivo/line";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import NavHeader from "../../../components/NavHeader";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class FeeStatsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            years: [],
            selectedYear: null,
            fees: [],
            error: null
        };
    }

    componentDidMount() {
        const years = [];
        for (let i = 2020; i <= new Date().getFullYear(); i++) {
            years.push({name: i.toString(), id: i,})
        }
        this.setState({
            years,
            selectedYear: years[years.length - 1],
        },() => this.refreshData())
    }

    onYearFilterChange = (event, value) => {
        this.setState({
            selectedYear: value,
        }, () => this.refreshData())
    }

    refreshData = () => {
        axiosCommon(`${BASE_URL}/api/fees?year=${this.state.selectedYear.id}&groupByDate=true&paid=true`)
            .then(response => {
                this.setState({
                    fees: [{
                        id: "Befizetések",
                        data: response.data.items.map(i => {
                            return {
                                x: i.first,
                                y: i.second
                            }
                        })
                    }]
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            });

    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Widget>
                    <Card title="Év kiválasztása">
                         <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.years}
                            onChange={this.onYearFilterChange}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedYear || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Év" variant="outlined" />
                            }
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>



                    {this.state.fees.length > 0 && this.state.fees[0].data?.length > 0
                        ?<Card title="Befizetések">
                            <section style={{height: "500px"}}>
                                <ResponsiveLine
                                    {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                    data={this.state.fees}
                                    colors={["#32CD32"]}
                                    xScale={{type: 'point'}}
                                    yScale={{type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false}}
                                    curve="monotoneX"
                                    enableArea
                                    pointSize={10}
                                    pointColor={{ from: 'color', modifiers: [] }}
                                    useMesh
                                />
                            </section>
                        </Card>
                        : "Még nincs az adott időszakra vonatkozó adat."
                    }

            </section>

            <Footer/>
        </section>;
    }
}
