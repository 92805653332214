import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import Card from "../../../components/Card";
import Search from "../../../components/Search";
import Widget from "../../../components/Widget";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import truncateString from "../../../util/truncateString";

export default class ConsumerCategoryListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            categories: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        }
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        const queryParams = {}
        if (this.state.keyword && this.state.keyword !== "") {
            queryParams.keyword = this.state.keyword
        }

        axiosCommon(`${BASE_URL}/api/consumercategories`,
            {
                params: queryParams
            })
            .then(response => {
                this.setState({
                    categories: response.data.items,
                })
            })
            .catch(error => {
                this.setState({error})
            })
    };

    initiateDeletion = (category) => {
        this.setState({
            deleteInProgress: category.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/consumercategories/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/consumers/edittypes",
            categoryId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/consumers/edittypes",
        });
    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a típust?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek! Így a törzsvásárlói
            típusba tartozó vásárlók is törlődnek.
        </DeleteDialog>;
    };

    renderList = () => {
        return (<>
            <Widget>
                <Card title="Keresés a névben / leírásban">
                    <Search
                        setKeyword={this.setKeyword}
                        refreshData={this.refreshData}
                    />
                </Card>
                <div style={{flex: 1}}/>
            </Widget>

            <Paper>
                <Toolbar>
                    <Typography variant="h5" id="tableTitle" component="div">
                        Törzsvásárlói típusok
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Név</TableCell>
                                <TableCell>Leírás</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.categories
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((c, i) => {
                                        return <TableRow key={c.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(c.id)}>
                                            <TableCell>{c.name}</TableCell>
                                            <TableCell className="break-line">{truncateString(c.description, 127)}</TableCell>
                                            <TableCell padding="none" align="right">
                                                <IconButton aria-label="delete"
                                                            onClick={() => this.initiateDeletion(c)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    }
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Elemek az oldalon:"
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        component="div"
                        count={this.state.categories.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>

            {this.renderDeleteDialog()}

            {this.state.error ?
                <section className="minor-error">{getGeneralError(this.state.error)}</section>
                : ""}
        </>)
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Típus"
                onClick={this.initiateCreate}/>
        </>
    }
}
