import React from "react";
import "../../css/Pages.css"
import Footer from "../../components/Footer";
import CustomerFinanceStatisticsTemplate from "./stats/CustomerFinanceStatisticsTemplate";
import {BASE_URL} from "../../util/Urls";
import {ResponsiveLine} from "@nivo/line";
import NavHeader from "../../components/NavHeader";
import Card from "../../components/Card";
import { BasicTooltip } from "@nivo/tooltip";
import formatCurrency from "../../util/formatCurrency";

export default class CustomerOverviewPage extends React.Component {

    processData = (data) => {
        if(data != null) {


            const totalIncome = data.map(item => item.income).reduce((a, b) => a + b, 0);
            const stats = [];

            for (let i = 0; i < 24; i += 1) {
                stats[i] = {
                    label: i < 10 ? `0${i}:00` : `${i}:00`,
                    income: 0
                }
            }

            data.forEach(i => {
                const time = i.label.split(" ")[1]
                const hour = Number(time.split(":")[0])

                stats[hour].income = i.income
            })

            const incomeStats = [{
                id: "Bevétel",
                data: stats.map(i => {
                    return { x: i.label, y: i.income }
                })
            }]

            this.setState({
                stats,
                incomeStats,
                totalIncome
            })
        }
    }

    sliceTooltip = (props) => {
        const xLabel = props.slice.points[0].data.xFormatted
        return (
            <>
                <BasicTooltip
                    key="title"
                    id="Időpont"
                    value={xLabel}
                />
                {props.slice.points.map(i => {
                    return <BasicTooltip
                        key={i.serieId}
                        id={i.serieId}
                        value={formatCurrency(i.data.yFormatted, 0, "HUF")}
                        color={i.color}
                        enableChip
                    />
                })}
            </>
        );
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <CustomerFinanceStatisticsTemplate url={`${BASE_URL}/api/stats/finance`}
                                                   onDataReceived={this.processData}
                                                   hidePickers
                                                   highlightLabel="Teljes Bevétel (Ft)"
                                                   highlightValue={this.state?.totalIncome}>

                    {this.state?.stats?.length
                        ?<Card title="Teljes mai bevétel">
                            <section style={{height: "500px"}}>
                                <ResponsiveLine
                                    {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                    data={this.state.incomeStats}
                                    colors={["#32CD32"]}
                                    xScale={{type: 'point'}}
                                    yScale={{type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false}}
                                    curve="monotoneX"
                                    enableArea
                                    pointColor={{ from: 'color', modifiers: [] }}
                                    useMesh
                                    pointLabelYOffset={-12}
                                    enableSlices="x"
                                    sliceTooltip={point => this.sliceTooltip(point)}
                                />
                            </section>
                        </Card>
                        : ""
                    }
                </CustomerFinanceStatisticsTemplate>
            </section>
            <Footer/>
        </section>;
    }
};
