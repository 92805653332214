import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import formatDate from "../../../util/formatDate";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import {
    FormControlLabel,
    Paper, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getBoolenValue,
    getKeyword,
    getPageNumber,
    getRowsPerPage, getListItems,
    removeSessionKeys,
    saveSessionKey, getDate
} from "../../../util/SessionStorageHandler";

export default class CustomerDiscountsListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            discounts: [],
            keyword: "",
            fromDate: null,
            toDate: null,
            availableShops: [],
            selectedShops: [],
            editInProgress: null,
            createInProgress: false,
            error: null,
            showOnlyActives: false,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            count: 0,
        };
        this.pageKey = 'discountListPagePageKey'
        this.rowsPerPageKey = 'discountListPageRowsPerPageKey'
        this.keywordKey = 'discountListPageKeyword'
        this.showOnlyActivesKey = 'discountListPageShowOnlyActives'
        this.selectedShopsKey = 'discountListPageSelectedShops'
        this.fromDateKey = 'discountListPageFromDate'
        this.toDateKey = 'discountListPageToDate'
    }

    componentDidMount() {
        axiosCommon(`${BASE_URL}/api/shops`)
            .then(response => {
                this.setState({ availableShops: response.data.items })
            })
            .catch(error => this.setState({ error }));

        this.handleNavigation()
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedShopsKey, JSON.stringify(this.state.selectedShops));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
        saveSessionKey(this.showOnlyActivesKey, this.state.showOnlyActives)
        saveSessionKey(this.fromDateKey, this.state.fromDate)
        saveSessionKey(this.toDateKey, this.state.toDate)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            const showOnlyActives = getBoolenValue(this.showOnlyActivesKey)
            const selectedShops = getListItems(this.selectedShopsKey)
            const fromDate = getDate(this.fromDateKey)
            const toDate = getDate(this.toDateKey)
            this.setState({ page, rowsPerPage, keyword,  showOnlyActives, selectedShops, fromDate, toDate}, () => this.refreshData())
        }else{
            removeSessionKeys([
              this.pageKey, this.rowsPerPageKey, this.keywordKey, this.showOnlyActivesKey, this.selectedShopsKey, this.fromDateKey, this.toDateKey
            ])
            this.refreshData()
        }
    }

    refreshData = () => {
        const queryParams = {pageSize: this.state.rowsPerPage, pageNumber: this.state.page}
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        const shopIds = this.state.selectedShops.map(s => s.id).reduce((s, v, i) => i === 0 ? v : `${s},${v}`, "");
        if (shopIds !== "") {
            queryParams.shopIds = shopIds
        }
        if (this.state.fromDate) {
            queryParams.fromDateTime = `${formatDate(this.state.fromDate)}T00:00:00.00`;
        }
        if (this.state.toDate) {
            queryParams.toDateTime = `${formatDate(this.state.toDate)}T23:59:59.99`;
        }

        if(this.state.showOnlyActives){
            queryParams.active = true;
        }

        axiosCommon(`${BASE_URL}/api/discounts?`, { params: queryParams })
            .then(response => {
                this.setState({
                    discounts: response.data.items,
                    count: response.data.count,
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error
                })
            });
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/products/editdiscount",
            discountId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/products/editdiscount",
        });
    };


    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShops: value, page: 0 }, () => this.refreshData())
    };

    discountSubject = (discount) => {
        switch (discount.discountType) {
            case "CONSUMER":
                return discount.consumerType ? discount.consumerType.name : "Bármely vásárló";
            case "CATEGORY":
                return discount.category ? discount.category.name : "Bármely kategória";
            case "PRODUCT":
                return discount.product ? discount.product.name : "Bármely termék";
            default:
                break;
        }
        return "Hibás adat"
    }

    validity = (discount) => {
        if (discount.fromDateTime === null && discount.toDateTime === null) {
            return "Folyamatos"
        }
        const from = discount.fromDateTime === null ? "" : discount.fromDateTime.replace(/T.*/, "");
        const to = discount.toDateTime === null ? "" : discount.toDateTime.replace(/T.*/, "");
        return `${from} – ${to}`
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0 }, () => this.refreshData());
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.availableShops}
                            onChange={this.onChangeShopFilter}
                            multiple
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShops || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés név alapján">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés állapot alapján">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.showOnlyActives}
                                    onChange={(event) => {
                                        this.setState({
                                            showOnlyActives: event.target.checked,
                                        }, () => {
                                            this.refreshData();
                                        })
                                    }}
                                    name="diffOnly"
                                    color="primary"
                                />
                            }
                            label="Csak az aktívakat mutasd"
                        />
                    </Card>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Kedvezmények
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                  <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell align="center">Üzlet</TableCell>
                                    <TableCell align="center">Állapot</TableCell>
                                    <TableCell align="center">Érvényesség</TableCell>
                                    <TableCell align="center">Kedvezményezett</TableCell>
                                    <TableCell align="center">Mérték</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.discounts.map((d, i) => {
                                    return <TableRow key={d.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(d.id)}>
                                        <TableCell>{d.name}</TableCell>
                                        <TableCell align="center">{d.shop ? d.shop.name : "Mind"}</TableCell>
                                        <TableCell align="center">{d.enabled ? "Aktív" : "Szünetel"}</TableCell>
                                        <TableCell align="center">{this.validity(d)}</TableCell>
                                        <TableCell align="center">{this.discountSubject(d)}</TableCell>
                                        <TableCell align="center">{d.percent ? `${d.percent} %` : `- ${d.difference} Ft`}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.count}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Kedvezmény"
                onClick={this.initiateCreate}/>
        </>;
    }
}
