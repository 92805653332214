import React from "react";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {Toolbar, Typography} from "@material-ui/core";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";

export default function OfficeVideos (props) {

    const renderList = () => {
        return <>
            <Toolbar className="align-center justify-space-between mb-32">
                <Typography variant="h5" id="tableTitle" component="div" >
                    Tudásbázis - Webiroda videók
                </Typography>
            </Toolbar>
            <YouTubePlaylist
                apiKey="AIzaSyA2ZJQn-HLZXdsGpHTAGcqblgvfZ4vThyU"
                playlistId="PLr5qQcOPS94OAC-Wifr-pKgyWhY0PTJiO"
                uniqueName="officeVideos"
            />
        </>
    }

    return(
        <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={props.changeSidebarToggled}/>
                    {renderList()}
                </section>

                <Footer/>
            </section>
        </>
    );
}

