
const worker = () => {
    onmessage = (e) => {
        let accumulatedProductStats = e.data.reduce((acc, d) => {
            if (Object.keys(acc).includes(d.productId)) return acc;

            acc[d.productId] = e.data.filter(g => g.productId === d.productId);
            return acc;
        }, {})

        accumulatedProductStats = Object.values(accumulatedProductStats).map(i => {
            return  i.reduce((i1, i2) => {
                i1.count += i2.count
                i1.income += i2.income
                i1.customerCount = (i1.customerCount == null && i2.customerCount == null) ? null : (i1.customerCount || 0) + (i2.customerCount || 0)
                i1.employeeCount = (i1.employeeCount == null && i2.employeeCount == null) ? null : (i1.employeeCount || 0) + (i2.employeeCount || 0)
                i1.customerIncome = (i1.customerIncome == null && i2.customerIncome == null) ? null : (i1.customerIncome || 0) + (i2.customerIncome || 0)
                i1.employeeIncome = (i1.employeeIncome == null && i2.employeeIncome == null) ? null : (i1.employeeIncome || 0) + (i2.employeeIncome || 0)
                return i1
            })
        })

        postMessage(accumulatedProductStats)
    }
}

let code = worker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: 'application/javascriptssky' })
const workerScript = URL.createObjectURL(blob)
module.exports = workerScript;

