import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button, Chip,
    CircularProgress, Collapse,
    IconButton, Menu, MenuItem,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow, TextField,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SequenceIcon from "@material-ui/icons/FormatListBulleted";
import OptionsIcon from "@material-ui/icons/MoreVert";
import SaveIcon from "@material-ui/icons/Save";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import truncateString from "../../../util/truncateString";
import {DraggableComponent, DroppableComponent} from "../../../components/DnDTable";
import {Alert} from "@material-ui/lab";
import {
    getItem,
    getKeyword,
    getPageNumber,
    getRowsPerPage,
    removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class ProductCategoryListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            categories: [],
            categoryOptions: [],
            selectedParentCategoryOption: null,
            selectedCategoryForSequence: null,
            deleteInProgress: null,
            error: null,
            count: 0,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            isLoading: false,
            isSavingSequence: false,
            isEditingSequence: false,
            anchorEl: null,
            notSupportedSearch: false,
            notSupportedSelection: false,
        };
        this.pageKey = 'categoryListPagePageKey'
        this.rowsPerPageKey = 'categoryListPageRowsPerPageKey'
        this.keywordKey = 'categoryListPageKeyword'
        this.selectedParentCategoryKey = 'productCategoryListPageSelectedParentCategory'
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/categories/basic?deleted=false`)
        .then(response => {
            const categoryOptions = response.data.items.map(c => {
                return {label: c.name, value: c.id}
            });
            categoryOptions.unshift({label: "Főkategória", value: -1})
            const selectedCategoryForSequence = categoryOptions[0]
            this.setState({ categoryOptions, selectedCategoryForSequence })
        })
        .catch(error => this.setState({error}));


        this.handleNavigation()
    }

    componentWillUnmount(){
        saveSessionKey(this.selectedParentCategoryKey, JSON.stringify(this.state.selectedParentCategoryOption));
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    handleNavigation = () => {
        if(this.props.history.action === "POP") {
            const page = getPageNumber(this.pageKey)
            const rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
            const keyword = getKeyword(this.keywordKey)
            const selectedParentCategoryOption = getItem(this.selectedParentCategoryKey)
            this.setState({ page, rowsPerPage, keyword,  selectedParentCategoryOption}, () => this.refreshData())
        }else{
            removeSessionKeys([this.pageKey, this.rowsPerPageKey, this.keywordKey, this.selectedParentCategoryKey])
            this.refreshData()
        }
    }

    refreshData = () => {
        this.setLoading(true);

        const queryParams = {}
        if(!this.state.isEditingSequence){
            queryParams.pageSize = this.state.rowsPerPage
            queryParams.pageNumber = this.state.page

        }else{
            queryParams.parentCategoryId = this.state.selectedCategoryForSequence
                ? this.state.selectedCategoryForSequence.value
                : null
        }
        if (this.state.keyword?.length > 0) {
            queryParams.keyword = this.state.keyword
        }
        if (this.state.selectedParentCategoryOption != null) {
            queryParams.parentCategoryId = this.state.selectedParentCategoryOption.value;
        }

        axiosCommon(`${BASE_URL}/api/categories?`, { params: queryParams })
            .then(response => {
                this.setState({
                    categories: response.data.items,
                    count: response.data.count,
                }, () => this.setLoading(false))
            })
            .catch(error => {
                this.setLoading(false);
                this.setState({error})
            })
    };

    setLoading = (flag) => {
        this.setState({
            isLoading: flag,
        });
    };

    setKeyword = (event) => {
        if(this.state.isEditingSequence == false){
            this.setState({ keyword: event.target.value, page: 0, }, () => this.refreshData())
        }else{
            this.setState({ notSupportedSearch: true })
        }
    };

    initiateDeletion = (category) => {
        this.setState({ deleteInProgress: category.id })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/categories/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/products/editcategory",
            categoryId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/products/editcategory",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, }, () => this.refreshData())
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage, page: 0, }, () => this.refreshData());
    };

    handleCategoryChange = (event, value) => {
        if(this.state.isEditingSequence == false){
            this.setState({ selectedParentCategoryOption: value, page: 0, }, () => this.refreshData());
        }else{
            this.setState({ notSupportedSelection: true })
        }
    }

    onChangeSequenceCategoryFiler = (event, value) => {
        this.setState({ selectedCategoryForSequence: value, }, () => this.refreshData())
    };

    sortAsc = () =>{
        const {categories} = this.state;

        categories.sort((a,b) => a.name.localeCompare(b.name));
        this.setState({categories});
    }

    sortDesc = () =>{
        const {categories} = this.state;

        categories.sort((a,b) => a.name.localeCompare(b.name)).reverse();
        this.setState({categories});
    }

    moveToTheTop = () => {
        const categories = this.reorder(
            this.state.categories,
            this.state.anchorEl.index,
            0
        )

        this.setState({
            categories
        })
        this.clearAnchorForMenu();

    }

    moveToTheBottom = () => {
        const categories = this.reorder(
            this.state.categories,
            this.state.anchorEl.index,
            this.state.categories.length
        )

        this.setState({
            categories
        })
        this.clearAnchorForMenu();
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const categories = this.reorder(
            this.state.categories,
            result.source.index,
            result.destination.index
        )

        this.setState({
            categories
        })
    }

    setAnchorForMenu = (event, index) => {
        this.setState({
            anchorEl:{
                top: event.clientY,
                left: event.clientX,
                index,
            }
        })
    };

    clearAnchorForMenu = () => {
        this.setState({anchorEl: null});
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    setIsSavingSequence = (value) => {
        this.setState({isSavingSequence: value});
    }

    saveSequence = () => {
        this.setIsSavingSequence(true);

        const {categories} = this.state;
        let sequence = 0;
        const data = {
            items: []
        };

        categories.forEach(item => {
            data.items.push({
                first: item.id,
                second: sequence
            })
            sequence++;
        })

        axiosCommon({
            method: "put",
            url: `${BASE_URL}/api/categories/sequence`,
            data,
        })
            .then(response => {
                this.setEditingSequence(false);
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            })
            .finally(() => {
                this.refreshData()
                this.setIsSavingSequence(false);
            });

    }

    toggleEditingSequence = () => {
        let {isEditingSequence} = this.state;
        isEditingSequence = !isEditingSequence ;
        this.setState({
            isLoading: true,
            isEditingSequence,
            keyword: '',
        }, () => this.refreshData());
    }

    setEditingSequence = (value) => {
        this.setState({isEditingSequence: value});
    }

    closeNotSupportedSearch = () =>  {
        this.setState({
            notSupportedSearch: false
        })
    }

    closeNotSupportedSelection = () =>  {
        this.setState({
            notSupportedSelection: false
        })
    }

    getPrinterText = (printer) => {
        const printLocations = [
            {label: "Pult1", value: "BAR1"},
            {label: "Pult2", value: "BAR2"},
            {label: "Pult3", value: "BAR3"},
            {label: "Konyha1", value: "KITCHEN1"},
            {label: "Konyha2", value: "KITCHEN2"},
            {label: "Konyha3", value: "KITCHEN3"},
        ]

        return <>
            {printLocations.filter(it => printer?.includes(it.value)).map(it => <Chip key={it.value} label={it.label}/>)}
        </>
    }

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd a kategóriát?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek! A kategória törlésével
            a benne lévő termékek is törölve lesznek illetve az összes hozzá tartozó alkategória a fő
            kategóriák közé fog kerülni!
        </DeleteDialog>;
    };

    renderToolbar = () => {
        return  <Toolbar  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Typography variant="h5" id="tableTitle" component="div" >
                    Kategóriák
                </Typography>
                {this.state.isLoading == true
                    ? <CircularProgress style={{marginLeft: 10, color: 'white', height: 30, width: 30}}/>
                    : ''
                }
            </div>

            <Button
                variant="contained"
                style={{backgroundColor: 'white'}}
                onClick={() => this.toggleEditingSequence()}
                startIcon={<SequenceIcon />}>
                Sorrend
            </Button>
        </Toolbar>
    }

    renderEditSequenceBar = () => {
        return <Collapse in={this.state.isEditingSequence} >
            <div className='table-edit-bar pr-24 pl-24 pt-24'>
                <Typography variant="h6">
                    Sorrend szerkesztése
                </Typography>
                <Typography variant="body2">
                    Az elemek sorrendjének szerkesztéséhez mozgazsd az elemet a kívánt helyre, vagy használd a beépített rendezéseinket a lenti gombokkal.
                </Typography>
                <Typography variant="body2" style={{marginTop: 8}}>
                    <strong>FONTOS: Új kategória kiválasztása előtt ne felejts el menteni!</strong>
                </Typography>

                <Autocomplete
                    onChange={this.onChangeSequenceCategoryFiler}
                    name="categoryFilterForSequence"
                    noOptionsText="Nincs ilyen tétel"
                    disableClearable
                    options={this.state.categoryOptions}
                    style={{marginTop: 24}}
                    getOptionLabel={(option) => option === "" ? "" : option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    value={this.state.selectedCategoryForSequence || ""}
                    renderInput={(params) =>
                        <TextField {...params} label="Szűlő kategória" variant="outlined" />
                    }
                />
                <div className='flex pt-24 pb-24'>
                    <Button
                        variant="contained"
                        style={{backgroundColor: 'white'}}
                        onClick={() => this.sortAsc()}
                        startIcon={<ImSortAlphaAsc />}>
                        ABC
                    </Button>
                    <div className='m-8'/>
                    <Button
                        variant="contained"
                        style={{backgroundColor: 'white'}}
                        onClick={() => this.sortDesc()}
                        startIcon={<ImSortAlphaDesc />}>
                        CBA
                    </Button>
                </div>

                <div className='flex justify-flex-end pb-24'>
                    <Button
                        variant="outlined"
                        style={{color: 'gray'}}
                        onClick={this.toggleEditingSequence}>
                        Mégse
                    </Button>
                    <div className='m-8'/>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{backgroundColor: 'green', color: 'white'}}
                        onClick={() => this.saveSequence()}
                        startIcon={
                            this.state.isSavingSequence
                                ?<CircularProgress color="primary" style={{color: 'white', width: 16, height: 16}}/>
                                :<SaveIcon />
                        }>
                        Mentés
                    </Button>
                </div>
            </div>
        </Collapse>
    }

    renderCategoryListItems = () => {
        return <TableBody>
            {this.state.categories.map((category) => {
                return <TableRow key={category.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(category.id)}>
                    {this.renderListItems(category)}
                    <TableCell padding="none" align="right">
                        <IconButton aria-label="delete" onClick={() => this.initiateDeletion(category)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            })}
        </TableBody>
    }

    renderEditingSequenceItems = () => {
        return <TableBody component={DroppableComponent(this.onDragEnd)}>
            {this.state.categories.map((category, i) => {
                return <TableRow key={category.id} component={DraggableComponent(category.id.toString(), i)} hover>
                    {this.renderListItems(category)}
                    <TableCell padding="none" align="right">
                        <IconButton aria-label="grab" onClick={(event) => {this.setAnchorForMenu(event, i)}}>
                            <OptionsIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            })}

            {this.renderEditingSequenceMenu()}

        </TableBody>
    }

    renderColorBox = (color) => {
        return <div style={{width: 52.5, height: 52.5, background: color}}/>
    }

    renderListItems = (category) => {
        return <>
            <TableCell padding="checkbox">
                {category.imageId
                  ? <img src={`${BASE_URL}/api/images/${category.imageId}`} alt="Fotó"/>
                  : category.colorCode
                    ? this.renderColorBox(category.colorCode)
                    : <img src="/img/icons/no_image.svg" alt="Nincs fotó"/>
                }
            </TableCell>
            <TableCell>{category.name}</TableCell>
            <TableCell className="break-line">{truncateString(category.description, 127)}</TableCell>
            <TableCell align='center'>
                {category.disabledInShops.length > 0
                    ?<Tooltip title="A kategória nem minden üzletben látható">
                        <VisibilityOff style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                    </Tooltip>
                    : ""
                }
            </TableCell>
            <TableCell align='center'>{category.parentCategory ? category.parentCategory.name : undefined}</TableCell>
            <TableCell align='center'>{this.getPrinterText(category.printer)}</TableCell>
        </>
    }

    renderEditingSequenceMenu = () => {
        return <Menu
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.clearAnchorForMenu}
            anchorReference="anchorPosition"
            anchorPosition={this.state.anchorEl !== null
                ? { top: this.state.anchorEl.top, left: this.state.anchorEl.left }
                : undefined
            }
        >
            <MenuItem onClick={this.moveToTheTop}>Mozgatás az elejére</MenuItem>
            <MenuItem onClick={this.moveToTheBottom}>Mozgatás a végére</MenuItem>
        </Menu>
    }

    renderPagination = () => {
        return this.state.isEditingSequence
            ? ''
            :<TablePagination
                labelRowsPerPage="Elemek az oldalon:"
                rowsPerPageOptions={RowsPerPageOptions.items}
                component="div"
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Szűrés szülő kategóriák alapján">
                        <Collapse in={this.state.notSupportedSelection} style={{marginBottom: 8}}>
                            <Alert severity="info" onClose={this.closeNotSupportedSelection}>Sorrend szerkesztés közben a szűrés nem lehetséges.</Alert>
                        </Collapse>
                        <Autocomplete
                            onChange={this.handleCategoryChange}
                            name="parentCategoryFilter"
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.categoryOptions}
                            getOptionLabel={(option) => option === "" ? "" : option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            value={this.state.selectedParentCategoryOption}
                            renderInput={(params) =>
                                <TextField {...params} label="Szülő kategória" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a névben">
                        <Collapse in={this.state.notSupportedSearch} style={{marginBottom: 8}}>
                            <Alert severity="info" onClose={this.closeNotSupportedSearch}>Sorrend szerkesztés közben a keresés nem lehetséges.</Alert>
                        </Collapse>
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>

                <Paper>
                    {this.renderToolbar()}
                    {this.renderEditSequenceBar()}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Kép</TableCell>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell>Leírás</TableCell>
                                    <TableCell align='center'>Tulajdonságok</TableCell>
                                    <TableCell align='center'>Szülő kategória</TableCell>
                                    <TableCell align='center'>Nyomtató</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            {this.state.isEditingSequence
                                ? this.state.isLoading
                                    ? ''
                                    : this.renderEditingSequenceItems()
                                : this.renderCategoryListItems()
                            }
                        </Table>
                        {this.renderPagination()}
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    };

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                   <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Kategória"
                onClick={this.initiateCreate}/>
        </>;
    }
}
