import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Accordion, AccordionDetails, AccordionSummary,
    CircularProgress, FormControlLabel, TextField,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import NoData from "../../../components/NoData";

export default class VisibilityListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            categoryMap: {},
            productMap: {},
            error: null,
            isLoading: false,
            expandedCategoryId: null,
            changingCategoryId: null,
            changingProductId: null,
            keyword: "",
            isSearching: false,
        };
        this.mainCategoryId = -1
        this.searchCategoryId = -2
        this.searchTimeout = 0
    }

    componentDidMount() {
        this.setLoading(true);
        const queryParams = { parentCategoryId: this.mainCategoryId , sort: "name"}

        axios.all([
            axiosCommon.get(`${BASE_URL}/api/categories?`, { params: queryParams }),
            axiosCommon.get(`${BASE_URL}/api/shops/basic`)])
        .then((response) => {
            const categoryMap = {}
            const productMap = {}
            categoryMap[this.mainCategoryId] = response[0].data.items
            productMap[this.mainCategoryId] = []

            const shopOptions = response[1].data.items.map(c => {
                return {name: c.name, id: c.id}
            });
            const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;

            this.setState({ categoryMap, productMap, shopOptions, selectedShopOption })
        })
        .catch(error => this.setState({error}))
        .finally(() => this.setLoading(false))
    }


    refreshData = () => {
        this.setLoading(true);

        const categoryQuery = { parentCategoryId: this.state.expandedCategoryId, deleted: false }
        const productQuery = { categoryIds: this.state.expandedCategoryId, deleted: false, sort: "name" }

        if(this.state.keyword.length > 0) {
            categoryQuery.parentCategoryId = undefined
            categoryQuery.keyword = this.state.keyword
            productQuery.parentCategoryId = undefined
            productQuery.keyword = this.state.keyword
        }

        axios.all([
            axiosCommon.get(`${BASE_URL}/api/categories?`, { params: categoryQuery }),
            axiosCommon.get(`${BASE_URL}/api/products?`, { params: productQuery })])
        .then((response) => {
            const {categoryMap, productMap} = this.state
            if(this.state.keyword.length > 0){
                categoryMap[this.searchCategoryId] = response[0].data.items
                productMap[this.searchCategoryId] = response[1].data.items
            }else {
                categoryMap[this.state.expandedCategoryId] = response[0].data.items
                productMap[this.state.expandedCategoryId] = response[1].data.items
            }
            this.setState({ categoryMap, productMap })
        })
        .catch(error => {this.setState({error})})
        .finally(() => this.setLoading(false))
    };

    setLoading = (flag) => {
        this.setState({ isLoading: flag, })
    };

    onChangeShopFilter = (event, value) => {
        this.setState({ selectedShopOption: value, })
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value })
        if(this.searchTimeout) clearTimeout(this.searchTimeout)

        this.searchTimeout = setTimeout(() => {
            const {categoryMap, productMap } = this.state
            const isSearching = this.state.keyword.length > 0
            delete categoryMap[this.searchCategoryId]
            delete productMap[this.searchCategoryId]

            this.setState({ categoryMap, productMap, isSearching }, () => isSearching ? this.refreshData() : undefined)
            },350)
    };

    handleChange = (id) => (event, isExpanded) => {
        if(isExpanded) {
            const needRefresh = this.state.categoryMap[id] === undefined || this.state.productMap[id] === undefined
            this.setState({expandedCategoryId: id}, () => { if(needRefresh) this.refreshData() })
        }
    };

    handleCategoryVisibilityChange = (parentId, id) => (event) => {
        this.setState({ isLoading: true, changingCategoryId: id })
        const data = {first: id, second: event.target.checked}
        const params = { shopId: this.state.selectedShopOption.id }

        axiosCommon.put(`${BASE_URL}/api/categories/visibility`, data, { params })
        .then(response => {
            const {categoryMap} = this.state
            categoryMap[parentId].forEach(item => { if(item.id == id) item.disabledInShops = response.data.disabledInShops})

            if(this.state.keyword.length > 0) {
                // save change to the normal location
                const parentCategoryId = response.data.parentCategoryId || this.mainCategoryId
                if(categoryMap[parentCategoryId] != undefined)
                    categoryMap[parentCategoryId].forEach(item => { if(item.id == id) item.disabledInShops = response.data.disabledInShops})
            }

            this.setState({categoryMap})
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ isLoading: false, changingCategoryId: null }))
    }

    handleProductVisibilityChange = (parentId, id) => (event) => {
        this.setState({ isLoading: true, changingProductId: id })
        const data = {first: id, second: event.target.checked}
        const params = { shopId: this.state.selectedShopOption.id }

        axiosCommon.put(`${BASE_URL}/api/products/visibility`, data, { params })
        .then(response => {
            const {productMap} = this.state
            productMap[parentId].forEach(item => { if(item.id == id) item.disabledInShops = response.data.disabledInShops})

            if(this.state.keyword.length > 0) {
                // save change to the normal location
                const parentCategoryId = response.data.category?.id || this.mainCategoryId
                if(productMap[parentCategoryId] != undefined)
                    productMap[parentCategoryId].forEach(item => { if(item.id == id) item.disabledInShops = response.data.disabledInShops})
            }

            this.setState({productMap})
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ isLoading: false, changingProductId: null }))
    }

    renderItems = (id) => {
        const {isSearching} = this.state
        const loading = this.state.categoryMap[id] === undefined || this.state.productMap[id] === undefined
        const categories = this.state.categoryMap[id] || []
        const products = this.state.productMap[id] || []

        if(this.state.isLoading == true && loading == true) return <div style={{textAlign: "center", paddingTop: "20px"}}> <CircularProgress /> </div>
        if(categories.length == 0 && products.length == 0) return <NoData/>

        return <>
            {categories.map((item, i) => {
                return <Accordion key={i} TransitionProps={{ mountOnEnter: true }} onChange={this.handleChange(item.id)} style={{ boxShadow: '0 0 0 1px rgba(0, 0, 0, .125) inset',}}>
                    <AccordionSummary
                        expandIcon={isSearching ? undefined : <ExpandMoreIcon/>}
                        onClickCapture={(event) => isSearching ? event.stopPropagation() : undefined}
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)',}}
                    >
                        <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={ this.state.isLoading == true && this.state.changingCategoryId == item.id
                                ? <CircularProgress style={{ margin:6, height: 30, width: 30 }}/>
                                : <Checkbox
                                    checked={!item.disabledInShops.includes(this.state.selectedShopOption.id.toString() || "-1")}
                                    onChange={this.handleCategoryVisibilityChange(id, item.id)}
                                    color="primary" />
                            }
                            label={
                                <Tooltip title="Ez egy kategória, a láthatóság megváltoztatása az alá tartozó elemekre is hatással lesz.">
                                    <div className="flex">
                                        <AccountTreeIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                                        <Typography style={{marginLeft: 8}}>{item.name}</Typography>
                                    </div>
                                </Tooltip>
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails className="flex-column">
                        {this.renderItems(item.id)}
                    </AccordionDetails>
                </Accordion>})
            }
            {products.map((item, i) => {
                return <Accordion key={i} TransitionProps={{ mountOnEnter: true }} expanded={false} style={{ boxShadow: '0 0 0 1px rgba(0, 0, 0, .125) inset', }}>
                    <AccordionSummary>
                        <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={ this.state.isLoading == true && this.state.changingProductId == item.id
                                ? <CircularProgress style={{ margin:6, height: 30, width: 30 }}/>
                                : <Checkbox
                                    checked={!item.disabledInShops.includes(this.state.selectedShopOption.id.toString() || "-1")}
                                    onChange={this.handleProductVisibilityChange(id, item.id)}
                                    color="primary" />
                            }
                            label={item.name}
                        />
                    </AccordionSummary>
                </Accordion>})
            }
        </>
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Üzlet kiválasztása">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Keresés a névben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                </Widget>

                <Toolbar className="align-center justify-space-between">
                    <Typography variant="h5" id="tableTitle" component="div" >
                        Láthatóság
                    </Typography>
                </Toolbar>
                {this.state.selectedShopOption == null
                    ? this.state.isLoading == true
                        ?<Card>
                            <div style={{textAlign: "center", paddingTop: "20px"}}> <CircularProgress /> </div>
                        </Card>
                        :<Card>
                            <NoData/>
                        </Card>

                    : this.state.isSearching === true
                        ? this.renderItems(this.searchCategoryId)
                        : this.renderItems(this.mainCategoryId)
                }
            </>
    };

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                   <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
