import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Button,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, FormGroup,
    IconButton,
    MenuItem,
    TextField,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "../../../components/Card";
import SellingUnits from "../../../util/SellingUnits";
import DeleteIcon from "@material-ui/icons/Delete";
import ColorPickerBox from "../../../components/ColorPickerBox";
import {DropzoneArea} from "material-ui-dropzone";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import VATValues from "../../../util/VATValues";
import { BottleProperties, BottleSizes } from "../../../util/BottleProperties";
import {
    ntakFokategoria,
    ntakMennyisegiEgyseg,
    ntakEtelAlketegoria,
    ntakAlkoholmentesItalHelybenAlketegoria,
    ntakAlkoholmentesItalNemHelybenAlketegoria,
    ntakAlkoholosItalAlketegoria,
    ntakDataSending, ntakEgyebAlketegoria, ntakNincsFokategoria
} from "../../../util/NTAK";
import WidgetAutoColumn from "../../../components/WidgetAutoColumn";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export default class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryOptions: [],
            selectedCategoryOption: null,
            selectedBottleProperty: null,
            products: [],
            ingredientProducts: [],
            productToppings: [],
            hasProductToppings: false,
            toppings: [],
            productOptions: [],
            isSubmitInProgress: false,
            hasScaleValues: false,
            showBottleDatabaseDialog: false,
            availableShops: [],
            showSpecialPriceDialog: false,
            selectedOtherPriceToAdd: null,
            product: {
                name: null,
                categoryId: null,
                description: null,
                defaultEanCode: "",
                acquisitionPrice: null,
                defaultSellingPrice: null,
                otherSellingPrice: {},
                defaultDesiredStockAmount: null,
                showInTerminal: true,
                hasInventory: true,
                hasBottleDeposit: false,
                isLateAmountSet: false,
                disabledInShops: [],
                vat: 27,
                takeAwayVat: 27,
                sellingUnit: "DB",
                emptyBottleWeight: null,
                fullBottleWeight: null,
                bottleSize: null,
                ntakFokategoria: null,
                ntakAlkategoria: null,
                ntakMennyisegiEgyseg: null,
                ntakMennyiseg: null,
            }
        };
    }

    componentDidMount() {
        if (this.props.location.productId) {
            axiosCommon.get(`${BASE_URL}/api/products/${this.props.location.productId}`)
                .then(response => {
                    const product = response.data
                    const selectedCategoryOption = { label: product.category.name, value: product.category.id }
                    const ingredientProducts =  product.recipeProductIngredients.map(i => {
                        return { product: i.ingredientProduct, quantity: i.quantity }
                    })
                    const productToppings = product.productToppings.map(t => {
                        return { value: t.topping.id, label: t.topping.name, min: t.min, max: t.max }
                    })
                    const hasProductToppings = productToppings.length > 0

                    const hasScaleValues = product.emptyBottleWeight != null && product.fullBottleWeight != null && product.bottleSize != null
                    this.setState({
                        product,
                        selectedCategoryOption,
                        ingredientProducts,
                        hasScaleValues,
                        productToppings,
                        hasProductToppings
                    })
                })
                .catch(error => this.setState({error}))
        }

        axiosCommon.get(`${BASE_URL}/api/products?deleted=false`)
            .then(response => {
                const productOptions = response.data.items.map(p => {
                    return {label: p.name, value: p.id}
                })
                this.setState({
                    products: response.data.items,
                    productOptions
                })
            }).catch(error => {
            this.setState({error});
        });


        axiosCommon.get(`${BASE_URL}/api/categories`)
            .then(response => {
                const categoryOptions = response.data.items.map(c => {
                    return {label: c.name, value: c.id}
                });
                this.setState({
                    categoryOptions
                })
            })
            .catch(error => {
                this.setState({error});
            });

        axiosCommon.get(`${BASE_URL}/api/toppings`)
        .then(response => {
            const toppings = response.data.items.map(t => {
                return { value: t.id, label: t.name,}
            })
            this.setState({ toppings })
        })
        .catch(error => this.setState({ error }))

        axiosCommon.get(`${BASE_URL}/api/shops`)
        .then(response => {
            const availableShops = response.data.items.map(c => {
                return {label: c.name, id: c.id}
            })
            this.setState({ availableShops })
        }).catch(error => {this.setState({error})})
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});
        const {product} = this.state;

        if (this.state.selectedCategoryOption === null) {
            product.categoryId = undefined;
        } else {
            product.categoryId = this.state.selectedCategoryOption.value;
        }

        if (this.state.encodedImage) {
            product.imageData = this.state.encodedImage;
            product.imageContentType = this.state.imageType;
        }

        if (product.isRecipeProduct) {
            if(this.state.ingredientProducts.length < 1){
                this.setState({
                    error: "Legalább egy összetevőt meg kell adni a receptúra terméknél",
                    isSubmitInProgress: false
                })
                return null;
            }

            const wrongNumbers = this.state.ingredientProducts.filter(e => !String(e.quantity).match(/^[0-9]+([.,][0-9]+)?$/));
            if (wrongNumbers.length > 0) {
                this.setState({
                    error: "Érvénytelen alapanyag mennyiség(ek)",
                    isSubmitInProgress: false
                })
                return null;
            }
            product.recipeProductIngredients = this.state.ingredientProducts.map(e => {
                return {
                    ingredientProductId: e.product.id,
                    quantity: String(e.quantity).replace(",", ".")
                }
            })
            product.acquisitionPrice = 0;
        } else {
            product.recipeProductIngredients = [];
        }

        if(this.state.hasProductToppings == true && this.state.productToppings.length > 0){
            const {productToppings} = this.state
            for (let i = 0; i < productToppings.length; i++) {
                if(productToppings[i].min > productToppings[i].max){
                    this.setState({error: `${productToppings[i].label} feltét csoport minimum mennyisége nem lehet nagyobb a maximum mennyiségnél.`, isSubmitInProgress: false})
                    return null;
                }
            }
            product.productToppings = productToppings.map((e, i) => {
                return { sequence: i, toppingId: e.value, min: e.min, max: e.max}
            })
        } else {
            product.productToppings = []
        }

        if(!product.hasInventory){
            product.defaultDesiredStockAmount = null
        }

        if(!this.isScaleOptionEnabled() || !this.state.hasScaleValues || !product.hasInventory){
            product.emptyBottleWeight = null
            product.fullBottleWeight = null
            product.bottleSize = null
        }

        Object.entries(product.otherSellingPrice).forEach(entry => {
            if(entry[1] === "") delete product.otherSellingPrice[entry[0]]
            if(entry[0].includes('SHOP_ID_')){
                const key = entry[0].replace('SHOP_ID_', '')
                const value = entry[1]
                delete product.otherSellingPrice[entry[0]]
                product.otherSellingPrice[key] = value
            }
        })

        const queryParams = {}
        if (this.state.deleteImage) {
            queryParams.deleteImage = true
        }
        axiosCommon({
            method: this.props.location.productId ? "put" : "post",
            url: `${BASE_URL}/api/products`,
            data: product,
            params: queryParams
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false})
            });
    };

    onSelectImage = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    encodedImage: btoa(reader.result),
                    imageType: loadedFiles[0].type,
                    deleteImage: false
                })
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteImage = () => {
        const {product} = this.state
        delete product.imageId
        this.setState({
            product,
            deleteImage: true
        })
    }

    productForId = (id) => {
        return this.state.products.filter(p => p.id == id)[0];
    }

    remainingProductOptions = () => {
        return this.state.productOptions.filter(p => !this.state.ingredientProducts.find(i => i.product.id == p.value) && this.state.product.id != p.value)
    }

    remainingToppings = () => {
        return this.state.toppings.filter(p => !this.state.productToppings.find(i => i.value == p.value))
    }

    calculateAcquisitionPrice = () => {
        const price = (this.state.ingredientProducts || [])
            .map(i => this.acquisitionPriceOfProduct(i.product) * (i.quantity || 0))
            .reduce((a, b) => a + b, 0);
        return +price.toFixed(3);
    }

    acquisitionPriceOfProduct = (p) =>
        p?.isRecipeProduct ? p.recipeProductIngredients
            .map(i => this.acquisitionPriceOfProduct(i.ingredientProduct) * (i.quantity || 0))
            .reduce((a, b) => a + b, 0) : (p?.acquisitionPrice || 0);

    getSellingUnitOption = () => {
        return SellingUnits.filter(o => o.value === this.state.product?.sellingUnit)[0];
    }

    handleChange = (event) => {
        const { product } = this.state;
        if(event.target.name === 'ntakFokategoria' && product.ntakFokategoria !== event.target.value) product.ntakAlkategoria = null
        product[event.target.name] = event.target.value;
        this.setState({ product });
    }

    handleOtherPriceChange = (event) => {
        const { otherSellingPrice } = this.state.product;
        otherSellingPrice[event.target.name] = event.target.value
        this.setState({ otherSellingPrice });
    }

    handleCategoryChange = (event, value) => {
        this.setState({ selectedCategoryOption: value });
    }

    handleSellingUnitChange = (event, value) => {
        const { product } = this.state;
        product.sellingUnit = value.value;
        this.setState({ product });
    }

    handleBottleSizeChange = (event, value) => {
        const { product } = this.state;
        product.bottleSize = value.value;
        this.setState({ product });
    }

    onChangeColor = (value) => {
        const { product } = this.state;
        product.colorCode = value.hex ? `#${value.hex}` : null;
        this.setState({ product });
    }

    onClearColor = () => {
        const { product } = this.state;
        product.colorCode =  null;
        this.setState({ product });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    isScaleOptionEnabled = () => {
        return this.state.product.sellingUnit === "L" || this.state.product.sellingUnit === "DL" ||
            this.state.product.sellingUnit === "CL" || this.state.product.sellingUnit === "ML"
    }

    getNTAKAlkategoria = () => {
        switch(this.state.product?.ntakFokategoria){
            case "ETEL":
                return ntakEtelAlketegoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
            case "ALKMENTESITAL_HELYBEN":
                return ntakAlkoholmentesItalHelybenAlketegoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
            case "ALKMENTESITAL_NEM_HELYBEN":
                return ntakAlkoholmentesItalNemHelybenAlketegoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
            case "ALKOHOLOSITAL":
                return ntakAlkoholosItalAlketegoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
            case "EGYEB":
                return ntakEgyebAlketegoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
            default:
                return ntakNincsFokategoria.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
        }
    }

    renderBottleDatabaseDialog = () => {
        return <Dialog
            open={this.state.showBottleDatabaseDialog === true}
            onClose={() => this.setState({showBottleDatabaseDialog: false})}
        >
            <DialogTitle id="alert-dialog-title">Üveg adatbázis</DialogTitle>
            <DialogContent>
                <DialogContentText style={{marginBottom: 20}}>
                    Válaszd ki az adatbázisból a megfelelő üveget és a rendszer beállítja a hozzá tartozó súly értékeket.
                    Az adatbázisban szereplő adatok pontossága helyenként akár 15%-ban is eltérhet a valóságtól.
                    Ha ennél pontosabb számításokra van szükséged, akkor kérlek végezd el az üveg súlyadatainak mérését manuális.
                </DialogContentText>
                <Autocomplete
                    onChange={(event, value) =>
                        this.setState({ selectedBottleProperty: value })
                    }
                    name="bottleSize"
                    fullWidth
                    disableClearable
                    noOptionsText="Nincs ilyen tétel"
                    options={BottleProperties}
                    getOptionLabel={(option) => option === "" ? "" : option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={this.state.selectedBottleProperty || ""}
                    renderInput={(params) =>
                        <TextField {...params} label="Alkohol fajtája" variant="outlined" />
                    }
                />
            </DialogContent>
            <DialogActions style={{marginTop: 20}}>
                <Button onClick={() => this.setState({showBottleDatabaseDialog: false})} color="primary">Mégse</Button>
                <Button onClick={() => {
                    const { product } = this.state
                    product.emptyBottleWeight = this.state.selectedBottleProperty.empty
                    product.fullBottleWeight = this.state.selectedBottleProperty.full
                    product.bottleSize = this.state.selectedBottleProperty.size
                    this.setState({
                        product,
                        showBottleDatabaseDialog: false,
                        selectedBottleProperty: null})
                }} color="primary">Beállít</Button>
            </DialogActions>

        </Dialog>;
    };

    getSpecificPriceName = (id) => {
        if(id === "foodora") return "Eladási ár (Bruttó) - Foodora"
        if(id === "wolt") return "Eladási ár (Bruttó) - Wolt"
        if(id === "falatozz.hu") return "Eladási ár (Bruttó) - Falatozz.hu"
        const shopList = this.state.availableShops.filter(shop => shop.id.toString() === id || `SHOP_ID_${shop.id}` === id)
        if(shopList.length == 0) return "Eladási ár (Bruttó) - "
        return `Eladási ár (Bruttó) - ${shopList[0].label}`
    }

    renderOtherPrice = () => {
        return <>
            <div className="flex">
                <Typography style={{fontWeight: 500, marginBottom: 16, paddingLeft: 8, marginRight: 8}}>Specifikus árak</Typography>
                <Tooltip title="A specifikus árak bizonyos esetekben felülírják az általános árakat. Specifikus árat tudunk felvenni üzletekhez, illetve
                    kiszállítási módokhoz.">
                    <InfoIcon style={{color: "gray"}}/>
                </Tooltip>
            </div>

            <WidgetAutoColumn>
                {Object.entries(this.state.product.otherSellingPrice).map((entry, i) =>
                    <div className="flex" key={i}>
                        <TextValidator
                            label={this.getSpecificPriceName(entry[0])}
                            variant="outlined"
                            fullWidth
                            key={i}
                            onChange={this.handleOtherPriceChange}
                            name={entry[0]}
                            value={entry[1] == null ? "" : entry[1]}
                            inputProps={{ maxLength: 8 }}
                            required
                            validators={['required','isNumber','minNumber:0']}
                            errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                        />
                        <IconButton onClick={() => {
                            const {otherSellingPrice} = this.state.product
                            delete otherSellingPrice[entry[0]]
                            this.setState({otherSellingPrice})
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
                {Object.entries(this.state.product.otherSellingPrice).length % 2 > 0
                    ? <div/>
                    : undefined
                }
            </WidgetAutoColumn>
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ showSpecialPriceDialog : true , selectedOtherPriceToAdd: null})}
                style={{color: 'white'}}>Ár hozzáadása</Button>
        </>
    }

    getPossibleOtherPrices = () => {
        const otherPrices = [
            {label: "Wolt", value: "wolt"},
            {label: "Foodora", value: "foodora"},
            {label: "Falatozz.hu", value: "falatozz.hu"},
        ]

        // use SHOP_ID_ prefix to preserve the orther of insertion
        this.state.availableShops.forEach(shop => otherPrices.push({label: shop.label, value: `SHOP_ID_${shop.id}`}))

        let result = otherPrices
        Object.entries(this.state.product.otherSellingPrice).forEach(entry => {
            result = result.filter(item => item.value !== entry[0] && item.value !== `SHOP_ID_${entry[0]}`)
        })

        return result
    }

    renderAddOtherPriceDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.showSpecialPriceDialog}
            onClose={() => this.setState({ showSpecialPriceDialog : false })}
        >
            <DialogTitle>További ár hozzáadása</DialogTitle>
            <DialogContent>
                <Typography style={{marginBottom: 16}}>Válasszd ki a listából, hogy milyen árat szeretnél hozzáadni.</Typography>
                <Autocomplete
                    onChange={(event, value) => this.setState({selectedOtherPriceToAdd: value})}
                    fullWidth
                    disableClearable
                    noOptionsText="Nincs ilyen tétel"
                    options={this.getPossibleOtherPrices()}
                    getOptionLabel={(option) => option === "" ? "" : option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    value={this.state.selectedOtherPriceToAdd || ""}
                    renderInput={(params) =>
                        <TextField {...params} label="Ár típus" required variant="outlined" />
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        const {otherSellingPrice} = this.state.product
                        otherSellingPrice[this.state.selectedOtherPriceToAdd.value] = ""
                        this.setState({otherSellingPrice, showSpecialPriceDialog : false})
                    }}
                    color="primary"
                >
                    Hozzáad
                </Button>
                <Button onClick={() => this.setState({ showSpecialPriceDialog : false })} color="primary">
                    Mégse
                </Button>
            </DialogActions>
        </Dialog>
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.productId ? "Termék szerkesztése" : "Új termék"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.product?.name || ""}
                                required
                                validators={['required']}
                                inputProps={{ maxLength: 70 }}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleCategoryChange}
                                name="categoryId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.categoryOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedCategoryOption}
                                renderInput={(params) =>
                                    <TextField {...params} label="Kategória" required variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleSellingUnitChange}
                                name="sellingUnit"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={SellingUnits}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.getSellingUnitOption()}
                                renderInput={(params) =>
                                    <TextField {...params} label="Mértékegység" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Cikkszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="defaultEanCode"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.product?.defaultEanCode || ""}
                            />
                        </div>
                    </Widget>
                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.product?.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>
                <div className="m-8"/>
                <Card title="Láthatóság üzletenként">
                    <FormControl component="fieldset">
                        <FormGroup>
                            {Object.values(this.state.availableShops).map((shop, i) =>
                                <div key={i} style={{display: "flex", alignItems: 'center',}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={i}
                                                checked={!this.state.product.disabledInShops.includes(shop.id.toString())}
                                                onChange={(event) => {
                                                    const { product } = this.state;
                                                    if(event.target.checked) product.disabledInShops = product.disabledInShops.filter(item => item !== shop.id.toString())
                                                    else product.disabledInShops.push(shop.id.toString())
                                                    this.setState({ product });
                                                }}
                                                color="primary"
                                                name={shop.label} />}
                                        label={shop.label}
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </FormControl>
                </Card>
                <div className="m-8"/>
                <Card title="Termék tulajdonságok">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.product?.isRecipeProduct || false}
                                    onChange={(event) => {
                                        const { product } = this.state;
                                        product.isRecipeProduct = event.target.checked;
                                        this.setState({ product });
                                    }}
                                    color="primary"
                                    name="isReceiptProduct" />}
                            label="Receptúra termék"
                        />
                        <FormControlLabel
                          control={
                              <Checkbox
                                checked={this.state.product?.hasInventory || false}
                                onChange={(event) => {
                                    const { product } = this.state;
                                    product.hasInventory = event.target.checked;
                                    this.setState({ product });
                                }}
                                color="primary"
                                name="hasInventory" />}
                          label="Rendelkezik raktárkészlettel"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.hasProductToppings}
                                    onChange={(event) => {
                                        this.setState({ hasProductToppings:  event.target.checked});
                                    }}
                                    color="primary"
                                    name="hasInventory" />}
                            label="Feltétek használata"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.product.isLateAmountSet || false}
                                    onChange={(event) => {
                                        const { product } = this.state;
                                        product.isLateAmountSet = event.target.checked;
                                        this.setState({ product });
                                    }}
                                    color="primary"
                                    name="isLateAmountSet" />}
                            label="Mennyiség megadása az elkészítés után"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.product.hasBottleDeposit || false}
                                    onChange={(event) => {
                                        const { product } = this.state;
                                        product.hasBottleDeposit = event.target.checked;
                                        this.setState({ product });
                                    }}
                                    color="primary"
                                    name="hasBottleDeposit" />}
                            label="A termékhez tartozik betétdíj (+50Ft)"
                        />
                    </FormGroup>
                </Card>
                <div className="m-8"/>
                {this.state.product?.isRecipeProduct === true
                  ?<>
                      <Card title="Receptúra összeállítás">
                      {this.state.ingredientProducts.length < 1 &&
                        <section style={{ textAlign: "center", color: "red" }}>
                            Legalább egy összetevőt meg kell adni
                        </section>
                      }
                      {this.state.ingredientProducts.map((e, i) =>
                        <Widget key={i}>
                            <div className="flex-1">
                                <Autocomplete
                                  onChange={(event, value) => {
                                      const { ingredientProducts } = this.state;
                                      ingredientProducts[i].product = this.productForId(value.value)
                                      this.setState({
                                          ingredientProducts
                                      })
                                  }}
                                  fullWidth
                                  disableClearable
                                  noOptionsText="Nincs ilyen tétel"
                                  options={this.remainingProductOptions()}
                                  getOptionLabel={(option) => option === "" ? "" : option.label}
                                  getOptionSelected={(option, value) => option.value === value.value}
                                  value={{ label: e.product.name, value: e.product.id }}
                                  renderInput={(params) =>
                                    <TextField {...params} label={`Alapanyag termék ${i + 1}`} variant="outlined" />
                                  }
                                />
                            </div>
                            <div className="m-8" />
                            <div className="flex-1">
                                <TextValidator
                                  label={`Mennyiség [${e.product.sellingUnit.toLowerCase()}]:`}
                                  variant="outlined"
                                  fullWidth
                                  name={this.props.name}
                                  onChange={(event) => {
                                      if (!String(event.target.value).match(/^[0-9]*([.,]?[0-9]*)?$/)) {
                                          return
                                      }

                                      const { ingredientProducts } = this.state;
                                      ingredientProducts[i].quantity = String(event.target.value).replace(",", ".")
                                      this.setState({
                                          ingredientProducts
                                      })
                                  }}
                                  value={this.state.ingredientProducts[i].quantity || ""}
                                  required
                                  validators={['required', 'isFloat', 'minFloat:0.0001']}
                                  errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0.0001-nál']}
                                />
                            </div>
                            <div className="m-8" />
                            <IconButton onClick={() => {
                                const { ingredientProducts } = this.state;
                                ingredientProducts.splice(i, 1);
                                this.setState({
                                    ingredientProducts
                                })
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Widget>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        disabled={this.remainingProductOptions().length <= 0}
                        onClick={() => {
                            const { ingredientProducts } = this.state;
                            ingredientProducts.push({
                                product: this.productForId(this.remainingProductOptions()[0].value),
                                amount: 0
                            })
                            this.setState({
                                ingredientProducts
                            })
                        }}>
                          Új alapanyag
                      </Button>
                    </Card>
                      <div className="m-8"/>
                  </>
                  : undefined
                }
                {this.state.product?.hasInventory === true
                    ?<>
                      <Card title="Raktárkészlet kezelés">
                          <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Minimum készletérték"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => {
                                            const { product } = this.state;
                                            product[event.target.name] = event.target.value.replace(",", ".");
                                            this.setState({ product });
                                        }}
                                        name="defaultDesiredStockAmount"
                                        value={this.state.product.defaultDesiredStockAmount === null
                                            ? ""
                                            : this.state.product.defaultDesiredStockAmount}
                                        inputProps={{ maxLength: 8 }}
                                        validators={['isFloat','minFloat:0']}
                                        errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                                    />
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1"/>
                          </Widget>
                          {this.isScaleOptionEnabled()
                              ?<FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={this.state.hasScaleValues}
                                          onChange={(event) => {
                                              const hasScaleValues = !this.state.hasScaleValues
                                              this.setState({ hasScaleValues });
                                          }}
                                          color="primary"
                                          name="hasScaleValues" />}
                                  label="Mérleges standolás üveg esetén"
                              />
                              : undefined
                          }
                          {this.state.hasScaleValues == true && this.isScaleOptionEnabled()
                              ?<>
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      style={{color: 'white', marginLeft: 20,}}
                                      onClick={() => this.setState({showBottleDatabaseDialog: true})}>
                                      Üveg adatbázis használata
                                  </Button>
                                  <Widget>
                                      <div className="flex-1">
                                          <TextValidator
                                              label="Üres üveg súlya (gramm)"
                                              variant="outlined"
                                              fullWidth
                                              onChange={this.handleChange}
                                              name="emptyBottleWeight"
                                              value={this.state.product?.emptyBottleWeight || ""}
                                              required={this.state.hasScaleValues}
                                              validators={['isNumber','minNumber:0']}
                                              errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                                          />
                                      </div>
                                      <div className="m-8"/>
                                      <div className="flex-1">
                                          <TextValidator
                                              label="Teli üveg súlya (gramm)"
                                              variant="outlined"
                                              fullWidth
                                              onChange={this.handleChange}
                                              name="fullBottleWeight"
                                              value={this.state.product?.fullBottleWeight || ""}
                                              required={this.state.hasScaleValues}
                                              validators={['isNumber','minNumber:0']}
                                              errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                                          />
                                      </div>
                                  </Widget>
                                  <Widget>
                                      <div className="flex-1">
                                          <Autocomplete
                                              onChange={this.handleBottleSizeChange}
                                              name="bottleSize"
                                              fullWidth
                                              disableClearable
                                              noOptionsText="Nincs ilyen tétel"
                                              options={BottleSizes}
                                              getOptionLabel={(option) => option === "" ? "" : option.label}
                                              getOptionSelected={(option, value) => option.value === value.value}
                                              value={BottleSizes.filter((size) => size.value == this.state.product?.bottleSize)[0] || ""}
                                              renderInput={(params) =>
                                                  <TextField {...params}
                                                         label="Üveg mérete"
                                                         variant="outlined"
                                                         required={this.state.hasScaleValues}
                                                  />
                                              }
                                          />
                                      </div>
                                      <div className="m-8"/>
                                      <div className="flex-1"/>
                                  </Widget>
                              </>
                              : undefined
                          }

                      </Card>
                      <div className="m-8"/>
                    </>
                    : undefined
                }

                {this.state.hasProductToppings === true
                    ?<>
                        <Card title="Feltét kezelés">
                            {this.state.productToppings.map((e, i) =>
                                <Widget key={i}>
                                    <div className="flex-1">
                                        <Autocomplete
                                            onChange={(event, value) => {
                                                const { productToppings } = this.state;
                                                productToppings[i].value = value.value
                                                productToppings[i].label = value.label
                                                this.setState({ productToppings })
                                            }}
                                            fullWidth
                                            disableClearable
                                            noOptionsText="Nincs ilyen tétel"
                                            options={this.remainingToppings()}
                                            getOptionLabel={(option) => option === "" ? "" : option.label}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            value={{ label: e.label, value: e.value }}
                                            renderInput={(params) =>
                                                <TextField {...params} label={`Feltét csoport ${i + 1}`} variant="outlined" />
                                            }
                                        />
                                    </div>
                                    <div className="m-8" />
                                    <div className="flex-1">
                                        <TextValidator
                                            label="Minimum választás"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(event) => {
                                                const { productToppings } = this.state;
                                                productToppings[i].min = event.target.value.replace(",", ".")
                                                this.setState({ productToppings })
                                            }}
                                            name="minChooseFomTopping"
                                            value={e.min != null ? e.min : ""}
                                            inputProps={{ maxLength: 8 }}
                                            required
                                            validators={['required', 'isNumber', 'isPositive', 'maxNumber:12']}
                                            errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'A megadott érték negatív', 'A megadott érték nem lehet nagyobb 12-nél']}
                                        />
                                    </div>
                                    <div className="m-8" />
                                    <div className="flex-1">
                                        <TextValidator
                                            label="Maximum választás"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(event) => {
                                                const { productToppings } = this.state;
                                                productToppings[i].max = event.target.value.replace(",", ".")
                                                this.setState({ productToppings })
                                            }}
                                            name="maxChooseFomTopping"
                                            value={e.max != null ? e.max : ""}
                                            inputProps={{ maxLength: 8 }}
                                            required
                                            validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:12']}
                                            errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'A megadott érték nem lehet kisebb 1-nél.', 'A megadott érték nem lehet nagyobb 12-nél']}
                                        />
                                    </div>
                                    <div className="m-8" />
                                    <IconButton onClick={() => {
                                        const { productToppings } = this.state;
                                        productToppings.splice(i, 1);
                                        this.setState({ productToppings })
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Widget>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ color: 'white' }}
                                disabled={this.remainingToppings().length <= 0}
                                onClick={() => {
                                    const { productToppings } = this.state;
                                    const topping = this.remainingToppings()[0]
                                    productToppings.push({ value: topping.value, label: topping.label, min: 0, max: 1 })
                                    this.setState({ productToppings })
                                }}>
                                Új feltét csoport
                            </Button>
                        </Card>
                        <div className="m-8"/>
                    </>
                    : undefined
                }

                {ntakDataSending === true
                    ?<>
                        <Card title="NTAK Adatok">
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        select
                                        label="Főkategória"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        name="ntakFokategoria"
                                        fullWidth
                                        value={this.state.product?.ntakFokategoria || ""}
                                    >
                                        {
                                            ntakFokategoria.map(value => <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>)
                                        }
                                    </TextValidator>
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1">
                                    <TextValidator
                                        select
                                        label="Alkategória"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        name="ntakAlkategoria"
                                        fullWidth
                                        value={this.state.product?.ntakAlkategoria || ""}
                                    >
                                        {this.getNTAKAlkategoria()}
                                    </TextValidator>
                                </div>
                            </Widget>
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        select
                                        label="Mennyiségi egység"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        name="ntakMennyisegiEgyseg"
                                        fullWidth
                                        value={this.state.product?.ntakMennyisegiEgyseg || ""}
                                    >
                                        {
                                            ntakMennyisegiEgyseg.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
                                        }
                                    </TextValidator>
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Mennyiség"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(event) => {
                                            const { product } = this.state;
                                            product[event.target.name] = event.target.value.replace(",", ".");
                                            this.setState({ product });
                                        }}
                                        name="ntakMennyiseg"
                                        value={this.state.product.ntakMennyiseg === null
                                            ? ""
                                            : this.state.product.ntakMennyiseg}
                                        validators={['isFloat','minFloat:0']}
                                        errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                                    />
                                </div>
                            </Widget>
                        </Card>
                        <div className="m-8"/>
                    </>
                    : undefined
                }

                <Card title="Kép vagy színkód">
                    <Widget>
                        <ColorPickerBox
                            value={this.state.product?.colorCode ? this.state.product.colorCode : "none"}
                            onChange={this.onChangeColor}
                            onClear={this.onClearColor}
                        />
                        <div className="m-8"/>
                        <div className="flex-1">
                            <DropzoneArea
                                filesLimit={1}
                                key={this.state.product?.id}
                                acceptedFiles={['image/*']}
                                maxFileSize={300000}
                                initialFiles={this.state.product?.imageId ? [`${BASE_URL}/api/images/${this.state.product.imageId}`] : undefined}
                                dropzoneText="Kép feltöltés (150 x 150 px)"
                                onChange={this.onSelectImage}
                                onDelete={this.onDeleteImage}
                            />
                        </div>
                    </Widget>
                </Card>
                <div className="m-8"/>

                <Card title="Áfa kulcsok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Eladási ÁFA kulcs"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="vat"
                                fullWidth
                                required
                                value={this.state.product?.vat == null ? "" : this.state.product.vat }
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező','Csak számot lehet megadni']}
                            >
                                {
                                    VATValues.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Elviteli ÁFA kulcs"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="takeAwayVat"
                                fullWidth
                                required
                                value={this.state.product?.takeAwayVat == null ? "" : this.state.product.takeAwayVat }
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező','Csak számot lehet megadni']}
                            >
                                {
                                    VATValues.map(vat => <MenuItem key={vat.value} value={vat.value}>{vat.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                    </Widget>
                </Card>
                <div className="m-8"/>

                <Card title="Árazás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Bekerülési ár (Nettó)"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="acquisitionPrice"
                                value={this.state.product.isRecipeProduct
                                        ? this.calculateAcquisitionPrice()
                                        : (this.state.product.acquisitionPrice === null ? "" : this.state.product.acquisitionPrice)}
                                inputProps={{ maxLength: 8 }}
                                required
                                disabled={this.state.product?.isRecipeProduct || false}
                                validators={['required', this.state.product.isRecipeProduct ? 'isFloat' : 'isNumber','minNumber:0']}
                                errorMessages={['A mező kitöltése kötelező','Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Eladási ár (Bruttó)"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="defaultSellingPrice"
                                value={this.state.product?.defaultSellingPrice === null ? "" : this.state.product.defaultSellingPrice}
                                inputProps={{ maxLength: 8 }}
                                required
                                validators={['required','isNumber','minNumber:0']}
                                errorMessages={['A mező kitöltése kötelező','Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0-nál']}
                            />
                        </div>
                    </Widget>
                    {this.renderOtherPrice()}
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={this.goBack}
                />
            </ValidatorForm>

            {this.renderBottleDatabaseDialog()}
            {this.renderAddOtherPriceDialog()}
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
