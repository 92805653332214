import {Button, CircularProgress} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import getGeneralError from "../util/getGeneralError";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 8,
    },
    error: {
        marginTop: 16,
        fontSize: 20,
        color: "red",
    },
    container: {
        marginBottom: 10,
        marginTop: 10,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
    },
    submitButton: {
        color: 'white',
        marginTop: 30,
        width: 250,
        height: 60,
        fontSize: 20,
    },
    cancelButton: {
        color: '#aaa',
        marginTop: 30,
        width: 250,
        height: 60,
        fontSize: 20,
    },
    progress: {
        marginTop: 30,
        width: 250,
        height: 60,
        textAlign: "center",
    },
}));


function SaveCancelButtons(props) {
    const classes = useStyles();

    return <div className={classes.root}>
        {props.error
            ? <section className={classes.error}>{getGeneralError(props.error)}</section>
            : ""
        }
        <div className={classes.container}>
            {!props.isSubmitInProgress
                ? <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}>
                        Mentés
                </Button>
                : <div className={classes.progress}>
                    <CircularProgress color="primary"/>
                </div>
            }
            <div className="m-16"/>
            <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={props.onCancel}
            >
                Mégse
            </Button>
        </div>
    </div>
}

export default SaveCancelButtons;
