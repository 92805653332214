import {IconButton, Typography} from "@material-ui/core";
import {ColorPicker} from "material-ui-color";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        textAlign: "center",
        border: "3px dashed rgba(0, 0, 0, 0.12)",
        borderRadius: 4,
    },
    title: {
        marginTop: 24,
        marginBottom: 16,
        fontSize: "1.5rem",
    },
    subtitle: {
        fontSize: "0.9rem",
        marginBottom: 16,
    },
    container: {
        display: "inline-flex",
        marginTop: 32,
        marginBottom: 16,
        alignItems: "center",
    }
}));


function ColorPickerBox(props) {
    const classes = useStyles();

    const palette = {
        red: 'red',
        blue: 'blue',
        green: 'green',
        yellow: 'yellow',
        cyan: 'cyan',
        lime: 'lime',
        gray: 'gray',
        orange: 'orange',
        purple: 'purple',
        black: 'black',
        white: 'white',
        pink: 'pink',
        darkblue: 'darkblue',
    };

    return <div className={classes.root}>
        <Typography className={classes.title}>Színkód kiválsztás</Typography>
        <Typography className={classes.subtitle}>A színkódot csak abban az esetben alkalmazzuk ha nincs kép.</Typography>
        <div className={classes.container}>
            <ColorPicker
                disableAlpha
                palette={palette}
                value={props.value}
                onChange={props.onChange}
            />
            <IconButton onClick={props.onClear}>
                <DeleteIcon fontSize="large"/>
            </IconButton>
        </div>
    </div>
}

export default ColorPickerBox;
