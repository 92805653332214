import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import { Checkbox, FormControlLabel, FormGroup, IconButton, MenuItem, Toolbar, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import formatEmail from "../../../util/formatEmail";

export default class EditCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                lastName: null,
                firstName: null,
                email: null,
                phone: null,
                monthlyFee: null,
                password: '',
                address: {
                    street: null,
                    city: null,
                    zipCode: null,
                },
                companyData: {
                    companyName: null,
                    taxNumber: null,
                    accountNumber: null,
                    companyAddress: {
                        street: null,
                        city: null,
                        zipCode: null,
                    }
                },
                profile: {
                    id: null,
                    hasAccessToVideos: false,
                },
                role: "ROLE_CUSTOMER",
                enabled: true,
            },
            passwordAgain: '',
            isImportDemoProducts: true,
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });

        if (this.props.location.userId) {
            axiosCommon.get(`${BASE_URL}/api/users/${this.props.location.userId}`)
                .then(response => {
                    const user = response.data;
                    user.password = user.password ? user.password : '';
                    if(user.profile == null) user.profile = {id: null, hasAccessToVideos: false}
                    this.setState({ user })
                }).catch(error => {
                    this.setState({error})
                });
        }
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    onSubmit = () => {
        if(this.state.passwordAgain !== this.state.user.password){
            this.setState({error: "A két jelszónak meg kell egyeznie"});
            return 0;
        }

        this.setState({isSubmitInProgress: true});

        const {user, isImportDemoProducts} = this.state;
        if(user.password === ''){
            user.password = undefined;
        }
        user.address = user.companyData.companyAddress;

        axiosCommon({
            method: this.props.location.userId ? "put" : "post",
            url: `${BASE_URL}/api/users`,
            data: user,
            params: {isImportDemoProducts}
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    handleCompanyDateChange = (event) => {
        const { companyData } = this.state.user;
        companyData[event.target.name] = event.target.value;
        this.setState({ companyData });
    }

    handleAddressChange = (event) => {
        const { companyAddress } = this.state.user.companyData;
        companyAddress[event.target.name] = event.target.value;
        this.setState({ companyAddress });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.userId ? "Ügyfél szerkesztése" : "Új ügyfél"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Személyes adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Vezetéknév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="lastName"
                                value={this.state.user.lastName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Keresztnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="firstName"
                                value={this.state.user.firstName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const { user } = this.state
                                    user.email = formatEmail(event.target.value)
                                    this.setState({ user })
                                }}
                                name="email"
                                value={this.state.user.email || ""}
                                required
                                validators={['required', 'isEmail']}
                                errorMessages={['A mező kitöltése kötelező', 'Érvénytelen email cím']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Telefonszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="phone"
                                value={this.state.user.phone || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Céges adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cégnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleCompanyDateChange}
                                name="companyName"
                                value={this.state.user.companyData.companyName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Adószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleCompanyDateChange}
                                name="taxNumber"
                                value={this.state.user.companyData.taxNumber || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Számlaszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleCompanyDateChange}
                                name="accountNumber"
                                value={this.state.user.companyData.accountNumber || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Város"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="city"
                                value={this.state.user.companyData.companyAddress.city || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Irányítószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="zipCode"
                                value={this.state.user.companyData.companyAddress.zipCode || ""}
                                inputProps={{ maxLength: 10 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Cím"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="street"
                                value={this.state.user.companyData.companyAddress.street || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Program használat">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Havidíj"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="monthlyFee"
                                value={this.state.user.monthlyFee === null ? "" : this.state.user.monthlyFee}
                                required
                                validators={['required', 'isNumber', 'isPositive']}
                                errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'A megadott érték negatív']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Állapot"
                                variant="outlined"
                                onChange={(event) => {
                                    const { user } = this.state;
                                    user.enabled = event.target.value === 'true';
                                    this.setState({ user });
                                }}
                                fullWidth
                                value={this.state.user.enabled === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Aktív</MenuItem>
                                <MenuItem key='0' value='false'>Szünetel</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Profil">
                    <Widget>
                        <div className="flex-1">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.user.profile.hasAccessToVideos}
                                            onChange={(event) => {
                                                const {profile} = this.state.user
                                                profile.hasAccessToVideos = event.target.checked
                                                this.setState({ profile });
                                            }}
                                            color="primary"
                                            name="hasAccessToVideos" />}
                                    label="Hozzáférés a videós anyagokhoz"
                                />
                                {this.props.location.userId === undefined
                                    ?<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isImportDemoProducts}
                                                onChange={(event) => {
                                                    this.setState({ isImportDemoProducts: event.target.checked });
                                                }}
                                                color="primary"
                                                name="demoProducts" />}
                                        label="Demo termékek importálása"
                                    />
                                    :undefined
                                }

                            </FormGroup>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Jelszó megadása">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Jelszó"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="password"
                                value={this.state.user.password || ""}
                                required={!this.props.location.userId}
                                validators={!this.props.location.userId ? ['required'] : []}
                                errorMessages={!this.props.location.userId ? ['A mező kitöltése kötelező'] : []}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Jelszó újra"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    this.setState({ passwordAgain:  event.target.value});
                                }}
                                value={this.state.passwordAgain || ""}
                                required={!this.props.location.userId}
                                validators={!this.props.location.userId ? ['required', 'isPasswordMatch'] : ['isPasswordMatch']}
                                errorMessages={!this.props.location.userId ? ['A mező kitöltése kötelező', 'A két jelszó különböző'] : ['A két jelszó különböző']}
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
