import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import downloadFile from "../../../util/downloadFile";
import Footer from "../../../components/Footer";
import {
    Button, CircularProgress,
    Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Paper,
    Toolbar,
    Typography
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import NavHeader from "../../../components/NavHeader";
import {Alert} from "@material-ui/lab";
import CloudDownload from "@material-ui/icons/CloudDownload";
import getGeneralError from "../../../util/getGeneralError";

export default class CsrFileGeneration extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null,
            hasError: false,
            openDialog: false,
            regNumber: null,
            isSubmitInProgress: false,
        };
        this.importFile = React.createRef();
    }

    downloadCSR = () => {
        this.setState({openDialog: false, isSubmitInProgress: true})
        const queryParams = {regNumber: this.state.regNumber}

        axiosCommon.get(`${BASE_URL}/api/ntak/certificate?`, {
            headers: {Accept: "application/zip"},
            responseType: 'blob',
            params: queryParams
        }).then(response => {
            downloadFile(response.data, `${this.state.regNumber}.zip`);
        }).catch(error => {
            this.setState({ error, hasError: true });
        }).finally(() => {
            this.setState({ regNumber: null, isSubmitInProgress: false });
        });
    };


    closeError = () => {
        this.setState({hasError: false})
    }

    renderDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.openDialog === true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Üzlet NTAK regisztrációs szám</DialogTitle>
            <DialogContent>
                <ValidatorForm onSubmit={this.downloadCSR}>
                    <Typography variant="body1" id="tableTitle" component="div">
                        Kérlek add meg az üzleted NTAK regiszrációs számát, amihez a tanusítvány kérelmet szeretnéd generálni. A regisztrációs
                        számot a következő formában kell megadni: 12345678
                    </Typography>
                    <TextValidator
                        label="NTAK regisztrációs szám"
                        variant="outlined"
                        onChange={(event) => this.setState({regNumber: event.target.value})}
                        value={this.state.regNumber || ""}
                        name="regNumber"
                        style={{width: 350, marginTop: 20}}
                        required
                        validators={['required', 'matchRegexp:^\\d{8}$']}
                        errorMessages={['A mező kitöltése kötelező', "A mezőnek 8 számjegyből kell állnia."]}
                    />
                    <DialogActions>
                        <Button onClick={() => this.setState({openDialog: false})} color="primary">
                            Mégsem
                        </Button>
                        <Button color="primary" autoFocus type="submit">
                            Generalás
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
               <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <Collapse in={this.state.hasError} style={{marginBottom: 8}}>
                    <Alert severity="error" onClose={this.closeError}>{getGeneralError(this.state.error)}</Alert>
                </Collapse>

                <Paper style={{marginTop: 20}}>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Tanusítvány kérelem generálás
                        </Typography>
                    </Toolbar>
                    <Typography variant="body1" id="tableTitle" component="div" style={{padding: 10}}>
                        Az NTAK adatszolgáltatás megkezdéséhez szükséges van egy aláírási tanusítványra, amit a tanusítvány kérelem alapján
                        az NTAK tud kiállítani a szolgáltató részére. A tanusítvány kérelem generálás végeredménye egy tömörített fájl, ami tartalmazza
                        a tanusítvány kérelmet (.csr fájl), a titkos kulcsot (.key fájl) és a konfigurációs állományt (.conf fájl). Az aláírási tanusítványt
                        az NTAK a tanusítvány kérelem (.csr fájl) alapján tudja elkészíteni, így ezt kell feltölteni az NTAK honlapjára a megfelelő mezőbe.
                    </Typography>
                    <Typography variant="body1" id="tableTitle" component="div" style={{padding: 10}}>
                        Az NTAK által elkészített aláírási tanusítványt (.cer fájl) le kell tölteni az NTAK honlapjáról és a titkos kulccsal (.key fájl)
                        együtt fel kell tölteni a Bythepay rendszerébe az <b>NTAK / Adatküldés beállítása</b> menüpontban a megfelelő üzlet szerkesztésénél.
                    </Typography>
                    <Typography variant="body1" id="tableTitle" component="div" style={{padding: 10}}>
                        <b>A aláírási tanusítvány generálás során keletkező tömörített fájl meg kell őrizni!</b>
                    </Typography>
                    {!this.state.isSubmitInProgress
                        ? <Button variant="contained" startIcon={<CloudDownload />} style={{margin: 15}} onClick={() => this.setState({openDialog: true})}>
                            Generálás
                        </Button>
                        : <div style={{marginTop: 15, width: 100, height: 50, marginLeft: 15}}>
                            <CircularProgress color="primary"/>
                        </div>
                    }
                </Paper>
                {this.renderDialog()}
            </section>
            <Footer/>
        </section>;
    }
}
