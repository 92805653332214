import React from 'react'
import { hu } from 'date-fns/locale'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import '../css/StyledDateRangePicker.css'

export default function StyledDateRangePicker(props) {
    return (
        <section style={{position: "relative"}}>
            <DateRangePicker
                startDate={props.fromDate}
                endDate={props.toDate}
                onStartDateChange={props.fromDateChange}
                onEndDateChange={props.toDateChange}
                maximumLength={props.maximumLength}
                format="yyyy-MM-dd"
                locale={hu}
            >
                {({startDateInputProps, endDateInputProps, focus}) => (
                    <div className='date-range'>
                        <input
                            className={focus === START_DATE ? 'input-focused' : 'input'}
                            {...startDateInputProps}
                            placeholder='Kezdő dátum'
                        />
                        <span className='date-range_arrow'/>
                        <input
                            className={focus === END_DATE ? 'input-focused' : 'input'}
                            {...endDateInputProps}
                            placeholder='Vég dátum'
                        />
                    </div>
                )}
            </DateRangePicker>
        </section>
    );
}
