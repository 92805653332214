import React from "react";
import moment from "moment";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import formatDate from "../../../util/formatDate";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {Checkbox, FormControlLabel, IconButton, MenuItem, TextField, Toolbar, Typography} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export default class EditDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectTypeOptions: [{label: "Bármelyik", value: -1}],
            selectedSubjectType: {label: "Bármelyik", value: -1},
            selectedDiscountType: this.discountTypeOptions()[0],
            shopOptions: [],
            selectedShopOption: null,
            continuous: true,
            discount: {
                categoryId: undefined,
                consumerTypeId: undefined,
                description: null,
                difference: undefined,
                discountType: "CATEGORY",
                enabled: true,
                fromDateTime: null,
                name: null,
                percent: undefined,
                productId: undefined,
                shopId: null,
                toDateTime: null
            },
            isSubmitInProgress: false,
        };
        this.PriceTypes =[
            {label: "Százalékos", value: "PERCENT"},
            {label: "Árkülönbség", value: "DIFFERENCE"}]
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items.map(c => {
                    return {label: c.name, value: c.id}
                });
                shopOptions.unshift({label: "Bármelyik", value: -1})
                this.setState({
                    shopOptions,
                    selectedShopOption: shopOptions[0]
                }, () => {
                    if (this.props.location.discountId) {
                        axiosCommon.get(`${BASE_URL}/api/discounts/${this.props.location.discountId}`)
                            .then(response => {
                                const priceTypeValue = response.data.difference ? "DIFFERENCE" : "PERCENT";
                                const selectedDiscountType = this.discountTypeOptions().filter(o => o.value === response.data.discountType)[0]
                                const selectedShopOption = response.data.shop ? this.state.shopOptions.filter(s => s.value === response.data.shop.id)[0] : {
                                    label: "Bármelyik",
                                    value: -1
                                }
                                const continuous = response.data.fromDateTime === null && response.data.toDateTime === null
                                const newState = {
                                    discount: response.data,
                                    selectedPriceType: this.PriceTypes.filter(o => o.value === priceTypeValue)[0],
                                    selectedShopOption,
                                    continuous,
                                    toDate: response.data.toDateTime ? moment(response.data.toDateTime).toDate() : null,
                                    fromDate: response.data.fromDateTime ? moment(response.data.fromDateTime).toDate() : null
                                }
                                this.setState(newState, () => this.setDiscountType(null, selectedDiscountType))
                            })
                            .catch(error => {
                                this.setState({error});
                            });
                    } else {
                        this.setState({
                            selectedPriceType: this.priceTypeOptions()[0]
                        }, () => this.setDiscountType(null, this.discountTypeOptions()[0]));
                    }

                })
            })

    }

    priceTypeOptions = () => {
        // TODO
        return this.state.selectedDiscountType?.value === "CONSUMER"
            ? [this.PriceTypes[0]]
            : this.PriceTypes
    }

    discountTypeOptions = () => {
        return [
            {label: "Törzsvásárlói csoport", value: "CONSUMER"},
            {label: "Kategória", value: "CATEGORY"},
            {label: "Termék", value: "PRODUCT"}
        ]
    }

    setDiscountType = (event, value) => {
        let {subjectTypeOptions} = this.state
        subjectTypeOptions = [{label: "Bármelyik", value: -1}]

        this.setState({selectedDiscountType: value, subjectTypeOptions, selectedSubjectType: subjectTypeOptions[0] }, () => {
            switch (value.value) {
                case "CONSUMER":
                    axiosCommon.get(`${BASE_URL}/api/consumercategories`)
                        .then(response => {
                            const subjectTypeOptions = response.data.items.map(c => {
                                return {label: c.name, value: c.id}
                            });
                            subjectTypeOptions.unshift({label: "Bármelyik", value: -1})
                            const selectedSubjectType = this.state?.discount?.consumerType ?
                                {label: this.state.discount.consumerType.name, value: this.state.discount.consumerType.id} :
                                {label: "Bármelyik", value: -1};
                            this.setState({
                                subjectTypeOptions,
                                selectedSubjectType,
                                selectedPriceType: {label: "Százalékos", value: "PERCENT"}
                            })
                        })
                        .catch(error => this.setState({error}))
                    break;
                case "CATEGORY":
                    axiosCommon.get(`${BASE_URL}/api/categories?deleted=false`)
                        .then(response => {
                            const subjectTypeOptions = response.data.items.map(c => {
                                return {label: c.name, value: c.id}
                            });
                            subjectTypeOptions.unshift({label: "Bármelyik", value: -1})
                            const selectedSubjectType = this.state?.discount?.category ?
                                {label: this.state.discount.category.name, value: this.state.discount.category.id} :
                                {label: "Bármelyik", value: -1};
                            this.setState({
                                subjectTypeOptions,
                                selectedSubjectType
                            })
                        })
                        .catch(error => this.setState({error}))
                    break;
                case "PRODUCT":
                    axiosCommon.get(`${BASE_URL}/api/products/basic?deleted=false`)
                        .then(response => {
                            const subjectTypeOptions = response.data.items.map(p => {
                                return {label: p.name, value: p.id}
                            })
                            subjectTypeOptions.unshift({label: "Bármelyik", value: -1})
                            const selectedSubjectType = this.state?.discount?.product ?
                                {label: this.state.discount.product.name, value: this.state.discount.product.id} :
                                {label: "Bármelyik", value: -1};
                            this.setState({
                                subjectTypeOptions,
                                selectedSubjectType
                            })
                        })
                        .catch(error => this.setState({error}))
                    break;
            }
        })
    }

    onSubmit = () => {
        const {discount} = this.state;

        discount.owner = undefined
        discount.shop = undefined
        discount.category = undefined
        discount.product = undefined

        discount.shopId = this.state.selectedShopOption.value === -1 ? undefined : this.state.selectedShopOption.value;

        if (this.state.continuous) {
            discount.fromDateTime = undefined;
            discount.toDateTime = undefined;
        } else {
            discount.fromDateTime = this.state.fromDate ? `${formatDate(this.state.fromDate)}T00:00:00.00` : undefined;
            discount.toDateTime = this.state.toDate ? `${formatDate(this.state.toDate)}T23:59:59.99` : undefined;
        }

        if (this.state.selectedPriceType?.value === "PERCENT") {
            discount.difference = undefined;
        } else {
            discount.percent = undefined;
        }

        discount.discountType = this.state.selectedDiscountType.value;

        switch (this.state.selectedDiscountType.value) {
            case "CONSUMER":
                // TODO
                discount.consumerTypeId = this.state.selectedSubjectType.value === -1 ? null : this.state.selectedSubjectType.value;
                break;
            case "CATEGORY":
                discount.categoryId = this.state.selectedSubjectType.value === -1 ? null : this.state.selectedSubjectType.value;
                break;
            case "PRODUCT":
                discount.productId = this.state.selectedSubjectType.value === -1 ? null : this.state.selectedSubjectType.value;
                break;
            default:
                break;
        }

        const method = this.props.location.discountId ? "put" : "post"
        axiosCommon({
            method,
            url: `${BASE_URL}/api/discounts`,
            data: discount
        })
            .then(response => {
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };


    handleChange = (event) => {
        const { discount } = this.state;
        discount[event.target.name] = event.target.value;
        this.setState({ discount });
    }

    handleStateChange = (event) => {
        const { discount } = this.state;
        discount.enabled = event.target.value === 'true';
        this.setState({ discount });
    }

    handleShopChange = (event, value) => {
        this.setState({ selectedShopOption: value });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.discountId ? "Kedvezmény szerkesztése" : "Új kedvezmény"}
                </Typography>
                <IconButton onClick={() => this.props.history.goBack()}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.discount.name || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Állapot"
                                variant="outlined"
                                onChange={this.handleStateChange}
                                name="enable"
                                fullWidth
                                value={this.state.discount.enabled === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Aktív</MenuItem>
                                <MenuItem key='0' value='false'>Szünetel</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.handleShopChange}
                                name="shopId"
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.shopOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedShopOption || ""}
                                renderInput={(params) =>
                                    <TextField {...params} label="Üzlet" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" />
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.discount.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <div className="m-8"/>

                <Card title="Kedvezményezett">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={this.setDiscountType}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.discountTypeOptions()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedDiscountType || this.discountTypeOptions()[0]}
                                renderInput={(params) =>
                                    <TextField {...params} label="Típus" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({selectedSubjectType: value})
                                }}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.state.subjectTypeOptions}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedSubjectType || this.state.subjectTypeOptions[0]}
                                renderInput={(params) =>
                                    <TextField {...params} label="Alany" variant="outlined" />
                                }
                            />
                        </div>
                    </Widget>
                </Card>

                <div className="m-8"/>

                <Card title="Összeg">
                    <Widget>
                        <div className="flex-1">
                            <Autocomplete
                                onChange={(event, value) => {
                                    this.setState({selectedPriceType: value})
                                }}
                                fullWidth
                                disableClearable
                                noOptionsText="Nincs ilyen tétel"
                                options={this.priceTypeOptions()}
                                getOptionLabel={(option) => option === "" ? "" : option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                value={this.state.selectedPriceType || this.priceTypeOptions()[0]}
                                renderInput={(params) =>
                                    <TextField {...params} label="Típus" variant="outlined" />
                                }
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            {this.state.selectedPriceType?.value === "PERCENT"
                                ? <TextValidator
                                    label="Mértéke (%)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="percent"
                                    value={this.state.discount.percent || ""}
                                    required
                                    validators={['required', 'isNumber','minNumber:1', 'maxNumber:100']}
                                    errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 1-nél', 'A megadott érték nem lehet nagyobb 100-nál']}
                                />

                                : <TextValidator
                                    label="Mértéke (Ft)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="difference"
                                    value={this.state.discount.difference || ""}
                                    inputProps={{ maxLength: 8 }}
                                    required
                                    validators={['required', 'isNumber','minNumber:1']}
                                    errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 1-nél']}
                                />
                            }
                        </div>
                    </Widget>
                </Card>

                <div className="m-8"/>

                <Card title="Érvényesség">
                    <Widget>
                        <div className="flex-1">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.continuous || false}
                                        onChange={(event) => {
                                            let { continuous } = this.state;
                                            continuous = event.target.checked;
                                            this.setState({ continuous });
                                        }}
                                        color="primary"
                                        name="showInTerminal" />}
                                label="Folyamatos"
                            />
                        </div>
                        <div className="m-8"/>
                        {this.state?.continuous === true
                            ? <div className="flex-1" />
                            : <div className="flex-1" >
                                <StyledDateRangePicker
                                    fromDate={this.state.fromDate}
                                    toDate={this.state.toDate}
                                    fromDateChange={(date) =>{
                                        this.setState({fromDate: date})
                                    }}
                                    toDateChange={(date) => {
                                        this.setState({toDate: date})
                                    }}
                                />
                            </div>
                        }
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
