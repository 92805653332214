import React from 'react';
import {Button, Card as MaterialCard, CardContent, CardHeader, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
    root: {
        top: "90px",
        backgroundColor: "#CB2C2C",
        zIndex: 999,
        right: 0,
        position: "absolute",
        padding: 0,
        margin: 0,
        width: "44px",
        overflow: "hidden",
        borderBottomLeftRadius: "5px",
        borderTopLeftRadius: "5px",
        '&:hover': {
            width: "auto",
        },
    },
    button: {
        color: "white",
        paddingBottom: "3px",
        paddingTop: "3px",
        overflow: "hidden",
    },
    icon: {
        color: "white",
        paddingBottom: "3px",
        paddingTop: "3px",
        fontSize: 36,
    }
});

function NewItemButton(props){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Button onClick={props.onClick}>
                <AddIcon className={classes.icon}/>
                <Typography className={classes.button} variant="h5">{props.title}</Typography>
            </Button>
        </div>
    );
}

export default NewItemButton;
