import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import {BASE_URL} from "../../../util/Urls";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import {ResponsiveLine} from "@nivo/line";
import NavHeader from "../../../components/NavHeader";
import Card from "../../../components/Card";
import {
    Paper, Tab,
} from "@material-ui/core";
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import {ResponsiveBar} from "@nivo/bar";
import NoData from "../../../components/NoData";
import { BasicTooltip } from "@nivo/tooltip";
import formatCurrency from "../../../util/formatCurrency";
import formatNumber from "../../../util/formatNumber";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";

export default class CustomerFinanceStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            tabIndex: 0,
            stats: [],
            incomeStats: [],
            isLoading: true,
            columns: [
                {field: 'date', headerName: 'Időpont', width: 120},
                {field: 'income', headerName: 'Bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 140, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'profit', headerName: 'Profit (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'netAcquisitionPrice', headerName: 'Nettó Beszer. érték (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 220, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'vat', headerName: 'Áfa (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value, 0)},
            ],
            rows: [],
        };
    }

    processData = (data, dailyStat) => {
        this.setState({isLoading: true, rows: []})

        let mustShowServiceFee = null
        let totalIncome = null
        let totalProfit = null
        let totalAcquisitionPrice = null
        let totalVat = null
        let totalNetServiceFee = null
        let totalServiceFeeVat = null
        let stats = []
        let incomeStats = []
        const {columns} = this.state
        let rows = []

        if(data !== null){
            mustShowServiceFee = data.filter(i => i.incomeServiceFee > 0).length > 0;
            totalIncome = data.map(item => item.income).reduce((a, b) => a + b, 0);
            totalProfit = data.map(item => item.profitSales).reduce((a, b) => a + b, 0);
            totalAcquisitionPrice = data.map(item => item.acquisitionPrice).reduce((a, b) => a + b, 0);
            totalVat = data.map(item => item.vatReceivedSales).reduce((a, b) => a + b, 0);
            totalNetServiceFee = data.map(item => item.netServiceFee).reduce((a, b) => a + b, 0);
            totalServiceFeeVat = data.map(item => item.vatReceivedServiceFee).reduce((a, b) => a + b, 0);

            if (dailyStat) {
                stats = [];
                for (let i = 0; i < 24; i += 1) {
                    stats[i] = {
                        label: i < 10 ? `0${i}:00` : `${i}:00`,
                        income: 0,
                        incomeServiceFee: 0
                    }
                }

                data.forEach(i => {
                    const time = i.label.split(" ")[1]
                    const hour = Number(time.split(":")[0])

                    stats[hour]["ÁFA"] = i.vatReceivedSales
                    stats[hour].Profit = i.profitSales
                    stats[hour]["N. besz. érték"] = i.acquisitionPrice
                    stats[hour].income = i.income
                    stats[hour].incomeServiceFee = i.incomeServiceFee
                    if (mustShowServiceFee) {
                        stats[hour]["Szervizdíj"] = i.incomeServiceFee
                        stats[hour]["Nettó Szervizdíj"] = i.netServiceFee
                        stats[hour]["Szervizdíj ÁFA"] = i.vatReceivedServiceFee
                    }
                })

            } else {
                stats = data.map(i => {
                    i["ÁFA"] = i.vatReceivedSales
                    i.Profit = i.profitSales
                    i["N. besz. érték"] = i.acquisitionPrice
                    if (mustShowServiceFee) {
                        i["Szervizdíj"] = i.incomeServiceFee
                        i["Nettó Szervizdíj"] = i.netServiceFee
                        i["Szervizdíj ÁFA"] = i.vatReceivedServiceFee
                    }
                    return i
                })
            }

            incomeStats = [{
                id: "Teljes bevétel",
                data: stats.map(i => {
                    return {x: i.label, y: i.income}
                })
            }]

            if (mustShowServiceFee) {
                incomeStats.push({
                        id: "Szervizdíj",
                        data: stats.map(i => {
                            return {x: i.label, y: i.incomeServiceFee}
                        })
                })
            }

            // Prepare table
            if(mustShowServiceFee){
                columns.push({field: 'netServiceFee', headerName: 'Nettó Szervizdíj (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 200, valueFormatter: (params) => formatNumber(params.value, 0)})
                columns.push({field: 'serviceFeeVat', headerName: 'Szerviz Áfa (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 180, valueFormatter: (params) => formatNumber(params.value, 0)})

                rows = stats.map((s, i) => {
                    return {
                        id: i,
                        date: s.label,
                        income: s.income,
                        profit: s.Profit  || 0,
                        netAcquisitionPrice: s["N. besz. érték"] || 0,
                        vat: s.ÁFA || 0,
                        netServiceFee: s["Nettó Szervizdíj"] || 0,
                        serviceFeeVat: s["Szervizdíj ÁFA"] || 0,
                    }
                })
                rows.push({
                    id: stats.length + 1,
                    style: "bold",
                    date: "Összesen",
                    income: totalIncome  || 0,
                    profit: totalProfit  || 0,
                    netAcquisitionPrice: totalAcquisitionPrice || 0,
                    vat: totalVat || 0,
                    netServiceFee: totalNetServiceFee || 0,
                    serviceFeeVat: totalServiceFeeVat || 0,
                })
            }else {
                rows = stats.map((s, i) => {
                    return {
                        id: i,
                        date: s.label,
                        income: s.income,
                        profit: s.Profit  || 0,
                        netAcquisitionPrice: s["N. besz. érték"] || 0,
                        vat: s.ÁFA || 0,
                    }
                })
                rows.push({
                    id: stats.length + 1,
                    style: "bold",
                    date: "Összesen",
                    income: totalIncome  || 0,
                    profit: totalProfit  || 0,
                    netAcquisitionPrice: totalAcquisitionPrice || 0,
                    vat: totalVat || 0,
                })
            }
        }

        this.setState({
            stats,
            columns,
            rows,
            incomeStats,
            totalIncome,
            totalProfit,
            totalAcquisitionPrice,
            totalVat,
            totalNetServiceFee,
            totalServiceFeeVat,
            mustShowServiceFee,
            isLoading: false
        })
    }

    sliceTooltip = (props) => {
        const xLabel = props.slice.points[0].data.xFormatted
        return (
            <>
                <BasicTooltip
                    key="title"
                    id="Időpont"
                    value={xLabel}
                />
                {props.slice.points.map(i => {
                    return <BasicTooltip
                        key={i.serieId}
                        id={i.serieId}
                        value={formatCurrency(i.data.yFormatted, 0, "HUF")}
                        color={i.color}
                        enableChip
                    />
                })}
            </>
        );
    };

    barTooltip = (props) => {
        const {data} = props
        return (<>
            <BasicTooltip
                id="Profit"
                value={formatCurrency(data.Profit, 0, "HUF")}
                color="#8DD3C7"
                enableChip
            />
            <BasicTooltip
                id="N. besz. érték"
                value={formatCurrency(data["N. besz. érték"], 0, "HUF")}
                color="#FFFFB3"
                enableChip
            />
            <BasicTooltip
                id="ÁFA"
                value={formatCurrency(data["ÁFA"], 0, "HUF")}
                color="#BEBADA"
                enableChip
            />

            {this.state.mustShowServiceFee
                ?<BasicTooltip
                    id="Szervizdíj"
                    value={formatCurrency(data["Szervizdíj"], 0, "HUF")}
                    color="#FB8072"
                    enableChip
                />
                :undefined
            }
        </>);
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Diagram"/>
                    <Tab label="Táblázat"/>
                </TabList>

                <CustomerFinanceStatisticsTemplate url={`${BASE_URL}/api/stats/finance`}
                                                   onDataReceived={this.processData}
                                                   highlightLabel="Teljes bevétel (Ft)"
                                                   highlightValue={this.state?.totalIncome}>

                    <TabPanel value={this.state.tabIndex} index={0}>

                        <Card title="Bevétel">
                            <section style={{height: "500px"}}>
                                {(this.state.isLoading == true || this.state?.stats?.length > 0)
                                    ?<ResponsiveLine
                                        {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                        data={this.state.incomeStats}
                                        colors={["#32CD32", "#324ccd"]}
                                        xScale={{type: 'point'}}
                                        yScale={{type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false}}
                                        curve="monotoneX"
                                        enableArea
                                        pointColor={{ from: 'color', modifiers: [] }}
                                        pointLabelYOffset={-12}
                                        useMesh
                                        enableSlices="x"
                                        sliceTooltip={point => this.sliceTooltip(point)}
                                    />
                                    :<NoData/>
                                }
                            </section>
                        </Card>


                        <div className="mb-16"/>

                        <Card title="Profit és Áfa tartalom" className='mt-16'>
                            <section style={{height: "500px"}}>
                                {(this.state.isLoading == true || this.state?.stats?.length > 0)
                                    ?<ResponsiveBar
                                        {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                        colors={{ scheme: 'set3' }}
                                        data={this.state.stats}
                                        keys={this.state.mustShowServiceFee ? ["Profit", "N. besz. érték", "ÁFA", "Szervizdíj"] : ["Profit", "N. besz. érték", "ÁFA"]}
                                        indexBy="label"
                                        tooltip={point => this.barTooltip(point)}
                                        labelSkipHeight={20}
                                        label={d => formatCurrency(d.value, 0, "HUF")}
                                    />
                                    :<NoData/>
                                }
                            </section>
                        </Card>
                    </TabPanel>

                    <TabPanel value={this.state.tabIndex} index={1}>
                        {this.state?.stats?.length
                            ? <Paper className="mt-16">
                                <DataGrid
                                    columns={this.state.columns}
                                    rows={this.state.rows}
                                    disableSelectionOnClick
                                    disableColumnMenu
                                    autoHeight
                                    loading={this.state.isLoading}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <GridToolbarContainer>
                                                    <GridToolbarExport/>
                                                </GridToolbarContainer>
                                            );
                                        },
                                        NoRowsOverlay: NoData,
                                    }}
                                    getRowClassName={(params) => {
                                        if(params.row.style === "bold") return "grid-row-bold"
                                        return ""
                                    }}
                                />
                            </Paper>
                            : "Nem található a szűrőknek megfelelő eladás."
                        }
                    </TabPanel>

                </CustomerFinanceStatisticsTemplate>
            </section>
            <Footer/>
        </section>;
    }
};
