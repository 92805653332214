import React from "react";
import "../../../css/Pages.css"
import moment from "moment";
import {axiosCommon} from "../../../util/axiosSetup";
import {BASE_URL} from "../../../util/Urls";
import Loading from "../../../util/Loading";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import HighlightCard from "../../../components/HighlightCard";
import {MenuItem, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import formatNumber from "../../../util/formatNumber";

export default class CustomerFinanceStatisticsTemplate extends React.Component {
    static THEME = {
        textColor: "#000",
        fontSize: '0.9rem',
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        legends: {
            text: {
                fontSize: '1rem',
                fontWeight: 'bold',
                textColor: "#000"
            }
        },
    };

    static COMMON_PROPS = {
        theme: CustomerFinanceStatisticsTemplate.THEME,
        margin: {top: 20, right: 8, bottom: 100, left: 75},
        padding: 0.3,
        colors: ["#2b94e5", "#2bc0e5"],
        borderColor: {from: 'color', modifiers: [['darker', 1.6]]},
        axisTop: null,
        axisRight: null,
        axisBottom: {
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -45,
        },
        axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: value =>
                Number(value).toLocaleString('hu-HU', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                }),
        },
        labelSkipWidth: 0,
        labelSkipHeight: 0,
        labelTextColor: "#000",
        legends: [
            {
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'row',
                justify: false,
                translateY: 100,
                itemsSpacing: 10,
                itemWidth: 120,
                itemHeight: 20,
                symbolSize: 20,
            }
        ],
        motionStiffness: 90,
        motionDamping: 15,
        pointSize: 10,
    }


    constructor(props) {
        super(props);
        this.state = {
            period: "DAY",
            shopOptions: [],
            selectedShopOption: null,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                shopOptions.unshift({
                    name: "Összes üzlet",
                    id: -1
                })
                this.setState({
                    shopOptions,
                    selectedShopOption: shopOptions[0]
                }, () => this.refreshData());
            });
    }

    setPeriod = (event) => {
        this.setState({
            period: event.target.value,
            fromDate: moment().set({"hour": 0, "minute": 0, "second": 0}).toDate(),
            toDate: moment().set({"hour": 0, "minute": 0, "second": 0}).toDate(),
        }, () => this.refreshData())
    };

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    refreshData = () => {
        this.setState({isLoading: true}, () => {
            let aggregation;
            let fromDate;
            let toDate = moment().format("YYYY-MM-DD");
            switch (this.state.period) {
                case "YEAR":
                    aggregation = "MONTH";
                    fromDate = moment().subtract(1, "years").format("YYYY-MM-DD");
                    break;
                case "MONTH":
                    aggregation = "DAY";
                    fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");
                    break;
                case "WEEK":
                    aggregation = "DAY";
                    fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
                    break;
                case "DAY":
                    aggregation = "HOUR";
                    fromDate = moment().format("YYYY-MM-DD");
                    break;
                default:
                    toDate = moment(this.state.toDate).format("YYYY-MM-DD");
                    fromDate = moment(this.state.fromDate).format("YYYY-MM-DD");
                    aggregation = toDate === fromDate ? "HOUR" : "DAY";
            }
            const queryParams = {
                aggregation,
                fromDate,
                toDate
            }

            if (this.state.period === "DAY") {
                queryParams.dailyStat = true
            }

            if (this.state.selectedShopOption.id !== -1) {
                queryParams.shopId = this.state.selectedShopOption.id;
            }

            axiosCommon.get(this.props.url,
                {
                    params: queryParams
                })
                .then(response => {
                    this.setState({isLoading: false})
                    this.props.onDataReceived(response.data, aggregation === "HOUR")
                })
                .catch(error => {
                    this.setState({isLoading: false})
                    this.props.onDataReceived(null, aggregation === "HOUR")
                })

        })
    }

    wrongDates = () => {
        if (!this.state.fromDate || !this.state.toDate) {
            return false;
        }
        return moment(this.state.fromDate).isAfter(moment(this.state.toDate))
    }

    renderContent = () => {
        if (this.wrongDates()) {
            return <section>
                Hiba: mindig a kezdő dátum lehet időrendben előrébb, mint a végső dátum.
            </section>
        }
        if (this.state.isLoading) {
            return <Loading/>
        }
        return this.props.children

    }

    render() {
        return <>
            {this.props.highlightLabel
                ?<section className="flex justify-flex-end mb-16 gap-8" >
                    <HighlightCard title={this.props.highlightLabel}>
                        <Typography variant="h3" align="center">
                            {formatNumber(this.props.highlightValue,2)}
                        </Typography>
                    </HighlightCard>
                </section>
                :""
            }


            {this.props.hidePickers
                ?""
                :<Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <TextField
                            select
                            label="Periódus"
                            variant="outlined"
                            onChange={this.setPeriod}
                            fullWidth
                            value={this.state.period || ""}
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem key="DAY" value="DAY">Mai nap</MenuItem>
                            <MenuItem key="WEEK" value="WEEK">Egy hét</MenuItem>
                            <MenuItem key="MONTH" value="MONTH">Egy hónap</MenuItem>
                            <MenuItem key="CUSTOM" value="CUSTOM">Egyéni</MenuItem>
                        </TextField>

                        {this.state.period === "CUSTOM"
                            ?<StyledDateRangePicker
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                fromDateChange={(date) =>{
                                    this.setState({fromDate: date})
                                }}
                                toDateChange={(date) => {
                                    this.setState({toDate: date}, () => this.refreshData())
                                }}
                            />
                            :""
                        }
                    </Card>
                </Widget>
            }

            {this.renderContent()}

        </>;
    }
};
