import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import moment from "moment";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {
    Paper,
    TextField, Typography
} from "@material-ui/core";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import formatNumber from "../../../util/formatNumber";
import HighlightCard from "../../../components/HighlightCard";

export default class ServiceFeeListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            shopOptions: [],
            selectedShopOption: null,
            entries: [],
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            columns: [
                {field: 'date', headerName: 'Dátum', align: 'left', headerAlign: 'left', width: 100},
                {field: 'vat5', headerName: 'Szervidíj 5% (Ft)', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'vat18', headerName: 'Szervidíj 18% (Ft)', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'vat27', headerName: 'Szervidíj 27% (Ft)', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
                {field: 'sum', headerName: 'Összesen (Ft)', align: 'center', headerAlign: 'center', type: 'number', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},],
            rows: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})

        axiosCommon.get(`${BASE_URL}/api/shops`)
            .then(response => {
                const shopOptions = response.data.items;
                const selectedShopOption = shopOptions.length > 0 ? shopOptions[0] : null;
                this.setState({
                    shopOptions,
                    selectedShopOption,
                }, () => this.refreshData());
            });
    }

    refreshData = () => {
        this.setState({isLoading: true})

        const entryParams = {
            toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
            fromDate: moment(this.state.fromDate).format("YYYY-MM-DD")
        }

        if (this.state.selectedShopOption) {
            entryParams.shopId = this.state.selectedShopOption.id;
        }

        axiosCommon(`${BASE_URL}/api/stats/servicefee`, {
            params: entryParams
        })
            .then(response => {
                const entries = response.data;
                const rows = entries.map((e, i) => {
                    return {
                        id: i,
                        date: e.label,
                        vat5: e.serviceFees["5.0"] || 0,
                        vat18: e.serviceFees["18.0"] || 0,
                        vat27: e.serviceFees["27.0"] || 0,
                        sum: Object.values(e.serviceFees).reduce((a, b) => a + b, 0),
                    }
                })
                if(entryParams.fromDate != entryParams.toDate && entries.length > 0){
                    rows.push({
                        id: -1,
                        date: "Összesen:",
                        vat5: entries.map(it => it.serviceFees["5.0"] || 0).reduce((a, b) => a + b, 0),
                        vat18: entries.map(it => it.serviceFees["18.0"] || 0).reduce((a, b) => a + b, 0),
                        vat27: entries.map(it => it.serviceFees["27.0"] || 0).reduce((a, b) => a + b, 0),
                        sum: entries.map(it => Object.values(it.serviceFees).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0),
                    })
                }

                this.setState({ entries, rows, isLoading: false, })
            })
            .catch(error => {
                this.setState({ error, isLoading: false, })
            });
    }

    onChangeShopFilter = (event, value) => {
        this.setState({
            selectedShopOption: value
        }, () => {
            this.refreshData()
        })
    };

    handleChangeRowsPerPage = (entry) => {
        this.setState({ rowsPerPage: parseInt(entry, 10)});
    };

    sumServiceFee = () => this.state.entries.map(it => Object.values(it.serviceFees).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                <section className="flex justify-flex-end mb-16 gap-8" >
                    <HighlightCard
                        title="Teljes szervizdíj (Ft)"
                        tooltip="A teljes szervizdíj összege a szűrési feltételek alapján.">
                        <Typography variant="h3" align="center">
                            {formatNumber(this.sumServiceFee() || 0, 0)}
                        </Typography>
                    </HighlightCard>
                </section>

                <Widget>
                    <Card title="Szűrés üzlet alapján">
                        <Autocomplete
                            fullWidth
                            noOptionsText="Nincs ilyen tétel"
                            options={this.state.shopOptions}
                            onChange={this.onChangeShopFilter}
                            disableClearable
                            getOptionLabel={(option) => option === "" ? "" : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.state.selectedShopOption || ""}
                            renderInput={(params) =>
                                <TextField {...params} label="Üzlet" variant="outlined" />
                            }
                        />
                    </Card>
                    <div className="m-8"/>
                    <Card title="Szűrés dátum alapján">
                        <StyledDateRangePicker
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            fromDateChange={(date) =>{
                                this.setState({fromDate: date})
                            }}
                            toDateChange={(date) => {
                                this.setState({toDate: date}, () => this.refreshData())
                            }}
                        />
                    </Card>
                </Widget>
                <Paper>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        onPageSizeChange={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        pageSize={this.state.rowsPerPage}
                        pagination
                        loading={this.state.isLoading}
                        components={{
                            Toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <GridToolbarExport/>
                                    </GridToolbarContainer>
                                );
                            },
                            NoRowsOverlay: NoData,
                        }} />
                </Paper>
            </section>
            <Footer/>
        </section>;
    }
}
