import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import {BASE_URL} from "../../../util/Urls";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import {ResponsiveBar} from "@nivo/bar";
import NavHeader from "../../../components/NavHeader";
import Card from "../../../components/Card";
import {Paper, Tab} from "@material-ui/core";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@material-ui/data-grid";
import TabList from "../../../components/TabList";
import TabPanel from "../../../components/TabPanel";
import NoData from "../../../components/NoData";
import CustomerEmployeeStatisticsTab from "./CustomerEmployeeStatisticsTab";
import { BasicTooltip } from "@nivo/tooltip";
import formatNumber from "../../../util/formatNumber";
import formatCurrency from "../../../util/formatCurrency";

export default class CustomerEmployeeStatisticsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            tabIndex: 0,
            avarageRows: [],
            avarageColumns: [],
            isLoading: true,
            stats: [],
        };
    }

    processData = data => {
        this.setState({isLoading: true})
        let avgIncome = null
        let stats = []
        let avarageRows = []
        const avarageColumns=[
            {field: 'employee', headerName: 'Alkalmazott', flex: 1},
            {field: 'income', headerName: 'Generált bevétel (Ft)', align: 'center', headerAlign: 'center', flex: 1, valueFormatter: (params) => formatNumber(params.value, 0)},
        ];

        if(data !== null){
            stats = data.map(i => {
                i.Alkalmazott = i.employeeName
                i["Bevétel"] = Math.round(i.income)
                return i
            })
            avarageRows= stats.map((s, i) => {return {id: i, employee: s.Alkalmazott, income: s["Bevétel"]}});
            avgIncome = stats.length > 0 ? Math.round(stats.map(item => item.income).reduce((a, b) => a + b, 0) / stats.length) : 0;
        }

        this.setState({stats, avgIncome, avarageColumns, avarageRows, isLoading: false})
    }

    barTooltip = (props) => {
        return (<>
            <BasicTooltip
                id={props.indexValue}
                value={formatCurrency(props.formattedValue, 0, "HUF")}
                color={props.color}
                enableChip
            />
        </>);
    };

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <TabList
                    value={this.state.tabIndex}
                    onChange={(event, newValue) => {
                        this.setState({tabIndex: newValue});
                    }}
                >
                    <Tab label="Összesített"/>
                    <Tab label="Személyenként"/>
                </TabList>

                <TabPanel value={this.state.tabIndex} index={0}>
                    <CustomerFinanceStatisticsTemplate url={`${BASE_URL}/api/stats/employees`}
                                                       onDataReceived={s => this.processData(s)}
                                                       highlightLabel="Átlag generált bevétel (Ft)"
                                                       highlightValue={this.state?.avgIncome}>
                        <Card title="Alkalmazotti teljesítmény">
                            <section style={{height: "500px"}}>
                                {(this.state.isLoading == true || this.state?.stats?.length > 0)
                                    ?<ResponsiveBar
                                        {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                        data={this.state.stats}
                                        colors={{ scheme: 'red_yellow_blue' }}
                                        colorBy="index"
                                        keys={["Bevétel"]}
                                        indexBy="Alkalmazott"
                                        labelSkipHeight={20}
                                        label={d => formatCurrency(d.value, 0,"HUF")}
                                        tooltip={point => this.barTooltip(point)}
                                    />
                                    :<NoData/>
                                }
                            </section>
                        </Card>

                        <Paper className="mt-16">
                            <DataGrid
                                columns={this.state.avarageColumns}
                                rows={this.state.avarageRows}
                                disableSelectionOnClick
                                disableColumnMenu
                                autoHeight
                                autoPageSize
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <GridToolbarContainer>
                                                <GridToolbarExport />
                                            </GridToolbarContainer>
                                        );
                                    },
                                    NoRowsOverlay: NoData,
                                }}/>
                        </Paper>
                    </CustomerFinanceStatisticsTemplate>
                </TabPanel>

                <TabPanel value={this.state.tabIndex} index={1}>
                   <CustomerEmployeeStatisticsTab />
                </TabPanel>
            </section>
            <Footer/>
        </section>;
    }
};
