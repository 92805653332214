import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    IconButton,
    MenuItem,
    Toolbar,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {DropzoneArea} from "material-ui-dropzone";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import { ntakDataSending } from "../../../util/NTAK";


export default class EditNtakShopConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: {
                ntakData: {
                    ntakDataSendingEnabled: false,
                    ntakTaxNumber: null,
                    ntakRegistrationNumber: null,
                    certFile: null,
                    keyFile: null,
                },
            },
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        axiosCommon.get(`${BASE_URL}/api/shops/${this.props.location.shopId}`)
            .then(response => this.setState({ shop: response.data}))
            .catch(error => this.setState({error}))
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {shop} = this.state;

        axiosCommon({
            method: "put",
            url: `${BASE_URL}/api/shops`,
            data: shop,
            params: undefined
        })
            .then(response => this.goBack())
            .catch(error => this.setState({error}))
            .finally(() => this.setState({isSubmitInProgress: false}))
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleNTAKDataChange = (event) => {
        const { ntakData } = this.state.shop;
        ntakData[event.target.name] = event.target.value;
        this.setState({ ntakData });
    };

    onSelectCertFile = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const { ntakData } = this.state.shop;
                ntakData.certFile = {
                    name: loadedFiles[0].name,
                    type: loadedFiles[0].type,
                    data: btoa(reader.result)
                }
                this.setState({ ntakData });
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteCertFile = () => {
        const { ntakData } = this.state.shop;
        ntakData.certFile = null
        this.setState({ ntakData });
    }

    onSelectKeyFile = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const { ntakData } = this.state.shop;
                ntakData.keyFile = {
                    name: loadedFiles[0].name,
                    type: loadedFiles[0].type,
                    data: btoa(reader.result)
                }
                this.setState({ ntakData });
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteKeyFile = () => {
        const { ntakData } = this.state.shop;
        ntakData.keyFile = null
        this.setState({ ntakData });
    }

    getFileObject = (file) => new File([], file.name, {type: file.type})

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.shopId ? "Üzlet szerkesztése" : "Új üzlet"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="NTAK adatszolgáltatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Adatküldés az NTAK-nak"
                                variant="outlined"
                                onChange={(event) => {
                                    const { ntakData } = this.state.shop;
                                    ntakData.ntakDataSendingEnabled = event.target.value === 'true';
                                    this.setState({ ntakData });
                                }}
                                name="ntakDataSendingEnabled"
                                fullWidth
                                value={this.state.shop.ntakData.ntakDataSendingEnabled}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='0' value='false'>Nincs</MenuItem>
                                {ntakDataSending === true
                                    ?<MenuItem key='1' value='true'>Igen</MenuItem>
                                    : undefined
                                }

                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="NTAK regisztációs szám"
                                variant="outlined"
                                onChange={this.handleNTAKDataChange}
                                name="ntakRegistrationNumber"
                                fullWidth
                                value={this.state.shop.ntakData.ntakRegistrationNumber || ""}
                                required={this.state.shop.ntakData.ntakDataSendingEnabled}
                                validators={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['required', 'matchRegexp:^[a-zA-Z]{2}\\d{8}$']
                                    : ['matchRegexp:^[a-zA-Z]{2}\\d{8}$']
                                }
                                errorMessages={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['A mező kitöltése kötelező', 'A mező 2 nagybetűvel kezdődik amelyet 8 szám követ. pl.: XY12345678']
                                    : ['A mező 2 nagybetűvel kezdődik amelyet 8 szám követ. pl.: XY12345678']
                                }
                            />
                        </div>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Adószám"
                                variant="outlined"
                                onChange={this.handleNTAKDataChange}
                                name="ntakTaxNumber"
                                fullWidth
                                value={this.state.shop.ntakData.ntakTaxNumber || ""}
                                required={this.state.shop.ntakData.ntakDataSendingEnabled}
                                validators={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['required', 'matchRegexp:^\\d{11}$']
                                    : ['matchRegexp:^\\d{11}$']
                                }
                                errorMessages={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['A mező kitöltése kötelező', 'A mezőnek 11 szám karakterből kell állnia pl.: 11223344122']
                                    : ['A mezőnek 11 szám karakterből kell állnia pl.: 11223344122']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <DropzoneArea
                                key={this.state.shop.id}
                                filesLimit={1}
                                acceptedFiles={['.cer']}
                                maxFileSize={5000}
                                showFileNames
                                initialFiles={this.state.shop.ntakData.certFile ? [this.getFileObject(this.state.shop.ntakData.certFile)] : undefined}
                                dropzoneText=".cer fájl feltöltése"
                                onChange={this.onSelectCertFile}
                                onDelete={this.onDeleteCertFile}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <DropzoneArea
                                filesLimit={1}
                                key={this.state.shop.id}
                                acceptedFiles={['.key']}
                                maxFileSize={5000}
                                showFileNames
                                initialFiles={this.state.shop.ntakData.keyFile ? [this.getFileObject(this.state.shop.ntakData.keyFile)] : undefined}
                                dropzoneText=".key fájl feltöltése"
                                onChange={this.onSelectKeyFile}
                                onDelete={this.onDeleteKeyFile}
                            />
                        </div>
                    </Widget>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
