import React from "react";
import "../../../css/Pages.css"
import InventoryChangeList from "./InventoryChangeList";

export default function CustomerInventoryChargePage(props) {
    return <InventoryChangeList {...props}
            kind="CHARGE"
            pageKey="inventoryChargePagePageKey"
            rowsPerPageKey="inventoryChargePageRowsPerPageKey"
            keywordKey="inventoryChargePageKeywordKey"
            fromDateKey="inventoryChargePageFromDateKey"
            toDateKey="inventoryChargePageToDateKey"
            selectedShopsKey="inventoryChargePageSelectedShopsKey"
    />;
};
