import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

function DeleteDialog(props){
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.children}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onReject} color="primary">
                Mégsem
            </Button>
            <Button onClick={props.onConfirm} color="primary" autoFocus>
                Igen
            </Button>
        </DialogActions>
    </Dialog>;
}

export default DeleteDialog;
