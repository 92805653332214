// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import axios from "axios";
import {BASE_URL, FRONTEND_URL} from "./Urls";

const axiosCommon = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const axiosRefresh = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

function log(message, obj) {
    if(sessionStorage.getItem("log") === "frombahu"){
        console.log(message, obj || "" )
    }
}

function setUpTokens(body) {
    sessionStorage.setItem("accessToken", body.authToken);
    sessionStorage.setItem("refreshToken", body.refreshToken);
    const token = jwt_decode(body.authToken);

    if (token.authorities.includes("ROLE_ADMIN")) {
        if(token.sub === "balazs.froman@gmail.com" || token.sub === "b.brigi2013@gmail.com"){
            sessionStorage.setItem('extended_role', 'ROLE_SUPER_ADMIN');
        }
        sessionStorage.setItem('role', 'ROLE_ADMIN');
    } else if (token.authorities.includes("ROLE_CUSTOMER")) {
        sessionStorage.setItem('role', 'ROLE_CUSTOMER');
    } else if (token.authorities.includes("ROLE_EMPLOYEE")) {
        sessionStorage.setItem('role', 'ROLE_EMPLOYEE');
    }
}

function setUpPermissions(body, profile) {
    sessionStorage.setItem('permissions', profile.employeeJobTitle != null ? profile.employeeJobTitle.permissions : "")
}

function doLogin(username, password, onSuccess, onFailure) {
    const loginFormData = {
        username,
        password
    };
    return axiosRefresh.post(`${BASE_URL}/authenticate`, loginFormData)
        .then(response => {
            log("Successful login");
            setUpTokens(response.data);
            axiosCommon.get(`${BASE_URL}/api/users/me`)
                .then(profileResponse => {
                    setUpPermissions(response.data, profileResponse.data)
                    if (onSuccess)
                        onSuccess();
                })
                .catch(error => {
                    log("Getting profile information failed", error)
                    if (onFailure)
                        onFailure();
                })
        })
        .catch(error => {
            log("Login failed", error);
            if (onFailure)
                onFailure();
        })
}

const doLogout = () => {
    sessionStorage.clear();
};

function doRefresh(refreshToken) {
    const loginFormData = {
        refreshToken
    };
    return axiosRefresh.post(`${BASE_URL}/authenticate/renew`, loginFormData)
        .then(response => {
            log("Successful token renewal");
            setUpTokens(response.data);
        })
        .catch(error => {
            log("Token renewal failed", error);
            doLogout();
            window.location.href = FRONTEND_URL;
        })
}

function initializeNetwork() {
    axiosCommon.interceptors.request.use(request => {
        const accessToken = sessionStorage.getItem("accessToken");
        if (accessToken)
            request.headers.Authorization = `Bearer ${accessToken}`;
        log('Starting Request', request);
        return request;
    });

    axiosCommon.interceptors.response.use(
        response => {
            log('Response:', response);
            return response;
        },
        error => {
            if (axios.isCancel(error)) return Promise.reject(error);

            if (error.response.status === 401) {
                log("Token expired");
                const refreshToken = sessionStorage.getItem("refreshToken");
                if (refreshToken) {
                    return doRefresh(refreshToken).then(() => {
                        return axiosCommon.request(error.config);
                    })
                }
            }
            log('Error: ', error.response);
            return Promise.reject(error);
        });

    axiosRefresh.interceptors.request.use(request => {
        log('Getting access token', request);
        return request;
    });

    axiosRefresh.interceptors.response.use(response => {
        log('Access token received:', response);
        return response;
    });
}

function impersonate(email, name, onSuccess) {
    axiosCommon.get(`${BASE_URL}/authenticate/impersonate`,
        {params: {username: email}})
        .then(response => {
            sessionStorage.setItem("impersonating", name)
            sessionStorage.setItem("backupRefreshToken", sessionStorage.getItem("refreshToken"))
            setUpTokens(response.data);
            axiosCommon.get(`${BASE_URL}/api/users/me`)
                .then(profileResponse => {
                    setUpPermissions(response.data, profileResponse.data)
                    if (onSuccess)
                        onSuccess();
                })
                .catch(error => {
                    log("Getting profile information failed", error)
                })
        })
}

function exitImpersonate(onSuccess) {
    doRefresh(sessionStorage.getItem("backupRefreshToken"))
        .then(() => {
            if (onSuccess) {
                onSuccess()
            }
        })
}

export {axiosCommon, axiosRefresh, initializeNetwork, doLogin, doRefresh, doLogout, setUpTokens, impersonate, exitImpersonate}
