import React, {useEffect, useState} from "react";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Divider, Toolbar, Typography} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Youtube from "../../../components/Youtube";
import Widget from "../../../components/Widget";
import Card from "../../../components/Card";
import Search from "../../../components/Search";
import TerminalDescription from "../../../util/TerminalDescription";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        maxWidth: 1400,
        margin: "auto",
        marginBottom: 8,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            marginBottom: 16,
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 70,
        '&$expanded': {
            minHeight: 70,
        },
    },
    content: {
        fontSize: "1.2rem",
        color: "black",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        fontSize: "1.1rem",
        textAlign: "justify",
        alignItems: "center",
        marginLeft: 8,
        marginRight: 8,
        '& img': {
            maxWidth: 650,
            marginTop: 30,
            marginBottom: 30,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    },
}))(MuiAccordionDetails);

export default function TerminalHelpPage (props) {
    const [videos] = useState(TerminalDescription);
    const [searchedVideos, setSearchedVideos] = useState(TerminalDescription);
    const [keyword, setKeywordSearch] = useState('');

    useEffect(() => {
        if(keyword == ''){
            setSearchedVideos(videos);
        }else{
            setSearchedVideos(videos.filter((object) => object.name.toLowerCase().includes(keyword.toLowerCase())));
        }
    }, [keyword]);

    const setKeyword = (event) =>{
        setKeywordSearch(event.target.value);
    }

    const renderList = () => {
        return <>
            <Widget>
                <Card title="Keresés a tudásbázisban">
                    <Search
                        setKeyword={setKeyword}
                    />
                </Card>
                <div style={{flex: 1}}/>
            </Widget>
            <Toolbar className="align-center justify-space-between mb-16">
                <Typography variant="h5" id="tableTitle" component="div" >
                    Tudásbázis - Terminál
                </Typography>
            </Toolbar>
            {searchedVideos.map((video, i) => {
                return <Accordion key={i}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {video.name}
                    </AccordionSummary>
                    <AccordionDetails>
                        {video.link != ""
                            ?<>
                                <Youtube videoid={video.link} style={{marginTop: 24, marginBottom: 40}}/>
                                <Divider style={{width: "100%", marginBottom: 24}}/>
                            </>
                            :""
                        }
                        {video.text}
                    </AccordionDetails>
                </Accordion>
            })}
        </>
    }

    return(
        <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={props.changeSidebarToggled}/>
                    {renderList()}
                </section>

                <Footer/>
            </section>
        </>
    );
}
