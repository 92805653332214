import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import {
    Button, Checkbox, FormControlLabel,
    IconButton,
    TextField,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Widget from "../../../components/Widget";
import NavHeader from "../../../components/NavHeader";
import ClearIcon from "@material-ui/icons/Clear";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export default class EditTopping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topping: {
                id: null,
                name: null,
                description: null,
                embedded: false,
                ignorePrice: false,
                products: [],
            },
            toppingProducts: [],
            products: [],
            productOptions: [],
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        if (this.props.location.entryId) {
            axiosCommon.get(`${BASE_URL}/api/toppings/${this.props.location.entryId}`)
                .then(response => {
                    const topping = response.data
                    const toppingProducts = response.data.products.map(p => {
                        return {label: p.name, value: p.id}
                    })
                    this.setState({ topping, toppingProducts})
                })
                .catch(error => {
                    this.setState({error});
                });
        }

        axiosCommon.get(`${BASE_URL}/api/products?deleted=false`)
        .then(response => {
            const productOptions = response.data.items.map(p => {
                return {label: p.name, value: p.id}
            })
            this.setState({ products: response.data.items, productOptions })
        }).catch(error => this.setState({error}))
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {topping} = this.state;
        topping.products = this.state.toppingProducts.map(e => {
            return e.value
        })

        axiosCommon({
            method: this.props.location.entryId ? "put" : "post",
            url: `${BASE_URL}/api/toppings`,
            data: topping,
        })
            .then(response => {
                this.setState({ topping: response.data, });
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    handleChange = (event) => {
        const { topping } = this.state;
        topping[event.target.name] = event.target.value;
        this.setState({ topping });
    }

    productForId = (id) => {
        return this.state.products.filter(p => p.id == id)[0];
    }

    remainingProductOptions = () => {
        return this.state.productOptions.filter(p => !this.state.toppingProducts.find(i => i.value == p.value))
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.entryId ? "Feltét csoport szerkesztése" : "Új feltét csoport"}
                </Typography>
                <IconButton onClick={() => this.props.history.goBack()}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.topping.name || ""}
                                required
                                inputProps={{ maxLength: 70 }}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="flex-1"/>
                    </Widget>
                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.topping.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>
                <div className="m-8"/>

                <Card title="Feltét tulajdonságok">
                    <div style={{display: "flex", alignItems: 'center',}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.topping.embedded}
                                    onChange={(event) => {
                                        const { topping } = this.state
                                        topping.embedded = event.target.checked
                                        topping.ignorePrice = false
                                        this.setState({ topping })
                                    }}
                                    color="primary"
                                    name="embedded" />}
                            label="A feltétek épüljenek be az alap termékbe"
                        />
                        <Tooltip title="Amennyiben be van pipálva úgy a feltét tételei nem fognak a blokkon / számlán szerepelni.
                            Ilyen esetben a feltétek ára beépül az alap termék árába és az alap termék ÁFA kulcsával fog számolódni.">
                            <InfoIcon style={{color: "gray"}}/>
                        </Tooltip>
                    </div>
                    { this.state.topping.embedded === true
                        ?<div style={{display: "flex", alignItems: 'center',}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.topping.ignorePrice}
                                        onChange={(event) => {
                                            const { topping } = this.state
                                            topping.ignorePrice = event.target.checked
                                            this.setState({ topping })
                                        }}
                                        color="primary"
                                        name="ignorePrice" />}
                                label="A feltétek ára ne növelje meg az alaptermék árát"
                            />
                            <Tooltip title="Amennyiben be van pipálva úgy a feltét termékek ára nem adódik hozzá az alaptermék árához,
                                így az alaptermék ára mindig marad.">
                                <InfoIcon style={{color: "gray"}}/>
                            </Tooltip>
                        </div>
                        : undefined
                    }
                </Card>
                <div className="m-8"/>

                <Card title="Feltétek">
                    {this.state.toppingProducts.map((e, i) =>
                        <Widget key={i}>
                            <div className="flex-1">
                                <Autocomplete
                                    onChange={(event, value) => {
                                        const { toppingProducts } = this.state;
                                        toppingProducts[i] = value
                                        this.setState({ toppingProducts })
                                    }}
                                    fullWidth
                                    disableClearable
                                    noOptionsText="Nincs ilyen tétel"
                                    options={this.remainingProductOptions()}
                                    getOptionLabel={(option) => option === "" ? "" : option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={{ label: e.label, value: e.value }}
                                    renderInput={(params) =>
                                        <TextField {...params} label={`Feltét termék ${i + 1}`} variant="outlined" />
                                    }
                                />

                            </div>
                            <div className="m-8" />
                            <IconButton onClick={() => {
                                const { toppingProducts } = this.state;
                                toppingProducts.splice(i, 1);
                                this.setState({ toppingProducts })
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Widget>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        disabled={this.remainingProductOptions().length <= 0}
                        onClick={() => {
                            const { toppingProducts } = this.state;
                            toppingProducts.push(this.remainingProductOptions()[0])
                            this.setState({ toppingProducts })
                        }}>
                        Új feltét
                    </Button>
                </Card>

                <div style={{ marginTop: 16}}/>
                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderEdit()}
                </section>

                <Footer/>
            </section>;
    }
}
