import React from "react";
import "../../../css/Pages.css"
import Footer from "../../../components/Footer";
import {BASE_URL} from "../../../util/Urls";
import CustomerFinanceStatisticsTemplate from "./CustomerFinanceStatisticsTemplate";
import {ResponsivePie} from "@nivo/pie";
import NavHeader from "../../../components/NavHeader";
import Card from "../../../components/Card";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";

export default class CustomerCartStatisticsPage extends React.Component {

    processData = data => {
        const allCategoryStats = data.categoryPercents.map(i => {
            return {
                id: i.categoryName,
                label: i.categoryDeleted ? `${i.categoryName  } (Törölt)` : i.categoryName,
                value: Math.round(i.avgPercent)
            }
        }).sort((a, b) => b.value - a.value)

        const categoryStats = allCategoryStats.slice(0, 20)
        const minorCategories = allCategoryStats.slice(20).map(c => c.value).reduce((a, b) => a + b, 0);
        if (minorCategories > 0) {
            categoryStats.push({
                id: "Egyéb",
                label: "Egyéb",
                value: minorCategories
            })
        }

        this.setState({
            stats: data,
            categoryStats
        })
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                <CustomerFinanceStatisticsTemplate url={`${BASE_URL}/api/stats/carts`}
                                                   onDataReceived={s => this.processData(s)}>

                        {this.state?.stats.count > 0
                            ?<Card title="Átlagos kosárösszetétel">
                                <section style={{height: "500px"}}>
                                    <ResponsivePie
                                        {...CustomerFinanceStatisticsTemplate.COMMON_PROPS}
                                        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
                                        data={this.state.categoryStats}
                                        padAngle={0.7}
                                        sliceLabel={d => `${d.value} %`}
                                        innerRadius={0.5}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        colors={{ scheme: 'red_yellow_blue' }}
                                        radialLabelsSkipAngle={5}
                                        radialLabelsTextXOffset={5}
                                        radialLabelsTextColor="#000"
                                        radialLabelsLinkOffset={5}
                                        radialLabelsLinkDiagonalLength={16}
                                        radialLabelsLinkHorizontalLength={24}
                                        radialLabelsLinkStrokeWidth={2}
                                        radialLabelsLinkColor={{from: 'color'}}
                                        sliceLabelsSkipAngle={5}
                                        sliceLabelsTextColor="#000"
                                        legends={[]}
                                    />
                                </section>
                            </Card>
                            : ""
                        }

                        {this.state?.stats.count > 0
                            ?<Paper className="mt-16">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Kosárszám</TableCell>
                                                <TableCell align="center">Összbevétel</TableCell>
                                                <TableCell align="center">Átlagos kosárérték</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             <TableRow hover>
                                                <TableCell>{this.state.stats.count}</TableCell>
                                                <TableCell align="center">{this.state.stats.income} Ft</TableCell>
                                                <TableCell align="center">{this.state.stats.avgIncome} Ft</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                            : ""
                        }

                    {!this.state?.stats.count > 0 ?
                        <section>
                            Nem található a szűrőknek megfelelő eladás.
                        </section>
                        : ""}

                </CustomerFinanceStatisticsTemplate>

            </section>
            <Footer/>
        </section>;
    }
};
