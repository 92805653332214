import React from "react";
import {withRouter} from "react-router";
import {axiosCommon, doLogout, exitImpersonate, impersonate} from "../util/axiosSetup";
import AdminMenuConstants from "../util/AdminMenuConstants";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter, SidebarHeader, SidebarContent, } from 'react-pro-sidebar';
import '../css/ProSideBar.css';
import { MdMenu } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import {BASE_URL} from "../util/Urls";
import CustomerMenuConstants from "../util/CustomerMenuConstants";
import { Typography} from "@material-ui/core";
import VERSION from "../util/Version";
import { LAYOUT_TYPE } from "../util/Config";

class ProSideBarNav extends React.Component {
    state = {
        activeItem: AdminMenuConstants.defaultItem,
        collapsed: false,
        refresh: false,
        dropdownOpen: [],
        user: null,
    };

    COLLAPSED_STATE = "collapsed_state";

    logout = () => {
        doLogout();
        this.props.history.push("/");
    };

    getMenuConstants = () => {
        if(sessionStorage.getItem("role") === "ROLE_ADMIN") return AdminMenuConstants
        return CustomerMenuConstants
    }

    componentDidMount() {
        const menu = this.getMenuConstants();
        const item = this.findItemByTitle(sessionStorage.getItem(menu.ACTIVE_MENU_KEY));

        const {dropdownOpen} = this.state;
        Object.values(menu.menuItems).forEach(menuItem => {
            const value = sessionStorage.getItem(menuItem.title);
            if(!value){
                sessionStorage.setItem(menuItem.title, "false");
                dropdownOpen[menuItem.title] = false;
            }else {
                dropdownOpen[menuItem.title] = (value == 'true');
            }
        });

        const collapsed = (sessionStorage.getItem(this.COLLAPSED_STATE) == 'true');

        axiosCommon.get(`${BASE_URL}/api/users/me`)
            .then(response => {
                this.setState({
                        user: response.data
                    }
                )
            })
            .catch(error => {
                this.setState({error});
            });

        this.setState({dropdownOpen, collapsed});
        this.setActiveItem(item);
    }

    findItemByTitle = (title) => {
        let found = false;
        const menu = this.getMenuConstants();
        let item = menu.defaultItem;

        Object.values(menu.menuItems).forEach(menuItem =>{
            if(!found){
                if(menuItem.subMenus){
                    Object.values(menu.menuItems[menuItem.title].subMenus).forEach(subMenuItem => {
                        if(subMenuItem.title === title){
                            item = subMenuItem;
                            found = true;
                        }
                    })
                }
                if(!found && menuItem.title === title){
                    item = menuItem;
                    found = true;
                }
            }
        })
        return item;
    }

    setActiveItem = (item) => {
        const menu = this.getMenuConstants();

        sessionStorage.setItem(menu.ACTIVE_MENU_KEY, item.title);
        this.setState({
            activeItem: item,
        });
        this.props.history.push(item.url);
        this.changeToggle();
    };

    toggleCollapsed = () => {
        let {collapsed} = this.state;
        collapsed = !collapsed;
        sessionStorage.setItem(this.COLLAPSED_STATE, collapsed.toString());
        this.setState({
            collapsed
        });
    }

    renderSidebarHeader = () => {
        return <>
            <SidebarHeader>
                <section className="sidebar-header">
                    <label className="sidebar-header-label" style={{textTransform: 'uppercase', color: "var(--color-primary)"}}>
                        {LAYOUT_TYPE}
                    </label>
                    <div className="icon f-30 mr-20" title="Oldalsáv váltás">
                        <MdMenu onClick={this.toggleCollapsed} />
                    </div>
                </section>
            </SidebarHeader>
            <SidebarHeader>
                <section className="sidebar-header-userinfo">
                    <div>
                        <img src="/img/user_image.jpeg" alt="user_image"/>
                    </div>
                    {sessionStorage.getItem("impersonating")
                        ? <div>
                            <label style={{fontSize: 16}}>{sessionStorage.getItem("impersonating")}</label>
                            <label><FaCircle style={{color: "red", width: 8}} /> Impersonation</label>
                            <label
                               style={{color: "var(--color-primary)", cursor: "pointer"}}
                               onClick={() => {
                                   exitImpersonate(() => {
                                       this.props.history.push("/admin/people/impersonation");
                                   });
                                   sessionStorage.setItem("impersonating", "");
                               }}>Kilépés</label>
                        </div>
                        : <div>
                            <label style={{fontSize: 16}}>{this.state.user ? `${this.state.user.lastName} ${this.state.user.firstName}` : ""}</label>
                            <label>{this.state.user ? this.state.user.email : ""}</label>
                            <label><FaCircle style={{color: "forestgreen", width: 8}} /> Online</label>
                        </div>
                    }

                </section>
                <div className='icon f-26 mr-20 text-align-right' onClick={this.logout} title="Kilépés">
                    <RiLogoutBoxRLine alt="Kijelentkezés"  />
                </div>
            </SidebarHeader>
        </>;
    }

    renderSidebarMenuItems = () => {
        const menu = this.getMenuConstants();

        return <SidebarContent>
            <Menu iconShape="circle">
                {Object.values(menu.menuItems)
                    .filter(i => menu.isUserAuthorizedFor(i, this.state.user?.profile))
                    .map(menuItem => {
                        if(menuItem.hidden)
                            return "";

                        if(menuItem.subMenus){
                            return <SubMenu
                                key={menuItem.title}
                                title={menuItem.title}
                                open={this.state.dropdownOpen[menuItem.title]}
                                onOpenChange={(open) => {
                                    sessionStorage.setItem(menuItem.title, (!this.state.dropdownOpen[menuItem.title]).toString());
                                    const {dropdownOpen} = this.state;
                                    dropdownOpen[menuItem.title] = !dropdownOpen[menuItem.title];
                                    this.setState({dropdownOpen});
                                }}
                                icon={menuItem.img}>
                                {Object.values(menu.menuItems[menuItem.title].subMenus)
                                    .filter(i => menu.isUserAuthorizedFor(i, this.state.user?.profile))
                                    .map(subMenu => {
                                        if(subMenu.hidden)
                                            return "";

                                        return <MenuItem
                                            key={subMenu.title}
                                            active={subMenu.title === this.state.activeItem.title}
                                            onClick={() => this.setActiveItem(subMenu)}>{subMenu.title}
                                        </MenuItem>;
                                    }
                                )}
                            </SubMenu>
                        }

                        return <MenuItem
                            key={menuItem.title}
                            icon={menuItem.img}
                            active={menuItem.title === this.state.activeItem.title}
                            onClick={() => this.setActiveItem(menuItem)}>{menuItem.title}
                        </MenuItem>;
                    }
                )}
            </Menu>
        </SidebarContent>;
    }

    renderSidebarFooter = () => {
        return <>
            <SidebarFooter>
                <section>
                    <Typography variant="body2" align="center">Version: {VERSION}</Typography>
                </section>
            </SidebarFooter>
        </>;
    }

    changeToggle = () => {
        this.props.changeSidebarToggled()
    }

    render() {
        return <ProSidebar
            collapsed={this.state.collapsed}
            breakPoint="md"
            toggled={this.props.toggled}
            onToggle={this.changeToggle}>
            {this.renderSidebarHeader()}
            {this.renderSidebarMenuItems()}
            {this.renderSidebarFooter()}
        </ProSidebar>;
    }
}

export default withRouter(ProSideBarNav);
