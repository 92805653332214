import React from "react";
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

function Search(props){
    return (
        <TextField
            label="Keresés..."
            variant="outlined"
            fullWidth
            onChange={props.setKeyword}
            value={props.value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={props.refreshData}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

export default Search;
