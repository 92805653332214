import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import truncateString from "../../../util/truncateString";

export default class ShopListPage extends React.Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            shops: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            isAdmin: false,
            showVerificationDialog: false,
            ntakVerificationResult: null,
            ntakVerificationInProgress: false,
        }
    }

    componentDidMount() {
        this.setState({isAdmin: sessionStorage.getItem("impersonating") != null})
        this.refreshData()
    }

    refreshData = () => {
        axiosCommon(`${BASE_URL}/api/shops?keyword=${this.state.keyword}`)
            .then(response => {
                this.setState({
                    shops: response.data.items,
                })
            })
            .catch(error => {
                this.setState({error})
            })
    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/shops/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({
                    error
                })
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/customer/shops/editshop",
            shopId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/customer/shops/editshop",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd az üzletet?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek! Az üzlet törlésénél
            az üzlet összes adata törlődni fog.(pl.: vásárlások, törzsvásárlók stb.)
        </DeleteDialog>;
    };

    onCloseVerificationDialog = () => this.setState({showVerificationDialog: false})

    renderVerificationDialog = () => {
        return <Dialog
            maxWidth='sm'
            fullWidth
            open={this.state.showVerificationDialog}
            onClose={this.onCloseVerificationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">NTAK adatok ellenőrzése</DialogTitle>
            <DialogContent>
                <Typography>{this.state.ntakVerificationResult}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseVerificationDialog} color="primary">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés a névben / leírásban">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Üzletek
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="15%">Név</TableCell>
                                    <TableCell>Cím</TableCell>
                                    <TableCell width="25%">Leírás</TableCell>
                                    <TableCell>Állapot</TableCell>
                                    <TableCell align="center">NTAK adatküldés</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.shops
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((s, i) => {
                                            return <TableRow key={s.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(s.id)}>
                                                <TableCell>{s.name}</TableCell>
                                                <TableCell>{`${s.address.zipCode} ${s.address.city} ${s.address.street}`}</TableCell>
                                                <TableCell className="break-line">{truncateString(s.description, 127)}</TableCell>
                                                <TableCell>{s.enabled ? "Aktív" : "Szünetel"}</TableCell>
                                                <TableCell align="center">{s.ntakData.ntakDataSendingEnabled === true ? "Igen" : "-"}</TableCell>
                                                <TableCell padding="none" align="right">
                                                    {this.state.isAdmin
                                                      ?<IconButton aria-label="delete" onClick={() => this.initiateDeletion(s)}>
                                                          <DeleteIcon />
                                                      </IconButton>
                                                      : undefined
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.shops.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
                {this.renderVerificationDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            {this.state.isAdmin
              ?<NewItemButton
                title="Üzlet"
                onClick={this.initiateCreate}/>
              : undefined
            }
        </>;
    }
}
