import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import Permissions from "../../../util/Permissions";
import InfoIcon from '@material-ui/icons/InfoOutlined';


export default class EditRole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role: {
                name: null,
                permissions: [],
            },
            isSubmitInProgress: false,
            permissions: [],
        };
    }

    componentDidMount() {
        if (this.props.location.roleId) {
            axiosCommon.get(`${BASE_URL}/api/jobtitles/${this.props.location.roleId}`)
                .then(response => {
                    const {role, permissions} = this.state;
                    role.id = response.data.id;
                    role.name = response.data.name;
                    response.data.permissions.forEach(p => permissions[p] = true)
                    this.setState({role, permissions});
                })
                .catch(error => {
                    this.setState({error});
                });
        }
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {role} = this.state;
        Object.values(Permissions).forEach(perm => {
            if (this.state.permissions[perm.value]) {
                role.permissions.push(perm.value)
            }
        })

        axiosCommon({
            method: this.props.location.roleId ? "put" : "post",
            url: `${BASE_URL}/api/jobtitles`,
            data: role
        })
            .then(response => {
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = (event) => {
        const { role } = this.state;
        role[event.target.name] = event.target.value;
        this.setState({ role });
    }

    renderEdit =() => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.roleId ? "Szerepkör szerkesztése" : "Új szerepkör"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.role.name || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>
                <div style={{ marginTop: 16}}/>

                <Card title="Hozzáférés">
                    <FormControl component="fieldset">
                        <FormGroup>
                            {Object.values(Permissions).map((perm, i) =>
                                <div key={i} style={{display: "flex", alignItems: 'center',}}>
                                    <div style={{paddingRight: 10}}>
                                        <Tooltip title={<p style={{ fontSize: "14px",}}>{perm.tooltip}</p>} aria-label={perm.tooltip}>
                                            <InfoIcon style={{color: "gray"}}/>
                                        </Tooltip>
                                    </div>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.permissions[perm.value] || false}
                                                onChange={(event) => {
                                                    const { permissions } = this.state;
                                                    permissions[perm.value] = event.target.checked;
                                                    this.setState({ permissions });
                                                }}
                                                color="primary"
                                                name={perm.value} />}
                                        label={perm.label}
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </FormControl>
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
