import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";

export default class AdminListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            keyword: "",
            admins: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
        }
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        axiosCommon(`${BASE_URL}/api/users?deleted=false&role=ADMIN&keyword=${this.state.keyword}`)
            .then(response => {
                this.setState({
                    admins: response.data.items,
                })
            })
            .catch(error => {
                this.setState({
                    error
                })
            })
    };

    setKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        }, () => this.refreshData())
    };

    initiateDeletion = (user) => {
        this.setState({
            deleteInProgress: user.id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/users/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({error})
            });
        this.setState({
            deleteInProgress: null
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/admin/people/editadmins",
            userId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/admin/people/editadmins",
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd az Admint?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés a névben">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Adminok
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>

                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell>Cím</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Tel. szám</TableCell>
                                    <TableCell>Állapot</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.admins
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((a, i) => {
                                        return <TableRow key={a.id} hover className="cursor-pointer" onDoubleClick={() => this.initiateEdit(a.id)}>
                                            <TableCell>{`${a.lastName} ${a.firstName}`}</TableCell>
                                            <TableCell>{`${a.address.zipCode} ${a.address.city} ${a.address.street}`}</TableCell>
                                            <TableCell>{a.email}</TableCell>
                                            <TableCell>{a.phone}</TableCell>
                                            <TableCell>{a.enabled ? "Aktív" : "Szünetel"}</TableCell>
                                            <TableCell padding="none" align="right">
                                                <IconButton aria-label="delete" onClick={() => this.initiateDeletion(a)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        }
                                    )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            labelRowsPerPage="Elemek az oldalon:"
                            rowsPerPageOptions={RowsPerPageOptions.items}
                            component="div"
                            count={this.state.admins.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                    <section className="main-content-body">
                        <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>

                        {this.renderList()}
                    </section>

                    <Footer/>
            </section>
            <NewItemButton
                title="Admin"
                onClick={this.initiateCreate}/>
        </>;
    }
}
