import React from 'react';
import {IconButton} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { LAYOUT_TYPE } from "../util/Config";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: "#1d1d1d",
        marginTop: -10,
        marginRight: -10,
        marginLeft: -10,
        marginBottom: 15,
        color: "white",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            display: "visible",
        },
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
    },
    label: {
        color: "#adadad",
        marginLeft: 10,
        fontSize: "1.9rem",
    },
    icon: {
        color: "#adadad",
        fontSize: 30,
    }
}));

function NavHeader(props){
    const classes = useStyles();

    return <section className={classes.root}>
        <label className={classes.label} style={{textTransform: 'uppercase', color: "var(--color-primary)"}}>{LAYOUT_TYPE}</label>
        <IconButton onClick={() => props.changeSidebarToggled()}>
            <MenuIcon className={classes.icon}/>
        </IconButton>
    </section>;
}

export default NavHeader;
